import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Portal, Spacer, Stack, SubHeader } from '@bt-healthcare/ui-toolkit';
import * as Sentry from '@sentry/react';

import { ContactTheTeam } from '../../components/Modal/ContactTheTeam';
import { encodeMailtoURL } from '../../formatters/mailto';
import { MailTo } from '../../formatters/types';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';

import { LinkCard } from '../../components/Cards/LinkCard';
import { LinkCardTitle } from '../../components/Cards/LinkCard/styles';
import {
  RequestCardButton,
  StyledRequestCard,
} from '../../components/AccountDetailsSection/styles';
import { support } from '../../components/AccountDetailsSection/intl';

export const accountMailto: { [key: string]: MailTo } = {
  help: {
    recipient: 'support.healthyyou@bt.com',
    body: '• Care Setting: {careSetting}\n• Support ID: {sentryId}\n• What would you like support with? :',
    subject: 'HealthyYou technical support',
  },
  feedback: {
    recipient: 'feedback.healthyyou@bt.com',
    subject: 'HealthyYou feedback',
    body: '• Care Setting: {careSetting}\n',
  },
};
export const SupportSection = () => {
  const { userProfile: data } = useAuthUserProfile();
  const [modalOpen, setModalOpen] = useState(false);

  const isStandalonePatient = !!data?.user?.attributes.isStandalonePatient;
  const careSettingName = data?.careSetting.attributes.name ?? 'Not known';

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const feedbackMail = useCallback(() => {
    const id = Sentry.captureMessage('Support Email', { level: 'debug' });
    window.open(
      encodeMailtoURL({
        ...accountMailto.help,
        ...(accountMailto?.help?.body && {
          body: accountMailto.help.body
            .replace('{careSetting}', careSettingName)
            .replace('{sentryId}', id),
        }),
      })
    );
  }, [careSettingName]);

  return (
    <Stack space="s2" id="support">
      <SubHeader>
        <FormattedMessage {...support.title} />
      </SubHeader>
      <Spacer size="s2" />
      {!isStandalonePatient && (
        <>
          <Portal>
            <ContactTheTeam modalOpen={modalOpen} handleClose={closeModal} />
          </Portal>
          <RequestCardButton onClick={openModal}>
            <StyledRequestCard
              cardTitle={
                // eslint-disable-next-line react/jsx-no-undef
                <LinkCardTitle color="grey.grey10">
                  <FormattedMessage {...support.contact} />
                </LinkCardTitle>
              }
              id="contact-mail"
            />
          </RequestCardButton>
        </>
      )}
      <LinkCard
        title={<FormattedMessage {...support.help} />}
        onClick={feedbackMail}
        path=""
        dataTestId="help-mail"
        isTargetSelf={false}
        colorTitle="grey.grey10"
      />
      <LinkCard
        title={<FormattedMessage {...support.feedback} />}
        path={encodeMailtoURL({
          ...accountMailto.feedback,
          body: accountMailto.feedback.body?.replace(
            '{careSetting}',
            careSettingName
          ),
        })}
        dataTestId="feedback-mail"
        isTargetSelf={false}
        colorTitle="grey.grey10"
      />
    </Stack>
  );
};
