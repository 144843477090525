import {
  Stack,
  Spacer,
  colors,
  fontSizes,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { collateralLandingHeader } from './intl';
import { HeaderTitle, GradientText } from './styles';

export const CollateralLandingHeader = () => (
  <Stack id="collateral-landing-header">
    <Spacer size="l4" />
    <HeaderTitle>
      <FormattedMessage {...collateralLandingHeader.title} />
      <br />
      <GradientText>
        <FormattedMessage {...collateralLandingHeader.titleSpan} />
      </GradientText>
    </HeaderTitle>
    <Spacer size="s4" />
    <Text
      color={colors.grey.grey09}
      fontSize={fontSizes.base}
      style={{
        textAlign: 'center',
      }}
    >
      <FormattedMessage {...collateralLandingHeader.subTitle} />
    </Text>
  </Stack>
);
