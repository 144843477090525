import { Spacer, Stack, SubHeader } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { settings } from './intl';
import { LinkCard } from '../../components/Cards/LinkCard';
import { ROUTE } from '../../config/routes';
import { useTracking } from '../../hooks/useTracking';

export const SettingsSection = () => {
  const { trackEvent } = useTracking();

  return (
    <Stack id="account-settings-section" space="s2">
      <SubHeader>
        <FormattedMessage {...settings.title} />
      </SubHeader>
      <Spacer size="s2" />

      <LinkCard
        title={<FormattedMessage {...settings.notifications} />}
        path={ROUTE.ACCOUNT_CONTACT_PREFERENCES}
        dataTestId="notification-settings-link"
        colorTitle="grey.grey10"
        onClick={() => trackEvent('Notification', 'click')}
      />
    </Stack>
  );
};
