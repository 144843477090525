import { FormattedMessage } from 'react-intl';

import {
  Button,
  colors,
  fontSizes,
  Modal,
  ModalBody,
  Stack,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';

import { maxMobileWidth } from '../../utils';

import { ButtonWrapper, ButtonsWrapper } from './styles';
import { DeclineTermsProps } from './types';

export const DeclineTerms = ({
  modalOpen,
  handleClose,
  decline,
}: DeclineTermsProps) => (
  <Modal isModalOpen={modalOpen} onClose={handleClose} id="decline-terms-modal">
    <ModalBody>
      <Stack
        space="s4"
        id="confirmation"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Text
          as="h3"
          fontSize={fontSizes.h3}
          color={colors.grey.grey10}
          style={{
            display: 'block',
            textAlign: 'center',
          }}
        >
          <FormattedMessage
            id="modal.decline-terms-modal.header"
            defaultMessage="To use HealthyYou you must accept our terms and conditions"
          />
        </Text>
        <Text
          color={colors.grey.grey08}
          style={{
            display: 'block',
            textAlign: 'center',
            margin: ` 0 ${spacing.s2} ${spacing.s6}`,
          }}
        >
          <FormattedMessage
            id="modal.decline-terms-modal.message"
            defaultMessage="If you do not agree you can decline below and you will be logged out of HealthyYou."
          />
        </Text>
      </Stack>
      <Stack space="s4" id="ctas-and-warning-wrapper">
        <ButtonsWrapper maxMobileWidth={maxMobileWidth}>
          <ButtonWrapper>
            <Button variant="primary" onClick={handleClose} id="close">
              <FormattedMessage
                id="modal.decline-terms-modal.secondary.button"
                defaultMessage="Back to terms"
              />
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              variant="primaryRed"
              id="decline"
              autoFocus
              onClick={decline}
            >
              <FormattedMessage
                id="modal.decline-terms-modal.primary.button"
                defaultMessage="Decline"
              />
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </Stack>
    </ModalBody>
  </Modal>
);
