import * as yup from 'yup';
import { dateOfBirthSchema } from '../../components/RHFDateOfBirthInput/schema';

export const requireMessage = 'This is a required field.';

const nameRule = (msg: string) =>
  yup.string().max(50, msg).required(requireMessage);

export const validationSchema = yup.object().shape({
  surname: nameRule('Surname must be between 1-50 characters in length.'), // TODO: i18n
  ...dateOfBirthSchema,
});
