import styled from 'styled-components';

import { colors, RequestCard } from '@bt-healthcare/ui-toolkit';

export const RequestCardButton = styled.button`
  cursor: pointer;
  border: 0;
  margin: 0;
  padding: 0;
`;

export const StyledRequestCard = styled(RequestCard).attrs({
  icon: 'ChevronRight',
  iconColor: colors.grey.grey10,
  borderRadius: 8,
})``;
