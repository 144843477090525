import { is } from 'ramda';
import { IntlShape } from 'react-intl';
import {
  HealthInformation,
  HealthInformationInput,
  HeightUnit,
  YesNoType,
} from '../services/graphql';
import { Form, HealthInformationType } from '../pages/HealthInformation/types';
import {
  getFullFeetAndInchesFromInches,
  getInchesFromFeetAndInches,
} from '../formatters/inchFeet';
import {
  diabetesStatusOptions,
  familyHadCvdOptions,
  smokingStatusOptions,
} from '../pages/HealthInformation/intl';

const typeToField = {
  [HealthInformationType.BLOOD_PRESSURE_MEDICATION]:
    'bloodPressureMedicationInformation',
  [HealthInformationType.CARDIOVASCULAR_DISEASE]: 'cvdInformation',
  [HealthInformationType.CHRONIC_KIDNEY_DISEASE]: 'ckdInformation',
  [HealthInformationType.DIABETES]: 'diabetesInformation',
  [HealthInformationType.HEIGHT]: 'heightInformation',
  [HealthInformationType.SMOKING]: 'smokingInformation',
} as const;

const toYesNo = (value: boolean) => (value ? YesNoType.Yes : YesNoType.No);

export const toHealthInformationInfo = (
  type: HealthInformationType,
  latest?: Partial<HealthInformation>
) => latest?.[typeToField[type]];

export const heightFormToInput = ({
  cm,
  inch,
  ft,
}: {
  cm?: number;
  inch?: number;
  ft?: number;
}) => {
  const unit = is(Number, cm) ? HeightUnit.Cm : HeightUnit.Inch;
  const convertedInch =
    is(Number, ft) &&
    is(Number, inch) &&
    getInchesFromFeetAndInches({ inch, ft });
  const value = convertedInch || cm!;
  return { value, unit };
};

export const toHealthInformationInput = (
  values: Form,
  type: HealthInformationType
): Partial<HealthInformationInput | undefined> => {
  switch (type) {
    case HealthInformationType.SMOKING:
      return {
        smoking: {
          status: values?.smokingStatus?.value,
          created: null,
        },
      };
    case HealthInformationType.HEIGHT: {
      return {
        height: {
          ...heightFormToInput(values),
          created: null,
        },
      };
    }
    case HealthInformationType.BLOOD_PRESSURE_MEDICATION: {
      const { hasBPMedication, hadBPMedication } = values;
      return {
        bloodPressureMedication: {
          hadBPMedication: hadBPMedication === YesNoType.Yes,
          hasBPMedication: hasBPMedication === YesNoType.Yes,
          created: null,
        },
      };
    }
    case HealthInformationType.CHRONIC_KIDNEY_DISEASE: {
      return {
        ckd: {
          ckdStatus: values.ckdStatus === YesNoType.Yes,
        },
      };
    }
    case HealthInformationType.CARDIOVASCULAR_DISEASE: {
      return {
        cvd: {
          hasCvd: values.hasCvd === YesNoType.Yes,
          familyHadCvd: values.familyHadCvd.value,
        },
      };
    }
    case HealthInformationType.DIABETES: {
      return {
        diabetes: {
          diabetesStatus: values.diabetesStatus.value,
        },
      };
    }
    default:
      return undefined;
  }
};

export const toHealthInformationDefaultValues = (
  latestHealthInformation: Partial<HealthInformation>,
  healthInformationType: HealthInformationType,
  intl: IntlShape
): Partial<Form> | undefined => {
  const info = toHealthInformationInfo(
    healthInformationType,
    latestHealthInformation
  );
  if (!info) return undefined;

  switch (info.__typename) {
    case 'Smoking':
      return {
        smokingStatus: {
          value: info.status,
          label: intl.formatMessage(smokingStatusOptions[info.status]),
        },
      };
    case 'Height': {
      const { value, unit } = info;
      switch (unit) {
        case HeightUnit.Cm:
          return { cm: value };
        case HeightUnit.Inch:
          return getFullFeetAndInchesFromInches({ inch: value });
        default:
          return {};
      }
    }
    case 'BloodPressureMedication': {
      const { hasBPMedication, hadBPMedication } = info;
      return {
        hadBPMedication: toYesNo(hadBPMedication),
        hasBPMedication: toYesNo(hasBPMedication),
      };
    }
    case 'Ckd':
      return {
        ckdStatus: toYesNo(info.ckdStatus),
      };
    case 'Cvd':
      return {
        hasCvd: toYesNo(info.hasCvd),
        familyHadCvd: {
          value: info.familyHadCvd,
          label: intl.formatMessage(familyHadCvdOptions[info.familyHadCvd]),
        },
      };
    case 'Diabetes':
      return {
        diabetesStatus: {
          value: info.diabetesStatus,
          label: intl.formatMessage(diabetesStatusOptions[info.diabetesStatus]),
        },
      };
    default:
      return undefined;
  }
};
