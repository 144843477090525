import { object, ref, string } from 'yup';

export const schema = object().shape({
  email: string()
    .label('Email address')
    .matches(
      /^([a-z0-9_\-\\.+]+)@([a-z0-9_\-\\.]+)\.([a-z]{2,15})$/,
      'Please enter a valid email address'
    )
    .required(),
  confirmEmail: string()
    .label('Confirm email address')
    .required()
    .oneOf([ref('email'), null], 'Email addresses must match'),
});
