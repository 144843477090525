import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  Stack,
  colors,
  Text,
  RequestCard,
  Spacer,
  Notification,
  Grid,
  Col,
  GRID_BREAKPOINT,
} from '@bt-healthcare/ui-toolkit';

import {
  useGetGeneralPracticeAppointmentSlotQuery,
  useGetGeneralPracticeSettingsQuery,
} from '../../services/graphql';
import { ROUTE } from '../../config/routes';

import { AppointmentsList, AppointmentsListItem } from './styles';
import { NewStatusChip } from '../NewStatusChip';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { useTracking } from '../../hooks/useTracking';

export const AppointmentButtonsList = () => {
  const { userProfile: dataUser } = useAuthUserProfile();
  const { trackEvent } = useTracking();

  const patientRegistration =
    dataUser?.person?.attributes.generalPracticeRegistration;

  const patientIdentifier = patientRegistration?.patientIdentifier;

  const { data, loading, error } = useGetGeneralPracticeAppointmentSlotQuery({
    variables: {
      patientIdentifier: patientIdentifier!,
      days: 30,
    },
    skip: !patientIdentifier,
    context: { disableErrorHandler: true },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: dataGpSettings,
    error: errorGpSettings,
    loading: loadingGpSettings,
  } = useGetGeneralPracticeSettingsQuery({
    variables: {
      gpOdsCode: patientRegistration?.odsCode!,
      patientIdentifier: patientIdentifier!,
    },
    skip: !patientRegistration?.odsCode! || !patientIdentifier,
    context: { disableErrorHandler: true },
  });

  const isLoading = loading || loadingGpSettings;

  const areAppointmentsSupported =
    dataGpSettings?.generalPracticeServicePracticeSettings?.attributes
      ?.appointmentsSupported &&
    dataGpSettings?.generalPracticePatientSettings?.attributes?.appointments &&
    data?.appointments?.length;

  if (!isLoading && (error || errorGpSettings || !areAppointmentsSupported)) {
    return (
      <Notification type="softWarning">
        <Text color={colors.grey.grey10}>
          <FormattedMessage
            id="appointment-buttons-list.error.waring"
            defaultMessage="There are currently no appointments available to book online. Contact your GP practice for more information."
          />
        </Text>
      </Notification>
    );
  }

  return (
    <Stack space="none" id="appointment-buttons-list">
      <Stack
        space="s2"
        id="appointment-buttons-list-header"
        flexDirection="row"
      >
        <Text color={colors.base.dark}>
          <FormattedMessage
            id="appointment-buttons-list.subheader"
            defaultMessage="Book online"
          />
        </Text>
        <NewStatusChip />
      </Stack>
      <Spacer size="s5" />
      <Grid>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 5',
            [GRID_BREAKPOINT.medium]: '0 / 4',
            [GRID_BREAKPOINT.large]: '0 / 11',
          }}
        >
          {isLoading ? (
            <RequestCard
              cardTitle=""
              id="appointment-slots-loading"
              withIndicator={false}
              isLoading
              icon="ChevronRight"
              iconColor={colors.base.dark}
            />
          ) : (
            <AppointmentsList>
              {data?.appointments?.map((appointmentSlots) => (
                <AppointmentsListItem
                  key={appointmentSlots?.type}
                  onClick={() => trackEvent(`Appointment type CTA`, 'click')}
                >
                  <Link
                    to={ROUTE.APPOINTMENTS_CALENDAR}
                    state={{
                      type: appointmentSlots?.type,
                    }}
                  >
                    <RequestCard
                      cardTitle={appointmentSlots?.type || ''}
                      withIndicator
                      id={appointmentSlots?.type || ''}
                      icon="ChevronRight"
                      iconColor={colors.base.dark}
                    />
                  </Link>
                </AppointmentsListItem>
              ))}
            </AppointmentsList>
          )}
        </Col>
      </Grid>
    </Stack>
  );
};
