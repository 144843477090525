import { useEffect } from 'react';
import {
  Button,
  Col,
  Grid,
  Header,
  Paragraph,
  Spacer,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Hero } from '../../assets/hero.svg';
import { Content } from './styles';
import { useTracking } from '../../hooks/useTracking';
import { ROUTE } from '../../config/routes';

export const ReadingSubmitted = () => {
  const navigate = useNavigate();
  const { trackPageWithAppName } = useTracking();

  useEffect(() => {
    trackPageWithAppName('Reading submitted');
  }, []);
  return (
    <Grid style={{ flex: 1, gridTemplateRows: 'auto' }}>
      <Col colStart={{ xxs: 1, md: 2 }} colEnd={{ xxs: 5, md: 6 }}>
        <Content>
          <Hero
            width="100%"
            height="auto"
            preserveAspectRatio="xMinYMin meet"
            style={{
              flex: '1 0 160px',
              maxHeight: '306px',
            }}
          />
          <Spacer xs="s6" md="s14" />
          <Header>
            <FormattedMessage
              id="take-observation.reading-submitted.title"
              defaultMessage="Reading submitted!"
            />
          </Header>
          <Spacer xs="s4" md="s8" />
          <Paragraph>
            <FormattedMessage
              id="take-observation.reading-submitted.body"
              defaultMessage="If anything is out of the ordinary, we’ll let you know. To submit another vital reading you can do so below, or if you’re finished you can head back to your home page."
            />
          </Paragraph>
          <Spacer xs="s6" md="s8" />
          <Button
            id="take-another-reading"
            onClick={() => navigate(ROUTE.CREATE_OBSERVATION)}
            variant="primary"
          >
            <FormattedMessage
              id="take-observation.submitted-modal.send-another-reading.button"
              defaultMessage="Submit another reading"
            />
          </Button>
          <Spacer xs="s4" />
          <Button
            id="back-to-home"
            onClick={() => navigate('/')}
            variant="secondary"
          >
            <FormattedMessage
              id="take-observation.submitted-modal.cancel.button"
              defaultMessage="Back to home"
            />
          </Button>
        </Content>
      </Col>
    </Grid>
  );
};
