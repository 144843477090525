import { CareSetting } from '../services/graphql';
import { encodeMailtoURL } from '../formatters/mailto';

export const getCareSettingContactPoints = (careSetting: CareSetting) => {
  if (careSetting?.attributes?.res_phone) {
    return `tel:${careSetting.attributes.res_phone}`;
  }
  if (careSetting?.attributes?.email) {
    return encodeMailtoURL({ recipient: careSetting.attributes.email });
  }
  return '';
};
