import { defineMessages } from 'react-intl';
import { PolicyType } from './types';

export const title = defineMessages({
  [PolicyType.COOKIE]: {
    id: 'policy.title.cookie',
    defaultMessage: 'Our cookie policy',
  },
  [PolicyType.TERMS_AND_CONDITIONS]: {
    id: 'policy.title.terms-and-conditions',
    defaultMessage: 'Terms and Conditions',
  },
  [PolicyType.PRIVACY]: {
    id: 'policy.title.privacy',
    defaultMessage: 'HealthyYou Privacy Policy',
  },
});
