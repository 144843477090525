import { colors, fontSizes, Spacer, Text } from '@bt-healthcare/ui-toolkit';

import { PublicContentCardFormHeaderContentWrapper } from './styles';
import { PublicContentCardHeaderPropsType } from './types';

export const PublicContentCardFormHeader = ({
  HeaderText,
  SubHeaderText,
}: PublicContentCardHeaderPropsType) => (
  <PublicContentCardFormHeaderContentWrapper>
    <Text
      color={colors.grey.grey10}
      as="h3"
      fontSize={fontSizes.h3}
      fontWeight={500}
      data-testid="public-content-card-form-header"
    >
      {HeaderText}
    </Text>
    <Spacer size="s4" />
    <Text color={colors.grey.grey08} fontSize={fontSizes.sm}>
      {SubHeaderText}
    </Text>
    <Spacer size="s6" />
  </PublicContentCardFormHeaderContentWrapper>
);
