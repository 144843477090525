import { Text, colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const StyledCard = styled.div`
  background-color: ${colors.base.white};
  box-shadow: 0px 4px 10px 1px rgba(112, 144, 176, 0.2);
  border-radius: 8px;
  padding: ${spacing.s6} ${spacing.s4};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
  }
`;

export const CardTitle = styled(Text).attrs({
  color: colors.grey.grey10,
  fontWeight: 500,
})``;

export const PlusContainer = styled.div`
  border-radius: 50%;
  background: ${colors.primaryIndigo.indigo08};

  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
