import { object, string } from 'yup';
import type { IntlShape } from 'react-intl';
import { number } from '../../utils/yup/extension';
import { HealthInformationType } from './types';
import {
  bloodPressureMedicationLabels,
  ckdLabel,
  cvdLabels,
  subheaders,
  errorMessages,
  familyHadCvdOptions,
  headers,
  heightFieldsLabelUnits,
  heightLabel,
  smokingStatusLabel,
  smokingStatusOptions,
  yesNo,
  cvdBulletPoints,
  diabetesLabel,
  diabetesStatusOptions,
} from './intl';
import {
  CvdFamilyInformation,
  DiabetesStatus,
  HeightUnit,
  SmokingStatus,
  YesNoType,
} from '../../services/graphql';
import {
  HealthInformationData,
  HealthInformationWithUnitsData,
} from '../../components/HealthInformationForm/types';
import { sortArrayByGivenOrder } from '../../utils/array.utils';

export const validateFeetInchInput = ({
  inch,
  feet,
}: {
  inch: number;
  feet: number;
}): {
  status: 'ok' | 'error';
  errorMessage?: string;
} => {
  if (feet === 4 && inch < 4) {
    return {
      status: 'error',
      errorMessage: errorMessages.inLowestFt.defaultMessage,
    };
  }
  if (feet === 7 && inch > 2) {
    return {
      status: 'error',
      errorMessage: errorMessages.inHighestFt.defaultMessage,
    };
  }
  return { status: 'ok' };
};

export const heightValidation = {
  cm: number()
    .decimal({
      min: 134,
      max: 219,
      name: 'Cm',
      decimalDigits: 1,
    })
    .required(),
  ft: number()
    .decimal({
      min: 4,
      max: 7,
      name: 'Feet',
    })
    .validateWith({
      dependentFieldName: 'inch',
      validationCallback: ({ dependentFieldValue, fieldValue }) =>
        validateFeetInchInput({
          inch: dependentFieldValue,
          feet: fieldValue,
        }),
    })
    .required(),
  inch: number()
    .decimal({
      min: 0,
      max: 11,
      name: 'Inches',
    })
    .validateWith({
      dependentFieldName: 'ft',
      validationCallback: ({ dependentFieldValue, fieldValue }) =>
        validateFeetInchInput({
          inch: fieldValue,
          feet: dependentFieldValue,
        }),
    })
    .required(),
};

export const height = (intl: IntlShape): HealthInformationWithUnitsData => ({
  header: intl.formatMessage(headers[HealthInformationType.HEIGHT]),
  unitSystems: [HeightUnit.Inch, HeightUnit.Cm],
  fields: [
    {
      name: 'cm',
      unitLabel: intl.formatMessage(heightFieldsLabelUnits.cm),
      label: intl.formatMessage(heightLabel),
      type: 'number',
      validationRule: heightValidation.cm,
      unitSystem: HeightUnit.Cm,
      inputMode: 'decimal',
    },
    {
      name: 'ft',
      unitLabel: intl.formatMessage(heightFieldsLabelUnits.ft),
      type: 'number',
      label: intl.formatMessage(heightLabel),
      validationRule: heightValidation.ft,
      unitSystem: HeightUnit.Inch,
    },
    {
      name: 'inch',
      unitLabel: intl.formatMessage(heightFieldsLabelUnits.inch),
      type: 'number',
      label: intl.formatMessage(heightLabel),
      validationRule: heightValidation.inch,
      unitSystem: HeightUnit.Inch,
    },
  ],
});

export const smokingOptionsOrder = [
  SmokingStatus.PreferNotSay,
  SmokingStatus.NoNeverSmoked,
  SmokingStatus.NoQuit,
  SmokingStatus.YesLessThan_10ADay,
  SmokingStatus.YesLessThan_20ADay,
  SmokingStatus.YesMoreThan_20ADay,
];

export const smoking = (intl: IntlShape): HealthInformationData => ({
  header: intl.formatMessage(headers[HealthInformationType.SMOKING]),
  fields: [
    {
      name: 'smokingStatus',
      label: intl.formatMessage(smokingStatusLabel),
      validationRule: object()
        .shape({
          value: string().oneOf(Object.values(SmokingStatus)).required(),
          label: string().required(),
        })
        .typeError(''),
      options: sortArrayByGivenOrder(
        Object.values(SmokingStatus),
        smokingOptionsOrder
      ).map((status) => ({
        value: status,
        label: intl.formatMessage(smokingStatusOptions[status]),
      })),
      type: 'select',
    },
  ],
});

const yesNoOptions = (intl: IntlShape) => [
  {
    value: YesNoType.Yes,
    label: intl.formatMessage(yesNo.yes),
  },
  {
    value: YesNoType.No,
    label: intl.formatMessage(yesNo.no),
  },
];
export const bloodPressureMedication = (
  intl: IntlShape
): HealthInformationData => ({
  header: intl.formatMessage(
    headers[HealthInformationType.BLOOD_PRESSURE_MEDICATION]
  ),
  fields: [
    {
      name: 'hasBPMedication',
      label: intl.formatMessage(bloodPressureMedicationLabels.hasBPMedication),
      validationRule: string().required(),
      type: 'yesNo',
      options: yesNoOptions(intl),
    },
    {
      name: 'hadBPMedication',
      label: intl.formatMessage(bloodPressureMedicationLabels.hadBPMedication),
      validationRule: string().required(),
      type: 'yesNo',
      options: yesNoOptions(intl),
      conditionOptions: {
        conditionFieldName: 'hasBPMedication',
        disable: (value) => value === YesNoType.Yes,
        condition: (value, setValue) => {
          if (value === YesNoType.Yes) {
            setValue('hadBPMedication', YesNoType.Yes);
          }
        },
      },
      disabledMessage: intl.formatMessage(
        bloodPressureMedicationLabels.hadBPMedicationDisabled
      ),
    },
  ],
});

export const ckd = (intl: IntlShape): HealthInformationData => ({
  header: intl.formatMessage(
    headers[HealthInformationType.CHRONIC_KIDNEY_DISEASE]
  ),
  description: [
    {
      type: 'subheader',
      text: intl.formatMessage(
        subheaders[HealthInformationType.CHRONIC_KIDNEY_DISEASE]
      ),
    },
  ],
  fields: [
    {
      name: 'ckdStatus',
      label: intl.formatMessage(ckdLabel),
      validationRule: string().required(),
      type: 'yesNo',
      options: yesNoOptions(intl),
    },
  ],
});

const familyHadCvdOrder = [
  CvdFamilyInformation.PreferNotToSay,
  CvdFamilyInformation.Yes,
  CvdFamilyInformation.No,
  CvdFamilyInformation.DontKnow,
];

export const cvd = (intl: IntlShape): HealthInformationData => ({
  header: intl.formatMessage(
    headers[HealthInformationType.CARDIOVASCULAR_DISEASE]
  ),
  description: [
    {
      type: 'subheader',
      text: intl.formatMessage(
        subheaders[HealthInformationType.CARDIOVASCULAR_DISEASE]
      ),
    },
    {
      type: 'bullet',
      title: intl.formatMessage(cvdBulletPoints.stroke),
    },
    {
      type: 'bullet',
      title: intl.formatMessage(cvdBulletPoints.coronaryHeartDiseaseTitle),
      explanation: intl.formatMessage(
        cvdBulletPoints.coronaryHeartDiseaseExplanation
      ),
    },
    {
      type: 'bullet',
      title: intl.formatMessage(cvdBulletPoints.peripheralArterialDiseaseTitle),
      explanation: intl.formatMessage(
        cvdBulletPoints.peripheralArterialDiseaseExplanation
      ),
    },
  ],
  fields: [
    {
      name: 'hasCvd',
      label: intl.formatMessage(cvdLabels.hasCvd),
      validationRule: string().required(),
      type: 'yesNo',
      options: yesNoOptions(intl),
    },
    {
      name: 'familyHadCvd',
      label: intl.formatMessage(cvdLabels.familyHadCvd),
      validationRule: object()
        .shape({
          value: string().oneOf(Object.values(CvdFamilyInformation)).required(),
          label: string().required(),
        })
        .typeError(''),
      options: sortArrayByGivenOrder(
        Object.values(CvdFamilyInformation),
        familyHadCvdOrder
      ).map((status) => ({
        value: status,
        label: intl.formatMessage(familyHadCvdOptions[status]),
      })),
      type: 'select',
    },
  ],
});

const DiabetesStatusOrder = [
  DiabetesStatus.PreferNotToSay,
  DiabetesStatus.No,
  DiabetesStatus.Type_1,
  DiabetesStatus.Type_2,
];

export const diabetes = (intl: IntlShape): HealthInformationData => ({
  header: intl.formatMessage(headers[HealthInformationType.DIABETES]),
  fields: [
    {
      name: 'diabetesStatus',
      label: intl.formatMessage(diabetesLabel),
      validationRule: object()
        .shape({
          value: string().oneOf(Object.values(DiabetesStatus)).required(),
          label: string().required(),
        })
        .typeError(''),
      options: sortArrayByGivenOrder(
        Object.values(DiabetesStatus),
        DiabetesStatusOrder
      ).map((status) => ({
        value: status,
        label: intl.formatMessage(diabetesStatusOptions[status]),
      })),
      type: 'select',
    },
  ],
});

export const data = (type: string, intl: IntlShape) => {
  switch (type) {
    case HealthInformationType.HEIGHT:
      return height(intl);
    case HealthInformationType.SMOKING:
      return smoking(intl);
    case HealthInformationType.BLOOD_PRESSURE_MEDICATION:
      return bloodPressureMedication(intl);
    case HealthInformationType.CHRONIC_KIDNEY_DISEASE:
      return ckd(intl);
    case HealthInformationType.CARDIOVASCULAR_DISEASE:
      return cvd(intl);
    case HealthInformationType.DIABETES:
      return diabetes(intl);
    default:
      return null;
  }
};
