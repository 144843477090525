import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Container = styled.div`
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
  }
`;

export const Content = styled.div`
  @media ${device.tablet} {
    width: 410px;
  }
`;

export const HeaderContainer = styled.div`
  @media ${device.tablet} {
    text-align: center;
  }
`;

export const NeedHelpLink = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.s1};
  cursor: pointer;
  align-items: center;

  @media ${device.tablet} {
    align-self: center;
  }
`;
