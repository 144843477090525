import styled from 'styled-components';
import {
  colors,
  device,
  fontCalcMixin,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import { rgbaFromHexAndOpacity } from '../../utils/css';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  padding: ${spacing.s5} ${spacing.s4};
  background-color: ${rgbaFromHexAndOpacity(colors.base.white, 0.1)};
  border-radius: 8px;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  ${fontCalcMixin(fontSizes.sm)};
  color: ${rgbaFromHexAndOpacity(colors.base.white, 0.8)};

  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.base)}
  }

  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background-color: ${rgbaFromHexAndOpacity(colors.base.white, 0.3)};
  }

  &:last-of-type {
    &:after {
      display: none;
    }

    & div {
      margin-bottom: 0;
    }
  }

  &:last-child {
  }
`;

export const ItemContent = styled.div`
  display: flex;
  gap: ${spacing.s3};
  margin: 0 0 ${spacing.s3} 0;
`;
