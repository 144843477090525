import { ItemLeftContainer } from './styles';
import { ItemLeftTypes } from './types';

export const ItemLeft = ({
  children,
  flexDirection = 'row',
  gap,
}: ItemLeftTypes) => (
  <ItemLeftContainer flexDirection={flexDirection} gap={gap}>
    {children}
  </ItemLeftContainer>
);
