import { device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const TasksContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-align: left;
`;

export const Footer = styled.div`
  display: flex;
`;

export const GridItem = styled.div`
  grid-column-end: span 4;

  @media ${device.tablet} {
    grid-column-end: span 5;
  }
`;
