// temporary solution, get rid when gql will be ready
export enum SmokingDummy {
  NEVER_SMOKED = 'NEVER_SMOKED',
  QUIT_OVER_20 = 'QUIT_OVER_20',
  QUIT_OVER_15 = 'QUIT_OVER_15',
  QUIT_OVER_10 = 'QUIT_OVER_10',
  QUIT_OVER_5 = 'QUIT_OVER_5',
  QUIT_LESS_5 = 'QUIT_LESS_5',
  YES = 'YES',
}

export type SmokingForm = {
  smoking?: {
    value: SmokingDummy;
    label: string;
  };
};
