import { useEffect, useState } from 'react';
import {
  Text,
  Header,
  Input,
  Button,
  colors,
  Notification,
  fontSizes,
  Stack,
  BTHealthIcon,
} from '@bt-healthcare/ui-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, Outlet } from 'react-router-dom';
import { formatISO } from 'date-fns';
import { useTracking } from '../../hooks/useTracking';
import { BodyText } from '../GetReadyGPLinkage/styles';
import { Container, Content, HeaderContainer, NeedHelpLink } from './styles';
import { FindYourLinkageInformation } from '../../components/Modal/FindYourLinkageInformation';
import { useRegisterGeneralPracticePatientLinkageMutation } from '../../services/graphql';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { isGraphQLError4xx } from '../../utils/response';
import { UnableToLinkGP } from '../../components/Modal/UnableToLinkGP';
import { SomethingWentWrongLinkGP } from '../../components/Modal/SomethingWentWrongLinkGP';
import { ROUTE } from '../../config/routes';
import { schema } from './data';

type ConnectGPLinkageFormValues = {
  organisationCode: string;
  accountId: string;
  linkageKey: string;
};

export const ConnectGPLinkage = () => {
  const { trackPageWithAppName, trackEvent } = useTracking();
  const intl = useIntl();

  const { userProfile: data } = useAuthUserProfile();
  const navigate = useNavigate();

  useEffect(() => {
    trackPageWithAppName('GP linkage - connect');
  }, []);

  const [isOpenFindYourLinkageModal, setIsOpenFindYourLinkageModal] =
    useState(false);
  const [isOpenUnableToLinkModal, setIsOpenUnableToLinkModal] = useState(false);
  const [isOpenSomethingWentWrongModal, setIsOpenSomethingWentWrongModal] =
    useState(false);
  const [isVisibleInvalidFormatWarning, setIsVisibleInvalidFormatWarning] =
    useState(false);

  const [
    registerGeneralPracticePatientLinkageMutation,
    registerGeneralPracticePatientLinkageMutationResult,
  ] = useRegisterGeneralPracticePatientLinkageMutation();

  const {
    loading,
    error,
    data: result,
  } = registerGeneralPracticePatientLinkageMutationResult;

  useEffect(() => {
    if (loading) {
      return;
    }

    if (result) {
      navigate(ROUTE.GP_LINKAGE_SUCCESS);
    }

    if (!error?.graphQLErrors?.[0]) {
      return;
    }

    const isResponseError400 = isGraphQLError4xx(
      registerGeneralPracticePatientLinkageMutationResult
    );

    if (isResponseError400) {
      setIsVisibleInvalidFormatWarning(true);
      setIsOpenUnableToLinkModal(true);
    } else {
      setIsOpenSomethingWentWrongModal(true);
    }
  }, [loading]);

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = useForm<ConnectGPLinkageFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const handleBeforeUnload = (event: WindowEventMap['unload']) => {
      if (isDirty) {
        event.preventDefault();
        // This is needed for chrome
        // eslint-disable-next-line no-param-reassign
        event.returnValue = false;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const submit = handleSubmit(({ accountId, linkageKey, organisationCode }) => {
    trackEvent('Continue', 'click');
    const person = data?.person;

    const personalInformation = person?.attributes?.personalInformation;

    setIsVisibleInvalidFormatWarning(false);

    registerGeneralPracticePatientLinkageMutation({
      variables: {
        input: {
          surname: personalInformation?.surname as string,
          dateOfBirth: formatISO(
            new Date(personalInformation?.dateOfBirth as string),
            {
              representation: 'date',
            }
          ),
          accountId: accountId.toString(),
          linkageKey,
          gpOdsCode: organisationCode,

          personId: person?.id!,
        },
      },
    });
  });

  return (
    <Container>
      <Content>
        <form>
          <Stack id="gp-linkage-connect-page.form" space="s8">
            <HeaderContainer>
              <Header>
                <FormattedMessage
                  id="gp-linkage-connect-page.header"
                  defaultMessage="Link your GP practice"
                />
              </Header>
            </HeaderContainer>
            <HeaderContainer>
              <Stack id="header.need-help" space="s4">
                <Text color={colors.grey.grey08} fontSize={fontSizes.base}>
                  <BodyText>
                    <FormattedMessage
                      id="gp-linkage-connect-page.body"
                      defaultMessage="Please enter your Account ID and Passphrase as shown on your letter / email."
                    />
                  </BodyText>
                </Text>

                <NeedHelpLink
                  onClick={() => {
                    setIsOpenFindYourLinkageModal(true);
                  }}
                >
                  <BTHealthIcon
                    icon="Help"
                    size={16}
                    color={colors.primaryIndigo.indigo08}
                  />
                  <Text
                    color={colors.primaryIndigo.indigo08}
                    fontSize={fontSizes.base}
                    fontWeight={500}
                  >
                    <BodyText>
                      <FormattedMessage
                        id="gp-linkage-connect-page.header.need-help-text"
                        defaultMessage="Need help finding these?"
                      />
                    </BodyText>
                  </Text>
                </NeedHelpLink>
              </Stack>
            </HeaderContainer>
            <Stack id="gp-linkage-connect-page.form-controls" space="s4">
              <Input
                id="organisationCode"
                label={intl.formatMessage({
                  id: 'gp-linkage-connect-page.form.organisationCode',
                  defaultMessage: 'Organisation Code',
                })}
                type="text"
                errorText={errors.organisationCode?.message}
                inputMode="text"
                helperText="Case sensitive"
                {...register('organisationCode')}
              />
              <Input
                id="accountId"
                label={intl.formatMessage({
                  id: 'gp-linkage-connect-page.form.accountId',
                  defaultMessage: 'Account ID',
                })}
                type="text"
                errorText={errors.accountId?.message}
                inputMode="text"
                helperText="Case sensitive"
                {...register('accountId')}
              />
              <Input
                id="linkageKey"
                label={intl.formatMessage({
                  id: 'gp-linkage-connect-page.form.linkageKey',
                  defaultMessage: 'Passphrase / Linkage key',
                })}
                type="text"
                errorText={errors.linkageKey?.message}
                inputMode="text"
                helperText="Case sensitive"
                {...register('linkageKey')}
              />
            </Stack>
            {isVisibleInvalidFormatWarning && (
              <Notification type="softWarning" alignSelf="flex-start">
                <Text color={colors.grey.grey10}>
                  <FormattedMessage
                    id="gp-linkage-connect-page.header.invalid-format-warning"
                    defaultMessage="Please check that the format matches the letter or email from your GP practice"
                  />
                </Text>
              </Notification>
            )}
            <Button
              id="submit-reading-btn"
              variant="primary"
              size="maximum"
              onClick={submit}
              isLoading={
                registerGeneralPracticePatientLinkageMutationResult?.loading ===
                true
              }
              disabled={!isDirty || !isValid}
            >
              <FormattedMessage
                id="gp-linkage-connect-page.form.submit-button"
                defaultMessage="Continue"
              />
            </Button>
          </Stack>
        </form>
        <FindYourLinkageInformation
          isModalOpen={isOpenFindYourLinkageModal}
          handleClose={() => {
            setIsOpenFindYourLinkageModal(false);
          }}
        />

        <UnableToLinkGP
          isModalOpen={isOpenUnableToLinkModal}
          handleClose={() => {
            setIsOpenUnableToLinkModal(false);
          }}
        />

        <SomethingWentWrongLinkGP
          isModalOpen={isOpenSomethingWentWrongModal}
          handleClose={() => {
            setIsOpenSomethingWentWrongModal(false);
          }}
        />
      </Content>

      <Outlet />
    </Container>
  );
};
