import { Card } from '@bt-healthcare/ui-toolkit';
import type { InfoCardTypes } from './types';
import { Title, Value } from './styles';

export const InfoCard = ({ title, value, dataTestId }: InfoCardTypes) => (
  <Card id={dataTestId} borderRadius={8} isListCard>
    <Title>{title}</Title>
    <Value>{value}</Value>
  </Card>
);
