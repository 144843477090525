import { createIntl, createIntlCache } from 'react-intl';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'en-GB',
    messages: {
      requiredText: 'This is a required field.',
      invalidYear: 'Year is invalid',
      invalidDate: 'Date is invalid',
      futureDate: "Must be earlier than today's date.",
      under18: 'Must be older than 18',
    },
  },
  cache
);

export const REQUIRED_TEXT = intl.formatMessage({ id: 'requiredText' });
export const INVALID_YEAR = intl.formatMessage({ id: 'invalidYear' });
export const INVALID_DATE_MESSAGE = intl.formatMessage({ id: 'invalidDate' });
export const FUTURE_DATE_MESSAGE = intl.formatMessage({ id: 'futureDate' });
export const UNDER_18_DATE_MESSAGE = intl.formatMessage({ id: 'under18' });
