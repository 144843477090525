import { Button, Notification } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';

import HealthScorePageWrapper from '../../components/HealthScorePageWrapper';
import { healthScoreNavigator } from '../HealthScoreNavigator/intl';
import { healthScoreSmoking } from './intl';
import { RHFDropDown } from '../../components/RHFDropDown';
import { data } from './data';
import { SmokingForm } from './types';
import { useAppDispatch } from '../../context/AppContext';

export const HealthScoreSmoking = () => {
  const intl = useIntl();
  const { smoking: smokingData } = data(intl);

  const validationSchema = object({ smoking: smokingData.validationRule });
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<SmokingForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();

  const onSubmit = (values: SmokingForm) => {
    const smoking = values.smoking?.value;
    if (smoking) {
      dispatch({ type: 'healthScoreSetSmoking', payload: { smoking } });
    }
  };

  return (
    <HealthScorePageWrapper.Form>
      <HealthScorePageWrapper.Content>
        <HealthScorePageWrapper.Header>
          <FormattedMessage {...healthScoreSmoking.header} />
        </HealthScorePageWrapper.Header>
        <HealthScorePageWrapper.InputWithInfo>
          <Notification type="informative">
            <FormattedMessage {...healthScoreSmoking.explanationCard} />
          </Notification>
          <RHFDropDown
            label=""
            options={smokingData.options}
            fieldName="smoking"
            control={control}
          />
        </HealthScorePageWrapper.InputWithInfo>
      </HealthScorePageWrapper.Content>
      <Button
        id="health-score-button"
        variant="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={!isValid}
      >
        <FormattedMessage {...healthScoreNavigator.button} />
      </Button>
    </HealthScorePageWrapper.Form>
  );
};
