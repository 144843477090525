import { Props } from './FindYourLinkageInformation';
import { ErrorGPLinkage } from './ErrorGPLInkage';

export const SomethingWentWrongLinkGP = (props: Props) => (
  <ErrorGPLinkage
    {...props}
    id="gp-linkage-something-went-wrong-page-modal"
    header="Something went wrong :("
    subtitle="There was an issue trying to link your GP practice to your account. Please check your internet connection or try again later."
  />
);
