import styled from 'styled-components';
import { device, Grid, spacing } from '@bt-healthcare/ui-toolkit';

export const StyledGrid = styled(Grid)`
  gap: ${spacing.s6};
`;

export const ButtonWrapper = styled.div`
  margin-top: ${spacing.s4};
  @media ${device.tablet} {
    margin-top: ${spacing.s10};
  }
  @media ${device.desktop} {
    margin-top: ${spacing.s14};
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media ${device.tablet} {
    height: unset;
`;

export const DisabledMessageWrapper = styled.div`
  margin-left: ${spacing.s4};
  margin-top: ${spacing.s2};
  @media ${device.tablet} {
    margin-top: ${spacing.s4};
  }
`;
