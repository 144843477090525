import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Modal,
  ModalBody,
  Stack,
  Body,
  ModalFooter,
  Button,
  Notification,
  Text,
  colors,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';

import { deleteMyAccountModal } from './intl';
import { SimpleModalProps } from './types';
import { useDeletePatientMutation } from '../../services/graphql';
import { useTracking } from '../../hooks/useTracking';

export const DeleteMyAccountModal = ({
  modalOpen,
  handleClose,
}: SimpleModalProps) => {
  const { trackPageWithAppName, trackEvent } = useTracking();

  useEffect(() => {
    trackPageWithAppName('Delete My Account Modal');
  }, []);

  const [deletePatient, { data, error, loading }] = useDeletePatientMutation({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      trackEvent(`Yes, delete my account`, 'click');
      handleClose();
    }
  }, [data]);

  const handleUnsubscribe = () => {
    deletePatient();
  };

  const handleCancel = () => {
    trackEvent(`No, go back`, 'click');
    handleClose();
  };

  return (
    <Modal
      onClose={handleClose}
      isModalOpen={modalOpen}
      id="delete-my-account-modal"
    >
      <ModalBody>
        <Stack space="s4" id="confirmation" alignItems="center">
          <Text
            color={colors.grey.grey09}
            as="h3"
            fontSize={fontSizes.h3}
            style={{
              display: 'block',
              textAlign: 'center',
            }}
            fontWeight={500}
          >
            <FormattedMessage {...deleteMyAccountModal.header} />
          </Text>
          <Body style={{ textAlign: 'center' }}>
            <Text color={colors.grey.grey09}>
              <FormattedMessage {...deleteMyAccountModal.textDescription} />
            </Text>
          </Body>

          {error && (
            <Notification type="softWarning">
              <FormattedMessage {...deleteMyAccountModal.error} />
            </Notification>
          )}
        </Stack>
        <ModalFooter>
          <Stack id="modal-buttons-wrapper" gap={spacing.s6}>
            <Button id="cancel" onClick={handleCancel} variant="primary">
              <FormattedMessage {...deleteMyAccountModal.primaryCTA} />
            </Button>
            <Button
              id="submit"
              onClick={handleUnsubscribe}
              variant="primaryRed"
              isLoading={loading}
            >
              <FormattedMessage {...deleteMyAccountModal.secondaryCTA} />
            </Button>
          </Stack>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
