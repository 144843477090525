import { useState } from 'react';
import {
  Stack,
  Spacer,
  colors,
  Text,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

import {
  RisksCardsWrapper,
  RisksCardsHeader,
  Description,
  MillimetersOfMercury,
  RisksCardsList,
  RisksCardsListHeader,
  RisksCardsListItem,
} from './styles';
import { SimpleTooltip } from '../SimpleTooltip';

export const RisksCards = () => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <RisksCardsWrapper>
      <Stack id="" alignContent="center" maxWidth={450} justifyContent="center">
        <RisksCardsHeader>Did you know...</RisksCardsHeader>
        <Spacer size="s6" />
        <Description>
          Lowering your systolic blood pressure by{' '}
          <MillimetersOfMercury
            style={{
              position: 'relative',
              maxWidth: '100vw',
            }}
            aria-describedby="did-you-know"
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            onFocus={() => setTooltipVisible(true)}
            onBlur={() => setTooltipVisible(false)}
          >
            10mmHg
            <SimpleTooltip isVisible={isTooltipVisible} id="did-you-know">
              <>
                <Text color={colors.grey.grey08} fontSize={fontSizes.sm}>
                  Blood pressure is measured in millimetres of mercury (mmHg)
                  and is given as two figures.{' '}
                </Text>
                <Text color={colors.grey.grey08} fontSize={fontSizes.sm}>
                  <span
                    style={{
                      color: colors.primaryIndigo.indigo08,
                    }}
                  >
                    Systolic (higher number)
                  </span>{' '}
                  - the pressure when your heart pushes blood out.{' '}
                </Text>
                <Text color={colors.grey.grey08} fontSize={fontSizes.sm}>
                  <span
                    style={{
                      color: colors.primaryIndigo.indigo08,
                    }}
                  >
                    Diastolic (lower number)
                  </span>{' '}
                  - the pressure when your heart rests between beats
                </Text>
              </>
            </SimpleTooltip>
          </MillimetersOfMercury>{' '}
          can reduce your risk of stroke, heart disease and heart failure
        </Description>
      </Stack>
      <Spacer size="s14" />
      <Stack id="" flexDirection="row" gap={10}>
        <RisksCardsList>
          <RisksCardsListHeader background={colors.primaryIndigo.indigo09}>
            Health risk
          </RisksCardsListHeader>
          <RisksCardsListItem background={colors.primaryIndigo.indigo01}>
            Stroke
          </RisksCardsListItem>
          <RisksCardsListItem background={colors.primaryIndigo.indigo01}>
            Heart disease
          </RisksCardsListItem>
          <RisksCardsListItem background={colors.primaryIndigo.indigo01}>
            Heart failure
          </RisksCardsListItem>
        </RisksCardsList>
        <RisksCardsList>
          <RisksCardsListHeader background={colors.secondaryBlue.blue06}>
            Reduced by
          </RisksCardsListHeader>
          <RisksCardsListItem background={colors.secondaryBlue.blue01}>
            27%
          </RisksCardsListItem>
          <RisksCardsListItem background={colors.secondaryBlue.blue01}>
            17%
          </RisksCardsListItem>
          <RisksCardsListItem background={colors.secondaryBlue.blue01}>
            28%
          </RisksCardsListItem>
        </RisksCardsList>
      </Stack>
    </RisksCardsWrapper>
  );
};
