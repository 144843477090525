export enum ROUTE {
  LOGIN = '/login',
  ACCEPT_TERMS_AND_CONDITIONS = '/accept-terms-and-conditions',
  ACCOUNT = '/account',
  ACCOUNT_ETHNICITY = '/account/ethnicity',
  ACCOUNT_HEALTH_INFORMATION = '/account/health-information',
  ACCOUNT_HEALTH_INFORMATION_INPUT = '/account/health-information/:type',
  ACCOUNT_CONTACT_PREFERENCES = '/account/contact-preferences',
  ACTIVATE = '/activate',
  APPOINTMENTS_CALENDAR = '/appointments-calendar',
  BOOKING_SUCCESS = '/booking/success',
  COOKIES = '/cookies',
  CREATE_OBSERVATION = '/take-vital',
  CREATE_OBSERVATION_CONFIRM = '/take-vital/:type/confirm',
  CREATE_OBSERVATION_GUIDE = '/take-vital/:type/guide',
  CREATE_OBSERVATION_NEW = '/take-vital/:type/new',
  CREATE_OBSERVATION_NEW_VO2 = '/take-vital/vo2/new',
  CREATE_OBSERVATION_SUCCESS = '/take-vital/success',
  CANCELLATION_SUCCESS = '/cancellation/success',
  DISCHARGED = '/discharged',
  EMAIL_PREFERENCES = '/email-preferences',
  GP_LINKAGE_CONNECT = '/gp-linkage/connect',
  GP_LINKAGE_GET_READY = '/gp-linkage/get-ready',
  GP_LINKAGE_SUCCESS = '/gp-linkage/success',
  GP_UNLINKAGE_SUCCESS = '/gp-unlinkage/success',
  HEALTH_SCORE_PERSONAL_INFO = '/health-score/personal-info',
  HEALTH_SCORE_STRENGTH = '/health-score/strength',
  HEALTH_SCORE_SMOKING = '/health-score/smoking',
  HEALTH_SCORE_ALCOHOL_FREQUENCY = '/health-score/alcohol-frequency',
  HEALTH_SCORE_ALCOHOL_MODERATION = '/health-score/alcohol-moderation',
  HEALTH_SCORE_HEIGHT_AND_WEIGHT = '/health-score/height-and-weight',
  HOME = '/',
  HISTORICAL_READINGS = '/historical-readings',
  OBSERVATION_RATINGS = '/observation-ratings/:type',
  HISTORICAL_READINGS_TYPE = '/historical-readings/:type',
  NOT_FOUND = '/404',
  PRIVACY = '/privacy',
  ONBOARDING = '/onboarding',
  ONBOARDING_EMAIL_ADDRESS = '/onboarding/email-address',
  ONBOARDING_CHECK_EMAIL = '/onboarding/check-email',
  ONBOARDING_VERIFY_SUCCESS = '/onboarding/verify-success',
  ONBOARDING_SUCCESS = '/onboarding/success',
  ONBOARDING_PERSONAL_DETAILS = '/onboarding/personal-details',
  ONBOARDING_PASSWORD = '/onboarding/password',
  ONBOARDING_ERROR = '/onboarding/error',
  RECOMMENDATION_INFO = '/recommendation/info',
  RECOMMENDATION_HOW_IT_WORKS = '/recommendation/how-it-works',
  RECOMMENDATION_CONFIRM_PLAN = '/recommendation/confirm-plan',
  RECOMMENDATION_SUCCESS = '/recommendation/success',
  RECOMMENDATION_PROGRAMME = '/recommendation-programme',
  RECOMMENDATION_PROGRAMME_OBSERVATIONS = '/recommendation-programme/:programmeId/observations',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  NOTIFICATION = '/notification',
  COLLATERAL_LANDING = '/collateral-landing',
  REMOTE_CARE_SERVICE = '/collateral-landing/remote-care-service',
  NOTIFICATION_EMAIL_PREFERENCES = '/notification/email-preferences/:personId',
  UNSUBSCRIBED = '/notification/email-preferences/unsubscribed',
  STEP_UP_INVITATION = '/patient-stepup-invitations',
  STEP_UP_ACCEPT_TNC = '/patient-stepup-accept-tnc',
  STEP_UP_SUCCESS = '/patient-stepup/success',
  STEP_UP_INVALID = '/patient-stepup/invalid',
}
