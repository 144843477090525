import styled from 'styled-components';
import {
  colors,
  device,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';

const ITEM_HEIGHT = '50px';
const LINE_COLOR = colors.grey.grey04;
const CONTAINER_PADDING = spacing.s4;

export const InsightsBarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: ${spacing.s3};
  padding: ${CONTAINER_PADDING};
  position: relative;
  @media ${device.mobile} {
    gap: 0;
    padding: 0;
    height: 85px;
    grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr;
    grid-template-rows: none;
  }
  align-items: center;
  width: 100%;
  background-color: ${colors.base.white};
  border-radius: 8px;
  margin-top: ${spacing.s6};

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: ${spacing.s4};
    width: calc(100% - ${parseInt(CONTAINER_PADDING, 10) * 2}px);
    height: 1px;
    background-color: ${LINE_COLOR};
    @media ${device.mobile} {
      display: none;
    }
`;

export const Line = styled.div`
  background-color: ${LINE_COLOR};
  height: ${ITEM_HEIGHT};
  width: 100%;
`;

export const MiddleLine = styled(Line)`
  display: none;
  @media ${device.mobile} {
    display: block;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: ${ITEM_HEIGHT};
  margin-left: ${spacing.s2};
  margin-right: ${spacing.s2};
`;

export const BadgeContainer = styled(ItemContainer)`
  justify-content: center;
`;

export const MobileSpan = styled.span`
  @media ${device.tablet} {
    display: none;
  }
`;
export const Span = styled.span`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

export const Value = styled(Text).attrs({
  color: colors.grey.grey10,
  fontWeight: 500,
})``;

export const Description = styled(Text).attrs({
  color: colors.grey.grey08,
  fontSize: fontSizes.xs,
})`
  text-align: center;
`;
