import { BTHealthIcon, Stack, colors } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { CardTitle, PlusContainer, StyledCard } from './styles';

export const AddReadingCard = () => (
  <StyledCard data-testid="add-reading-app-card">
    <Stack
      id="add-reading"
      space="s4"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      flex="1"
    >
      <CardTitle>
        <FormattedMessage
          id="home.add-reading.card"
          defaultMessage="Add reading"
        />
      </CardTitle>
      <PlusContainer>
        <BTHealthIcon icon="Add" size={18} color={colors.base.white} />
      </PlusContainer>
    </Stack>
  </StyledCard>
);
