import { Container, Item, ItemContent } from './styles';
import { RecommendationBulletListProps } from './types';

export const RecommendationBulletList = ({
  items,
}: RecommendationBulletListProps) => (
  <Container>
    {items.map(({ icon: Icon, text }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Item key={`bullet-item-${index}`}>
        <ItemContent>
          <div>
            <Icon />
          </div>
          {text}
        </ItemContent>
      </Item>
    ))}
  </Container>
);
