import { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import { Link, useNavigate } from 'react-router-dom';
import { colors, Text } from '@bt-healthcare/ui-toolkit';
import { ReactComponent as ErrorSVG } from '../../assets/error.svg';
import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { useTracking } from '../../hooks/useTracking';

import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';
import { GridLayout } from '../../Layouts/GridLayout';
import { CARE_SETTING_NAME, WARD_PATIENT_ID } from '../../App.constants';

export const StepUpInvalid = () => {
  const { trackPage, trackTapped } = useTracking();
  const navigate = useNavigate();
  const pageName = 'Step Up - Invalid';

  useEffect(() => {
    localStorage.removeItem(CARE_SETTING_NAME);
    localStorage.removeItem(WARD_PATIENT_ID);
    trackPage(pageName);
    window.scrollTo(0, 0);
  }, []);

  const onBackHome = () => {
    trackTapped('Back home', pageName);
    navigate('/');
  };

  return (
    <GridLayout>
      <Navbar />
      <GridPageCenteredSection>
        <PublicContentCard
          Hero={<ErrorSVG width="100%" height="100%" />}
          HeaderText={
            <FormattedMessage
              id="step-up-invalid.header-text"
              defaultMessage="This link is no longer valid."
            />
          }
          TextDescription={[
            <FormattedMessage
              key="step-up-invalid.text-description"
              id="step-up-invalid.text-description"
              defaultMessage="Either this account has already joined the program, or the link you were sent has expired. If you require a new link, please contact us at {email}."
              values={{
                email: (
                  <Link to="mailto:support.healthyyou@bt.com">
                    <Text color={colors.primaryIndigo.indigo08}>
                      support.healthyyou@bt.com
                    </Text>
                  </Link>
                ),
              }}
            />,
          ]}
          CtaText={
            <FormattedMessage
              id="step-up-invalid.back-home"
              defaultMessage="Back home"
            />
          }
          ctaAction={onBackHome}
        />
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </GridLayout>
  );
};
