import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  colors,
  Text,
  Notification,
  Button,
  Header,
  Stack,
} from '@bt-healthcare/ui-toolkit';

import { ReactComponent as CancellationIllustration } from '../../assets/cancellation-success.svg';
import { Container, HeaderWrapper, HeroWrapper } from './styles';

export const CancellationSuccess = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Stack space="s4" id="" justifyContent="center" flexDirection="column">
        <HeroWrapper>
          <CancellationIllustration width="100%" height="100%" />
        </HeroWrapper>
        <HeaderWrapper>
          <Header>
            <FormattedMessage
              id="page.cancellation-success.message.title"
              defaultMessage="Your appointment has been cancelled"
            />
          </Header>
        </HeaderWrapper>
        <Text style={{ textAlign: 'center' }} color={colors.grey.grey09}>
          <FormattedMessage
            id="page.cancellation-success.message.description"
            defaultMessage="We appreciate you letting us know you need to cancel your appointment."
          />
        </Text>

        <Notification type="softWarning">
          <Text style={{ textAlign: 'center' }} color={colors.grey.grey09}>
            <FormattedMessage
              id="page.cancellation-success.message"
              defaultMessage="If you have been requested by your GP to have this appointment, please re-book another appointment at a more convenient time."
            />
          </Text>
        </Notification>

        <Button
          variant="primary"
          onClick={() => navigate('/')}
          id="go-back-cta"
        >
          <FormattedMessage
            id="page.cancellation-success.primary.button"
            defaultMessage="Back home"
          />
        </Button>
      </Stack>
    </Container>
  );
};
