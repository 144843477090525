import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, Notification, Row } from '@bt-healthcare/ui-toolkit';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { HealthInformationField } from './HealthInformationField';
import {
  errorsHealthInformation,
  submitButton,
} from '../../pages/HealthInformation/intl';
import { toHealthInformationInput } from '../../mappers/healthInformation';
import { PersonInput, useUpdatePersonMutation } from '../../services/graphql';
import { ROUTE } from '../../config/routes';
import {
  Form,
  HealthInformationType,
} from '../../pages/HealthInformation/types';
import { prepareSchema } from '../../utils/yup/schema';
import { HealthInformationFormProps } from './types';
import { StyledGrid, ButtonWrapper, StyledForm } from './styles';

export const HealthInformationForm = ({
  fields,
  type,
  initialValues,
}: HealthInformationFormProps) => {
  const [addHealthInformationMutation, { loading, error }] =
    useUpdatePersonMutation();
  const navigate = useNavigate();
  const schema = prepareSchema(fields);

  const methods = useForm<Form>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const {
    formState: { isValid },
    reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
    reset(initialValues);
  }, [initialValues]);

  const onSubmit = handleSubmit(async (values) => {
    const input = {
      healthInformation: toHealthInformationInput(values, type),
    } as PersonInput;
    await addHealthInformationMutation({
      variables: {
        input,
      },
    });
    navigate(ROUTE.ACCOUNT_HEALTH_INFORMATION);
  });
  const colEnd = {
    xxs: 'span 4',
    md: [
      HealthInformationType.BLOOD_PRESSURE_MEDICATION,
      HealthInformationType.CHRONIC_KIDNEY_DISEASE,
      HealthInformationType.CARDIOVASCULAR_DISEASE,
    ].includes(type)
      ? 'span 8'
      : 'span 5',
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={onSubmit}>
        <StyledGrid>
          {fields.map((field) => (
            <Row key={field.name}>
              <Col colEnd={colEnd}>
                <HealthInformationField field={field} />
              </Col>
            </Row>
          ))}
          {error && (
            <Row>
              <Col colEnd={colEnd}>
                <Notification type="softWarning">
                  <FormattedMessage {...errorsHealthInformation.mutation} />
                </Notification>
              </Col>
            </Row>
          )}
        </StyledGrid>
        <Row>
          <Col colEnd={colEnd}>
            <ButtonWrapper>
              <Button
                id="submit-reading-btn"
                variant="primary"
                size="maximum"
                onClick={onSubmit}
                disabled={!isValid || loading}
              >
                <FormattedMessage {...submitButton} />
              </Button>
            </ButtonWrapper>
          </Col>
        </Row>
      </StyledForm>
    </FormProvider>
  );
};
