import React, { useEffect } from 'react';
import { Row, Toggle } from '@bt-healthcare/ui-toolkit';
import { CreateObservationsForm } from './CreateObservationsForm';
import { useInputFieldsForUnitToggle } from '../../hooks/useInputFieldsForUnitToggle';
import { unitsDisplayName } from './intl';
import {
  CreateObservationDataFieldWithToggle,
  CreateObservationsFormWithToggleProps,
} from './types';
import { ToggleWrapper } from './styles';

export const CreateObservationsFormWithToggle = ({
  fields,
  unitSystems,
  handleActiveUnit,
  initialActiveUnit,
  ...props
}: CreateObservationsFormWithToggleProps) => {
  const { activeFields, activeUnit, setActiveUnit, items } =
    useInputFieldsForUnitToggle<CreateObservationDataFieldWithToggle>({
      unitSystems,
      fields,
      unitsIntl: unitsDisplayName,
      initialActiveUnit,
    });

  useEffect(() => {
    handleActiveUnit(activeUnit);
  }, [activeUnit]);

  return (
    <>
      {items && activeUnit && (
        <Row>
          <ToggleWrapper>
            <Toggle
              activeItem={activeUnit}
              items={items}
              setActive={setActiveUnit}
            />
          </ToggleWrapper>
        </Row>
      )}
      <CreateObservationsForm fields={activeFields} {...props} />
    </>
  );
};
