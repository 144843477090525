import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AimHumanName = {
  __typename?: 'AIMHumanName';
  family: Maybe<Scalars['String']>;
  given: Maybe<Scalars['String']>;
};

export type ActivateUserInput = {
  dateOfBirth: Scalars['Date'];
  surname: Scalars['String'];
  userId: Scalars['ID'];
};

export type ActivitiesGroupedView = {
  __typename?: 'ActivitiesGroupedView';
  activities: Array<ActivityView>;
  activityCategory: ActivityCategory;
  description: Scalars['String'];
};

export enum Activity {
  Basketball = 'BASKETBALL',
  BodyweightExercise = 'BODYWEIGHT_EXERCISE',
  Boxing = 'BOXING',
  BoxingOrKickboxing = 'BOXING_OR_KICKBOXING',
  BriskWalking = 'BRISK_WALKING',
  CasualCycling = 'CASUAL_CYCLING',
  CrossFit = 'CROSS_FIT',
  Dance = 'DANCE',
  ExerciseClasses = 'EXERCISE_CLASSES',
  FastWalking = 'FAST_WALKING',
  Football = 'FOOTBALL',
  Gardening = 'GARDENING',
  GeneralDiy = 'GENERAL_DIY',
  Golf = 'GOLF',
  HeavyGardening = 'HEAVY_GARDENING',
  Hiking = 'HIKING',
  HorseRiding = 'HORSE_RIDING',
  HouseworkAndDomesticChores = 'HOUSEWORK_AND_DOMESTIC_CHORES',
  IndoorClimbing = 'INDOOR_CLIMBING',
  IntenseCycling = 'INTENSE_CYCLING',
  IntervalTraining = 'INTERVAL_TRAINING',
  KayakingOrCanoeing = 'KAYAKING_OR_CANOEING',
  LapSwimming = 'LAP_SWIMMING',
  LeisurelySwimming = 'LEISURELY_SWIMMING',
  ManualWheelchairRunningPace = 'MANUAL_WHEELCHAIR_RUNNING_PACE',
  ManualWheelchairWalkingPace = 'MANUAL_WHEELCHAIR_WALKING_PACE',
  MartialArts = 'MARTIAL_ARTS',
  Other = 'OTHER',
  Pilates = 'PILATES',
  ResistanceTraining = 'RESISTANCE_TRAINING',
  RockClimbing = 'ROCK_CLIMBING',
  Rowing = 'ROWING',
  Rugby = 'RUGBY',
  RunningOrJogging = 'RUNNING_OR_JOGGING',
  SkiingOrSnowboarding = 'SKIING_OR_SNOWBOARDING',
  StandupPaddleBoarding = 'STANDUP_PADDLE_BOARDING',
  Surfing = 'SURFING',
  Taichi = 'TAICHI',
  TennisOrBadminton = 'TENNIS_OR_BADMINTON',
  WeightLifting = 'WEIGHT_LIFTING',
  Yoga = 'YOGA',
}

export enum ActivityCategory {
  BallSports = 'BALL_SPORTS',
  DanceAndMartialArts = 'DANCE_AND_MARTIAL_ARTS',
  GeneralFitness = 'GENERAL_FITNESS',
  GymActivities = 'GYM_ACTIVITIES',
  OtherActivities = 'OTHER_ACTIVITIES',
  OutdoorActivities = 'OUTDOOR_ACTIVITIES',
  Strength = 'STRENGTH',
}

export enum ActivityType {
  Balance = 'BALANCE',
  CardioModerate = 'CARDIO_MODERATE',
  CardioVigorous = 'CARDIO_VIGOROUS',
  Core = 'CORE',
  Flexibility = 'FLEXIBILITY',
  Functional = 'FUNCTIONAL',
  MindBody = 'MIND_BODY',
  Plyometrics = 'PLYOMETRICS',
  SportSpecific = 'SPORT_SPECIFIC',
  Stability = 'STABILITY',
  Strength = 'STRENGTH',
}

export type ActivityView = {
  __typename?: 'ActivityView';
  activity: Activity;
  activityDescription: Scalars['String'];
  activityTypeList: Array<ActivityType>;
};

export enum AdmissionStatus {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
  Monitoring = 'MONITORING',
}

export type AlcoholConversionView = {
  __typename?: 'AlcoholConversionView';
  alcoholDrinkDetails: AlcoholDrinkDetails;
  alcoholDrinkSize: AlcoholDrinkSize;
  alcoholType: AlcoholType;
  alcoholTypeDescription: Scalars['String'];
  strengthPercentage: Scalars['Float'];
  unitConversionFactor: Scalars['Float'];
  volumeInML: Scalars['Int'];
};

export enum AlcoholDrinkDetails {
  AlcopopsBottles = 'ALCOPOPS_BOTTLES',
  AlcopopsLargeCans = 'ALCOPOPS_LARGE_CANS',
  AlcopopsSmallCans = 'ALCOPOPS_SMALL_CANS',
  CocktailsNoOfShots = 'COCKTAILS_NO_OF_SHOTS',
  RegularBeerOrCiderBottles = 'REGULAR_BEER_OR_CIDER_BOTTLES',
  RegularBeerOrCiderLargeCans = 'REGULAR_BEER_OR_CIDER_LARGE_CANS',
  RegularBeerOrCiderPints = 'REGULAR_BEER_OR_CIDER_PINTS',
  RegularBeerOrCiderSmallCans = 'REGULAR_BEER_OR_CIDER_SMALL_CANS',
  SpiritsShots = 'SPIRITS_SHOTS',
  StrongBeerOrCiderBottles = 'STRONG_BEER_OR_CIDER_BOTTLES',
  StrongBeerOrCiderLargeCans = 'STRONG_BEER_OR_CIDER_LARGE_CANS',
  StrongBeerOrCiderPints = 'STRONG_BEER_OR_CIDER_PINTS',
  StrongBeerOrCiderSmallCans = 'STRONG_BEER_OR_CIDER_SMALL_CANS',
  WineBottle = 'WINE_BOTTLE',
  WineLargeGlass = 'WINE_LARGE_GLASS',
  WineMediumGlass = 'WINE_MEDIUM_GLASS',
  WineSmallGlass = 'WINE_SMALL_GLASS',
}

export enum AlcoholDrinkSize {
  Bottles_275Ml = 'BOTTLES_275_ML',
  Bottles_330Ml = 'BOTTLES_330_ML',
  Bottle_750Ml = 'BOTTLE_750_ML',
  LargeCans = 'LARGE_CANS',
  LargeGlass = 'LARGE_GLASS',
  MediumGlass = 'MEDIUM_GLASS',
  NumberOfCocktails = 'NUMBER_OF_COCKTAILS',
  Pints = 'PINTS',
  Shots = 'SHOTS',
  SmallCans = 'SMALL_CANS',
  SmallGlass = 'SMALL_GLASS',
}

export enum AlcoholType {
  Alcopops = 'ALCOPOPS',
  Cocktails = 'COCKTAILS',
  RegularBeerOrCider = 'REGULAR_BEER_OR_CIDER',
  Spirits = 'SPIRITS',
  StrongBeerOrCider_6PlusPercentage = 'STRONG_BEER_OR_CIDER_6_PLUS_PERCENTAGE',
  Wine = 'WINE',
}

export type Audit = {
  __typename?: 'Audit';
  created: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['ID']>;
  createdByUser: Maybe<UserProfile>;
  lastUpdated: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['ID']>;
  updatedByUser: Maybe<UserProfile>;
  version: Scalars['Int'];
};

export type Auth0CommonPasswordValidationError = {
  __typename?: 'Auth0CommonPasswordValidationError';
  /** The associated back-end error code returned in the body */
  code: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type Auth0ConflictValidationError = {
  __typename?: 'Auth0ConflictValidationError';
  /** The associated back-end error code returned in the body */
  code: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type Auth0UserInfoValidationError = {
  __typename?: 'Auth0UserInfoValidationError';
  /** The associated back-end error code returned in the body */
  code: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export enum AwaitingFromDischargeAssess {
  AgainstMedicalAdvice = 'AGAINST_MEDICAL_ADVICE',
  AssessmentToBeCompleted = 'ASSESSMENT_TO_BE_COMPLETED',
  CarePackageAtHomePathway_1 = 'CARE_PACKAGE_AT_HOME_PATHWAY_1',
  ComplexCarePlacementPathway_3 = 'COMPLEX_CARE_PLACEMENT_PATHWAY_3',
  EndOfLifePathway_1 = 'END_OF_LIFE_PATHWAY_1',
  EquipmentAtHomePathway_1 = 'EQUIPMENT_AT_HOME_PATHWAY_1',
  IntermediateCarePathway_2 = 'INTERMEDIATE_CARE_PATHWAY_2',
  None = 'NONE',
  PatientDeath = 'PATIENT_DEATH',
  SimpleDischargePathway_0 = 'SIMPLE_DISCHARGE_PATHWAY_0',
}

export type BmiScore = HealthScoreByElement & {
  __typename?: 'BMIScore';
  bmiRecommendations: Array<HealthScoreBmiRecommendation>;
  distanceToMagicNumber: Maybe<Scalars['Float']>;
  distanceToMaxScore: Maybe<Scalars['Float']>;
  healthScoreElementType: HealthScoreElement;
  riskCategory: Maybe<HealthScoreBmiRiskCategory>;
};

export type BandRange = {
  __typename?: 'BandRange';
  /** Inclusive. Null if unbounded. */
  max: Maybe<Scalars['Int']>;
  /** Inclusive */
  min: Scalars['Int'];
};

export enum BedCleaningStatus {
  NotRequired = 'NOT_REQUIRED',
  Required = 'REQUIRED',
}

export type BloodPressure = RatedObservation & {
  __typename?: 'BloodPressure';
  /** The diastolic blood pressure */
  diastolic: Scalars['Int'];
  /** Comes from FHIR interpretation */
  diastolicRating: Maybe<Scalars['String']>;
  /** Comes from FHIR interpretation, or resolved lazily using assess observation */
  fhirRating: FhirRating;
  /**
   * Comes from FHIR interpretation, or resolved lazily using assess observation
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
  /** The systolic blood pressure */
  systolic: Scalars['Int'];
  /** Comes from FHIR interpretation */
  systolicRating: Maybe<Scalars['String']>;
};

export type BloodPressureBand = {
  __typename?: 'BloodPressureBand';
  diastolicRange: BandRange;
  displayName: Scalars['String'];
  displayOrder: Scalars['Int'];
  /** This field is weakly typed, reflecting the variability of its underlying data structure, which is determined by the responses from the associated REST API. */
  fhirRating: Scalars['String'];
  lightColourTheme: ColorTheme;
  systolicRange: BandRange;
};

export type BloodPressureBands = {
  __typename?: 'BloodPressureBands';
  bpRatingsForCharts: Array<BloodPressureBand>;
  lastNHSReviewDate: Scalars['Date'];
  lowerBloodPressureLink: Scalars['String'];
  nextNHSReviewDate: Scalars['Date'];
  nhsGuidanceLink: Scalars['String'];
};

export type BloodPressureInfo = {
  __typename?: 'BloodPressureInfo';
  lastNHSReviewDate: Scalars['Date'];
  lowerBloodPressureLink: Scalars['String'];
  nextNHSReviewDate: Scalars['Date'];
  nhsGuidanceLink: Scalars['String'];
};

export type BloodPressureInput = {
  /** The diastolic blood pressure */
  diastolic: Scalars['Int'];
  /** The systolic blood pressure */
  systolic: Scalars['Int'];
};

export type BloodPressureMedication = {
  __typename?: 'BloodPressureMedication';
  created: Scalars['DateTime'];
  hadBPMedication: Scalars['Boolean'];
  hasBPMedication: Scalars['Boolean'];
};

export type BloodPressureMedicationInput = {
  created: InputMaybe<Scalars['DateTime']>;
  hadBPMedication: Scalars['Boolean'];
  hasBPMedication: Scalars['Boolean'];
};

export type BodyHeight = {
  __typename?: 'BodyHeight';
  height: Scalars['Float'];
  heightUnit: BodyHeightUnit;
};

export type BodyHeightInput = {
  height: Scalars['Float'];
  heightUnit: BodyHeightUnit;
};

/** Unit for metric or imperial system */
export enum BodyHeightUnit {
  Cm = 'cm',
  Inch = 'inch',
}

export type BodyTemperature = {
  __typename?: 'BodyTemperature';
  celsius: Scalars['Float'];
};

export type BodyTemperatureInput = {
  celsius: Scalars['Float'];
};

export type BodyWeight = {
  __typename?: 'BodyWeight';
  /**
   * Metric system
   * @deprecated Use weight + weightUnit
   */
  kg: Scalars['Float'];
  /**
   * Imperial system
   * @deprecated Use weight + weightUnit
   */
  lb: Scalars['Float'];
  /** @deprecated Use weightUnit */
  userInputUnit: BodyWeightUnitInput;
  /** Weight value */
  weight: Scalars['Float'];
  weightUnit: BodyWeightUnitInput;
};

export type BodyWeightInput = {
  /** Weight in metric system */
  kg: InputMaybe<Scalars['Float']>;
  /** Weight in imperial system */
  lb: InputMaybe<Scalars['Float']>;
  /** Weight value */
  weight: InputMaybe<Scalars['Float']>;
  weightUnit: InputMaybe<BodyWeightUnitInput>;
};

/** Unit for metric or imperial system */
export enum BodyWeightUnitInput {
  Kg = 'kg',
  Lb = 'lb',
}

export type CalculateHealthScoreInput = {
  bmi: HealthScoreQuestionnaireBmiInput;
  excessiveDrinking: HealthScoreQuestionnaireBingeDrinkingInput;
  person: HealthScoreQuestionnairePersonInput;
  physicalActivity: HealthScoreQuestionnairePhysicalActivityInput;
  sittingHours: InputMaybe<HealthScoreQuestionnaireSittingHoursInput>;
  sleepInterval: InputMaybe<HealthScoreQuestionnaireSleepIntervalInput>;
  smoking: HealthScoreQuestionnaireSmokingInput;
  stepsCount: InputMaybe<HealthScoreQuestionnaireStepsCountInput>;
  strength: HealthScoreQuestionnaireStrengthInput;
  weeklyAlcoholConsumption: HealthScoreQuestionnaireWeeklyAlcoholConsumptionInput;
};

export enum CarePackageStatusType {
  Complete = 'COMPLETE',
  Delayed = 'DELAYED',
  Incomplete = 'INCOMPLETE',
  None = 'NONE',
  NotRequired = 'NOT_REQUIRED',
}

export type CareSetting = Identifier & {
  __typename?: 'CareSetting';
  attributes: CareSettingAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingAttributes = {
  __typename?: 'CareSettingAttributes';
  contactPoints: Maybe<Array<Maybe<ContactPoint>>>;
  description: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** A list of related organisations such as General practices for a PCN */
  relatedOrganisations: Maybe<Array<Maybe<RelatedOrganisation>>>;
  res_phone: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
};

export type CareSettingClinician = Identifier & {
  __typename?: 'CareSettingClinician';
  attributes: CareSettingClinicianAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingClinicianAttributes = {
  __typename?: 'CareSettingClinicianAttributes';
  audit: Maybe<Audit>;
  careSettingClinicianModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  careSettingClinicianModuleRoles: Array<Maybe<UserModuleRole>>;
  careSettingClinicianUser: Maybe<User>;
  careSettingId: Scalars['ID'];
  /** @deprecated Use userId */
  clinicianId: Maybe<Scalars['ID']>;
  /** @deprecated Review usage with contactPoints */
  contactInformation: Maybe<CareSettingClinicianAttributesContactInformation>;
  contactPoints: Maybe<Array<Maybe<ContactPoint>>>;
  displayName: Maybe<Scalars['String']>;
  /** @deprecated Use displayName */
  dnHumanNameTextCurrent: Maybe<Scalars['String']>;
  /** @deprecated Use res_mobileNumber */
  dnPhoneNumberCurrent: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  humanNames: Maybe<Array<Maybe<HumanName>>>;
  receiveBedAvailabilitySmsAlerts: Maybe<Scalars['Boolean']>;
  res_mobileNumber: Maybe<Scalars['String']>;
  specialties: Maybe<Array<Maybe<SpecialtyType>>>;
  surname: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type CareSettingClinicianAttributesContactInformation = {
  __typename?: 'CareSettingClinicianAttributesContactInformation';
  primaryContactNumber: Maybe<Scalars['String']>;
  primaryEmailAddress: Maybe<Scalars['String']>;
};

export type CareSettingPatient = Identifier & {
  __typename?: 'CareSettingPatient';
  attributes: CareSettingPatientAttributes;
  id: Scalars['ID'];
  person: Person;
  type: Scalars['String'];
  /** @deprecated Deprecated in favor of User.defaultWardPatient */
  wardPatients: Maybe<Array<Maybe<WardPatient>>>;
};

export type CareSettingPatientAttributes = {
  __typename?: 'CareSettingPatientAttributes';
  admissionComments: Maybe<Scalars['String']>;
  admissionStatus: Maybe<AdmissionStatus>;
  admissionStatusUpdateDateTime: Maybe<Scalars['DateTime']>;
  audit: Maybe<Audit>;
  careSettingId: Maybe<Scalars['ID']>;
  decisionToAdmitDateTime: Maybe<Scalars['DateTime']>;
  decisionToAdmitWaitTime: Maybe<Scalars['Int']>;
  furtherWardPreferences: Maybe<Scalars['String']>;
  isolationType: IsolationType;
  isolationTypeOther: Maybe<Scalars['String']>;
  origin: Maybe<OriginType>;
  patientWardPreference: Maybe<PatientWardPreferencesType>;
  personId: Scalars['ID'];
  requiresIsolationYN: Maybe<YesNoType>;
  socialCareYN: Maybe<YesNoType>;
  specialty: Maybe<SpecialtyType>;
};

export type CareSettingStepUpAcceptanceInput = {
  wardPatientId: Scalars['ID'];
};

export type CareSettingStepUpInvitationAttributes = {
  __typename?: 'CareSettingStepUpInvitationAttributes';
  careSettingId: Scalars['ID'];
  monitoredConditions: Array<MonitoredCondition>;
  observationTypes: Array<ObservationType>;
  personId: Scalars['ID'];
};

export type Cholesterol = {
  __typename?: 'Cholesterol';
  /** HDL cholesterol */
  hdl: Scalars['Float'];
  /** Total cholesterol */
  total: Scalars['Float'];
  unit: CholesterolUnit;
};

export type CholesterolInput = {
  /** HDL cholesterol */
  hdl: Scalars['Float'];
  /** Total cholesterol */
  total: Scalars['Float'];
  unit: CholesterolUnit;
};

export enum CholesterolUnit {
  MilligramPerDeciliter = 'milligramPerDeciliter',
  MillimolePerLiter = 'millimolePerLiter',
}

export type Ckd = {
  __typename?: 'Ckd';
  ckdStatus: Scalars['Boolean'];
  created: Scalars['DateTime'];
};

export type CkdInput = {
  ckdStatus: Scalars['Boolean'];
};

export type ColorTheme = {
  __typename?: 'ColorTheme';
  backgroundColour: Scalars['String'];
  foregroundColour: Scalars['String'];
};

export enum ContactExclusionPreferences {
  ContactexclAll = 'CONTACTEXCL_ALL',
  ContactexclEmailAdvise = 'CONTACTEXCL_EMAIL_ADVISE',
  ContactexclEmailAll = 'CONTACTEXCL_EMAIL_ALL',
  ContactexclEmailLatestNewsUpdates = 'CONTACTEXCL_EMAIL_LATEST_NEWS_UPDATES',
  ContactexclEmailReminder = 'CONTACTEXCL_EMAIL_REMINDER',
  ContactexclSmsApptReminderGp = 'CONTACTEXCL_SMS_APPT_REMINDER_GP',
}

export type ContactExclusionPreferencesInput = {
  CONTACTEXCL_EMAIL_ADVISE: InputMaybe<Scalars['Boolean']>;
  CONTACTEXCL_EMAIL_LATEST_NEWS_UPDATES: InputMaybe<Scalars['Boolean']>;
  CONTACTEXCL_EMAIL_REMINDER: InputMaybe<Scalars['Boolean']>;
  CONTACTEXCL_SMS_APPT_REMINDER_GP: InputMaybe<Scalars['Boolean']>;
  personId: Scalars['ID'];
};

export type ContactExclusionPreferencesResult = {
  __typename?: 'ContactExclusionPreferencesResult';
  contactExclusionPreferences: Array<Maybe<ContactExclusionPreferences>>;
};

export type ContactPoint = {
  __typename?: 'ContactPoint';
  period: Period;
  rank: Maybe<Scalars['Int']>;
  system: ContactPointsSystem;
  use: Maybe<ContactPointsUse>;
  value: Scalars['String'];
};

export enum ContactPointsSystem {
  Email = 'EMAIL',
  Fax = 'FAX',
  Other = 'OTHER',
  Pager = 'PAGER',
  Phone = 'PHONE',
  Sms = 'SMS',
  Url = 'URL',
}

export enum ContactPointsUse {
  Home = 'HOME',
  Mobile = 'MOBILE',
  Old = 'OLD',
  Temp = 'TEMP',
  Work = 'WORK',
}

export enum Covid19Status {
  ConfirmedNegative = 'CONFIRMED_NEGATIVE',
  ConfirmedPositive = 'CONFIRMED_POSITIVE',
  None = 'NONE',
  Quarantined = 'QUARANTINED',
  Symptomatic = 'SYMPTOMATIC',
}

export type CreateUserRegistrationRecordResponse =
  | UserAlreadyExistsValidationError
  | UserRegistrationRecord;

export type CreateWardPatientObservationInput = {
  bloodPressure: InputMaybe<WardPatientObservationBloodPressureInput>;
  bodyHeight: InputMaybe<WardPatientObservationBodyHeightInput>;
  bodyTemperature: InputMaybe<WardPatientObservationBodyTemperatureInput>;
  bodyWeight: InputMaybe<WardPatientObservationBodyWeightInput>;
  cholesterol: InputMaybe<WardPatientObservationCholesterolInput>;
  fastingBloodGlucose: InputMaybe<WardPatientObservationFastingBloodGlucoseInput>;
  heartRhythm: InputMaybe<WardPatientObservationHeartRhythmInput>;
  pulse: InputMaybe<WardPatientObservationPulseInput>;
  spo2: InputMaybe<WardPatientObservationSpo2Input>;
};

export type Cvd = {
  __typename?: 'Cvd';
  created: Scalars['DateTime'];
  familyHadCvd: CvdFamilyInformation;
  hasCvd: Scalars['Boolean'];
};

export enum CvdFamilyInformation {
  DontKnow = 'DONT_KNOW',
  No = 'NO',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Yes = 'YES',
}

export type CvdInput = {
  familyHadCvd: CvdFamilyInformation;
  hasCvd: Scalars['Boolean'];
};

export type Diabetes = {
  __typename?: 'Diabetes';
  created: Scalars['DateTime'];
  diabetesStatus: DiabetesStatus;
};

export type DiabetesInput = {
  diabetesStatus: DiabetesStatus;
};

export enum DiabetesStatus {
  No = 'NO',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Type_1 = 'TYPE_1',
  Type_2 = 'TYPE_2',
}

export enum DischargeReasonType {
  None = 'NONE',
  Other = 'OTHER',
  PatientAdmittedToHospital = 'PATIENT_ADMITTED_TO_HOSPITAL',
  PatientRefusedRemoteMonitoring = 'PATIENT_REFUSED_REMOTE_MONITORING',
  RemoteMonitoringCompleted = 'REMOTE_MONITORING_COMPLETED',
}

export enum DischargeStatusFailed {
  DelayedPharmacy = 'DELAYED_PHARMACY',
  DelayedPortering = 'DELAYED_PORTERING',
  DelayedTest = 'DELAYED_TEST',
  DelayedTransfer = 'DELAYED_TRANSFER',
  None = 'NONE',
  NoCarePackage = 'NO_CARE_PACKAGE',
  Other = 'OTHER',
  PatientChange = 'PATIENT_CHANGE',
}

export enum DischargeStatusType {
  ClinicallyReady = 'CLINICALLY_READY',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Delayed = 'DELAYED',
  None = 'NONE',
  NotFit = 'NOT_FIT',
  Potential = 'POTENTIAL',
}

export enum ErrorCode {
  AttributeNotSet = 'ATTRIBUTE_NOT_SET',
  Generic = 'GENERIC',
  MandatoryHeaderError = 'MANDATORY_HEADER_ERROR',
  NotFoundError = 'NOT_FOUND_ERROR',
  PasswordContainsUserInformation = 'PASSWORD_CONTAINS_USER_INFORMATION',
  PasswordTooCommon = 'PASSWORD_TOO_COMMON',
  PatientDischarged = 'PATIENT_DISCHARGED',
  RegisterGpPatientLinkage = 'REGISTER_GP_PATIENT_LINKAGE',
  Unauthorized = 'UNAUTHORIZED',
  UserAlreadyExists = 'USER_ALREADY_EXISTS',
  VersionMismatch = 'VERSION_MISMATCH',
}

export enum Ethnicity {
  AsianOrAsianBritishAnyOtherAsianBackground = 'ASIAN_OR_ASIAN_BRITISH_ANY_OTHER_ASIAN_BACKGROUND',
  AsianOrAsianBritishBangladeshi = 'ASIAN_OR_ASIAN_BRITISH_BANGLADESHI',
  AsianOrAsianBritishIndian = 'ASIAN_OR_ASIAN_BRITISH_INDIAN',
  AsianOrAsianBritishPakistani = 'ASIAN_OR_ASIAN_BRITISH_PAKISTANI',
  BlackOrBlackBritishAfrican = 'BLACK_OR_BLACK_BRITISH_AFRICAN',
  BlackOrBlackBritishAnyOtherBlackBackground = 'BLACK_OR_BLACK_BRITISH_ANY_OTHER_BLACK_BACKGROUND',
  BlackOrBlackBritishCaribbean = 'BLACK_OR_BLACK_BRITISH_CARIBBEAN',
  MixedAnyOtherMixed = 'MIXED_ANY_OTHER_MIXED',
  MixedWhiteAndAsian = 'MIXED_WHITE_AND_ASIAN',
  MixedWhiteAndBlackAfrican = 'MIXED_WHITE_AND_BLACK_AFRICAN',
  MixedWhiteAndBlackCaribbean = 'MIXED_WHITE_AND_BLACK_CARIBBEAN',
  NotKnown = 'NOT_KNOWN',
  NotStated = 'NOT_STATED',
  OtherEthnicGroupsAnyOtherEthnicGroup = 'OTHER_ETHNIC_GROUPS_ANY_OTHER_ETHNIC_GROUP',
  OtherEthnicGroupsChinese = 'OTHER_ETHNIC_GROUPS_CHINESE',
  WhiteAnyOtherWhiteBackground = 'WHITE_ANY_OTHER_WHITE_BACKGROUND',
  WhiteBritish = 'WHITE_BRITISH',
  WhiteIrish = 'WHITE_IRISH',
}

export enum FhirRating {
  CriticalHigh = 'CRITICAL_HIGH',
  CriticalLow = 'CRITICAL_LOW',
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL',
  SignificantlyHigh = 'SIGNIFICANTLY_HIGH',
}

export type FastingBloodGlucose = RatedObservation & {
  __typename?: 'FastingBloodGlucose';
  /** Fasting blood glucose */
  bloodGlucose: Scalars['Float'];
  fhirRating: FhirRating;
  /**
   * FHIR rating
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
};

export type FastingBloodGlucoseInput = {
  /** Fasting blood glucose */
  bloodGlucose: Scalars['Float'];
};

export enum GenderAssignedAtBirth {
  Female = 'FEMALE',
  Male = 'MALE',
}

export enum GenderIdentifiedAs {
  Female = 'FEMALE',
  Male = 'MALE',
  None = 'NONE',
  NonBinary = 'NON_BINARY',
  Other = 'OTHER',
  PreferNone = 'PREFER_NONE',
  Transman = 'TRANSMAN',
  Transwoman = 'TRANSWOMAN',
}

export type GeneralPracticeAppointmentBooking = {
  __typename?: 'GeneralPracticeAppointmentBooking';
  attributes: GeneralPracticeAppointmentBookingBody;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type GeneralPracticeAppointmentBookingBody = {
  __typename?: 'GeneralPracticeAppointmentBookingBody';
  appointmentSlotId: Scalars['ID'];
  bookingReason: Scalars['String'];
  emailAddress: Scalars['String'];
  endTime: Scalars['DateTime'];
  slotTypeName: Scalars['String'];
  startTime: Scalars['DateTime'];
  telephoneContactType: Scalars['String'];
  telephoneNumber: Scalars['String'];
};

export type GeneralPracticeAppointmentBookingInput = {
  appointmentSlotId: Scalars['ID'];
  bookingReason: Scalars['String'];
  emailAddress: Scalars['String'];
  endTime: Scalars['DateTime'];
  gpOdsCode: InputMaybe<Scalars['String']>;
  personId: Scalars['String'];
  practiceName: Scalars['String'];
  slotTypeName: Scalars['String'];
  startTime: Scalars['DateTime'];
  telephoneContactType: Scalars['String'];
  telephoneNumber: Scalars['String'];
};

export type GeneralPracticeAppointmentSlot = {
  __typename?: 'GeneralPracticeAppointmentSlot';
  attributes: GeneralPracticeAppointmentSlotAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type GeneralPracticeAppointmentSlotAttributes = {
  __typename?: 'GeneralPracticeAppointmentSlotAttributes';
  appointmentSlotId: Scalars['String'];
  clinicianDisplayName: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  locationAddress: Maybe<Scalars['String']>;
  locationName: Maybe<Scalars['String']>;
  locationPostcode: Maybe<Scalars['String']>;
  sessionType: Maybe<Scalars['String']>;
  slotTypeName: Scalars['String'];
  slotTypeStatus: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
};

export type GeneralPracticeAppointmentSlotGroup = {
  __typename?: 'GeneralPracticeAppointmentSlotGroup';
  appointmentSlots: Array<GeneralPracticeAppointmentSlot>;
  type: Scalars['String'];
};

export type GeneralPracticeBookedAppointmentAddress = {
  __typename?: 'GeneralPracticeBookedAppointmentAddress';
  buildingName: Maybe<Scalars['String']>;
  line1: Maybe<Scalars['String']>;
  line2: Maybe<Scalars['String']>;
  line3: Maybe<Scalars['String']>;
  line4: Maybe<Scalars['String']>;
  line5: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
};

export type GeneralPracticeBookedAppointmentAttributes = {
  __typename?: 'GeneralPracticeBookedAppointmentAttributes';
  address: Maybe<GeneralPracticeBookedAppointmentAddress>;
  appointmentId: Scalars['String'];
  appointmentType: Scalars['String'];
  created: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  isCancellable: Maybe<Scalars['Boolean']>;
  sessionType: Maybe<Scalars['String']>;
  staffName: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  status: Maybe<Scalars['String']>;
};

export type GeneralPracticeBookedAppointmentSlot = {
  __typename?: 'GeneralPracticeBookedAppointmentSlot';
  attributes: GeneralPracticeBookedAppointmentAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type GeneralPracticePatientLinkageRegistration = {
  __typename?: 'GeneralPracticePatientLinkageRegistration';
  attributes: GeneralPracticePatientLinkageRegistrationResponse;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type GeneralPracticePatientLinkageRegistrationInput = {
  accountId: Scalars['ID'];
  dateOfBirth: Scalars['Date'];
  gpOdsCode: Scalars['String'];
  linkageKey: Scalars['String'];
  personId: Scalars['ID'];
  surname: Scalars['String'];
};

export type GeneralPracticePatientLinkageRegistrationResponse = {
  __typename?: 'GeneralPracticePatientLinkageRegistrationResponse';
  accountId: Scalars['ID'];
  dateOfBirth: Scalars['Date'];
  gpOdsCode: Scalars['String'];
  linkageKey: Scalars['String'];
  patientId: Scalars['ID'];
  personId: Scalars['ID'];
  surname: Scalars['String'];
};

export type GeneralPracticePatientMedicalRecordDetails = {
  __typename?: 'GeneralPracticePatientMedicalRecordDetails';
  allergies: Scalars['Boolean'];
  consultations: Scalars['Boolean'];
  immunisations: Scalars['Boolean'];
  medication: Scalars['Boolean'];
  problems: Scalars['Boolean'];
  testResults: Scalars['Boolean'];
};

export type GeneralPracticePatientSettings = {
  __typename?: 'GeneralPracticePatientSettings';
  attributes: GeneralPracticePatientSettingsAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type GeneralPracticePatientSettingsAttributes = {
  __typename?: 'GeneralPracticePatientSettingsAttributes';
  appointments: Scalars['Boolean'];
  medicalRecord: Scalars['Boolean'];
  medicalRecordDetails: GeneralPracticePatientMedicalRecordDetails;
  repeatPrescriptions: Scalars['Boolean'];
};

export type GeneralPracticeServicePracticeSettings = {
  __typename?: 'GeneralPracticeServicePracticeSettings';
  attributes: GeneralPracticeServicePracticeSettingsAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type GeneralPracticeServicePracticeSettingsAttributes = {
  __typename?: 'GeneralPracticeServicePracticeSettingsAttributes';
  appointmentsSupported: Scalars['Boolean'];
  medicalRecordSupported: Scalars['Boolean'];
  repeatPrescriptionsSupported: Scalars['Boolean'];
  requestId: Scalars['ID'];
};

export type GetUserRegistrationRecordResponse =
  | RegistrationLinkExpiredValidationError
  | UserRegistrationRecord;

export type HealthInformation = {
  __typename?: 'HealthInformation';
  bloodPressureMedicationInformation: Maybe<BloodPressureMedication>;
  ckdInformation: Maybe<Ckd>;
  cvdInformation: Maybe<Cvd>;
  diabetesInformation: Maybe<Diabetes>;
  heightInformation: Maybe<Height>;
  smokingInformation: Maybe<Smoking>;
};

export type HealthInformationInput = {
  bloodPressureMedication: InputMaybe<BloodPressureMedicationInput>;
  ckd: InputMaybe<CkdInput>;
  cvd: InputMaybe<CvdInput>;
  diabetes: InputMaybe<DiabetesInput>;
  height: InputMaybe<HeightInput>;
  smoking: InputMaybe<SmokingInput>;
};

export type HealthScore = Identifier & {
  __typename?: 'HealthScore';
  attributes: HealthScoreAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type HealthScoreAttributes = {
  __typename?: 'HealthScoreAttributes';
  currentHealthScoreZone: HealthSpanZone;
  healthScoreElements: Array<HealthScoreUnion>;
  healthScoreQuestionnaire: HealthScoreQuestionnaire;
  healthSpanPotentialIncrease: Scalars['Int'];
  maxScore: Scalars['Int'];
  targetHealthScoreZone: HealthSpanZone;
  totalScore: Scalars['Int'];
  totalScoreInPercentage: Scalars['Int'];
  totalTargetScore: Scalars['Int'];
  totalTargetScoreInPercentage: Scalars['Int'];
};

export enum HealthScoreBmiRecommendation {
  BmiAgeUnder_18VisitNhsWebsite = 'BMI_AGE_UNDER_18_VISIT_NHS_WEBSITE',
  BmiHealthyWeightMaintainCurrentWeight = 'BMI_HEALTHY_WEIGHT_MAINTAIN_CURRENT_WEIGHT',
  BmiObeseReduceWeight = 'BMI_OBESE_REDUCE_WEIGHT',
  BmiOverweightReduceWeight = 'BMI_OVERWEIGHT_REDUCE_WEIGHT',
  BmiUnderweightIncreaseWeight = 'BMI_UNDERWEIGHT_INCREASE_WEIGHT',
}

export enum HealthScoreBmiRiskCategory {
  Healthy = 'HEALTHY',
  Obese = 'OBESE',
  OverWeight = 'OVER_WEIGHT',
  UnderWeight = 'UNDER_WEIGHT',
}

export enum HealthScoreBmiZone {
  BmiHealthy = 'BMI_HEALTHY',
  BmiObese = 'BMI_OBESE',
  BmiOverWeight = 'BMI_OVER_WEIGHT',
  BmiUnderWeight = 'BMI_UNDER_WEIGHT',
}

export type HealthScoreByElement = {
  distanceToMagicNumber: Maybe<Scalars['Float']>;
  distanceToMaxScore: Maybe<Scalars['Float']>;
  healthScoreElementType: HealthScoreElement;
};

export enum HealthScoreDailySittingZone {
  SittingAscending = 'SITTING_ASCENDING',
  SittingPeak = 'SITTING_PEAK',
  SittingStarter = 'SITTING_STARTER',
  SittingSummit = 'SITTING_SUMMIT',
}

export enum HealthScoreDailyStepsZone {
  StepsAscending = 'STEPS_ASCENDING',
  StepsPeak = 'STEPS_PEAK',
  StepsStarter = 'STEPS_STARTER',
  StepsSummit = 'STEPS_SUMMIT',
}

export enum HealthScoreElement {
  Bmi = 'BMI',
  ExcessiveAlcoholConsumptionInSession = 'EXCESSIVE_ALCOHOL_CONSUMPTION_IN_SESSION',
  PhysicalActivity = 'PHYSICAL_ACTIVITY',
  SittingDaily = 'SITTING_DAILY',
  SleepDaily = 'SLEEP_DAILY',
  SmokingStatus = 'SMOKING_STATUS',
  StepsDaily = 'STEPS_DAILY',
  StrengthTraining = 'STRENGTH_TRAINING',
  WeeklyAlcoholConsumption = 'WEEKLY_ALCOHOL_CONSUMPTION',
}

export enum HealthScoreElementZone {
  BmiHealthy = 'BMI_HEALTHY',
  BmiObese = 'BMI_OBESE',
  BmiOverWeight = 'BMI_OVER_WEIGHT',
  BmiUnderWeight = 'BMI_UNDER_WEIGHT',
  ExcessiveAlcoholConsumptionPeak = 'EXCESSIVE_ALCOHOL_CONSUMPTION_PEAK',
  ExcessiveAlcoholConsumptionStarter = 'EXCESSIVE_ALCOHOL_CONSUMPTION_STARTER',
  PhysicalActivityAscending = 'PHYSICAL_ACTIVITY_ASCENDING',
  PhysicalActivityPeak = 'PHYSICAL_ACTIVITY_PEAK',
  PhysicalActivityStarter = 'PHYSICAL_ACTIVITY_STARTER',
  PhysicalActivitySummit = 'PHYSICAL_ACTIVITY_SUMMIT',
  SittingAscending = 'SITTING_ASCENDING',
  SittingPeak = 'SITTING_PEAK',
  SittingStarter = 'SITTING_STARTER',
  SittingSummit = 'SITTING_SUMMIT',
  SleepAscending = 'SLEEP_ASCENDING',
  SleepPeak = 'SLEEP_PEAK',
  SleepStarter = 'SLEEP_STARTER',
  SleepSummit = 'SLEEP_SUMMIT',
  SmokingAscending = 'SMOKING_ASCENDING',
  SmokingPeak = 'SMOKING_PEAK',
  SmokingStarter = 'SMOKING_STARTER',
  SmokingSummit = 'SMOKING_SUMMIT',
  StepsAscending = 'STEPS_ASCENDING',
  StepsPeak = 'STEPS_PEAK',
  StepsStarter = 'STEPS_STARTER',
  StepsSummit = 'STEPS_SUMMIT',
  StrengthTrainingAscending = 'STRENGTH_TRAINING_ASCENDING',
  StrengthTrainingPeak = 'STRENGTH_TRAINING_PEAK',
  StrengthTrainingStarter = 'STRENGTH_TRAINING_STARTER',
  StrengthTrainingSummit = 'STRENGTH_TRAINING_SUMMIT',
  WeeklyAlcoholConsumptionAscending = 'WEEKLY_ALCOHOL_CONSUMPTION_ASCENDING',
  WeeklyAlcoholConsumptionPeak = 'WEEKLY_ALCOHOL_CONSUMPTION_PEAK',
  WeeklyAlcoholConsumptionStarter = 'WEEKLY_ALCOHOL_CONSUMPTION_STARTER',
  WeeklyAlcoholConsumptionSummit = 'WEEKLY_ALCOHOL_CONSUMPTION_SUMMIT',
}

export type HealthScoreElementZoneView = {
  __typename?: 'HealthScoreElementZoneView';
  description: Maybe<Scalars['String']>;
  healthScoreElementType: HealthScoreElement;
  healthScoreElementZone: HealthScoreElementZone;
  healthScoreRange: Maybe<HealthScoreRange>;
  healthSpanZone: Maybe<HealthSpanZone>;
};

export enum HealthScoreExcessiveAlcoholConsumptionZone {
  ExcessiveAlcoholConsumptionPeak = 'EXCESSIVE_ALCOHOL_CONSUMPTION_PEAK',
  ExcessiveAlcoholConsumptionStarter = 'EXCESSIVE_ALCOHOL_CONSUMPTION_STARTER',
}

export enum HealthScoreExerciseRecommendation {
  ExerciseIncreaseActivity = 'EXERCISE_INCREASE_ACTIVITY',
  ExerciseIncreaseActivityAtleast_150MinsPerWeek = 'EXERCISE_INCREASE_ACTIVITY_ATLEAST_150_MINS_PER_WEEK',
  ExerciseMaintainOrIncrease = 'EXERCISE_MAINTAIN_OR_INCREASE',
}

export enum HealthScorePerSessionExcessiveDrinkingRecommendation {
  PerSessionExcessiveAlcoholConsumptionNoGoodMaintain = 'PER_SESSION_EXCESSIVE_ALCOHOL_CONSUMPTION_NO_GOOD_MAINTAIN',
  PerSessionExcessiveAlcoholConsumptionYesReduce = 'PER_SESSION_EXCESSIVE_ALCOHOL_CONSUMPTION_YES_REDUCE',
}

export enum HealthScorePerSessionExcessiveDrinkingRiskCategory {
  High = 'HIGH',
  Low = 'LOW',
}

export enum HealthScorePhysicalActivityZone {
  PhysicalActivityAscending = 'PHYSICAL_ACTIVITY_ASCENDING',
  PhysicalActivityPeak = 'PHYSICAL_ACTIVITY_PEAK',
  PhysicalActivityStarter = 'PHYSICAL_ACTIVITY_STARTER',
  PhysicalActivitySummit = 'PHYSICAL_ACTIVITY_SUMMIT',
}

export type HealthScoreQuestionnaire = {
  __typename?: 'HealthScoreQuestionnaire';
  bmi: Maybe<HealthScoreQuestionnaireBmi>;
  excessiveDrinking: Maybe<HealthScoreQuestionnaireBingeDrinking>;
  person: Maybe<HealthScoreQuestionnairePerson>;
  physicalActivity: Maybe<HealthScoreQuestionnairePhysicalActivity>;
  sittingHours: Maybe<HealthScoreQuestionnaireSittingHours>;
  sleepInterval: Maybe<HealthScoreQuestionnaireSleepInterval>;
  smoking: Maybe<HealthScoreQuestionnaireSmoking>;
  stepsCount: Maybe<HealthScoreQuestionnaireStepsCount>;
  strength: Maybe<HealthScoreQuestionnaireStrength>;
  weeklyAlcoholConsumption: Maybe<HealthScoreQuestionnaireWeeklyAlcoholConsumption>;
};

export type HealthScoreQuestionnaireAlcoholQuantity = {
  __typename?: 'HealthScoreQuestionnaireAlcoholQuantity';
  details: AlcoholDrinkDetails;
  drinkType: AlcoholType;
  quantity: Scalars['Float'];
};

export type HealthScoreQuestionnaireAlcoholQuantityInput = {
  details: AlcoholDrinkDetails;
  drinkType: AlcoholType;
  quantity: Scalars['Float'];
};

export type HealthScoreQuestionnaireBmi = {
  __typename?: 'HealthScoreQuestionnaireBMI';
  height: BodyHeight;
  weight: BodyWeight;
};

export type HealthScoreQuestionnaireBmiInput = {
  height: BodyHeightInput;
  weight: BodyWeightInput;
};

export type HealthScoreQuestionnaireBingeDrinking = {
  __typename?: 'HealthScoreQuestionnaireBingeDrinking';
  excessiveAlcoholConsumptionInSession: Scalars['Boolean'];
};

export type HealthScoreQuestionnaireBingeDrinkingInput = {
  excessiveAlcoholConsumptionInSession: Scalars['Boolean'];
};

export type HealthScoreQuestionnairePerson = {
  __typename?: 'HealthScoreQuestionnairePerson';
  age: Scalars['Int'];
  genderAssignedAtBirth: GenderAssignedAtBirth;
  genderIdentifiedAs: Maybe<GenderIdentifiedAs>;
};

export type HealthScoreQuestionnairePersonInput = {
  age: Scalars['Int'];
  genderAssignedAtBirth: GenderAssignedAtBirth;
  genderIdentifiedAs: InputMaybe<GenderIdentifiedAs>;
};

export type HealthScoreQuestionnairePhysicalActivity = {
  __typename?: 'HealthScoreQuestionnairePhysicalActivity';
  activitiesDuration: Maybe<
    Array<HealthScoreQuestionnairePhysicalActivityDuration>
  >;
  engagedInPhysicalActivities: Scalars['Boolean'];
};

export type HealthScoreQuestionnairePhysicalActivityDuration = {
  __typename?: 'HealthScoreQuestionnairePhysicalActivityDuration';
  activity: Activity;
  averageSessionTime: Scalars['ISO8601Duration'];
  noOfDaysPerWeek: Scalars['Int'];
};

export type HealthScoreQuestionnairePhysicalActivityDurationInput = {
  activity: Activity;
  averageSessionTime: Scalars['ISO8601Duration'];
  noOfDaysPerWeek: Scalars['Int'];
};

export type HealthScoreQuestionnairePhysicalActivityInput = {
  activitiesDuration: InputMaybe<
    Array<HealthScoreQuestionnairePhysicalActivityDurationInput>
  >;
  engagedInPhysicalActivities: Scalars['Boolean'];
};

export type HealthScoreQuestionnaireSittingHours = {
  __typename?: 'HealthScoreQuestionnaireSittingHours';
  dailySittingHours: Maybe<Array<Scalars['Int']>>;
};

export type HealthScoreQuestionnaireSittingHoursInput = {
  dailySittingHours: InputMaybe<Array<Scalars['Int']>>;
};

export type HealthScoreQuestionnaireSleepInterval = {
  __typename?: 'HealthScoreQuestionnaireSleepInterval';
  sleepEndTime: Scalars['String'];
  sleepStartTime: Scalars['String'];
};

export type HealthScoreQuestionnaireSleepIntervalInput = {
  sleepEndTime: Scalars['String'];
  sleepStartTime: Scalars['String'];
};

export type HealthScoreQuestionnaireSmoking = {
  __typename?: 'HealthScoreQuestionnaireSmoking';
  status: HealthScoreSmokingStatus;
};

export type HealthScoreQuestionnaireSmokingInput = {
  status: HealthScoreSmokingStatus;
};

export type HealthScoreQuestionnaireStepsCount = {
  __typename?: 'HealthScoreQuestionnaireStepsCount';
  dailyStepsCount: Maybe<Scalars['Int']>;
  wheelChairUser: Maybe<Scalars['Boolean']>;
};

export type HealthScoreQuestionnaireStepsCountInput = {
  dailyStepsCount: InputMaybe<Scalars['Int']>;
  wheelChairUser: InputMaybe<Scalars['Boolean']>;
};

export type HealthScoreQuestionnaireStrength = {
  __typename?: 'HealthScoreQuestionnaireStrength';
  engagedInStrengthActivities: Scalars['Boolean'];
  strengthActivities: Maybe<Array<Activity>>;
  trainingDaysPerWeek: Maybe<Scalars['Int']>;
};

export type HealthScoreQuestionnaireStrengthInput = {
  engagedInStrengthActivities: Scalars['Boolean'];
  strengthActivities: InputMaybe<Array<Activity>>;
  trainingDaysPerWeek: InputMaybe<Scalars['Int']>;
};

export type HealthScoreQuestionnaireWeeklyAlcoholConsumption = {
  __typename?: 'HealthScoreQuestionnaireWeeklyAlcoholConsumption';
  alcoholByType: Maybe<Array<HealthScoreQuestionnaireAlcoholQuantity>>;
  weeklyAlcoholConsumptionDays: Scalars['Int'];
};

export type HealthScoreQuestionnaireWeeklyAlcoholConsumptionInput = {
  alcoholByType: InputMaybe<
    Array<HealthScoreQuestionnaireAlcoholQuantityInput>
  >;
  weeklyAlcoholConsumptionDays: Scalars['Int'];
};

export type HealthScoreRange = {
  __typename?: 'HealthScoreRange';
  endInclusive: Maybe<Scalars['Boolean']>;
  max: Maybe<Scalars['Int']>;
  min: Maybe<Scalars['Int']>;
  startInclusive: Maybe<Scalars['Boolean']>;
};

export type HealthScoreReferenceData = {
  __typename?: 'HealthScoreReferenceData';
  activitiesGrouped: Array<ActivitiesGroupedView>;
  alcohol: Array<AlcoholConversionView>;
  elementZones: Array<HealthScoreElementZoneView>;
  strengthActivities: Array<ActivityView>;
  zones: Array<HealthSpanZoneView>;
};

export enum HealthScoreSittingHoursCategory {
  Optimal = 'OPTIMAL',
  SubOptimal = 'SUB_OPTIMAL',
  UnderActive = 'UNDER_ACTIVE',
}

export enum HealthScoreSittingRecommendations {
  ExcessiveSittingReduceTo_6Hours = 'EXCESSIVE_SITTING_REDUCE_TO_6_HOURS',
  LimitedSittingReduceTo_4Hours = 'LIMITED_SITTING_REDUCE_TO_4_HOURS',
  ModerateSittingReduceTo_3Hours = 'MODERATE_SITTING_REDUCE_TO_3_HOURS',
  OptimalSittingStayActive = 'OPTIMAL_SITTING_STAY_ACTIVE',
}

export enum HealthScoreSleepCategory {
  Excessive = 'EXCESSIVE',
  Ideal = 'IDEAL',
  Insufficient = 'INSUFFICIENT',
  Low = 'LOW',
}

export enum HealthScoreSleepRecommendations {
  SleepIsInsufficientIncreaseTo_7Hours = 'SLEEP_IS_INSUFFICIENT_INCREASE_TO_7HOURS',
  SleepIsLowIncreaseTo_7Hours = 'SLEEP_IS_LOW_INCREASE_TO_7HOURS',
  SleepMaintain = 'SLEEP_MAINTAIN',
  SleepReduce = 'SLEEP_REDUCE',
}

export enum HealthScoreSleepingZone {
  SleepAscending = 'SLEEP_ASCENDING',
  SleepPeak = 'SLEEP_PEAK',
  SleepStarter = 'SLEEP_STARTER',
  SleepSummit = 'SLEEP_SUMMIT',
}

export enum HealthScoreSmokingRecommendation {
  GoodNeverSmoked = 'GOOD_NEVER_SMOKED',
  MaintainSmokingFreeLifestyle = 'MAINTAIN_SMOKING_FREE_LIFESTYLE',
  StopECigarettes = 'STOP_E_CIGARETTES',
  StopSmoking = 'STOP_SMOKING',
}

export enum HealthScoreSmokingRiskCategory {
  High = 'HIGH',
  Low = 'LOW',
  None = 'NONE',
}

export enum HealthScoreSmokingStatus {
  NoNeverSmoked = 'NO_NEVER_SMOKED',
  NoQuit_5YearsAgo = 'NO_QUIT_5_YEARS_AGO',
  NoQuit_10YearsAgo = 'NO_QUIT_10_YEARS_AGO',
  NoQuit_15YearsAgo = 'NO_QUIT_15_YEARS_AGO',
  NoQuit_20YearsAgo = 'NO_QUIT_20_YEARS_AGO',
  YesCurrentlySmoking = 'YES_CURRENTLY_SMOKING',
}

export enum HealthScoreSmokingZone {
  SmokingAscending = 'SMOKING_ASCENDING',
  SmokingPeak = 'SMOKING_PEAK',
  SmokingStarter = 'SMOKING_STARTER',
  SmokingSummit = 'SMOKING_SUMMIT',
}

export enum HealthScoreStepsCategory {
  Active = 'ACTIVE',
  Low = 'LOW',
  Moderate = 'MODERATE',
  Sedentary = 'SEDENTARY',
}

export enum HealthScoreStepsRecommendation {
  IncreaseStepCountTo_10K = 'INCREASE_STEP_COUNT_TO_10K',
  StepCountMaintain = 'STEP_COUNT_MAINTAIN',
}

export enum HealthScoreStrengthRecommendation {
  StrengthTrainingIncrease = 'STRENGTH_TRAINING_INCREASE',
  StrengthTrainingMaintain = 'STRENGTH_TRAINING_MAINTAIN',
}

export enum HealthScoreStrengthZone {
  StrengthTrainingAscending = 'STRENGTH_TRAINING_ASCENDING',
  StrengthTrainingPeak = 'STRENGTH_TRAINING_PEAK',
  StrengthTrainingStarter = 'STRENGTH_TRAINING_STARTER',
  StrengthTrainingSummit = 'STRENGTH_TRAINING_SUMMIT',
}

export type HealthScoreUnion =
  | BmiScore
  | PerSessionExcessivePerSessionAlcoholConsumptionScore
  | PhysicalActivityScore
  | SittingScore
  | SleepScore
  | SmokingScore
  | StepsScore
  | StrengthTrainingScore
  | WeeklyAlcoholConsumptionScore;

export enum HealthScoreWeeklyAlcoholConsumptionRecommendation {
  AlcoholConsumptionExcessiveReduceUnitsBelow_14 = 'ALCOHOL_CONSUMPTION_EXCESSIVE_REDUCE_UNITS_BELOW_14',
  AlcoholConsumptionExcessiveReduceUnitsBelow_26 = 'ALCOHOL_CONSUMPTION_EXCESSIVE_REDUCE_UNITS_BELOW_26',
  AlcoholConsumptionModerateReduceUnitsBelow_4 = 'ALCOHOL_CONSUMPTION_MODERATE_REDUCE_UNITS_BELOW_4',
  AlcoholConsumptionOptimumGoodAlcoholIntakeIsLow = 'ALCOHOL_CONSUMPTION_OPTIMUM_GOOD_ALCOHOL_INTAKE_IS_LOW',
}

export enum HealthScoreWeeklyAlcoholConsumptionZone {
  WeeklyAlcoholConsumptionAscending = 'WEEKLY_ALCOHOL_CONSUMPTION_ASCENDING',
  WeeklyAlcoholConsumptionPeak = 'WEEKLY_ALCOHOL_CONSUMPTION_PEAK',
  WeeklyAlcoholConsumptionStarter = 'WEEKLY_ALCOHOL_CONSUMPTION_STARTER',
  WeeklyAlcoholConsumptionSummit = 'WEEKLY_ALCOHOL_CONSUMPTION_SUMMIT',
}

export enum HealthSpanZone {
  Ascending = 'ASCENDING',
  Peak = 'PEAK',
  Starter = 'STARTER',
  Summit = 'SUMMIT',
}

export type HealthSpanZoneView = {
  __typename?: 'HealthSpanZoneView';
  zone: HealthSpanZone;
  zoneDescription: Scalars['String'];
  zoneRangeLower: Scalars['Int'];
  zoneRangeUpper: Scalars['Int'];
};

export type HeartRhythm = {
  __typename?: 'HeartRhythm';
  bpm: Scalars['Int'];
  interpretation: HeartRhythmInterpretation;
  symptoms: Maybe<Array<Scalars['String']>>;
};

export type HeartRhythmInput = {
  bpm: Scalars['Int'];
  interpretation: HeartRhythmInterpretation;
  symptoms: InputMaybe<Array<HeartRhythmSymptom>>;
};

export enum HeartRhythmInterpretation {
  Detected = 'Detected',
  Indeterminate = 'Indeterminate',
  NotDetected = 'NotDetected',
}

export enum HeartRhythmSymptom {
  Dizziness = 'dizziness',
  Lightheadedness = 'lightheadedness',
  Nausea = 'nausea',
  Palpitations = 'palpitations',
  ShortnessOfBreath = 'shortness_of_breath',
  Sweating = 'sweating',
}

export type Height = {
  __typename?: 'Height';
  created: Scalars['DateTime'];
  unit: HeightUnit;
  value: Scalars['Float'];
};

export type HeightInput = {
  created: InputMaybe<Scalars['DateTime']>;
  unit: HeightUnit;
  value: Scalars['Float'];
};

export enum HeightUnit {
  Cm = 'cm',
  Inch = 'inch',
}

export type HumanName = {
  __typename?: 'HumanName';
  family: Maybe<Scalars['String']>;
  given: Maybe<Array<Maybe<Scalars['String']>>>;
  period: Period;
  prefix: Maybe<Array<Maybe<Scalars['String']>>>;
  suffix: Maybe<Array<Maybe<Scalars['String']>>>;
  text: Maybe<Scalars['String']>;
  use: HumanNamesUse;
};

export enum HumanNamesUse {
  Anonymous = 'ANONYMOUS',
  Maiden = 'MAIDEN',
  Nickname = 'NICKNAME',
  Official = 'OFFICIAL',
  Old = 'OLD',
  Temp = 'TEMP',
  Usual = 'USUAL',
}

export type Identifier = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export enum IsolationType {
  CommonCold = 'COMMON_COLD',
  Covid_19 = 'COVID_19',
  Dv = 'DV',
  Mrsa = 'MRSA',
  None = 'NONE',
  Other = 'OTHER',
  PatientAggressive = 'PATIENT_AGGRESSIVE',
  PatientConfused = 'PATIENT_CONFUSED',
}

export enum Mobility {
  MaximumAssistance = 'MAXIMUM_ASSISTANCE',
  MinimumAssistance = 'MINIMUM_ASSISTANCE',
  ModerateAssistance = 'MODERATE_ASSISTANCE',
  NoAssistance = 'NO_ASSISTANCE',
}

export enum ModuleIdentifier {
  CommandCentre = 'COMMAND_CENTRE',
  HealthyyouClinician = 'HEALTHYYOU_CLINICIAN',
  HealthyyouPatient = 'HEALTHYYOU_PATIENT',
  SmartMessaging = 'SMART_MESSAGING',
}

export enum ModuleRole {
  CareSettingAdministrator = 'CARE_SETTING_ADMINISTRATOR',
  CommandCentreDefaultUser = 'COMMAND_CENTRE_DEFAULT_USER',
  HealthyyouClinicianDefaultUser = 'HEALTHYYOU_CLINICIAN_DEFAULT_USER',
  HealthyyouPatientDefaultUser = 'HEALTHYYOU_PATIENT_DEFAULT_USER',
  SmartMessagingDefaultUser = 'SMART_MESSAGING_DEFAULT_USER',
}

export type MonitoredCondition = {
  __typename?: 'MonitoredCondition';
  comments: Maybe<Scalars['String']>;
  condition: PatientDiagnosis;
  consultationStatus: Maybe<MonitoredConditionConsultationStatus>;
  dischargeStatus: Maybe<DischargeStatusType>;
  monitoringStatus: Maybe<MonitoringStatus>;
  /** @deprecated Replaced with monitoringStatus */
  period: Period;
  reasonForDischarge: Maybe<DischargeReasonType>;
  testStatus: Maybe<MonitoredConditionTestStatus>;
};

/** Dynamically generated schema type to extend status fields */
export type MonitoredConditionConsultationStatus = {
  __typename?: 'MonitoredConditionConsultationStatus';
  res_statusUpdatedBy: Maybe<Scalars['String']>;
  status: Maybe<MonitoredConditionConsultationStatusType>;
  statusUpdateDateTime: Maybe<Scalars['DateTime']>;
  statusUpdatedBy: Maybe<Scalars['ID']>;
};

export enum MonitoredConditionConsultationStatusType {
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Invited = 'INVITED',
  Missed = 'MISSED',
  NotRequired = 'NOT_REQUIRED',
  Required = 'REQUIRED',
}

/** Dynamically generated schema type to extend status fields */
export type MonitoredConditionTestStatus = {
  __typename?: 'MonitoredConditionTestStatus';
  res_statusUpdatedBy: Maybe<Scalars['String']>;
  status: Maybe<MonitoredConditionTestStatusType>;
  statusUpdateDateTime: Maybe<Scalars['DateTime']>;
  statusUpdatedBy: Maybe<Scalars['ID']>;
};

export enum MonitoredConditionTestStatusType {
  AwaitingResults = 'AWAITING_RESULTS',
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Invited = 'INVITED',
  Missed = 'MISSED',
  NotRequired = 'NOT_REQUIRED',
  Overdue = 'OVERDUE',
  Required = 'REQUIRED',
  Returned = 'RETURNED',
  Reviewed = 'REVIEWED',
}

/** Dynamically generated schema type to extend status fields */
export type MonitoringStatus = {
  __typename?: 'MonitoringStatus';
  res_statusUpdatedBy: Maybe<Scalars['String']>;
  status: Maybe<MonitoringStatusType>;
  statusUpdateDateTime: Maybe<Scalars['DateTime']>;
  statusUpdatedBy: Maybe<Scalars['ID']>;
};

export enum MonitoringStatusType {
  AwaitingReadings = 'AWAITING_READINGS',
  ReadingsAvailable = 'READINGS_AVAILABLE',
  ReadyForReview = 'READY_FOR_REVIEW',
  Reviewed = 'REVIEWED',
  SelfMonitoring = 'SELF_MONITORING',
  UrgentClinicalInterventionRequired = 'URGENT_CLINICAL_INTERVENTION_REQUIRED',
}

export type Mutation = {
  __typename?: 'Mutation';
  activateUser: Maybe<User>;
  bookGeneralPracticeAppointment: Maybe<GeneralPracticeAppointmentBooking>;
  calculateHealthScore: Maybe<HealthScore>;
  calculateSedentaryHealthScore: Maybe<SedentaryHealthScoreAttributes>;
  cancelGeneralPracticeAppointmentForPatient: Maybe<Scalars['Boolean']>;
  careSettingStepUpAcceptance: Maybe<CareSettingStepUpInvitationAttributes>;
  createProgramme: WardPatientProgramme;
  createUserRegistrationRecordMutationAnon: Maybe<CreateUserRegistrationRecordResponse>;
  createWardPatientObservation: Array<Maybe<WardPatientObservation>>;
  deleteUser: Maybe<User>;
  registerGeneralPracticePatientLinkage: Maybe<GeneralPracticePatientLinkageRegistration>;
  selfAdmittedPatientAnon: Maybe<SelfAdmittedPatientResponse>;
  unlinkGeneralPracticePatientMutation: Maybe<Person>;
  updateContactExclusionPreferences: Maybe<ContactExclusionPreferencesResult>;
  /** Executes a PUT operation on the Person resource, provided that the application of arguments result in modifications. */
  updatePerson: Maybe<Person>;
  updateUserTermsAndConditions: Maybe<User>;
};

export type MutationActivateUserArgs = {
  input: ActivateUserInput;
};

export type MutationBookGeneralPracticeAppointmentArgs = {
  input: GeneralPracticeAppointmentBookingInput;
  patientIdentifier: Scalars['ID'];
};

export type MutationCalculateHealthScoreArgs = {
  input: CalculateHealthScoreInput;
};

export type MutationCalculateSedentaryHealthScoreArgs = {
  input: SedentaryHealthScoreInput;
};

export type MutationCancelGeneralPracticeAppointmentForPatientArgs = {
  appointmentId: Scalars['String'];
  patientIdentifier: Scalars['ID'];
};

export type MutationCareSettingStepUpAcceptanceArgs = {
  input: CareSettingStepUpAcceptanceInput;
};

export type MutationCreateProgrammeArgs = {
  input: WardPatientProgrammeInput;
};

export type MutationCreateUserRegistrationRecordMutationAnonArgs = {
  input: UserRegistrationRecordInput;
};

export type MutationCreateWardPatientObservationArgs = {
  input: CreateWardPatientObservationInput;
};

export type MutationRegisterGeneralPracticePatientLinkageArgs = {
  input: GeneralPracticePatientLinkageRegistrationInput;
};

export type MutationSelfAdmittedPatientAnonArgs = {
  input: SelfRegistrationPersonalInformationInput;
};

export type MutationUnlinkGeneralPracticePatientMutationArgs = {
  userId: Scalars['ID'];
};

export type MutationUpdateContactExclusionPreferencesArgs = {
  input: ContactExclusionPreferencesInput;
};

export type MutationUpdatePersonArgs = {
  input: PersonInput;
};

export type MutationUpdateUserTermsAndConditionsArgs = {
  input: TermsAndConditionsInput;
};

export type ObservationAssessment = {
  __typename?: 'ObservationAssessment';
  rating: Maybe<ObservationRating>;
  type: ObservationType;
  warning: Maybe<ObservationWarning>;
};

export type ObservationBandInfo = BloodPressureInfo;

export type ObservationBands = {
  __typename?: 'ObservationBands';
  info: Maybe<ObservationBandInfo>;
  ratings: Array<ObservationRating>;
  type: ObservationType;
};

export enum ObservationComponentName {
  AtrialFibrillation = 'ATRIAL_FIBRILLATION',
  BloodPressureMedicationInformation = 'BLOOD_PRESSURE_MEDICATION_INFORMATION',
  BodyHeight = 'BODY_HEIGHT',
  BodyTemperature = 'BODY_TEMPERATURE',
  BodyWeight = 'BODY_WEIGHT',
  Cvd = 'CVD',
  Diabetes = 'DIABETES',
  DiastolicBloodPressure = 'DIASTOLIC_BLOOD_PRESSURE',
  Dizziness = 'DIZZINESS',
  FastingBloodGlucose = 'FASTING_BLOOD_GLUCOSE',
  HdlCholesterol = 'HDL_CHOLESTEROL',
  HeartRhythm = 'HEART_RHYTHM',
  Lightheadedness = 'LIGHTHEADEDNESS',
  MeanDiastolicBloodPressure = 'MEAN_DIASTOLIC_BLOOD_PRESSURE',
  MeanSystolicBloodPressure = 'MEAN_SYSTOLIC_BLOOD_PRESSURE',
  Nausea = 'NAUSEA',
  OxygenSaturation = 'OXYGEN_SATURATION',
  Palpitations = 'PALPITATIONS',
  Pulse = 'PULSE',
  ShortnessOfBreath = 'SHORTNESS_OF_BREATH',
  SmokingStatus = 'SMOKING_STATUS',
  Sweating = 'SWEATING',
  SystolicBloodPressure = 'SYSTOLIC_BLOOD_PRESSURE',
  TotalCholesterol = 'TOTAL_CHOLESTEROL',
}

export type ObservationDataPointAggregation = {
  interval: ObservationDataPointAggregationInterval;
};

export enum ObservationDataPointAggregationInterval {
  Day = 'day',
}

export type ObservationDataPointComponentValue = {
  __typename?: 'ObservationDataPointComponentValue';
  name: ObservationComponentName;
  value: Scalars['Float'];
};

export type ObservationDataPointFilters = {
  endDate: Scalars['DateTime'];
  observationType: ObservationType;
  startDate: Scalars['DateTime'];
};

export type ObservationDataPointInput = {
  aggregation: InputMaybe<ObservationDataPointAggregation>;
  filters: ObservationDataPointFilters;
  /** Ignored when aggregation is provided */
  size: InputMaybe<ObservationDataPointSize>;
};

export type ObservationDataPointResults = {
  __typename?: 'ObservationDataPointResults';
  /** Rounded average */
  average: Maybe<ObservationValue>;
  end: Scalars['DateTime'];
  hits: Scalars['Int'];
  max: Maybe<ObservationValue>;
  min: Maybe<ObservationValue>;
  observationType: ObservationType;
  personId: Scalars['ID'];
  /** data point before first search result. always null if searchResults is empty. */
  precedingDataPoint: Maybe<ObservationDataPointView>;
  /** random uuid for aggregate results, otherwise provided by search-service */
  searchRequestId: Scalars['ID'];
  searchResults: Array<ObservationDataPointView>;
  start: Scalars['DateTime'];
  timedOut: Scalars['Boolean'];
  wardPatientId: Scalars['ID'];
};

export type ObservationDataPointSize = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type ObservationDataPointView = {
  __typename?: 'ObservationDataPointView';
  /** observationId for non-aggregate results, otherwise: v5 UUID generated based on date and values */
  id: Scalars['ID'];
  observationType: ObservationType;
  recordedDateTime: Scalars['DateTime'];
  res_componentValues: Array<ObservationDataPointComponentValue>;
};

export type ObservationRange = {
  __typename?: 'ObservationRange';
  /**
   * Exclusive. Null if unbounded.
   * @deprecated Use maxDecimal
   */
  max: Maybe<Scalars['Int']>;
  /** Exclusive. Null if unbounded. */
  maxDecimal: Maybe<Scalars['Float']>;
  /**
   * Inclusive
   * @deprecated Use minDecimal
   */
  min: Scalars['Int'];
  /** Inclusive */
  minDecimal: Scalars['Float'];
  step: Scalars['Float'];
  type: ObservationComponentName;
};

export type ObservationRating = {
  __typename?: 'ObservationRating';
  displayName: Scalars['String'];
  displayOrder: Scalars['Int'];
  /** This field is weakly typed, reflecting the variability of its underlying data structure, which is determined by the responses from the associated REST API. */
  fhirRating: Scalars['String'];
  lightColourTheme: ColorTheme;
  ranges: Array<ObservationRange>;
};

export enum ObservationType {
  All = 'ALL',
  AverageBloodPressure = 'AVERAGE_BLOOD_PRESSURE',
  BloodPressure = 'BLOOD_PRESSURE',
  Cholesterol = 'CHOLESTEROL',
  DailyStepCount = 'DAILY_STEP_COUNT',
  FastingBloodGlucose = 'FASTING_BLOOD_GLUCOSE',
  HeartRhythm = 'HEART_RHYTHM',
  Height = 'HEIGHT',
  None = 'NONE',
  Pulse = 'PULSE',
  Spo2 = 'SPO2',
  Temperature = 'TEMPERATURE',
  Weight = 'WEIGHT',
}

export type ObservationValue =
  | BloodPressure
  | BodyHeight
  | BodyTemperature
  | BodyWeight
  | Cholesterol
  | FastingBloodGlucose
  | HeartRhythm
  | Pulse
  | Spo2;

export type ObservationWarning = {
  __typename?: 'ObservationWarning';
  level: ObservationWarningLevel;
  /** @deprecated use level */
  message: ObservationWarningType;
  ranges: Array<ObservationRange>;
};

export enum ObservationWarningLevel {
  SevereHigh = 'SEVERE_HIGH',
  SevereLow = 'SEVERE_LOW',
  SoftHigh = 'SOFT_HIGH',
  SoftLow = 'SOFT_LOW',
}

export enum ObservationWarningType {
  Elevated = 'ELEVATED',
  High = 'HIGH',
  Low = 'LOW',
  VeryHigh = 'VERY_HIGH',
  VeryLow = 'VERY_LOW',
}

export enum OriginType {
  AE = 'A_E',
  MedicalAssUnit = 'MEDICAL_ASS_UNIT',
  None = 'NONE',
  Outpatients = 'OUTPATIENTS',
  SurgicalAssUnit = 'SURGICAL_ASS_UNIT',
}

export enum PatientDiagnosis {
  Copd = 'COPD',
  Hypertension = 'HYPERTENSION',
  None = 'NONE',
}

export enum PatientWardPreferencesType {
  FemaleWard = 'FEMALE_WARD',
  GenderNeutral = 'GENDER_NEUTRAL',
  MaleWard = 'MALE_WARD',
  None = 'NONE',
}

export type PerSessionExcessivePerSessionAlcoholConsumptionScore =
  HealthScoreByElement & {
    __typename?: 'PerSessionExcessivePerSessionAlcoholConsumptionScore';
    currentPerSessionExcessiveDrinkingRiskCategory: HealthScorePerSessionExcessiveDrinkingRiskCategory;
    distanceToMagicNumber: Maybe<Scalars['Float']>;
    distanceToMaxScore: Maybe<Scalars['Float']>;
    healthScoreElementType: HealthScoreElement;
    perSessionExcessiveDrinkingCurrentZone: Maybe<HealthScoreExcessiveAlcoholConsumptionZone>;
    perSessionExcessiveDrinkingRecommendations: Array<HealthScorePerSessionExcessiveDrinkingRecommendation>;
    perSessionExcessiveDrinkingTargetZone: Maybe<HealthScoreExcessiveAlcoholConsumptionZone>;
    targetPerSessionExcessiveDrinkingRiskCategory: HealthScorePerSessionExcessiveDrinkingRiskCategory;
  };

export type Period = {
  __typename?: 'Period';
  end: Maybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
};

export type Person = Identifier & {
  __typename?: 'Person';
  attributes: PersonAttributes;
  /** @deprecated Deprecated in favor of User.defaultWardPatient */
  careSettingPatients: Maybe<Array<Maybe<CareSettingPatient>>>;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type PersonAttributes = {
  __typename?: 'PersonAttributes';
  audit: Maybe<Audit>;
  careSettingId: Maybe<Scalars['ID']>;
  contactExclusionPreferences: Maybe<Array<Maybe<ContactExclusionPreferences>>>;
  contactInformation: Maybe<PersonContactInformation>;
  contactParty: Maybe<Array<Maybe<PersonContactPartyFields>>>;
  generalPracticeRegistration: Maybe<PersonGeneralPracticeRegistration>;
  /** @deprecated Replaced with latestHealthInformation */
  healthInformationLatest: Maybe<HealthInformation>;
  /** Unique hospital reference to identify a patient e.g. for ESNEFT this is the hospital no. in the format of D1234567 */
  hospitalPatientReference: Maybe<Scalars['String']>;
  latestHealthInformation: Maybe<HealthInformation>;
  personalInformation: PersonPersonalInformation;
  registeredGPSurgery: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type PersonContactInformation = {
  __typename?: 'PersonContactInformation';
  primaryContactNumber: Maybe<Scalars['String']>;
  primaryEmailAddress: Maybe<Scalars['String']>;
};

export type PersonContactPartyFields = {
  __typename?: 'PersonContactPartyFields';
  humanNames: Maybe<Array<Maybe<AimHumanName>>>;
  personRelationshipType: Maybe<PersonRelationshipType>;
  primaryContactNumber: Maybe<Scalars['String']>;
};

export type PersonGeneralPracticeRegistration = {
  __typename?: 'PersonGeneralPracticeRegistration';
  accountId: Scalars['ID'];
  linkageKey: Scalars['String'];
  odsCode: Scalars['String'];
  patientIdentifier: Scalars['ID'];
};

export type PersonInput = {
  healthInformation: InputMaybe<HealthInformationInput>;
  personalInformation: InputMaybe<PersonPersonalInformationMutationInput>;
};

export type PersonPersonalInformation = {
  __typename?: 'PersonPersonalInformation';
  dateOfBirth: Maybe<Scalars['Date']>;
  ethnicity: Maybe<Ethnicity>;
  firstName: Scalars['String'];
  genderAssignedAtBirth: Maybe<GenderAssignedAtBirth>;
  genderIdentifiedAs: GenderIdentifiedAs;
  mobility: Maybe<Mobility>;
  nhsNumber: Maybe<Scalars['String']>;
  primaryAddress: Maybe<PrimaryAddress>;
  pronouns: Maybe<Pronouns>;
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type PersonPersonalInformationMutationInput = {
  ethnicity: InputMaybe<Ethnicity>;
  genderIdentifiedAs: InputMaybe<GenderIdentifiedAs>;
};

export enum PersonRelationshipType {
  NextOfKin = 'NEXT_OF_KIN',
  None = 'NONE',
  Other = 'OTHER',
}

export enum PharmacyStatusType {
  Delayed = 'DELAYED',
  None = 'NONE',
  NotRequired = 'NOT_REQUIRED',
  Ordered = 'ORDERED',
  Received = 'RECEIVED',
}

export type PhysicalActivityScore = HealthScoreByElement & {
  __typename?: 'PhysicalActivityScore';
  distanceToMagicNumber: Maybe<Scalars['Float']>;
  distanceToMaxScore: Maybe<Scalars['Float']>;
  exerciseMinutesPerWeek: Scalars['Int'];
  exerciseRecommendations: Array<HealthScoreExerciseRecommendation>;
  healthScoreElementType: HealthScoreElement;
  moderateActivityMinutesPerWeek: Scalars['Int'];
  physicalActivityCurrentZone: Maybe<HealthScorePhysicalActivityZone>;
  physicalActivityTargetZone: Maybe<HealthScorePhysicalActivityZone>;
  vigorousActivityMinutesPerWeek: Scalars['Int'];
};

export enum PorterStatusType {
  None = 'NONE',
  NotRequired = 'NOT_REQUIRED',
  Requested = 'REQUESTED',
}

export type PrimaryAddress = {
  __typename?: 'PrimaryAddress';
  line1: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
};

export type ProfileNonCompliant = UserDataType & {
  __typename?: 'ProfileNonCompliant';
  careSetting: CareSetting;
  careSettingId: Scalars['ID'];
  reason: ProfileNonCompliantReason;
  user: User;
  userId: Scalars['ID'];
};

export enum ProfileNonCompliantReason {
  TermsAndConditionsNotAccepted = 'TERMS_AND_CONDITIONS_NOT_ACCEPTED',
  Unverified = 'UNVERIFIED',
}

export enum Pronouns {
  HeHim = 'HE_HIM',
  SheHer = 'SHE_HER',
  TheyThem = 'THEY_THEM',
}

export type Pulse = RatedObservation & {
  __typename?: 'Pulse';
  /** The beats per minute */
  bpm: Scalars['Int'];
  fhirRating: FhirRating;
  /**
   * FHIR rating
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
};

export type PulseInput = {
  /** The beats per minute */
  bpm: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  appointments: Maybe<Array<Maybe<GeneralPracticeAppointmentSlotGroup>>>;
  assessObservation: Array<ObservationAssessment>;
  /** @deprecated Use observationRatings */
  bloodPressureBands: BloodPressureBands;
  bookedAppointments: Maybe<Array<Maybe<GeneralPracticeBookedAppointmentSlot>>>;
  contactExclusionPreferences: Maybe<Array<ContactExclusionPreferences>>;
  generalPracticePatientSettings: Maybe<GeneralPracticePatientSettings>;
  generalPracticeServicePracticeSettings: Maybe<GeneralPracticeServicePracticeSettings>;
  healthScoreReferenceData: Maybe<HealthScoreReferenceData>;
  latestHealthScore: Maybe<HealthScore>;
  observationBands: ObservationBands;
  observationBandsAll: Array<ObservationBands>;
  observationDataPoints: ObservationDataPointResults;
  /** Retrieves wardPatientObservations from WARD_PATIENT_OBSERVATIONS index. */
  searchWardPatientObservations: WardPatientObservationViewSearchResults;
  todayObservations: Array<WardPatientObservation>;
  /**
   * Query throws PATIENT_DISCHARGED error when no record with active flag found in view_wardpatients_persons index
   * @deprecated Use userPatientProfileResults
   */
  userPatientProfile: UserPatientProfile;
  /** Query throws PATIENT_DISCHARGED error when no record with active flag found in view_wardpatients_persons index. Returns ProfileNonCompliant when TC not accepted or user not verified.  */
  userPatientProfileResults: UserPatientResult;
  /** @deprecated Use userPatientProfile. */
  userProfile: Maybe<UserProfile>;
  userRegistrationRecordAnon: GetUserRegistrationRecordResponse;
  /** @deprecated Use wardPatientObservationsPaged or searchWardPatientObservations instead */
  wardPatientObservations: Maybe<Array<WardPatientObservation>>;
  wardPatientObservationsForProgramme: Maybe<WardPatientObservationViewSearchResults>;
  /** Retrieves wardPatientObservations from dynamoDB */
  wardPatientObservationsPaged: WardPatientObservationPaginatedResults;
  /** @deprecated Use searchWardPatientObservations instead */
  wardPatientObservationsSearch: WardPatientObservationViewSearchResults;
  wardPatientProgrammeForAverageObservationId: Maybe<WardPatientProgramme>;
};

export type QueryAppointmentsArgs = {
  days: InputMaybe<Scalars['Int']>;
  patientIdentifier: Scalars['ID'];
};

export type QueryAssessObservationArgs = {
  input: CreateWardPatientObservationInput;
};

export type QueryBookedAppointmentsArgs = {
  patientIdentifier: Scalars['ID'];
};

export type QueryContactExclusionPreferencesArgs = {
  personId: Scalars['ID'];
};

export type QueryGeneralPracticePatientSettingsArgs = {
  patientIdentifier: Scalars['ID'];
};

export type QueryGeneralPracticeServicePracticeSettingsArgs = {
  gpOdsCode: Scalars['String'];
};

export type QueryObservationBandsArgs = {
  observationType: ObservationType;
};

export type QueryObservationDataPointsArgs = {
  input: ObservationDataPointInput;
};

export type QuerySearchWardPatientObservationsArgs = {
  endDate: InputMaybe<Scalars['DateTime']>;
  offset: InputMaybe<Scalars['Int']>;
  size: InputMaybe<Scalars['Int']>;
  startDate: InputMaybe<Scalars['DateTime']>;
  types: InputMaybe<Array<ObservationType>>;
};

export type QueryUserProfileArgs = {
  safId: Scalars['String'];
};

export type QueryUserRegistrationRecordAnonArgs = {
  encryptedId: Scalars['String'];
};

export type QueryWardPatientObservationsArgs = {
  recordedDateTimeEnd: Scalars['DateTime'];
  recordedDateTimeStart: Scalars['DateTime'];
  wardPatientId: InputMaybe<Scalars['ID']>;
};

export type QueryWardPatientObservationsForProgrammeArgs = {
  offset: InputMaybe<Scalars['Int']>;
  programmeId: Scalars['ID'];
  size: InputMaybe<Scalars['Int']>;
};

export type QueryWardPatientObservationsPagedArgs = {
  exclusiveStartKey: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  recordedDateTimeEnd: InputMaybe<Scalars['DateTime']>;
  recordedDateTimeStart: InputMaybe<Scalars['DateTime']>;
};

export type QueryWardPatientObservationsSearchArgs = {
  endDate: InputMaybe<Scalars['DateTime']>;
  offset: InputMaybe<Scalars['Int']>;
  size: InputMaybe<Scalars['Int']>;
  startDate: InputMaybe<Scalars['DateTime']>;
  type: InputMaybe<ObservationType>;
};

export type QueryWardPatientProgrammeForAverageObservationIdArgs = {
  averageObservationId: Scalars['ID'];
};

export type RatedObservation = {
  fhirRating: FhirRating;
  /**
   * FHIR rating
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
};

export type RegistrationLinkExpiredValidationError = {
  __typename?: 'RegistrationLinkExpiredValidationError';
  /** The associated back-end error code returned in the body */
  code: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export enum RegistrationStatus {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type RelatedOrganisation = {
  __typename?: 'RelatedOrganisation';
  externalReferenceId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  relationshipType: RelatedOrganisationRelationshipType;
  type: RelatedOrganisationType;
};

export enum RelatedOrganisationRelationshipType {
  None = 'NONE',
  Pcn = 'PCN',
}

export enum RelatedOrganisationType {
  GeneralPractice = 'GENERAL_PRACTICE',
  None = 'NONE',
}

export type SedentaryHealthScoreAttributes = {
  __typename?: 'SedentaryHealthScoreAttributes';
  stepCountActivityHourAssessments: Maybe<Array<StepCountHourlyAttributes>>;
};

export type SedentaryHealthScoreInput = {
  sleepTimeEnd: Scalars['String'];
  sleepTimeStart: Scalars['String'];
  stepCountHistory: StepCountHistoryInput;
};

export type SelfAdmittedPatientResponse =
  | Auth0CommonPasswordValidationError
  | Auth0ConflictValidationError
  | Auth0UserInfoValidationError
  | SelfAdmittedPatientResult;

export type SelfAdmittedPatientResult = {
  __typename?: 'SelfAdmittedPatientResult';
  result: Scalars['Boolean'];
};

export type SelfRegistrationPersonalInformationInput = {
  dateOfBirth: Scalars['Date'];
  encryptedId: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  surname: Scalars['String'];
};

export type SittingScore = HealthScoreByElement & {
  __typename?: 'SittingScore';
  distanceToMagicNumber: Maybe<Scalars['Float']>;
  distanceToMaxScore: Maybe<Scalars['Float']>;
  healthScoreElementType: HealthScoreElement;
  sittingCurrentZone: Maybe<HealthScoreDailySittingZone>;
  sittingHoursScoreCategory: Maybe<HealthScoreSittingHoursCategory>;
  sittingRecommendations: Array<HealthScoreSittingRecommendations>;
  sittingTargetZone: Maybe<HealthScoreDailySittingZone>;
};

export type SleepScore = HealthScoreByElement & {
  __typename?: 'SleepScore';
  dailySleepDuration: Maybe<Scalars['ISO8601Duration']>;
  distanceToMagicNumber: Maybe<Scalars['Float']>;
  distanceToMaxScore: Maybe<Scalars['Float']>;
  durationDistanceToMagicNumber: Maybe<Scalars['ISO8601Duration']>;
  durationDistanceToMaxScore: Maybe<Scalars['ISO8601Duration']>;
  healthScoreElementType: HealthScoreElement;
  sleepCurrentZone: Maybe<HealthScoreSleepingZone>;
  sleepRecommendations: Array<HealthScoreSleepRecommendations>;
  sleepTargetZone: Maybe<HealthScoreSleepingZone>;
  sleepingScoreCategory: Maybe<HealthScoreSleepCategory>;
};

export type Smoking = {
  __typename?: 'Smoking';
  created: Scalars['DateTime'];
  status: SmokingStatus;
};

export type SmokingInput = {
  created: InputMaybe<Scalars['DateTime']>;
  status: SmokingStatus;
};

export type SmokingScore = HealthScoreByElement & {
  __typename?: 'SmokingScore';
  currentSmokingRiskCategory: HealthScoreSmokingRiskCategory;
  distanceToMagicNumber: Maybe<Scalars['Float']>;
  distanceToMaxScore: Maybe<Scalars['Float']>;
  healthScoreElementType: HealthScoreElement;
  smokingCurrentZone: Maybe<HealthScoreSmokingZone>;
  smokingRecommendations: Array<HealthScoreSmokingRecommendation>;
  smokingTargetZone: Maybe<HealthScoreSmokingZone>;
  targetSmokingRiskCategory: HealthScoreSmokingRiskCategory;
};

/** Used in health information */
export enum SmokingStatus {
  NoNeverSmoked = 'NO_NEVER_SMOKED',
  NoQuit = 'NO_QUIT',
  PreferNotSay = 'PREFER_NOT_SAY',
  YesLessThan_10ADay = 'YES_LESS_THAN_10_A_DAY',
  YesLessThan_20ADay = 'YES_LESS_THAN_20_A_DAY',
  YesMoreThan_20ADay = 'YES_MORE_THAN_20_A_DAY',
}

export enum SpecialtyType {
  Cardiology = 'CARDIOLOGY',
  EarNoseAndThroat = 'EAR_NOSE_AND_THROAT',
  EndocDiabetes = 'ENDOC_DIABETES',
  Gastro = 'GASTRO',
  GeniMedicine = 'GENI_MEDICINE',
  GenIntMedicine = 'GEN_INT_MEDICINE',
  GenSurgery = 'GEN_SURGERY',
  GeriMedicine = 'GERI_MEDICINE',
  MaxFax = 'MAX_FAX',
  Neurology = 'NEUROLOGY',
  None = 'NONE',
  Ortho = 'ORTHO',
  PlasticSurgery = 'PLASTIC_SURGERY',
  RenMedicine = 'REN_MEDICINE',
  RespMedicine = 'RESP_MEDICINE',
  Rhreu = 'RHREU',
  StrokeMedicine = 'STROKE_MEDICINE',
  Urology = 'UROLOGY',
  VascSurgery = 'VASC_SURGERY',
}

export type Spo2 = RatedObservation & {
  __typename?: 'Spo2';
  fhirRating: FhirRating;
  /** The oxygen saturation percentage */
  oxygenSaturation: Scalars['Int'];
  /**
   * FHIR rating
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
};

export type Spo2Input = {
  /** The oxygen saturation percentage */
  oxygenSaturation: Scalars['Int'];
};

export enum StepCountActivityCategory {
  Active = 'ACTIVE',
  Sitting = 'SITTING',
  Sleep = 'SLEEP',
}

export type StepCountDailyInput = {
  localDate: Scalars['String'];
  stepCountHourlyList: Array<StepCountHourlyInput>;
};

export type StepCountHistoryInput = {
  stepCountDailyList: Array<StepCountDailyInput>;
};

export type StepCountHourlyAttributes = {
  __typename?: 'StepCountHourlyAttributes';
  hourOfTheDay: Scalars['String'];
  stepCountActivityCategorisation: StepCountActivityCategory;
};

export type StepCountHourlyInput = {
  localTime: Scalars['String'];
  stepCount: Scalars['Int'];
};

export type StepsScore = HealthScoreByElement & {
  __typename?: 'StepsScore';
  distanceToMagicNumber: Maybe<Scalars['Float']>;
  distanceToMaxScore: Maybe<Scalars['Float']>;
  healthScoreElementType: HealthScoreElement;
  stepsCurrentZone: Maybe<HealthScoreDailyStepsZone>;
  stepsRecommendation: Array<HealthScoreStepsRecommendation>;
  stepsScoreCategory: Maybe<HealthScoreStepsCategory>;
  stepsTargetZone: Maybe<HealthScoreDailyStepsZone>;
};

export type StrengthTrainingScore = HealthScoreByElement & {
  __typename?: 'StrengthTrainingScore';
  distanceToMagicNumber: Maybe<Scalars['Float']>;
  distanceToMaxScore: Maybe<Scalars['Float']>;
  healthScoreElementType: HealthScoreElement;
  strengthCurrentZone: Maybe<HealthScoreStrengthZone>;
  strengthRecommendations: Array<HealthScoreStrengthRecommendation>;
  strengthTargetZone: Maybe<HealthScoreStrengthZone>;
  strengthTrainingDaysPerWeek: Scalars['Int'];
};

export enum TtoStatusType {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  None = 'NONE',
}

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  dateTime: Scalars['DateTime'];
  decision: TermsAndConditionsStatus;
  version: Scalars['String'];
};

export type TermsAndConditionsInput = {
  dataSharingAcceptanceDateZoned: InputMaybe<Scalars['String']>;
  dateTime: Scalars['DateTime'];
  decision: TermsAndConditionsStatus;
  version: Scalars['String'];
};

export enum TermsAndConditionsStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
}

export type User = Identifier & {
  __typename?: 'User';
  attributes: UserAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserAlreadyExistsValidationError = {
  __typename?: 'UserAlreadyExistsValidationError';
  /** The associated back-end error code returned in the body */
  code: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type UserAttributes = {
  __typename?: 'UserAttributes';
  audit: Maybe<Audit>;
  currentWardPatientId: Maybe<Scalars['ID']>;
  defaultCareSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  deletionRequested: Maybe<Scalars['DateTime']>;
  isStandalonePatient: Scalars['Boolean'];
  registrationStatus: Maybe<RegistrationStatus>;
  safId: Scalars['String'];
  termsAndConditions: Maybe<TermsAndConditions>;
  /** @deprecated To be removed in a future release */
  userRegistrationId: Maybe<Scalars['ID']>;
  userState: Maybe<UserState>;
};

export type UserDataType = {
  careSetting: CareSetting;
  careSettingId: Scalars['ID'];
  user: User;
  userId: Scalars['ID'];
};

export type UserModuleAvailability = Identifier & {
  __typename?: 'UserModuleAvailability';
  attributes: UserModuleAvailabilityAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleAvailabilityAttributes = {
  __typename?: 'UserModuleAvailabilityAttributes';
  availabilityStatus: UserModuleAvailabilityStatus;
  careSettingModuleId: Scalars['ID'];
  moduleId: ModuleIdentifier;
  moduleName: Scalars['String'];
  moduleShortName: Scalars['String'];
  userId: Scalars['ID'];
};

export enum UserModuleAvailabilityStatus {
  Accessible = 'ACCESSIBLE',
  Visible = 'VISIBLE',
}

export type UserModuleRole = Identifier & {
  __typename?: 'UserModuleRole';
  attributes: UserModuleRoleAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleRoleAttributes = {
  __typename?: 'UserModuleRoleAttributes';
  careSettingModuleId: Scalars['ID'];
  moduleRoleId: ModuleRole;
  userId: Scalars['ID'];
};

export type UserPatientProfile = UserDataType & {
  __typename?: 'UserPatientProfile';
  active: Scalars['Boolean'];
  careSetting: CareSetting;
  careSettingId: Scalars['ID'];
  careSettingPatient: CareSettingPatient;
  careSettingPatientId: Scalars['ID'];
  dateOfBirth: Scalars['Date'];
  firstName: Scalars['String'];
  person: Person;
  programmeInitiative: WardPatientProgrammeInitiative;
  safId: Scalars['ID'];
  surname: Scalars['String'];
  user: User;
  userId: Scalars['ID'];
  wardId: Scalars['ID'];
  wardPatient: WardPatient;
  wardPatientId: Scalars['ID'];
};

export type UserPatientResult = ProfileNonCompliant | UserPatientProfile;

export type UserProfile = Identifier & {
  __typename?: 'UserProfile';
  attributes: UserAttributes;
  careSetting: Maybe<CareSetting>;
  careSettingClinician: Maybe<CareSettingClinician>;
  /** @deprecated Use userPatientProfileResults query */
  defaultWardPatient: Maybe<WardPatient>;
  id: Scalars['ID'];
  person: Maybe<Person>;
  type: Scalars['String'];
  userModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  userModuleRoles: Array<Maybe<UserModuleRole>>;
};

export type UserRegistrationRecord = {
  __typename?: 'UserRegistrationRecord';
  attributes: UserRegistrationRecordMutationResponse;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserRegistrationRecordInput = {
  primaryEmailAddress: Scalars['String'];
};

export type UserRegistrationRecordMutationResponse = {
  __typename?: 'UserRegistrationRecordMutationResponse';
  audit: Maybe<Audit>;
  effectiveTo: Scalars['DateTime'];
  primaryEmailAddress: Scalars['String'];
};

export enum UserState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum WaitingForDischarge {
  FinalAssessment = 'FINAL_ASSESSMENT',
  None = 'NONE',
  OccupationalTherapySignOff = 'OCCUPATIONAL_THERAPY_SIGN_OFF',
  PharmacyItems = 'PHARMACY_ITEMS',
  PhysiotherapySignOff = 'PHYSIOTHERAPY_SIGN_OFF',
  ResultsOfInvestigations = 'RESULTS_OF_INVESTIGATIONS',
  SocialCareInput = 'SOCIAL_CARE_INPUT',
}

export enum WaitingForPotentialDischarge {
  BloodTestResults = 'BLOOD_TEST_RESULTS',
  ClinicalSignOff = 'CLINICAL_SIGN_OFF',
  CtScan = 'CT_SCAN',
  FurtherInvestigations = 'FURTHER_INVESTIGATIONS',
  MedicalReviews = 'MEDICAL_REVIEWS',
  MriScan = 'MRI_SCAN',
  None = 'NONE',
  Other = 'OTHER',
  PatientTransport = 'PATIENT_TRANSPORT',
  ResultsOfInvestigations = 'RESULTS_OF_INVESTIGATIONS',
  SpecialityNurseReview = 'SPECIALITY_NURSE_REVIEW',
  SurgicalReview = 'SURGICAL_REVIEW',
  VirtualWardReferral = 'VIRTUAL_WARD_REFERRAL',
  XRays = 'X_RAYS',
}

export type Ward = Identifier & {
  __typename?: 'Ward';
  attributes: WardAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardAttributes = {
  __typename?: 'WardAttributes';
  audit: Maybe<Audit>;
  name: Scalars['String'];
  specialty: Maybe<SpecialtyType>;
  staffActualCount: Maybe<Scalars['Int']>;
  staffRequiredCount: Maybe<Scalars['Int']>;
  wardGroupId: Scalars['ID'];
  wardType: Maybe<WardType>;
};

export type WardDischarge = Identifier & {
  __typename?: 'WardDischarge';
  attributes: WardDischargeAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardDischargeAttributes = {
  __typename?: 'WardDischargeAttributes';
  audit: Maybe<Audit>;
  bedCleaningStatus: Maybe<BedCleaningStatus>;
  carePackageStatus: CarePackageStatusType;
  clinicallyReadyForDischargeDateTime: Maybe<Scalars['DateTime']>;
  comments: Maybe<Scalars['String']>;
  decisionMakerLastVisitedDateTime: Maybe<Scalars['DateTime']>;
  decisionMakerYN: YesNoType;
  dischargeActualDateTime: Maybe<Scalars['DateTime']>;
  dischargeAssessmentOutcome: Maybe<AwaitingFromDischargeAssess>;
  dischargeExpectedDateTime: Maybe<Scalars['DateTime']>;
  dischargeReason: Maybe<DischargeReasonType>;
  dischargeStatus: DischargeStatusType;
  /** @deprecated Use dischargeStatusFailedReasons */
  dischargeStatusFailedReason: Maybe<DischargeStatusFailed>;
  dischargeStatusFailedReasonOther: Maybe<Scalars['String']>;
  dischargeStatusFailedReasons: Maybe<Array<Maybe<DischargeStatusFailed>>>;
  dischargeToAssessDateTime: Maybe<Scalars['DateTime']>;
  isolationType: IsolationType;
  isolationTypeOther: Maybe<Scalars['String']>;
  pharmacy: Maybe<PharmacyStatusType>;
  porterStatus: Maybe<PorterStatusType>;
  readyForDischargeDateTime: Maybe<Scalars['DateTime']>;
  requireDischargeToAssess: Maybe<YesNoType>;
  requiresIsolationYN: Maybe<YesNoType>;
  socialCareYN: Maybe<YesNoType>;
  toTakeOutStatus: TtoStatusType;
  /** @deprecated Use waitingReasonForClinicallyReadyForDischarge */
  waitingReasonForClinicallyReadyForDischarge: Maybe<WaitingForDischarge>;
  /** @deprecated Use waitingReasonsForPotentialDischarge */
  waitingReasonForPotentialDischarge: Maybe<WaitingForPotentialDischarge>;
  waitingReasonOther: Maybe<Scalars['String']>;
  waitingReasonsForClinicallyReadyForDischarge: Maybe<
    Array<Maybe<WaitingForDischarge>>
  >;
  waitingReasonsForPotentialDischarge: Maybe<
    Array<Maybe<WaitingForPotentialDischarge>>
  >;
};

export type WardPatient = Identifier & {
  __typename?: 'WardPatient';
  attributes: WardPatientAttributes;
  careSettingPatient: Maybe<CareSettingPatient>;
  id: Scalars['ID'];
  lastObservation: Maybe<WardPatientObservation>;
  type: Scalars['String'];
  ward: Maybe<Ward>;
  wardDischarge: Maybe<WardDischarge>;
};

export type WardPatientAttributes = {
  __typename?: 'WardPatientAttributes';
  admissionDateTime: Maybe<Scalars['DateTime']>;
  arrivalExpectedDateTime: Maybe<Scalars['DateTime']>;
  audit: Maybe<Audit>;
  careSettingId: Scalars['ID'];
  careSettingPatientId: Scalars['ID'];
  comments: Maybe<Scalars['String']>;
  covid19Status: Covid19Status;
  decisionToAdmitDateTime: Maybe<Scalars['DateTime']>;
  isolationType: IsolationType;
  isolationTypeOther: Maybe<Scalars['String']>;
  lastObservationDateTime: Maybe<Scalars['DateTime']>;
  /** User identifier of the clinician who last reviewed the patient. */
  lastReviewedBy: Maybe<Scalars['ID']>;
  lastReviewedDateTime: Maybe<Scalars['DateTime']>;
  monitoredConditions: Maybe<Array<MonitoredCondition>>;
  /** Observation Types assigned to this patient during admission. */
  observationTypes: Maybe<Array<ObservationType>>;
  origin: Maybe<OriginType>;
  outlierYN: Maybe<YesNoType>;
  porterStatus: Maybe<PorterStatusType>;
  predictedLengthOfStay: Maybe<Scalars['ISO8601Duration']>;
  primaryDiagnosis: PatientDiagnosis;
  requiresIsolationYN: Maybe<YesNoType>;
  /** @deprecated Period is deprecated so no longer required. Replaced with monitoring statuses */
  res_activeMonitoredConditions: Array<MonitoredCondition>;
  res_admittedBy: Maybe<Scalars['String']>;
  res_lastReviewedBy: Maybe<Scalars['String']>;
  secondaryDiagnosis: PatientDiagnosis;
  specialty: Maybe<SpecialtyType>;
  /** Observation Types submitted during this patient history, excluding derived observation types. */
  submittedObservationTypes: Maybe<Array<ObservationType>>;
  wardId: Scalars['ID'];
};

export enum WardPatientHypertensionHomeBannerStatus {
  CanceledMissingReading = 'CANCELED_MISSING_READING',
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  Offer = 'OFFER',
}

export type WardPatientObservation = Identifier & {
  __typename?: 'WardPatientObservation';
  attributes: WardPatientObservationAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardPatientObservationAttributes = {
  __typename?: 'WardPatientObservationAttributes';
  audit: Maybe<Audit>;
  observationType: ObservationType;
  recordedDateTime: Scalars['DateTime'];
  res_observationValue: Maybe<ObservationValue>;
  /** @deprecated Use res_observationValue */
  value: Maybe<Scalars['Float']>;
  wardPatientId: Scalars['ID'];
};

export type WardPatientObservationBloodPressureInput = {
  observationValue: BloodPressureInput;
  recordedDateTime: Scalars['DateTime'];
};

export type WardPatientObservationBodyHeightInput = {
  observationValue: BodyHeightInput;
  recordedDateTime: Scalars['DateTime'];
};

export type WardPatientObservationBodyTemperatureInput = {
  observationValue: BodyTemperatureInput;
  recordedDateTime: Scalars['DateTime'];
};

export type WardPatientObservationBodyWeightInput = {
  observationValue: BodyWeightInput;
  recordedDateTime: Scalars['DateTime'];
};

export type WardPatientObservationCholesterolInput = {
  observationValue: CholesterolInput;
  recordedDateTime: Scalars['DateTime'];
};

export type WardPatientObservationFastingBloodGlucoseInput = {
  observationValue: FastingBloodGlucoseInput;
  recordedDateTime: Scalars['DateTime'];
};

export type WardPatientObservationHeartRhythmInput = {
  observationValue: HeartRhythmInput;
  recordedDateTime: Scalars['DateTime'];
};

export type WardPatientObservationPaginatedResults = Identifier & {
  __typename?: 'WardPatientObservationPaginatedResults';
  attributes: WardPatientObservationPaginatedResultsAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardPatientObservationPaginatedResultsAttributes = {
  __typename?: 'WardPatientObservationPaginatedResultsAttributes';
  lastEvaluatedKey: Maybe<Scalars['String']>;
  records: Array<WardPatientObservation>;
};

export type WardPatientObservationPulseInput = {
  observationValue: PulseInput;
  recordedDateTime: Scalars['DateTime'];
};

export type WardPatientObservationSpo2Input = {
  observationValue: Spo2Input;
  recordedDateTime: Scalars['DateTime'];
};

export type WardPatientObservationView = {
  __typename?: 'WardPatientObservationView';
  id: Scalars['ID'];
  observationType: ObservationType;
  recordedDateTime: Scalars['DateTime'];
  res_observationValue: ObservationValue;
  wardPatientId: Scalars['ID'];
};

export type WardPatientObservationViewSearchResults = {
  __typename?: 'WardPatientObservationViewSearchResults';
  hits: Scalars['Int'];
  searchRequestId: Scalars['ID'];
  searchResults: Array<WardPatientObservationView>;
  timedOut: Scalars['Boolean'];
};

export type WardPatientProgramme = Identifier & {
  __typename?: 'WardPatientProgramme';
  attributes: WardPatientProgrammeAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardPatientProgrammeAttributes = {
  __typename?: 'WardPatientProgrammeAttributes';
  audit: Audit;
  averageObservation: Maybe<WardPatientObservationView>;
  averageObservationId: Maybe<Scalars['ID']>;
  calculationObservationIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Null unless programme is in COMPLETE state */
  durationInDays: Maybe<Scalars['Int']>;
  endDateTime: Maybe<Scalars['DateTime']>;
  observationsCount: Scalars['Int'];
  startDateTime: Scalars['DateTime'];
  startObservationId: Scalars['ID'];
  status: WardPatientProgrammeStatus;
  type: WardPatientProgrammeType;
  wardPatientId: Scalars['ID'];
};

export type WardPatientProgrammeInitiative = {
  __typename?: 'WardPatientProgrammeInitiative';
  hypertensionHomeBanner: Maybe<WardPatientHypertensionHomeBannerStatus>;
  mostRecent: Maybe<WardPatientProgramme>;
};

export type WardPatientProgrammeInput = {
  type: WardPatientProgrammeType;
};

export enum WardPatientProgrammeStatus {
  CanceledMissingReading = 'CANCELED_MISSING_READING',
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
}

export enum WardPatientProgrammeType {
  StandaloneHypertension_4Day = 'STANDALONE_HYPERTENSION_4_DAY',
  StandaloneHypertension_8Day = 'STANDALONE_HYPERTENSION_8_DAY',
}

export enum WardType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL',
}

export type WeeklyAlcoholConsumptionScore = HealthScoreByElement & {
  __typename?: 'WeeklyAlcoholConsumptionScore';
  avgAlcoholUnitsPerWeek: Scalars['Float'];
  distanceToMagicNumber: Maybe<Scalars['Float']>;
  distanceToMaxScore: Maybe<Scalars['Float']>;
  healthScoreElementType: HealthScoreElement;
  weeklyAlcoholCurrentZone: Maybe<HealthScoreWeeklyAlcoholConsumptionZone>;
  weeklyAlcoholRecommendations: Array<HealthScoreWeeklyAlcoholConsumptionRecommendation>;
  weeklyAlcoholTargetZone: Maybe<HealthScoreWeeklyAlcoholConsumptionZone>;
};

export enum YesNoType {
  No = 'NO',
  None = 'NONE',
  Yes = 'YES',
}

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockActivateUserMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ activateUser })
 *   )
 * })
 */
export const mockActivateUserMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ActivateUserMutationVariables>,
    GraphQLContext<ActivateUserMutation>,
    any
  >
) =>
  graphql.mutation<ActivateUserMutation, ActivateUserMutationVariables>(
    'activateUser',
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAdmitSelfRegisteredPatientMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ selfAdmittedPatientAnon })
 *   )
 * })
 */
export const mockAdmitSelfRegisteredPatientMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<AdmitSelfRegisteredPatientMutationVariables>,
    GraphQLContext<AdmitSelfRegisteredPatientMutation>,
    any
  >
) =>
  graphql.mutation<
    AdmitSelfRegisteredPatientMutation,
    AdmitSelfRegisteredPatientMutationVariables
  >('AdmitSelfRegisteredPatient', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBookGeneralPracticeAppointmentMutation((req, res, ctx) => {
 *   const { input, patientIdentifier } = req.variables;
 *   return res(
 *     ctx.data({ bookGeneralPracticeAppointment })
 *   )
 * })
 */
export const mockBookGeneralPracticeAppointmentMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<BookGeneralPracticeAppointmentMutationVariables>,
    GraphQLContext<BookGeneralPracticeAppointmentMutation>,
    any
  >
) =>
  graphql.mutation<
    BookGeneralPracticeAppointmentMutation,
    BookGeneralPracticeAppointmentMutationVariables
  >('BookGeneralPracticeAppointment', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCancelGeneralPracticeAppointmentForPatientMutation((req, res, ctx) => {
 *   const { patientIdentifier, appointmentId } = req.variables;
 *   return res(
 *     ctx.data({ cancelGeneralPracticeAppointmentForPatient })
 *   )
 * })
 */
export const mockCancelGeneralPracticeAppointmentForPatientMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CancelGeneralPracticeAppointmentForPatientMutationVariables>,
    GraphQLContext<CancelGeneralPracticeAppointmentForPatientMutation>,
    any
  >
) =>
  graphql.mutation<
    CancelGeneralPracticeAppointmentForPatientMutation,
    CancelGeneralPracticeAppointmentForPatientMutationVariables
  >('CancelGeneralPracticeAppointmentForPatient', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCareSettingStepUpAcceptanceMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ careSettingStepUpAcceptance })
 *   )
 * })
 */
export const mockCareSettingStepUpAcceptanceMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CareSettingStepUpAcceptanceMutationVariables>,
    GraphQLContext<CareSettingStepUpAcceptanceMutation>,
    any
  >
) =>
  graphql.mutation<
    CareSettingStepUpAcceptanceMutation,
    CareSettingStepUpAcceptanceMutationVariables
  >('CareSettingStepUpAcceptance', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRegisterGeneralPracticePatientLinkageMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ registerGeneralPracticePatientLinkage })
 *   )
 * })
 */
export const mockRegisterGeneralPracticePatientLinkageMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<RegisterGeneralPracticePatientLinkageMutationVariables>,
    GraphQLContext<RegisterGeneralPracticePatientLinkageMutation>,
    any
  >
) =>
  graphql.mutation<
    RegisterGeneralPracticePatientLinkageMutation,
    RegisterGeneralPracticePatientLinkageMutationVariables
  >('registerGeneralPracticePatientLinkage', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateProgrammeMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createProgramme })
 *   )
 * })
 */
export const mockCreateProgrammeMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateProgrammeMutationVariables>,
    GraphQLContext<CreateProgrammeMutation>,
    any
  >
) =>
  graphql.mutation<CreateProgrammeMutation, CreateProgrammeMutationVariables>(
    'createProgramme',
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateUserRegistrationRecordMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createUserRegistrationRecordMutationAnon })
 *   )
 * })
 */
export const mockCreateUserRegistrationRecordMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateUserRegistrationRecordMutationVariables>,
    GraphQLContext<CreateUserRegistrationRecordMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateUserRegistrationRecordMutation,
    CreateUserRegistrationRecordMutationVariables
  >('CreateUserRegistrationRecord', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateWardPatientObservationMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ createWardPatientObservation })
 *   )
 * })
 */
export const mockCreateWardPatientObservationMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateWardPatientObservationMutationVariables>,
    GraphQLContext<CreateWardPatientObservationMutation>,
    any
  >
) =>
  graphql.mutation<
    CreateWardPatientObservationMutation,
    CreateWardPatientObservationMutationVariables
  >('createWardPatientObservation', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeletePatientMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ deleteUser })
 *   )
 * })
 */
export const mockDeletePatientMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeletePatientMutationVariables>,
    GraphQLContext<DeletePatientMutation>,
    any
  >
) =>
  graphql.mutation<DeletePatientMutation, DeletePatientMutationVariables>(
    'DeletePatient',
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUnlinkGeneralPracticePatientMutation((req, res, ctx) => {
 *   const { userId } = req.variables;
 *   return res(
 *     ctx.data({ unlinkGeneralPracticePatientMutation })
 *   )
 * })
 */
export const mockUnlinkGeneralPracticePatientMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UnlinkGeneralPracticePatientMutationVariables>,
    GraphQLContext<UnlinkGeneralPracticePatientMutation>,
    any
  >
) =>
  graphql.mutation<
    UnlinkGeneralPracticePatientMutation,
    UnlinkGeneralPracticePatientMutationVariables
  >('unlinkGeneralPracticePatient', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateContactExclusionPreferencesMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateContactExclusionPreferences })
 *   )
 * })
 */
export const mockUpdateContactExclusionPreferencesMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateContactExclusionPreferencesMutationVariables>,
    GraphQLContext<UpdateContactExclusionPreferencesMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateContactExclusionPreferencesMutation,
    UpdateContactExclusionPreferencesMutationVariables
  >('updateContactExclusionPreferences', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePersonMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updatePerson })
 *   )
 * })
 */
export const mockUpdatePersonMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdatePersonMutationVariables>,
    GraphQLContext<UpdatePersonMutation>,
    any
  >
) =>
  graphql.mutation<UpdatePersonMutation, UpdatePersonMutationVariables>(
    'updatePerson',
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateUserTermsAndConditionsMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ updateUserTermsAndConditions })
 *   )
 * })
 */
export const mockUpdateUserTermsAndConditionsMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateUserTermsAndConditionsMutationVariables>,
    GraphQLContext<UpdateUserTermsAndConditionsMutation>,
    any
  >
) =>
  graphql.mutation<
    UpdateUserTermsAndConditionsMutation,
    UpdateUserTermsAndConditionsMutationVariables
  >('UpdateUserTermsAndConditions', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetGeneralPracticeAppointmentSlotQuery((req, res, ctx) => {
 *   const { patientIdentifier, days } = req.variables;
 *   return res(
 *     ctx.data({ appointments })
 *   )
 * })
 */
export const mockGetGeneralPracticeAppointmentSlotQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetGeneralPracticeAppointmentSlotQueryVariables>,
    GraphQLContext<GetGeneralPracticeAppointmentSlotQuery>,
    any
  >
) =>
  graphql.query<
    GetGeneralPracticeAppointmentSlotQuery,
    GetGeneralPracticeAppointmentSlotQueryVariables
  >('getGeneralPracticeAppointmentSlot', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetAssessObservationQuery((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ assessObservation })
 *   )
 * })
 */
export const mockGetAssessObservationQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetAssessObservationQueryVariables>,
    GraphQLContext<GetAssessObservationQuery>,
    any
  >
) =>
  graphql.query<GetAssessObservationQuery, GetAssessObservationQueryVariables>(
    'getAssessObservation',
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetContactExclusionPreferencesQuery((req, res, ctx) => {
 *   const { personId } = req.variables;
 *   return res(
 *     ctx.data({ contactExclusionPreferences })
 *   )
 * })
 */
export const mockGetContactExclusionPreferencesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetContactExclusionPreferencesQueryVariables>,
    GraphQLContext<GetContactExclusionPreferencesQuery>,
    any
  >
) =>
  graphql.query<
    GetContactExclusionPreferencesQuery,
    GetContactExclusionPreferencesQueryVariables
  >('getContactExclusionPreferences', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetGeneralPracticeBookedAppointmentsByUserQuery((req, res, ctx) => {
 *   const { patientIdentifier } = req.variables;
 *   return res(
 *     ctx.data({ bookedAppointments })
 *   )
 * })
 */
export const mockGetGeneralPracticeBookedAppointmentsByUserQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetGeneralPracticeBookedAppointmentsByUserQueryVariables>,
    GraphQLContext<GetGeneralPracticeBookedAppointmentsByUserQuery>,
    any
  >
) =>
  graphql.query<
    GetGeneralPracticeBookedAppointmentsByUserQuery,
    GetGeneralPracticeBookedAppointmentsByUserQueryVariables
  >('getGeneralPracticeBookedAppointmentsByUser', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetGeneralPracticeSettingsQuery((req, res, ctx) => {
 *   const { gpOdsCode, patientIdentifier } = req.variables;
 *   return res(
 *     ctx.data({ generalPracticeServicePracticeSettings, generalPracticePatientSettings })
 *   )
 * })
 */
export const mockGetGeneralPracticeSettingsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetGeneralPracticeSettingsQueryVariables>,
    GraphQLContext<GetGeneralPracticeSettingsQuery>,
    any
  >
) =>
  graphql.query<
    GetGeneralPracticeSettingsQuery,
    GetGeneralPracticeSettingsQueryVariables
  >('getGeneralPracticeSettings', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetLastObservationAndBandsQuery((req, res, ctx) => {
 *   const { observationType } = req.variables;
 *   return res(
 *     ctx.data({ observationBands, searchWardPatientObservations })
 *   )
 * })
 */
export const mockGetLastObservationAndBandsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetLastObservationAndBandsQueryVariables>,
    GraphQLContext<GetLastObservationAndBandsQuery>,
    any
  >
) =>
  graphql.query<
    GetLastObservationAndBandsQuery,
    GetLastObservationAndBandsQueryVariables
  >('getLastObservationAndBands', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetObservationBandsQuery((req, res, ctx) => {
 *   const { observationType } = req.variables;
 *   return res(
 *     ctx.data({ observationBands })
 *   )
 * })
 */
export const mockGetObservationBandsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetObservationBandsQueryVariables>,
    GraphQLContext<GetObservationBandsQuery>,
    any
  >
) =>
  graphql.query<GetObservationBandsQuery, GetObservationBandsQueryVariables>(
    'getObservationBands',
    resolver
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetObservationDataPointsQuery((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ observationDataPoints })
 *   )
 * })
 */
export const mockGetObservationDataPointsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetObservationDataPointsQueryVariables>,
    GraphQLContext<GetObservationDataPointsQuery>,
    any
  >
) =>
  graphql.query<
    GetObservationDataPointsQuery,
    GetObservationDataPointsQueryVariables
  >('getObservationDataPoints', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetPatientProgrammeInitiativeQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ userPatientProfileResults })
 *   )
 * })
 */
export const mockGetPatientProgrammeInitiativeQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetPatientProgrammeInitiativeQueryVariables>,
    GraphQLContext<GetPatientProgrammeInitiativeQuery>,
    any
  >
) =>
  graphql.query<
    GetPatientProgrammeInitiativeQuery,
    GetPatientProgrammeInitiativeQueryVariables
  >('GetPatientProgrammeInitiative', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetSearchWardPatientObservationsQuery((req, res, ctx) => {
 *   const { size, offset, types } = req.variables;
 *   return res(
 *     ctx.data({ searchWardPatientObservations })
 *   )
 * })
 */
export const mockGetSearchWardPatientObservationsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetSearchWardPatientObservationsQueryVariables>,
    GraphQLContext<GetSearchWardPatientObservationsQuery>,
    any
  >
) =>
  graphql.query<
    GetSearchWardPatientObservationsQuery,
    GetSearchWardPatientObservationsQueryVariables
  >('getSearchWardPatientObservations', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUserPatientProfileQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ userPatientProfileResults })
 *   )
 * })
 */
export const mockGetUserPatientProfileQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetUserPatientProfileQueryVariables>,
    GraphQLContext<GetUserPatientProfileQuery>,
    any
  >
) =>
  graphql.query<
    GetUserPatientProfileQuery,
    GetUserPatientProfileQueryVariables
  >('GetUserPatientProfile', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUserRegistrationRecordQuery((req, res, ctx) => {
 *   const { encryptedId } = req.variables;
 *   return res(
 *     ctx.data({ userRegistrationRecordAnon })
 *   )
 * })
 */
export const mockGetUserRegistrationRecordQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetUserRegistrationRecordQueryVariables>,
    GraphQLContext<GetUserRegistrationRecordQuery>,
    any
  >
) =>
  graphql.query<
    GetUserRegistrationRecordQuery,
    GetUserRegistrationRecordQueryVariables
  >('getUserRegistrationRecord', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetWardPatientObservationsQuery((req, res, ctx) => {
 *   const { exclusiveStartKey, limit, recordedDateTimeEnd, recordedDateTimeStart } = req.variables;
 *   return res(
 *     ctx.data({ wardPatientObservationsPaged })
 *   )
 * })
 */
export const mockGetWardPatientObservationsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetWardPatientObservationsQueryVariables>,
    GraphQLContext<GetWardPatientObservationsQuery>,
    any
  >
) =>
  graphql.query<
    GetWardPatientObservationsQuery,
    GetWardPatientObservationsQueryVariables
  >('getWardPatientObservations', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetWardPatientObservationsForProgrammeQuery((req, res, ctx) => {
 *   const { offset, programmeId, size } = req.variables;
 *   return res(
 *     ctx.data({ wardPatientObservationsForProgramme })
 *   )
 * })
 */
export const mockGetWardPatientObservationsForProgrammeQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetWardPatientObservationsForProgrammeQueryVariables>,
    GraphQLContext<GetWardPatientObservationsForProgrammeQuery>,
    any
  >
) =>
  graphql.query<
    GetWardPatientObservationsForProgrammeQuery,
    GetWardPatientObservationsForProgrammeQueryVariables
  >('getWardPatientObservationsForProgramme', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetWardPatientProgrammeByAverageObservationIdQuery((req, res, ctx) => {
 *   const { averageObservationId } = req.variables;
 *   return res(
 *     ctx.data({ wardPatientProgrammeForAverageObservationId })
 *   )
 * })
 */
export const mockGetWardPatientProgrammeByAverageObservationIdQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetWardPatientProgrammeByAverageObservationIdQueryVariables>,
    GraphQLContext<GetWardPatientProgrammeByAverageObservationIdQuery>,
    any
  >
) =>
  graphql.query<
    GetWardPatientProgrammeByAverageObservationIdQuery,
    GetWardPatientProgrammeByAverageObservationIdQueryVariables
  >('GetWardPatientProgrammeByAverageObservationId', resolver);

export type UserFieldsFragment = {
  __typename?: 'User';
  id: string;
  type: string;
  attributes: {
    __typename?: 'UserAttributes';
    isStandalonePatient: boolean;
    registrationStatus: RegistrationStatus | null;
    deletionRequested: string | null;
    defaultCareSettingId: string;
    termsAndConditions: {
      __typename?: 'TermsAndConditions';
      dateTime: string;
      decision: TermsAndConditionsStatus;
      version: string;
    } | null;
  };
};

export type WardPatientObservationViewFieldsFragment = {
  __typename?: 'WardPatientObservationView';
  id: string;
  observationType: ObservationType;
  recordedDateTime: string;
  wardPatientId: string;
  res_observationValue:
    | {
        __typename?: 'BloodPressure';
        diastolic: number;
        systolic: number;
        rating: string;
        fhirRating: FhirRating;
      }
    | { __typename?: 'BodyHeight' }
    | { __typename?: 'BodyTemperature' }
    | {
        __typename?: 'BodyWeight';
        weightUnit: BodyWeightUnitInput;
        weight: number;
      }
    | {
        __typename?: 'Cholesterol';
        unit: CholesterolUnit;
        total: number;
        hdl: number;
      }
    | {
        __typename?: 'FastingBloodGlucose';
        bloodGlucose: number;
        rating: string;
        fhirRating: FhirRating;
      }
    | { __typename?: 'HeartRhythm' }
    | {
        __typename?: 'Pulse';
        bpm: number;
        rating: string;
        fhirRating: FhirRating;
      }
    | {
        __typename?: 'Spo2';
        oxygenSaturation: number;
        rating: string;
        fhirRating: FhirRating;
      };
};

export type PatientProgrammeFragment = {
  __typename?: 'WardPatientProgramme';
  id: string;
  attributes: {
    __typename?: 'WardPatientProgrammeAttributes';
    status: WardPatientProgrammeStatus;
    type: WardPatientProgrammeType;
    endDateTime: string | null;
    durationInDays: number | null;
    observationsCount: number;
    averageObservation: {
      __typename?: 'WardPatientObservationView';
      id: string;
      observationType: ObservationType;
      recordedDateTime: string;
      res_observationValue:
        | {
            __typename?: 'BloodPressure';
            rating: string;
            fhirRating: FhirRating;
            systolic: number;
            diastolic: number;
          }
        | { __typename?: 'BodyHeight' }
        | { __typename?: 'BodyTemperature' }
        | { __typename?: 'BodyWeight' }
        | { __typename?: 'Cholesterol' }
        | { __typename?: 'FastingBloodGlucose' }
        | { __typename?: 'HeartRhythm' }
        | { __typename?: 'Pulse' }
        | { __typename?: 'Spo2' };
    } | null;
  };
};

export type ActivateUserMutationVariables = Exact<{
  input: ActivateUserInput;
}>;

export type ActivateUserMutation = {
  __typename?: 'Mutation';
  activateUser: {
    __typename?: 'User';
    id: string;
    attributes: {
      __typename?: 'UserAttributes';
      defaultCareSettingId: string;
      defaultWardId: string;
      safId: string;
      registrationStatus: RegistrationStatus | null;
      audit: {
        __typename?: 'Audit';
        created: string | null;
        createdBy: string | null;
        lastUpdated: string | null;
        updatedBy: string | null;
        version: number;
      } | null;
    };
  } | null;
};

export type AdmitSelfRegisteredPatientMutationVariables = Exact<{
  input: SelfRegistrationPersonalInformationInput;
}>;

export type AdmitSelfRegisteredPatientMutation = {
  __typename?: 'Mutation';
  selfAdmittedPatientAnon:
    | {
        __typename?: 'Auth0CommonPasswordValidationError';
        code: string | null;
        message: string;
      }
    | {
        __typename?: 'Auth0ConflictValidationError';
        code: string | null;
        message: string;
      }
    | {
        __typename?: 'Auth0UserInfoValidationError';
        code: string | null;
        message: string;
      }
    | { __typename?: 'SelfAdmittedPatientResult'; result: boolean }
    | null;
};

export type BookGeneralPracticeAppointmentMutationVariables = Exact<{
  input: GeneralPracticeAppointmentBookingInput;
  patientIdentifier: Scalars['ID'];
}>;

export type BookGeneralPracticeAppointmentMutation = {
  __typename?: 'Mutation';
  bookGeneralPracticeAppointment: {
    __typename?: 'GeneralPracticeAppointmentBooking';
    type: string;
    id: string;
    attributes: {
      __typename?: 'GeneralPracticeAppointmentBookingBody';
      appointmentSlotId: string;
      bookingReason: string;
      startTime: string;
      endTime: string;
      telephoneContactType: string;
      telephoneNumber: string;
      emailAddress: string;
    };
  } | null;
};

export type CancelGeneralPracticeAppointmentForPatientMutationVariables =
  Exact<{
    patientIdentifier: Scalars['ID'];
    appointmentId: Scalars['String'];
  }>;

export type CancelGeneralPracticeAppointmentForPatientMutation = {
  __typename?: 'Mutation';
  cancelGeneralPracticeAppointmentForPatient: boolean | null;
};

export type CareSettingStepUpAcceptanceMutationVariables = Exact<{
  input: CareSettingStepUpAcceptanceInput;
}>;

export type CareSettingStepUpAcceptanceMutation = {
  __typename?: 'Mutation';
  careSettingStepUpAcceptance: {
    __typename?: 'CareSettingStepUpInvitationAttributes';
    personId: string;
    careSettingId: string;
    observationTypes: Array<ObservationType>;
    monitoredConditions: Array<{
      __typename?: 'MonitoredCondition';
      condition: PatientDiagnosis;
      dischargeStatus: DischargeStatusType | null;
      reasonForDischarge: DischargeReasonType | null;
      comments: string | null;
      monitoringStatus: {
        __typename?: 'MonitoringStatus';
        status: MonitoringStatusType | null;
        statusUpdatedBy: string | null;
        statusUpdateDateTime: string | null;
        res_statusUpdatedBy: string | null;
      } | null;
      consultationStatus: {
        __typename?: 'MonitoredConditionConsultationStatus';
        status: MonitoredConditionConsultationStatusType | null;
        statusUpdatedBy: string | null;
        statusUpdateDateTime: string | null;
        res_statusUpdatedBy: string | null;
      } | null;
      testStatus: {
        __typename?: 'MonitoredConditionTestStatus';
        status: MonitoredConditionTestStatusType | null;
        statusUpdatedBy: string | null;
        statusUpdateDateTime: string | null;
        res_statusUpdatedBy: string | null;
      } | null;
    }>;
  } | null;
};

export type RegisterGeneralPracticePatientLinkageMutationVariables = Exact<{
  input: GeneralPracticePatientLinkageRegistrationInput;
}>;

export type RegisterGeneralPracticePatientLinkageMutation = {
  __typename?: 'Mutation';
  registerGeneralPracticePatientLinkage: {
    __typename?: 'GeneralPracticePatientLinkageRegistration';
    id: string;
    type: string;
    attributes: {
      __typename?: 'GeneralPracticePatientLinkageRegistrationResponse';
      accountId: string;
      dateOfBirth: string;
      gpOdsCode: string;
      linkageKey: string;
      patientId: string;
      personId: string;
      surname: string;
    };
  } | null;
};

export type CreateProgrammeMutationVariables = Exact<{
  input: WardPatientProgrammeInput;
}>;

export type CreateProgrammeMutation = {
  __typename?: 'Mutation';
  createProgramme: {
    __typename?: 'WardPatientProgramme';
    id: string;
    type: string;
  };
};

export type CreateUserRegistrationRecordMutationVariables = Exact<{
  input: UserRegistrationRecordInput;
}>;

export type CreateUserRegistrationRecordMutation = {
  __typename?: 'Mutation';
  createUserRegistrationRecordMutationAnon:
    | {
        __typename?: 'UserAlreadyExistsValidationError';
        code: string | null;
        message: string;
      }
    | {
        __typename?: 'UserRegistrationRecord';
        attributes: {
          __typename?: 'UserRegistrationRecordMutationResponse';
          primaryEmailAddress: string;
          effectiveTo: string;
        };
      }
    | null;
};

export type CreateWardPatientObservationMutationVariables = Exact<{
  input: CreateWardPatientObservationInput;
}>;

export type CreateWardPatientObservationMutation = {
  __typename?: 'Mutation';
  createWardPatientObservation: Array<{
    __typename?: 'WardPatientObservation';
    id: string;
  } | null>;
};

export type DeletePatientMutationVariables = Exact<{ [key: string]: never }>;

export type DeletePatientMutation = {
  __typename?: 'Mutation';
  deleteUser: {
    __typename?: 'User';
    id: string;
    type: string;
    attributes: {
      __typename?: 'UserAttributes';
      isStandalonePatient: boolean;
      registrationStatus: RegistrationStatus | null;
      deletionRequested: string | null;
      defaultCareSettingId: string;
      termsAndConditions: {
        __typename?: 'TermsAndConditions';
        dateTime: string;
        decision: TermsAndConditionsStatus;
        version: string;
      } | null;
    };
  } | null;
};

export type UnlinkGeneralPracticePatientMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UnlinkGeneralPracticePatientMutation = {
  __typename?: 'Mutation';
  unlinkGeneralPracticePatientMutation: {
    __typename?: 'Person';
    id: string;
    type: string;
    attributes: {
      __typename?: 'PersonAttributes';
      registeredGPSurgery: string | null;
      userId: string;
      generalPracticeRegistration: {
        __typename?: 'PersonGeneralPracticeRegistration';
        accountId: string;
        linkageKey: string;
        odsCode: string;
        patientIdentifier: string;
      } | null;
    };
  } | null;
};

export type UpdateContactExclusionPreferencesMutationVariables = Exact<{
  input: ContactExclusionPreferencesInput;
}>;

export type UpdateContactExclusionPreferencesMutation = {
  __typename?: 'Mutation';
  updateContactExclusionPreferences: {
    __typename?: 'ContactExclusionPreferencesResult';
    contactExclusionPreferences: Array<ContactExclusionPreferences | null>;
  } | null;
};

export type UpdatePersonMutationVariables = Exact<{
  input: PersonInput;
}>;

export type UpdatePersonMutation = {
  __typename?: 'Mutation';
  updatePerson: {
    __typename?: 'Person';
    id: string;
    attributes: {
      __typename?: 'PersonAttributes';
      generalPracticeRegistration: {
        __typename?: 'PersonGeneralPracticeRegistration';
        accountId: string;
        odsCode: string;
        linkageKey: string;
        patientIdentifier: string;
      } | null;
      personalInformation: {
        __typename?: 'PersonPersonalInformation';
        firstName: string;
        surname: string;
        dateOfBirth: string | null;
        ethnicity: Ethnicity | null;
      };
      contactInformation: {
        __typename?: 'PersonContactInformation';
        primaryContactNumber: string | null;
        primaryEmailAddress: string | null;
      } | null;
      latestHealthInformation: {
        __typename?: 'HealthInformation';
        bloodPressureMedicationInformation: {
          __typename?: 'BloodPressureMedication';
          hadBPMedication: boolean;
          hasBPMedication: boolean;
          created: string;
        } | null;
        heightInformation: {
          __typename?: 'Height';
          created: string;
          unit: HeightUnit;
          value: number;
        } | null;
        smokingInformation: {
          __typename?: 'Smoking';
          created: string;
          status: SmokingStatus;
        } | null;
        ckdInformation: {
          __typename?: 'Ckd';
          ckdStatus: boolean;
          created: string;
        } | null;
        cvdInformation: {
          __typename?: 'Cvd';
          created: string;
          familyHadCvd: CvdFamilyInformation;
          hasCvd: boolean;
        } | null;
        diabetesInformation: {
          __typename?: 'Diabetes';
          created: string;
          diabetesStatus: DiabetesStatus;
        } | null;
      } | null;
    };
  } | null;
};

export type UpdateUserTermsAndConditionsMutationVariables = Exact<{
  input: TermsAndConditionsInput;
}>;

export type UpdateUserTermsAndConditionsMutation = {
  __typename?: 'Mutation';
  updateUserTermsAndConditions: {
    __typename?: 'User';
    attributes: {
      __typename?: 'UserAttributes';
      termsAndConditions: {
        __typename?: 'TermsAndConditions';
        dateTime: string;
        decision: TermsAndConditionsStatus;
        version: string;
      } | null;
    };
  } | null;
};

export type GetGeneralPracticeAppointmentSlotQueryVariables = Exact<{
  patientIdentifier: Scalars['ID'];
  days: InputMaybe<Scalars['Int']>;
}>;

export type GetGeneralPracticeAppointmentSlotQuery = {
  __typename?: 'Query';
  appointments: Array<{
    __typename?: 'GeneralPracticeAppointmentSlotGroup';
    type: string;
    appointmentSlots: Array<{
      __typename?: 'GeneralPracticeAppointmentSlot';
      attributes: {
        __typename?: 'GeneralPracticeAppointmentSlotAttributes';
        appointmentSlotId: string;
        clinicianDisplayName: string | null;
        endTime: string;
        locationAddress: string | null;
        locationName: string | null;
        locationPostcode: string | null;
        sessionType: string | null;
        slotTypeName: string;
        slotTypeStatus: string | null;
        startTime: string;
      };
    }>;
  } | null> | null;
};

export type GetAssessObservationQueryVariables = Exact<{
  input: CreateWardPatientObservationInput;
}>;

export type GetAssessObservationQuery = {
  __typename?: 'Query';
  assessObservation: Array<{
    __typename?: 'ObservationAssessment';
    type: ObservationType;
    rating: {
      __typename?: 'ObservationRating';
      fhirRating: string;
      lightColourTheme: {
        __typename?: 'ColorTheme';
        backgroundColour: string;
        foregroundColour: string;
      };
    } | null;
    warning: {
      __typename?: 'ObservationWarning';
      level: ObservationWarningLevel;
    } | null;
  }>;
};

export type GetContactExclusionPreferencesQueryVariables = Exact<{
  personId: Scalars['ID'];
}>;

export type GetContactExclusionPreferencesQuery = {
  __typename?: 'Query';
  contactExclusionPreferences: Array<ContactExclusionPreferences> | null;
};

export type GetGeneralPracticeBookedAppointmentsByUserQueryVariables = Exact<{
  patientIdentifier: Scalars['ID'];
}>;

export type GetGeneralPracticeBookedAppointmentsByUserQuery = {
  __typename?: 'Query';
  bookedAppointments: Array<{
    __typename?: 'GeneralPracticeBookedAppointmentSlot';
    id: string;
    type: string;
    attributes: {
      __typename?: 'GeneralPracticeBookedAppointmentAttributes';
      appointmentId: string;
      isCancellable: boolean | null;
      staffName: string | null;
      start: string;
      end: string;
      created: string | null;
      appointmentType: string;
      status: string | null;
      sessionType: string | null;
      address: {
        __typename?: 'GeneralPracticeBookedAppointmentAddress';
        buildingName: string | null;
        line1: string | null;
        line2: string | null;
        line3: string | null;
        line4: string | null;
        line5: string | null;
        postalCode: string | null;
      } | null;
    };
  } | null> | null;
};

export type GetGeneralPracticeSettingsQueryVariables = Exact<{
  gpOdsCode: Scalars['String'];
  patientIdentifier: Scalars['ID'];
}>;

export type GetGeneralPracticeSettingsQuery = {
  __typename?: 'Query';
  generalPracticeServicePracticeSettings: {
    __typename?: 'GeneralPracticeServicePracticeSettings';
    id: string;
    type: string;
    attributes: {
      __typename?: 'GeneralPracticeServicePracticeSettingsAttributes';
      appointmentsSupported: boolean;
      medicalRecordSupported: boolean;
      repeatPrescriptionsSupported: boolean;
      requestId: string;
    };
  } | null;
  generalPracticePatientSettings: {
    __typename?: 'GeneralPracticePatientSettings';
    id: string;
    type: string;
    attributes: {
      __typename?: 'GeneralPracticePatientSettingsAttributes';
      repeatPrescriptions: boolean;
      appointments: boolean;
      medicalRecord: boolean;
      medicalRecordDetails: {
        __typename?: 'GeneralPracticePatientMedicalRecordDetails';
        immunisations: boolean;
        allergies: boolean;
        consultations: boolean;
        medication: boolean;
        problems: boolean;
        testResults: boolean;
      };
    };
  } | null;
};

export type GetLastObservationAndBandsQueryVariables = Exact<{
  observationType: ObservationType;
}>;

export type GetLastObservationAndBandsQuery = {
  __typename?: 'Query';
  observationBands: {
    __typename?: 'ObservationBands';
    type: ObservationType;
    info: {
      __typename?: 'BloodPressureInfo';
      lastNHSReviewDate: string;
      nextNHSReviewDate: string;
      lowerBloodPressureLink: string;
      nhsGuidanceLink: string;
    } | null;
    ratings: Array<{
      __typename?: 'ObservationRating';
      fhirRating: string;
      displayName: string;
      displayOrder: number;
      lightColourTheme: {
        __typename?: 'ColorTheme';
        foregroundColour: string;
        backgroundColour: string;
      };
      ranges: Array<{
        __typename?: 'ObservationRange';
        minDecimal: number;
        maxDecimal: number | null;
        step: number;
        type: ObservationComponentName;
      }>;
    }>;
  };
  searchWardPatientObservations: {
    __typename?: 'WardPatientObservationViewSearchResults';
    searchResults: Array<{
      __typename?: 'WardPatientObservationView';
      id: string;
      observationType: ObservationType;
      recordedDateTime: string;
      wardPatientId: string;
      res_observationValue:
        | {
            __typename?: 'BloodPressure';
            diastolic: number;
            systolic: number;
            rating: string;
            fhirRating: FhirRating;
          }
        | { __typename?: 'BodyHeight' }
        | { __typename?: 'BodyTemperature' }
        | {
            __typename?: 'BodyWeight';
            weightUnit: BodyWeightUnitInput;
            weight: number;
          }
        | {
            __typename?: 'Cholesterol';
            unit: CholesterolUnit;
            total: number;
            hdl: number;
          }
        | {
            __typename?: 'FastingBloodGlucose';
            bloodGlucose: number;
            rating: string;
            fhirRating: FhirRating;
          }
        | { __typename?: 'HeartRhythm' }
        | {
            __typename?: 'Pulse';
            bpm: number;
            rating: string;
            fhirRating: FhirRating;
          }
        | {
            __typename?: 'Spo2';
            oxygenSaturation: number;
            rating: string;
            fhirRating: FhirRating;
          };
    }>;
  };
};

export type GetObservationBandsQueryVariables = Exact<{
  observationType: ObservationType;
}>;

export type GetObservationBandsQuery = {
  __typename?: 'Query';
  observationBands: {
    __typename?: 'ObservationBands';
    type: ObservationType;
    info: {
      __typename?: 'BloodPressureInfo';
      lastNHSReviewDate: string;
      nextNHSReviewDate: string;
      lowerBloodPressureLink: string;
      nhsGuidanceLink: string;
    } | null;
    ratings: Array<{
      __typename?: 'ObservationRating';
      fhirRating: string;
      displayName: string;
      displayOrder: number;
      lightColourTheme: {
        __typename?: 'ColorTheme';
        foregroundColour: string;
        backgroundColour: string;
      };
      ranges: Array<{
        __typename?: 'ObservationRange';
        minDecimal: number;
        maxDecimal: number | null;
        step: number;
        type: ObservationComponentName;
      }>;
    }>;
  };
};

export type GetObservationDataPointsQueryVariables = Exact<{
  input: ObservationDataPointInput;
}>;

export type GetObservationDataPointsQuery = {
  __typename?: 'Query';
  observationDataPoints: {
    __typename?: 'ObservationDataPointResults';
    hits: number;
    searchRequestId: string;
    timedOut: boolean;
    average:
      | {
          __typename?: 'BloodPressure';
          systolic: number;
          diastolic: number;
          rating: string;
        }
      | { __typename?: 'BodyHeight' }
      | { __typename?: 'BodyTemperature' }
      | { __typename?: 'BodyWeight' }
      | { __typename?: 'Cholesterol' }
      | { __typename?: 'FastingBloodGlucose' }
      | { __typename?: 'HeartRhythm' }
      | { __typename?: 'Pulse'; bpm: number; rating: string }
      | { __typename?: 'Spo2'; oxygenSaturation: number; rating: string }
      | null;
    min:
      | { __typename?: 'BloodPressure'; systolic: number; diastolic: number }
      | { __typename?: 'BodyHeight' }
      | { __typename?: 'BodyTemperature' }
      | { __typename?: 'BodyWeight' }
      | { __typename?: 'Cholesterol' }
      | { __typename?: 'FastingBloodGlucose' }
      | { __typename?: 'HeartRhythm' }
      | { __typename?: 'Pulse'; bpm: number }
      | { __typename?: 'Spo2'; oxygenSaturation: number }
      | null;
    max:
      | { __typename?: 'BloodPressure'; systolic: number; diastolic: number }
      | { __typename?: 'BodyHeight' }
      | { __typename?: 'BodyTemperature' }
      | { __typename?: 'BodyWeight' }
      | { __typename?: 'Cholesterol' }
      | { __typename?: 'FastingBloodGlucose' }
      | { __typename?: 'HeartRhythm' }
      | { __typename?: 'Pulse'; bpm: number }
      | { __typename?: 'Spo2'; oxygenSaturation: number }
      | null;
    searchResults: Array<{
      __typename?: 'ObservationDataPointView';
      id: string;
      observationType: ObservationType;
      recordedDateTime: string;
      res_componentValues: Array<{
        __typename?: 'ObservationDataPointComponentValue';
        name: ObservationComponentName;
        value: number;
      }>;
    }>;
    precedingDataPoint: {
      __typename?: 'ObservationDataPointView';
      id: string;
      observationType: ObservationType;
      recordedDateTime: string;
      res_componentValues: Array<{
        __typename?: 'ObservationDataPointComponentValue';
        name: ObservationComponentName;
        value: number;
      }>;
    } | null;
  };
};

export type GetPatientProgrammeInitiativeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPatientProgrammeInitiativeQuery = {
  __typename?: 'Query';
  userPatientProfileResults:
    | { __typename?: 'ProfileNonCompliant' }
    | {
        __typename?: 'UserPatientProfile';
        userId: string;
        programmeInitiative: {
          __typename?: 'WardPatientProgrammeInitiative';
          hypertensionHomeBanner: WardPatientHypertensionHomeBannerStatus | null;
          mostRecent: {
            __typename?: 'WardPatientProgramme';
            id: string;
            attributes: {
              __typename?: 'WardPatientProgrammeAttributes';
              status: WardPatientProgrammeStatus;
              type: WardPatientProgrammeType;
              endDateTime: string | null;
              durationInDays: number | null;
              observationsCount: number;
              averageObservation: {
                __typename?: 'WardPatientObservationView';
                id: string;
                observationType: ObservationType;
                recordedDateTime: string;
                res_observationValue:
                  | {
                      __typename?: 'BloodPressure';
                      rating: string;
                      fhirRating: FhirRating;
                      systolic: number;
                      diastolic: number;
                    }
                  | { __typename?: 'BodyHeight' }
                  | { __typename?: 'BodyTemperature' }
                  | { __typename?: 'BodyWeight' }
                  | { __typename?: 'Cholesterol' }
                  | { __typename?: 'FastingBloodGlucose' }
                  | { __typename?: 'HeartRhythm' }
                  | { __typename?: 'Pulse' }
                  | { __typename?: 'Spo2' };
              } | null;
            };
          } | null;
        };
      };
};

export type GetSearchWardPatientObservationsQueryVariables = Exact<{
  size: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  types: InputMaybe<Array<ObservationType> | ObservationType>;
}>;

export type GetSearchWardPatientObservationsQuery = {
  __typename?: 'Query';
  searchWardPatientObservations: {
    __typename?: 'WardPatientObservationViewSearchResults';
    hits: number;
    searchRequestId: string;
    timedOut: boolean;
    searchResults: Array<{
      __typename?: 'WardPatientObservationView';
      id: string;
      observationType: ObservationType;
      recordedDateTime: string;
      wardPatientId: string;
      res_observationValue:
        | {
            __typename?: 'BloodPressure';
            diastolic: number;
            systolic: number;
            rating: string;
            fhirRating: FhirRating;
          }
        | { __typename?: 'BodyHeight' }
        | { __typename?: 'BodyTemperature' }
        | {
            __typename?: 'BodyWeight';
            weightUnit: BodyWeightUnitInput;
            weight: number;
          }
        | {
            __typename?: 'Cholesterol';
            unit: CholesterolUnit;
            total: number;
            hdl: number;
          }
        | {
            __typename?: 'FastingBloodGlucose';
            bloodGlucose: number;
            rating: string;
            fhirRating: FhirRating;
          }
        | { __typename?: 'HeartRhythm' }
        | {
            __typename?: 'Pulse';
            bpm: number;
            rating: string;
            fhirRating: FhirRating;
          }
        | {
            __typename?: 'Spo2';
            oxygenSaturation: number;
            rating: string;
            fhirRating: FhirRating;
          };
    }>;
  };
};

export type GetUserPatientProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserPatientProfileQuery = {
  __typename?: 'Query';
  userPatientProfileResults:
    | {
        __typename?: 'ProfileNonCompliant';
        userId: string;
        user: {
          __typename?: 'User';
          id: string;
          type: string;
          attributes: {
            __typename?: 'UserAttributes';
            isStandalonePatient: boolean;
            registrationStatus: RegistrationStatus | null;
            deletionRequested: string | null;
            defaultCareSettingId: string;
            termsAndConditions: {
              __typename?: 'TermsAndConditions';
              dateTime: string;
              decision: TermsAndConditionsStatus;
              version: string;
            } | null;
          };
        };
      }
    | {
        __typename?: 'UserPatientProfile';
        userId: string;
        careSettingPatientId: string;
        wardPatientId: string;
        user: {
          __typename?: 'User';
          id: string;
          type: string;
          attributes: {
            __typename?: 'UserAttributes';
            isStandalonePatient: boolean;
            registrationStatus: RegistrationStatus | null;
            deletionRequested: string | null;
            defaultCareSettingId: string;
            termsAndConditions: {
              __typename?: 'TermsAndConditions';
              dateTime: string;
              decision: TermsAndConditionsStatus;
              version: string;
            } | null;
          };
        };
        careSetting: {
          __typename?: 'CareSetting';
          id: string;
          attributes: {
            __typename?: 'CareSettingAttributes';
            name: string;
            shortName: string;
            email: string | null;
            res_phone: string | null;
          };
        };
        careSettingPatient: {
          __typename?: 'CareSettingPatient';
          attributes: {
            __typename?: 'CareSettingPatientAttributes';
            admissionStatus: AdmissionStatus | null;
          };
        };
        wardPatient: {
          __typename?: 'WardPatient';
          id: string;
          attributes: {
            __typename?: 'WardPatientAttributes';
            submittedObservationTypes: Array<ObservationType> | null;
            observationTypes: Array<ObservationType> | null;
            res_activeMonitoredConditions: Array<{
              __typename?: 'MonitoredCondition';
              condition: PatientDiagnosis;
              monitoringStatus: {
                __typename?: 'MonitoringStatus';
                status: MonitoringStatusType | null;
              } | null;
              consultationStatus: {
                __typename?: 'MonitoredConditionConsultationStatus';
                status: MonitoredConditionConsultationStatusType | null;
              } | null;
              testStatus: {
                __typename?: 'MonitoredConditionTestStatus';
                status: MonitoredConditionTestStatusType | null;
              } | null;
            }>;
          };
        };
        person: {
          __typename?: 'Person';
          id: string;
          attributes: {
            __typename?: 'PersonAttributes';
            contactExclusionPreferences: Array<ContactExclusionPreferences | null> | null;
            generalPracticeRegistration: {
              __typename?: 'PersonGeneralPracticeRegistration';
              accountId: string;
              odsCode: string;
              linkageKey: string;
              patientIdentifier: string;
            } | null;
            contactInformation: {
              __typename?: 'PersonContactInformation';
              primaryContactNumber: string | null;
              primaryEmailAddress: string | null;
            } | null;
            personalInformation: {
              __typename?: 'PersonPersonalInformation';
              firstName: string;
              surname: string;
              ethnicity: Ethnicity | null;
              dateOfBirth: string | null;
              genderAssignedAtBirth: GenderAssignedAtBirth | null;
              genderIdentifiedAs: GenderIdentifiedAs;
            };
            latestHealthInformation: {
              __typename?: 'HealthInformation';
              heightInformation: {
                __typename?: 'Height';
                created: string;
                unit: HeightUnit;
                value: number;
              } | null;
              bloodPressureMedicationInformation: {
                __typename?: 'BloodPressureMedication';
                created: string;
                hasBPMedication: boolean;
                hadBPMedication: boolean;
              } | null;
              smokingInformation: {
                __typename?: 'Smoking';
                created: string;
                status: SmokingStatus;
              } | null;
              cvdInformation: {
                __typename?: 'Cvd';
                created: string;
                hasCvd: boolean;
                familyHadCvd: CvdFamilyInformation;
              } | null;
              ckdInformation: {
                __typename?: 'Ckd';
                created: string;
                ckdStatus: boolean;
              } | null;
              diabetesInformation: {
                __typename?: 'Diabetes';
                created: string;
                diabetesStatus: DiabetesStatus;
              } | null;
            } | null;
          };
        };
      };
};

export type GetUserRegistrationRecordQueryVariables = Exact<{
  encryptedId: Scalars['String'];
}>;

export type GetUserRegistrationRecordQuery = {
  __typename?: 'Query';
  userRegistrationRecordAnon:
    | {
        __typename?: 'RegistrationLinkExpiredValidationError';
        code: string | null;
        message: string;
      }
    | {
        __typename?: 'UserRegistrationRecord';
        attributes: {
          __typename?: 'UserRegistrationRecordMutationResponse';
          primaryEmailAddress: string;
          effectiveTo: string;
        };
      };
};

export type GetWardPatientObservationsQueryVariables = Exact<{
  exclusiveStartKey: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  recordedDateTimeEnd: InputMaybe<Scalars['DateTime']>;
  recordedDateTimeStart: InputMaybe<Scalars['DateTime']>;
}>;

export type GetWardPatientObservationsQuery = {
  __typename?: 'Query';
  wardPatientObservationsPaged: {
    __typename?: 'WardPatientObservationPaginatedResults';
    attributes: {
      __typename?: 'WardPatientObservationPaginatedResultsAttributes';
      lastEvaluatedKey: string | null;
      records: Array<{
        __typename?: 'WardPatientObservation';
        id: string;
        type: string;
        attributes: {
          __typename?: 'WardPatientObservationAttributes';
          observationType: ObservationType;
          recordedDateTime: string;
          wardPatientId: string;
          res_observationValue:
            | {
                __typename?: 'BloodPressure';
                systolic: number;
                diastolic: number;
                rating: string;
              }
            | { __typename?: 'BodyHeight' }
            | { __typename?: 'BodyTemperature' }
            | {
                __typename?: 'BodyWeight';
                weightUnit: BodyWeightUnitInput;
                weight: number;
              }
            | {
                __typename?: 'Cholesterol';
                total: number;
                hdl: number;
                unit: CholesterolUnit;
              }
            | {
                __typename?: 'FastingBloodGlucose';
                bloodGlucose: number;
                rating: string;
              }
            | { __typename?: 'HeartRhythm' }
            | { __typename?: 'Pulse'; bpm: number }
            | { __typename?: 'Spo2'; oxygenSaturation: number }
            | null;
        };
      }>;
    };
  };
};

export type GetWardPatientObservationsForProgrammeQueryVariables = Exact<{
  offset: InputMaybe<Scalars['Int']>;
  programmeId: Scalars['ID'];
  size: InputMaybe<Scalars['Int']>;
}>;

export type GetWardPatientObservationsForProgrammeQuery = {
  __typename?: 'Query';
  wardPatientObservationsForProgramme: {
    __typename?: 'WardPatientObservationViewSearchResults';
    timedOut: boolean;
    searchRequestId: string;
    hits: number;
    searchResults: Array<{
      __typename?: 'WardPatientObservationView';
      id: string;
      observationType: ObservationType;
      recordedDateTime: string;
      wardPatientId: string;
      res_observationValue:
        | {
            __typename?: 'BloodPressure';
            diastolic: number;
            diastolicRating: string | null;
            fhirRating: FhirRating;
            systolic: number;
            systolicRating: string | null;
            rating: string;
          }
        | { __typename?: 'BodyHeight' }
        | { __typename?: 'BodyTemperature' }
        | { __typename?: 'BodyWeight' }
        | { __typename?: 'Cholesterol' }
        | { __typename?: 'FastingBloodGlucose' }
        | { __typename?: 'HeartRhythm' }
        | { __typename?: 'Pulse' }
        | { __typename?: 'Spo2' };
    }>;
  } | null;
};

export type GetWardPatientProgrammeByAverageObservationIdQueryVariables =
  Exact<{
    averageObservationId: Scalars['ID'];
  }>;

export type GetWardPatientProgrammeByAverageObservationIdQuery = {
  __typename?: 'Query';
  wardPatientProgrammeForAverageObservationId: {
    __typename?: 'WardPatientProgramme';
    id: string;
    attributes: {
      __typename?: 'WardPatientProgrammeAttributes';
      status: WardPatientProgrammeStatus;
      type: WardPatientProgrammeType;
      endDateTime: string | null;
      durationInDays: number | null;
      observationsCount: number;
      averageObservation: {
        __typename?: 'WardPatientObservationView';
        id: string;
        observationType: ObservationType;
        recordedDateTime: string;
        res_observationValue:
          | {
              __typename?: 'BloodPressure';
              rating: string;
              fhirRating: FhirRating;
              systolic: number;
              diastolic: number;
            }
          | { __typename?: 'BodyHeight' }
          | { __typename?: 'BodyTemperature' }
          | { __typename?: 'BodyWeight' }
          | { __typename?: 'Cholesterol' }
          | { __typename?: 'FastingBloodGlucose' }
          | { __typename?: 'HeartRhythm' }
          | { __typename?: 'Pulse' }
          | { __typename?: 'Spo2' };
      } | null;
    };
  } | null;
};

export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    attributes {
      isStandalonePatient
      registrationStatus
      termsAndConditions {
        dateTime
        decision
        version
      }
      deletionRequested
      defaultCareSettingId
    }
    type
  }
`;
export const WardPatientObservationViewFieldsFragmentDoc = gql`
  fragment WardPatientObservationViewFields on WardPatientObservationView {
    id
    observationType
    recordedDateTime
    wardPatientId
    res_observationValue {
      ... on Pulse {
        bpm
        rating
        fhirRating
      }
      ... on BloodPressure {
        diastolic
        systolic
        rating
        fhirRating
      }
      ... on Spo2 {
        oxygenSaturation
        rating
        fhirRating
      }
      ... on BodyWeight {
        weightUnit
        weight
      }
      ... on Cholesterol {
        unit
        total
        hdl
      }
      ... on FastingBloodGlucose {
        bloodGlucose
        rating
        fhirRating
      }
    }
  }
`;
export const PatientProgrammeFragmentDoc = gql`
  fragment PatientProgramme on WardPatientProgramme {
    id
    attributes {
      status
      type
      endDateTime
      durationInDays
      observationsCount
      averageObservation {
        id
        observationType
        recordedDateTime
        res_observationValue {
          ... on BloodPressure {
            rating
            fhirRating
            systolic
            diastolic
          }
        }
      }
    }
  }
`;
export const ActivateUserDocument = gql`
  mutation activateUser($input: ActivateUserInput!) {
    activateUser(input: $input) {
      id
      attributes {
        audit {
          created
          createdBy
          lastUpdated
          updatedBy
          version
        }
        defaultCareSettingId
        defaultWardId
        safId
        registrationStatus
      }
    }
  }
`;
export type ActivateUserMutationFn = Apollo.MutationFunction<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateUserMutation,
    ActivateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateUserMutation,
    ActivateUserMutationVariables
  >(ActivateUserDocument, options);
}
export type ActivateUserMutationHookResult = ReturnType<
  typeof useActivateUserMutation
>;
export type ActivateUserMutationResult =
  Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<
  ActivateUserMutation,
  ActivateUserMutationVariables
>;
export const AdmitSelfRegisteredPatientDocument = gql`
  mutation AdmitSelfRegisteredPatient(
    $input: SelfRegistrationPersonalInformationInput!
  ) {
    selfAdmittedPatientAnon(input: $input) {
      ... on Auth0CommonPasswordValidationError {
        code
        message
      }
      ... on Auth0ConflictValidationError {
        code
        message
      }
      ... on Auth0UserInfoValidationError {
        code
        message
      }
      ... on SelfAdmittedPatientResult {
        result
      }
    }
  }
`;
export type AdmitSelfRegisteredPatientMutationFn = Apollo.MutationFunction<
  AdmitSelfRegisteredPatientMutation,
  AdmitSelfRegisteredPatientMutationVariables
>;

/**
 * __useAdmitSelfRegisteredPatientMutation__
 *
 * To run a mutation, you first call `useAdmitSelfRegisteredPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmitSelfRegisteredPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [admitSelfRegisteredPatientMutation, { data, loading, error }] = useAdmitSelfRegisteredPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmitSelfRegisteredPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdmitSelfRegisteredPatientMutation,
    AdmitSelfRegisteredPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdmitSelfRegisteredPatientMutation,
    AdmitSelfRegisteredPatientMutationVariables
  >(AdmitSelfRegisteredPatientDocument, options);
}
export type AdmitSelfRegisteredPatientMutationHookResult = ReturnType<
  typeof useAdmitSelfRegisteredPatientMutation
>;
export type AdmitSelfRegisteredPatientMutationResult =
  Apollo.MutationResult<AdmitSelfRegisteredPatientMutation>;
export type AdmitSelfRegisteredPatientMutationOptions =
  Apollo.BaseMutationOptions<
    AdmitSelfRegisteredPatientMutation,
    AdmitSelfRegisteredPatientMutationVariables
  >;
export const BookGeneralPracticeAppointmentDocument = gql`
  mutation BookGeneralPracticeAppointment(
    $input: GeneralPracticeAppointmentBookingInput!
    $patientIdentifier: ID!
  ) {
    bookGeneralPracticeAppointment(
      input: $input
      patientIdentifier: $patientIdentifier
    ) {
      type
      id
      attributes {
        appointmentSlotId
        bookingReason
        startTime
        endTime
        telephoneContactType
        telephoneNumber
        emailAddress
      }
    }
  }
`;
export type BookGeneralPracticeAppointmentMutationFn = Apollo.MutationFunction<
  BookGeneralPracticeAppointmentMutation,
  BookGeneralPracticeAppointmentMutationVariables
>;

/**
 * __useBookGeneralPracticeAppointmentMutation__
 *
 * To run a mutation, you first call `useBookGeneralPracticeAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookGeneralPracticeAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookGeneralPracticeAppointmentMutation, { data, loading, error }] = useBookGeneralPracticeAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      patientIdentifier: // value for 'patientIdentifier'
 *   },
 * });
 */
export function useBookGeneralPracticeAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookGeneralPracticeAppointmentMutation,
    BookGeneralPracticeAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookGeneralPracticeAppointmentMutation,
    BookGeneralPracticeAppointmentMutationVariables
  >(BookGeneralPracticeAppointmentDocument, options);
}
export type BookGeneralPracticeAppointmentMutationHookResult = ReturnType<
  typeof useBookGeneralPracticeAppointmentMutation
>;
export type BookGeneralPracticeAppointmentMutationResult =
  Apollo.MutationResult<BookGeneralPracticeAppointmentMutation>;
export type BookGeneralPracticeAppointmentMutationOptions =
  Apollo.BaseMutationOptions<
    BookGeneralPracticeAppointmentMutation,
    BookGeneralPracticeAppointmentMutationVariables
  >;
export const CancelGeneralPracticeAppointmentForPatientDocument = gql`
  mutation CancelGeneralPracticeAppointmentForPatient(
    $patientIdentifier: ID!
    $appointmentId: String!
  ) {
    cancelGeneralPracticeAppointmentForPatient(
      patientIdentifier: $patientIdentifier
      appointmentId: $appointmentId
    )
  }
`;
export type CancelGeneralPracticeAppointmentForPatientMutationFn =
  Apollo.MutationFunction<
    CancelGeneralPracticeAppointmentForPatientMutation,
    CancelGeneralPracticeAppointmentForPatientMutationVariables
  >;

/**
 * __useCancelGeneralPracticeAppointmentForPatientMutation__
 *
 * To run a mutation, you first call `useCancelGeneralPracticeAppointmentForPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGeneralPracticeAppointmentForPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGeneralPracticeAppointmentForPatientMutation, { data, loading, error }] = useCancelGeneralPracticeAppointmentForPatientMutation({
 *   variables: {
 *      patientIdentifier: // value for 'patientIdentifier'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCancelGeneralPracticeAppointmentForPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelGeneralPracticeAppointmentForPatientMutation,
    CancelGeneralPracticeAppointmentForPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelGeneralPracticeAppointmentForPatientMutation,
    CancelGeneralPracticeAppointmentForPatientMutationVariables
  >(CancelGeneralPracticeAppointmentForPatientDocument, options);
}
export type CancelGeneralPracticeAppointmentForPatientMutationHookResult =
  ReturnType<typeof useCancelGeneralPracticeAppointmentForPatientMutation>;
export type CancelGeneralPracticeAppointmentForPatientMutationResult =
  Apollo.MutationResult<CancelGeneralPracticeAppointmentForPatientMutation>;
export type CancelGeneralPracticeAppointmentForPatientMutationOptions =
  Apollo.BaseMutationOptions<
    CancelGeneralPracticeAppointmentForPatientMutation,
    CancelGeneralPracticeAppointmentForPatientMutationVariables
  >;
export const CareSettingStepUpAcceptanceDocument = gql`
  mutation CareSettingStepUpAcceptance(
    $input: CareSettingStepUpAcceptanceInput!
  ) {
    careSettingStepUpAcceptance(input: $input) {
      personId
      careSettingId
      observationTypes
      monitoredConditions {
        condition
        dischargeStatus
        reasonForDischarge
        comments
        monitoringStatus {
          status
          statusUpdatedBy
          statusUpdateDateTime
          res_statusUpdatedBy
        }
        consultationStatus {
          status
          statusUpdatedBy
          statusUpdateDateTime
          res_statusUpdatedBy
        }
        testStatus {
          status
          statusUpdatedBy
          statusUpdateDateTime
          res_statusUpdatedBy
        }
      }
    }
  }
`;
export type CareSettingStepUpAcceptanceMutationFn = Apollo.MutationFunction<
  CareSettingStepUpAcceptanceMutation,
  CareSettingStepUpAcceptanceMutationVariables
>;

/**
 * __useCareSettingStepUpAcceptanceMutation__
 *
 * To run a mutation, you first call `useCareSettingStepUpAcceptanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareSettingStepUpAcceptanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careSettingStepUpAcceptanceMutation, { data, loading, error }] = useCareSettingStepUpAcceptanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCareSettingStepUpAcceptanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CareSettingStepUpAcceptanceMutation,
    CareSettingStepUpAcceptanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CareSettingStepUpAcceptanceMutation,
    CareSettingStepUpAcceptanceMutationVariables
  >(CareSettingStepUpAcceptanceDocument, options);
}
export type CareSettingStepUpAcceptanceMutationHookResult = ReturnType<
  typeof useCareSettingStepUpAcceptanceMutation
>;
export type CareSettingStepUpAcceptanceMutationResult =
  Apollo.MutationResult<CareSettingStepUpAcceptanceMutation>;
export type CareSettingStepUpAcceptanceMutationOptions =
  Apollo.BaseMutationOptions<
    CareSettingStepUpAcceptanceMutation,
    CareSettingStepUpAcceptanceMutationVariables
  >;
export const RegisterGeneralPracticePatientLinkageDocument = gql`
  mutation registerGeneralPracticePatientLinkage(
    $input: GeneralPracticePatientLinkageRegistrationInput!
  ) {
    registerGeneralPracticePatientLinkage(input: $input) {
      attributes {
        accountId
        dateOfBirth
        gpOdsCode
        linkageKey
        patientId
        personId
        surname
      }
      id
      type
    }
  }
`;
export type RegisterGeneralPracticePatientLinkageMutationFn =
  Apollo.MutationFunction<
    RegisterGeneralPracticePatientLinkageMutation,
    RegisterGeneralPracticePatientLinkageMutationVariables
  >;

/**
 * __useRegisterGeneralPracticePatientLinkageMutation__
 *
 * To run a mutation, you first call `useRegisterGeneralPracticePatientLinkageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterGeneralPracticePatientLinkageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerGeneralPracticePatientLinkageMutation, { data, loading, error }] = useRegisterGeneralPracticePatientLinkageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterGeneralPracticePatientLinkageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterGeneralPracticePatientLinkageMutation,
    RegisterGeneralPracticePatientLinkageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterGeneralPracticePatientLinkageMutation,
    RegisterGeneralPracticePatientLinkageMutationVariables
  >(RegisterGeneralPracticePatientLinkageDocument, options);
}
export type RegisterGeneralPracticePatientLinkageMutationHookResult =
  ReturnType<typeof useRegisterGeneralPracticePatientLinkageMutation>;
export type RegisterGeneralPracticePatientLinkageMutationResult =
  Apollo.MutationResult<RegisterGeneralPracticePatientLinkageMutation>;
export type RegisterGeneralPracticePatientLinkageMutationOptions =
  Apollo.BaseMutationOptions<
    RegisterGeneralPracticePatientLinkageMutation,
    RegisterGeneralPracticePatientLinkageMutationVariables
  >;
export const CreateProgrammeDocument = gql`
  mutation createProgramme($input: WardPatientProgrammeInput!) {
    createProgramme(input: $input) {
      id
      type
    }
  }
`;
export type CreateProgrammeMutationFn = Apollo.MutationFunction<
  CreateProgrammeMutation,
  CreateProgrammeMutationVariables
>;

/**
 * __useCreateProgrammeMutation__
 *
 * To run a mutation, you first call `useCreateProgrammeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgrammeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgrammeMutation, { data, loading, error }] = useCreateProgrammeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgrammeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProgrammeMutation,
    CreateProgrammeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProgrammeMutation,
    CreateProgrammeMutationVariables
  >(CreateProgrammeDocument, options);
}
export type CreateProgrammeMutationHookResult = ReturnType<
  typeof useCreateProgrammeMutation
>;
export type CreateProgrammeMutationResult =
  Apollo.MutationResult<CreateProgrammeMutation>;
export type CreateProgrammeMutationOptions = Apollo.BaseMutationOptions<
  CreateProgrammeMutation,
  CreateProgrammeMutationVariables
>;
export const CreateUserRegistrationRecordDocument = gql`
  mutation CreateUserRegistrationRecord($input: UserRegistrationRecordInput!) {
    createUserRegistrationRecordMutationAnon(input: $input) {
      ... on UserAlreadyExistsValidationError {
        code
        message
      }
      ... on UserRegistrationRecord {
        attributes {
          primaryEmailAddress
          effectiveTo
        }
      }
    }
  }
`;
export type CreateUserRegistrationRecordMutationFn = Apollo.MutationFunction<
  CreateUserRegistrationRecordMutation,
  CreateUserRegistrationRecordMutationVariables
>;

/**
 * __useCreateUserRegistrationRecordMutation__
 *
 * To run a mutation, you first call `useCreateUserRegistrationRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRegistrationRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRegistrationRecordMutation, { data, loading, error }] = useCreateUserRegistrationRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserRegistrationRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserRegistrationRecordMutation,
    CreateUserRegistrationRecordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserRegistrationRecordMutation,
    CreateUserRegistrationRecordMutationVariables
  >(CreateUserRegistrationRecordDocument, options);
}
export type CreateUserRegistrationRecordMutationHookResult = ReturnType<
  typeof useCreateUserRegistrationRecordMutation
>;
export type CreateUserRegistrationRecordMutationResult =
  Apollo.MutationResult<CreateUserRegistrationRecordMutation>;
export type CreateUserRegistrationRecordMutationOptions =
  Apollo.BaseMutationOptions<
    CreateUserRegistrationRecordMutation,
    CreateUserRegistrationRecordMutationVariables
  >;
export const CreateWardPatientObservationDocument = gql`
  mutation createWardPatientObservation(
    $input: CreateWardPatientObservationInput!
  ) {
    createWardPatientObservation(input: $input) {
      id
    }
  }
`;
export type CreateWardPatientObservationMutationFn = Apollo.MutationFunction<
  CreateWardPatientObservationMutation,
  CreateWardPatientObservationMutationVariables
>;

/**
 * __useCreateWardPatientObservationMutation__
 *
 * To run a mutation, you first call `useCreateWardPatientObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWardPatientObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWardPatientObservationMutation, { data, loading, error }] = useCreateWardPatientObservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWardPatientObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWardPatientObservationMutation,
    CreateWardPatientObservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWardPatientObservationMutation,
    CreateWardPatientObservationMutationVariables
  >(CreateWardPatientObservationDocument, options);
}
export type CreateWardPatientObservationMutationHookResult = ReturnType<
  typeof useCreateWardPatientObservationMutation
>;
export type CreateWardPatientObservationMutationResult =
  Apollo.MutationResult<CreateWardPatientObservationMutation>;
export type CreateWardPatientObservationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateWardPatientObservationMutation,
    CreateWardPatientObservationMutationVariables
  >;
export const DeletePatientDocument = gql`
  mutation DeletePatient {
    deleteUser {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type DeletePatientMutationFn = Apollo.MutationFunction<
  DeletePatientMutation,
  DeletePatientMutationVariables
>;

/**
 * __useDeletePatientMutation__
 *
 * To run a mutation, you first call `useDeletePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientMutation, { data, loading, error }] = useDeletePatientMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeletePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePatientMutation,
    DeletePatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePatientMutation,
    DeletePatientMutationVariables
  >(DeletePatientDocument, options);
}
export type DeletePatientMutationHookResult = ReturnType<
  typeof useDeletePatientMutation
>;
export type DeletePatientMutationResult =
  Apollo.MutationResult<DeletePatientMutation>;
export type DeletePatientMutationOptions = Apollo.BaseMutationOptions<
  DeletePatientMutation,
  DeletePatientMutationVariables
>;
export const UnlinkGeneralPracticePatientDocument = gql`
  mutation unlinkGeneralPracticePatient($userId: ID!) {
    unlinkGeneralPracticePatientMutation(userId: $userId) {
      id
      type
      attributes {
        generalPracticeRegistration {
          accountId
          linkageKey
          odsCode
          patientIdentifier
        }
        registeredGPSurgery
        userId
      }
    }
  }
`;
export type UnlinkGeneralPracticePatientMutationFn = Apollo.MutationFunction<
  UnlinkGeneralPracticePatientMutation,
  UnlinkGeneralPracticePatientMutationVariables
>;

/**
 * __useUnlinkGeneralPracticePatientMutation__
 *
 * To run a mutation, you first call `useUnlinkGeneralPracticePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkGeneralPracticePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkGeneralPracticePatientMutation, { data, loading, error }] = useUnlinkGeneralPracticePatientMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnlinkGeneralPracticePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkGeneralPracticePatientMutation,
    UnlinkGeneralPracticePatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkGeneralPracticePatientMutation,
    UnlinkGeneralPracticePatientMutationVariables
  >(UnlinkGeneralPracticePatientDocument, options);
}
export type UnlinkGeneralPracticePatientMutationHookResult = ReturnType<
  typeof useUnlinkGeneralPracticePatientMutation
>;
export type UnlinkGeneralPracticePatientMutationResult =
  Apollo.MutationResult<UnlinkGeneralPracticePatientMutation>;
export type UnlinkGeneralPracticePatientMutationOptions =
  Apollo.BaseMutationOptions<
    UnlinkGeneralPracticePatientMutation,
    UnlinkGeneralPracticePatientMutationVariables
  >;
export const UpdateContactExclusionPreferencesDocument = gql`
  mutation updateContactExclusionPreferences(
    $input: ContactExclusionPreferencesInput!
  ) {
    updateContactExclusionPreferences(input: $input) {
      contactExclusionPreferences
    }
  }
`;
export type UpdateContactExclusionPreferencesMutationFn =
  Apollo.MutationFunction<
    UpdateContactExclusionPreferencesMutation,
    UpdateContactExclusionPreferencesMutationVariables
  >;

/**
 * __useUpdateContactExclusionPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateContactExclusionPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactExclusionPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactExclusionPreferencesMutation, { data, loading, error }] = useUpdateContactExclusionPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactExclusionPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactExclusionPreferencesMutation,
    UpdateContactExclusionPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactExclusionPreferencesMutation,
    UpdateContactExclusionPreferencesMutationVariables
  >(UpdateContactExclusionPreferencesDocument, options);
}
export type UpdateContactExclusionPreferencesMutationHookResult = ReturnType<
  typeof useUpdateContactExclusionPreferencesMutation
>;
export type UpdateContactExclusionPreferencesMutationResult =
  Apollo.MutationResult<UpdateContactExclusionPreferencesMutation>;
export type UpdateContactExclusionPreferencesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateContactExclusionPreferencesMutation,
    UpdateContactExclusionPreferencesMutationVariables
  >;
export const UpdatePersonDocument = gql`
  mutation updatePerson($input: PersonInput!) {
    updatePerson(input: $input) {
      id
      attributes {
        generalPracticeRegistration {
          accountId
          odsCode
          linkageKey
          patientIdentifier
        }
        personalInformation {
          firstName
          surname
          dateOfBirth
          ethnicity
        }
        contactInformation {
          primaryContactNumber
          primaryEmailAddress
        }
        latestHealthInformation {
          bloodPressureMedicationInformation {
            hadBPMedication
            hasBPMedication
            created
          }
          heightInformation {
            created
            unit
            value
          }
          smokingInformation {
            created
            status
          }
          ckdInformation {
            ckdStatus
            created
          }
          cvdInformation {
            created
            familyHadCvd
            hasCvd
          }
          diabetesInformation {
            created
            diabetesStatus
          }
        }
      }
    }
  }
`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonMutation,
    UpdatePersonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonMutation,
    UpdatePersonMutationVariables
  >(UpdatePersonDocument, options);
}
export type UpdatePersonMutationHookResult = ReturnType<
  typeof useUpdatePersonMutation
>;
export type UpdatePersonMutationResult =
  Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;
export const UpdateUserTermsAndConditionsDocument = gql`
  mutation UpdateUserTermsAndConditions($input: TermsAndConditionsInput!) {
    updateUserTermsAndConditions(input: $input) {
      attributes {
        termsAndConditions {
          dateTime
          decision
          version
        }
      }
    }
  }
`;
export type UpdateUserTermsAndConditionsMutationFn = Apollo.MutationFunction<
  UpdateUserTermsAndConditionsMutation,
  UpdateUserTermsAndConditionsMutationVariables
>;

/**
 * __useUpdateUserTermsAndConditionsMutation__
 *
 * To run a mutation, you first call `useUpdateUserTermsAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTermsAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTermsAndConditionsMutation, { data, loading, error }] = useUpdateUserTermsAndConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserTermsAndConditionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserTermsAndConditionsMutation,
    UpdateUserTermsAndConditionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserTermsAndConditionsMutation,
    UpdateUserTermsAndConditionsMutationVariables
  >(UpdateUserTermsAndConditionsDocument, options);
}
export type UpdateUserTermsAndConditionsMutationHookResult = ReturnType<
  typeof useUpdateUserTermsAndConditionsMutation
>;
export type UpdateUserTermsAndConditionsMutationResult =
  Apollo.MutationResult<UpdateUserTermsAndConditionsMutation>;
export type UpdateUserTermsAndConditionsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateUserTermsAndConditionsMutation,
    UpdateUserTermsAndConditionsMutationVariables
  >;
export const GetGeneralPracticeAppointmentSlotDocument = gql`
  query getGeneralPracticeAppointmentSlot($patientIdentifier: ID!, $days: Int) {
    appointments(patientIdentifier: $patientIdentifier, days: $days) {
      type
      appointmentSlots {
        attributes {
          appointmentSlotId
          clinicianDisplayName
          endTime
          locationAddress
          locationName
          locationPostcode
          sessionType
          slotTypeName
          slotTypeStatus
          startTime
        }
      }
    }
  }
`;

/**
 * __useGetGeneralPracticeAppointmentSlotQuery__
 *
 * To run a query within a React component, call `useGetGeneralPracticeAppointmentSlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPracticeAppointmentSlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPracticeAppointmentSlotQuery({
 *   variables: {
 *      patientIdentifier: // value for 'patientIdentifier'
 *      days: // value for 'days'
 *   },
 * });
 */
export function useGetGeneralPracticeAppointmentSlotQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGeneralPracticeAppointmentSlotQuery,
    GetGeneralPracticeAppointmentSlotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGeneralPracticeAppointmentSlotQuery,
    GetGeneralPracticeAppointmentSlotQueryVariables
  >(GetGeneralPracticeAppointmentSlotDocument, options);
}
export function useGetGeneralPracticeAppointmentSlotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralPracticeAppointmentSlotQuery,
    GetGeneralPracticeAppointmentSlotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGeneralPracticeAppointmentSlotQuery,
    GetGeneralPracticeAppointmentSlotQueryVariables
  >(GetGeneralPracticeAppointmentSlotDocument, options);
}
export type GetGeneralPracticeAppointmentSlotQueryHookResult = ReturnType<
  typeof useGetGeneralPracticeAppointmentSlotQuery
>;
export type GetGeneralPracticeAppointmentSlotLazyQueryHookResult = ReturnType<
  typeof useGetGeneralPracticeAppointmentSlotLazyQuery
>;
export type GetGeneralPracticeAppointmentSlotQueryResult = Apollo.QueryResult<
  GetGeneralPracticeAppointmentSlotQuery,
  GetGeneralPracticeAppointmentSlotQueryVariables
>;
export const GetAssessObservationDocument = gql`
  query getAssessObservation($input: CreateWardPatientObservationInput!) {
    assessObservation(input: $input) {
      rating {
        fhirRating
        lightColourTheme {
          backgroundColour
          foregroundColour
        }
      }
      type
      warning {
        level
      }
    }
  }
`;

/**
 * __useGetAssessObservationQuery__
 *
 * To run a query within a React component, call `useGetAssessObservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessObservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessObservationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssessObservationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssessObservationQuery,
    GetAssessObservationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssessObservationQuery,
    GetAssessObservationQueryVariables
  >(GetAssessObservationDocument, options);
}
export function useGetAssessObservationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessObservationQuery,
    GetAssessObservationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssessObservationQuery,
    GetAssessObservationQueryVariables
  >(GetAssessObservationDocument, options);
}
export type GetAssessObservationQueryHookResult = ReturnType<
  typeof useGetAssessObservationQuery
>;
export type GetAssessObservationLazyQueryHookResult = ReturnType<
  typeof useGetAssessObservationLazyQuery
>;
export type GetAssessObservationQueryResult = Apollo.QueryResult<
  GetAssessObservationQuery,
  GetAssessObservationQueryVariables
>;
export const GetContactExclusionPreferencesDocument = gql`
  query getContactExclusionPreferences($personId: ID!) {
    contactExclusionPreferences(personId: $personId)
  }
`;

/**
 * __useGetContactExclusionPreferencesQuery__
 *
 * To run a query within a React component, call `useGetContactExclusionPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactExclusionPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactExclusionPreferencesQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetContactExclusionPreferencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactExclusionPreferencesQuery,
    GetContactExclusionPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContactExclusionPreferencesQuery,
    GetContactExclusionPreferencesQueryVariables
  >(GetContactExclusionPreferencesDocument, options);
}
export function useGetContactExclusionPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactExclusionPreferencesQuery,
    GetContactExclusionPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactExclusionPreferencesQuery,
    GetContactExclusionPreferencesQueryVariables
  >(GetContactExclusionPreferencesDocument, options);
}
export type GetContactExclusionPreferencesQueryHookResult = ReturnType<
  typeof useGetContactExclusionPreferencesQuery
>;
export type GetContactExclusionPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetContactExclusionPreferencesLazyQuery
>;
export type GetContactExclusionPreferencesQueryResult = Apollo.QueryResult<
  GetContactExclusionPreferencesQuery,
  GetContactExclusionPreferencesQueryVariables
>;
export const GetGeneralPracticeBookedAppointmentsByUserDocument = gql`
  query getGeneralPracticeBookedAppointmentsByUser($patientIdentifier: ID!) {
    bookedAppointments(patientIdentifier: $patientIdentifier) {
      id
      type
      attributes {
        appointmentId
        isCancellable
        staffName
        start
        end
        created
        appointmentType
        status
        sessionType
        address {
          buildingName
          line1
          line2
          line3
          line4
          line5
          postalCode
        }
      }
    }
  }
`;

/**
 * __useGetGeneralPracticeBookedAppointmentsByUserQuery__
 *
 * To run a query within a React component, call `useGetGeneralPracticeBookedAppointmentsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPracticeBookedAppointmentsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPracticeBookedAppointmentsByUserQuery({
 *   variables: {
 *      patientIdentifier: // value for 'patientIdentifier'
 *   },
 * });
 */
export function useGetGeneralPracticeBookedAppointmentsByUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGeneralPracticeBookedAppointmentsByUserQuery,
    GetGeneralPracticeBookedAppointmentsByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGeneralPracticeBookedAppointmentsByUserQuery,
    GetGeneralPracticeBookedAppointmentsByUserQueryVariables
  >(GetGeneralPracticeBookedAppointmentsByUserDocument, options);
}
export function useGetGeneralPracticeBookedAppointmentsByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralPracticeBookedAppointmentsByUserQuery,
    GetGeneralPracticeBookedAppointmentsByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGeneralPracticeBookedAppointmentsByUserQuery,
    GetGeneralPracticeBookedAppointmentsByUserQueryVariables
  >(GetGeneralPracticeBookedAppointmentsByUserDocument, options);
}
export type GetGeneralPracticeBookedAppointmentsByUserQueryHookResult =
  ReturnType<typeof useGetGeneralPracticeBookedAppointmentsByUserQuery>;
export type GetGeneralPracticeBookedAppointmentsByUserLazyQueryHookResult =
  ReturnType<typeof useGetGeneralPracticeBookedAppointmentsByUserLazyQuery>;
export type GetGeneralPracticeBookedAppointmentsByUserQueryResult =
  Apollo.QueryResult<
    GetGeneralPracticeBookedAppointmentsByUserQuery,
    GetGeneralPracticeBookedAppointmentsByUserQueryVariables
  >;
export const GetGeneralPracticeSettingsDocument = gql`
  query getGeneralPracticeSettings(
    $gpOdsCode: String!
    $patientIdentifier: ID!
  ) {
    generalPracticeServicePracticeSettings(gpOdsCode: $gpOdsCode) {
      attributes {
        appointmentsSupported
        medicalRecordSupported
        repeatPrescriptionsSupported
        requestId
      }
      id
      type
    }
    generalPracticePatientSettings(patientIdentifier: $patientIdentifier) {
      attributes {
        repeatPrescriptions
        appointments
        medicalRecord
        medicalRecordDetails {
          immunisations
          allergies
          consultations
          medication
          problems
          testResults
        }
      }
      id
      type
    }
  }
`;

/**
 * __useGetGeneralPracticeSettingsQuery__
 *
 * To run a query within a React component, call `useGetGeneralPracticeSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPracticeSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPracticeSettingsQuery({
 *   variables: {
 *      gpOdsCode: // value for 'gpOdsCode'
 *      patientIdentifier: // value for 'patientIdentifier'
 *   },
 * });
 */
export function useGetGeneralPracticeSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGeneralPracticeSettingsQuery,
    GetGeneralPracticeSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGeneralPracticeSettingsQuery,
    GetGeneralPracticeSettingsQueryVariables
  >(GetGeneralPracticeSettingsDocument, options);
}
export function useGetGeneralPracticeSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralPracticeSettingsQuery,
    GetGeneralPracticeSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGeneralPracticeSettingsQuery,
    GetGeneralPracticeSettingsQueryVariables
  >(GetGeneralPracticeSettingsDocument, options);
}
export type GetGeneralPracticeSettingsQueryHookResult = ReturnType<
  typeof useGetGeneralPracticeSettingsQuery
>;
export type GetGeneralPracticeSettingsLazyQueryHookResult = ReturnType<
  typeof useGetGeneralPracticeSettingsLazyQuery
>;
export type GetGeneralPracticeSettingsQueryResult = Apollo.QueryResult<
  GetGeneralPracticeSettingsQuery,
  GetGeneralPracticeSettingsQueryVariables
>;
export const GetLastObservationAndBandsDocument = gql`
  query getLastObservationAndBands($observationType: ObservationType!) {
    observationBands(observationType: $observationType) {
      info {
        ... on BloodPressureInfo {
          lastNHSReviewDate
          nextNHSReviewDate
          lowerBloodPressureLink
          nhsGuidanceLink
        }
      }
      type
      ratings {
        fhirRating
        displayName
        displayOrder
        lightColourTheme {
          foregroundColour
          backgroundColour
        }
        ranges {
          minDecimal
          maxDecimal
          step
          type
        }
      }
    }
    searchWardPatientObservations(
      types: [$observationType]
      offset: 0
      size: 1
    ) {
      searchResults {
        ...WardPatientObservationViewFields
      }
    }
  }
  ${WardPatientObservationViewFieldsFragmentDoc}
`;

/**
 * __useGetLastObservationAndBandsQuery__
 *
 * To run a query within a React component, call `useGetLastObservationAndBandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastObservationAndBandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastObservationAndBandsQuery({
 *   variables: {
 *      observationType: // value for 'observationType'
 *   },
 * });
 */
export function useGetLastObservationAndBandsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastObservationAndBandsQuery,
    GetLastObservationAndBandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLastObservationAndBandsQuery,
    GetLastObservationAndBandsQueryVariables
  >(GetLastObservationAndBandsDocument, options);
}
export function useGetLastObservationAndBandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastObservationAndBandsQuery,
    GetLastObservationAndBandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLastObservationAndBandsQuery,
    GetLastObservationAndBandsQueryVariables
  >(GetLastObservationAndBandsDocument, options);
}
export type GetLastObservationAndBandsQueryHookResult = ReturnType<
  typeof useGetLastObservationAndBandsQuery
>;
export type GetLastObservationAndBandsLazyQueryHookResult = ReturnType<
  typeof useGetLastObservationAndBandsLazyQuery
>;
export type GetLastObservationAndBandsQueryResult = Apollo.QueryResult<
  GetLastObservationAndBandsQuery,
  GetLastObservationAndBandsQueryVariables
>;
export const GetObservationBandsDocument = gql`
  query getObservationBands($observationType: ObservationType!) {
    observationBands(observationType: $observationType) {
      info {
        ... on BloodPressureInfo {
          lastNHSReviewDate
          nextNHSReviewDate
          lowerBloodPressureLink
          nhsGuidanceLink
        }
      }
      type
      ratings {
        fhirRating
        displayName
        displayOrder
        lightColourTheme {
          foregroundColour
          backgroundColour
        }
        ranges {
          minDecimal
          maxDecimal
          step
          type
        }
      }
    }
  }
`;

/**
 * __useGetObservationBandsQuery__
 *
 * To run a query within a React component, call `useGetObservationBandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObservationBandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObservationBandsQuery({
 *   variables: {
 *      observationType: // value for 'observationType'
 *   },
 * });
 */
export function useGetObservationBandsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetObservationBandsQuery,
    GetObservationBandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetObservationBandsQuery,
    GetObservationBandsQueryVariables
  >(GetObservationBandsDocument, options);
}
export function useGetObservationBandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetObservationBandsQuery,
    GetObservationBandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetObservationBandsQuery,
    GetObservationBandsQueryVariables
  >(GetObservationBandsDocument, options);
}
export type GetObservationBandsQueryHookResult = ReturnType<
  typeof useGetObservationBandsQuery
>;
export type GetObservationBandsLazyQueryHookResult = ReturnType<
  typeof useGetObservationBandsLazyQuery
>;
export type GetObservationBandsQueryResult = Apollo.QueryResult<
  GetObservationBandsQuery,
  GetObservationBandsQueryVariables
>;
export const GetObservationDataPointsDocument = gql`
  query getObservationDataPoints($input: ObservationDataPointInput!) {
    observationDataPoints(input: $input) {
      average {
        ... on BloodPressure {
          systolic
          diastolic
          rating
        }
        ... on Pulse {
          bpm
          rating
        }
        ... on Spo2 {
          oxygenSaturation
          rating
        }
      }
      min {
        ... on BloodPressure {
          systolic
          diastolic
        }
        ... on Pulse {
          bpm
        }
        ... on Spo2 {
          oxygenSaturation
        }
      }
      max {
        ... on BloodPressure {
          systolic
          diastolic
        }
        ... on Pulse {
          bpm
        }
        ... on Spo2 {
          oxygenSaturation
        }
      }
      hits
      searchRequestId
      timedOut
      searchResults {
        id
        observationType
        recordedDateTime
        res_componentValues {
          name
          value
        }
      }
      precedingDataPoint {
        id
        observationType
        recordedDateTime
        res_componentValues {
          name
          value
        }
      }
    }
  }
`;

/**
 * __useGetObservationDataPointsQuery__
 *
 * To run a query within a React component, call `useGetObservationDataPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObservationDataPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObservationDataPointsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetObservationDataPointsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetObservationDataPointsQuery,
    GetObservationDataPointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetObservationDataPointsQuery,
    GetObservationDataPointsQueryVariables
  >(GetObservationDataPointsDocument, options);
}
export function useGetObservationDataPointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetObservationDataPointsQuery,
    GetObservationDataPointsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetObservationDataPointsQuery,
    GetObservationDataPointsQueryVariables
  >(GetObservationDataPointsDocument, options);
}
export type GetObservationDataPointsQueryHookResult = ReturnType<
  typeof useGetObservationDataPointsQuery
>;
export type GetObservationDataPointsLazyQueryHookResult = ReturnType<
  typeof useGetObservationDataPointsLazyQuery
>;
export type GetObservationDataPointsQueryResult = Apollo.QueryResult<
  GetObservationDataPointsQuery,
  GetObservationDataPointsQueryVariables
>;
export const GetPatientProgrammeInitiativeDocument = gql`
  query GetPatientProgrammeInitiative {
    userPatientProfileResults {
      ... on UserPatientProfile {
        userId
        programmeInitiative {
          mostRecent {
            ...PatientProgramme
          }
          hypertensionHomeBanner
        }
      }
    }
  }
  ${PatientProgrammeFragmentDoc}
`;

/**
 * __useGetPatientProgrammeInitiativeQuery__
 *
 * To run a query within a React component, call `useGetPatientProgrammeInitiativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientProgrammeInitiativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientProgrammeInitiativeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPatientProgrammeInitiativeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPatientProgrammeInitiativeQuery,
    GetPatientProgrammeInitiativeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientProgrammeInitiativeQuery,
    GetPatientProgrammeInitiativeQueryVariables
  >(GetPatientProgrammeInitiativeDocument, options);
}
export function useGetPatientProgrammeInitiativeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientProgrammeInitiativeQuery,
    GetPatientProgrammeInitiativeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientProgrammeInitiativeQuery,
    GetPatientProgrammeInitiativeQueryVariables
  >(GetPatientProgrammeInitiativeDocument, options);
}
export type GetPatientProgrammeInitiativeQueryHookResult = ReturnType<
  typeof useGetPatientProgrammeInitiativeQuery
>;
export type GetPatientProgrammeInitiativeLazyQueryHookResult = ReturnType<
  typeof useGetPatientProgrammeInitiativeLazyQuery
>;
export type GetPatientProgrammeInitiativeQueryResult = Apollo.QueryResult<
  GetPatientProgrammeInitiativeQuery,
  GetPatientProgrammeInitiativeQueryVariables
>;
export const GetSearchWardPatientObservationsDocument = gql`
  query getSearchWardPatientObservations(
    $size: Int
    $offset: Int
    $types: [ObservationType!]
  ) {
    searchWardPatientObservations(size: $size, offset: $offset, types: $types) {
      searchResults {
        ...WardPatientObservationViewFields
      }
      hits
      searchRequestId
      timedOut
    }
  }
  ${WardPatientObservationViewFieldsFragmentDoc}
`;

/**
 * __useGetSearchWardPatientObservationsQuery__
 *
 * To run a query within a React component, call `useGetSearchWardPatientObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchWardPatientObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchWardPatientObservationsQuery({
 *   variables: {
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetSearchWardPatientObservationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSearchWardPatientObservationsQuery,
    GetSearchWardPatientObservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchWardPatientObservationsQuery,
    GetSearchWardPatientObservationsQueryVariables
  >(GetSearchWardPatientObservationsDocument, options);
}
export function useGetSearchWardPatientObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchWardPatientObservationsQuery,
    GetSearchWardPatientObservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchWardPatientObservationsQuery,
    GetSearchWardPatientObservationsQueryVariables
  >(GetSearchWardPatientObservationsDocument, options);
}
export type GetSearchWardPatientObservationsQueryHookResult = ReturnType<
  typeof useGetSearchWardPatientObservationsQuery
>;
export type GetSearchWardPatientObservationsLazyQueryHookResult = ReturnType<
  typeof useGetSearchWardPatientObservationsLazyQuery
>;
export type GetSearchWardPatientObservationsQueryResult = Apollo.QueryResult<
  GetSearchWardPatientObservationsQuery,
  GetSearchWardPatientObservationsQueryVariables
>;
export const GetUserPatientProfileDocument = gql`
  query GetUserPatientProfile {
    userPatientProfileResults {
      ... on ProfileNonCompliant {
        userId
        user {
          ...UserFields
        }
      }
      ... on UserPatientProfile {
        userId
        careSettingPatientId
        wardPatientId
        user {
          ...UserFields
        }
        careSetting {
          id
          attributes {
            name
            shortName
            email
            res_phone
          }
        }
        careSettingPatient {
          attributes {
            admissionStatus
          }
        }
        wardPatient {
          id
          attributes {
            submittedObservationTypes
            observationTypes
            res_activeMonitoredConditions {
              condition
              monitoringStatus {
                status
              }
              consultationStatus {
                status
              }
              testStatus {
                status
              }
            }
          }
        }
        person {
          id
          attributes {
            generalPracticeRegistration {
              accountId
              odsCode
              linkageKey
              patientIdentifier
            }
            contactInformation {
              primaryContactNumber
              primaryEmailAddress
            }
            contactExclusionPreferences
            personalInformation {
              firstName
              surname
              ethnicity
              dateOfBirth
              genderAssignedAtBirth
              genderIdentifiedAs
            }
            latestHealthInformation {
              heightInformation {
                created
                unit
                value
              }
              bloodPressureMedicationInformation {
                created
                hasBPMedication
                hadBPMedication
              }
              smokingInformation {
                created
                status
              }
              cvdInformation {
                created
                hasCvd
                familyHadCvd
              }
              ckdInformation {
                created
                ckdStatus
              }
              diabetesInformation {
                created
                diabetesStatus
              }
            }
          }
        }
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetUserPatientProfileQuery__
 *
 * To run a query within a React component, call `useGetUserPatientProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPatientProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPatientProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPatientProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserPatientProfileQuery,
    GetUserPatientProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserPatientProfileQuery,
    GetUserPatientProfileQueryVariables
  >(GetUserPatientProfileDocument, options);
}
export function useGetUserPatientProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPatientProfileQuery,
    GetUserPatientProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserPatientProfileQuery,
    GetUserPatientProfileQueryVariables
  >(GetUserPatientProfileDocument, options);
}
export type GetUserPatientProfileQueryHookResult = ReturnType<
  typeof useGetUserPatientProfileQuery
>;
export type GetUserPatientProfileLazyQueryHookResult = ReturnType<
  typeof useGetUserPatientProfileLazyQuery
>;
export type GetUserPatientProfileQueryResult = Apollo.QueryResult<
  GetUserPatientProfileQuery,
  GetUserPatientProfileQueryVariables
>;
export const GetUserRegistrationRecordDocument = gql`
  query getUserRegistrationRecord($encryptedId: String!) {
    userRegistrationRecordAnon(encryptedId: $encryptedId) {
      ... on RegistrationLinkExpiredValidationError {
        code
        message
      }
      ... on UserRegistrationRecord {
        attributes {
          primaryEmailAddress
          effectiveTo
        }
      }
    }
  }
`;

/**
 * __useGetUserRegistrationRecordQuery__
 *
 * To run a query within a React component, call `useGetUserRegistrationRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRegistrationRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRegistrationRecordQuery({
 *   variables: {
 *      encryptedId: // value for 'encryptedId'
 *   },
 * });
 */
export function useGetUserRegistrationRecordQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserRegistrationRecordQuery,
    GetUserRegistrationRecordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserRegistrationRecordQuery,
    GetUserRegistrationRecordQueryVariables
  >(GetUserRegistrationRecordDocument, options);
}
export function useGetUserRegistrationRecordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRegistrationRecordQuery,
    GetUserRegistrationRecordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserRegistrationRecordQuery,
    GetUserRegistrationRecordQueryVariables
  >(GetUserRegistrationRecordDocument, options);
}
export type GetUserRegistrationRecordQueryHookResult = ReturnType<
  typeof useGetUserRegistrationRecordQuery
>;
export type GetUserRegistrationRecordLazyQueryHookResult = ReturnType<
  typeof useGetUserRegistrationRecordLazyQuery
>;
export type GetUserRegistrationRecordQueryResult = Apollo.QueryResult<
  GetUserRegistrationRecordQuery,
  GetUserRegistrationRecordQueryVariables
>;
export const GetWardPatientObservationsDocument = gql`
  query getWardPatientObservations(
    $exclusiveStartKey: String
    $limit: Int
    $recordedDateTimeEnd: DateTime
    $recordedDateTimeStart: DateTime
  ) {
    wardPatientObservationsPaged(
      exclusiveStartKey: $exclusiveStartKey
      limit: $limit
      recordedDateTimeEnd: $recordedDateTimeEnd
      recordedDateTimeStart: $recordedDateTimeStart
    ) {
      attributes {
        lastEvaluatedKey
        records {
          id
          type
          attributes {
            observationType
            recordedDateTime
            res_observationValue {
              ... on Pulse {
                bpm
              }
              ... on Spo2 {
                oxygenSaturation
              }
              ... on BloodPressure {
                systolic
                diastolic
                rating
              }
              ... on BodyWeight {
                weightUnit
                weight
              }
              ... on Cholesterol {
                total
                hdl
                unit
              }
              ... on FastingBloodGlucose {
                bloodGlucose
                rating
              }
            }
            wardPatientId
          }
        }
      }
    }
  }
`;

/**
 * __useGetWardPatientObservationsQuery__
 *
 * To run a query within a React component, call `useGetWardPatientObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardPatientObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardPatientObservationsQuery({
 *   variables: {
 *      exclusiveStartKey: // value for 'exclusiveStartKey'
 *      limit: // value for 'limit'
 *      recordedDateTimeEnd: // value for 'recordedDateTimeEnd'
 *      recordedDateTimeStart: // value for 'recordedDateTimeStart'
 *   },
 * });
 */
export function useGetWardPatientObservationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWardPatientObservationsQuery,
    GetWardPatientObservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardPatientObservationsQuery,
    GetWardPatientObservationsQueryVariables
  >(GetWardPatientObservationsDocument, options);
}
export function useGetWardPatientObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardPatientObservationsQuery,
    GetWardPatientObservationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardPatientObservationsQuery,
    GetWardPatientObservationsQueryVariables
  >(GetWardPatientObservationsDocument, options);
}
export type GetWardPatientObservationsQueryHookResult = ReturnType<
  typeof useGetWardPatientObservationsQuery
>;
export type GetWardPatientObservationsLazyQueryHookResult = ReturnType<
  typeof useGetWardPatientObservationsLazyQuery
>;
export type GetWardPatientObservationsQueryResult = Apollo.QueryResult<
  GetWardPatientObservationsQuery,
  GetWardPatientObservationsQueryVariables
>;
export const GetWardPatientObservationsForProgrammeDocument = gql`
  query getWardPatientObservationsForProgramme(
    $offset: Int
    $programmeId: ID!
    $size: Int
  ) {
    wardPatientObservationsForProgramme(
      offset: $offset
      programmeId: $programmeId
      size: $size
    ) {
      timedOut
      searchRequestId
      hits
      searchResults {
        id
        observationType
        recordedDateTime
        res_observationValue {
          ... on BloodPressure {
            diastolic
            diastolicRating
            fhirRating
            systolic
            systolicRating
            rating
          }
        }
        wardPatientId
      }
    }
  }
`;

/**
 * __useGetWardPatientObservationsForProgrammeQuery__
 *
 * To run a query within a React component, call `useGetWardPatientObservationsForProgrammeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardPatientObservationsForProgrammeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardPatientObservationsForProgrammeQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      programmeId: // value for 'programmeId'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetWardPatientObservationsForProgrammeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardPatientObservationsForProgrammeQuery,
    GetWardPatientObservationsForProgrammeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardPatientObservationsForProgrammeQuery,
    GetWardPatientObservationsForProgrammeQueryVariables
  >(GetWardPatientObservationsForProgrammeDocument, options);
}
export function useGetWardPatientObservationsForProgrammeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardPatientObservationsForProgrammeQuery,
    GetWardPatientObservationsForProgrammeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardPatientObservationsForProgrammeQuery,
    GetWardPatientObservationsForProgrammeQueryVariables
  >(GetWardPatientObservationsForProgrammeDocument, options);
}
export type GetWardPatientObservationsForProgrammeQueryHookResult = ReturnType<
  typeof useGetWardPatientObservationsForProgrammeQuery
>;
export type GetWardPatientObservationsForProgrammeLazyQueryHookResult =
  ReturnType<typeof useGetWardPatientObservationsForProgrammeLazyQuery>;
export type GetWardPatientObservationsForProgrammeQueryResult =
  Apollo.QueryResult<
    GetWardPatientObservationsForProgrammeQuery,
    GetWardPatientObservationsForProgrammeQueryVariables
  >;
export const GetWardPatientProgrammeByAverageObservationIdDocument = gql`
  query GetWardPatientProgrammeByAverageObservationId(
    $averageObservationId: ID!
  ) {
    wardPatientProgrammeForAverageObservationId(
      averageObservationId: $averageObservationId
    ) {
      ...PatientProgramme
    }
  }
  ${PatientProgrammeFragmentDoc}
`;

/**
 * __useGetWardPatientProgrammeByAverageObservationIdQuery__
 *
 * To run a query within a React component, call `useGetWardPatientProgrammeByAverageObservationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardPatientProgrammeByAverageObservationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardPatientProgrammeByAverageObservationIdQuery({
 *   variables: {
 *      averageObservationId: // value for 'averageObservationId'
 *   },
 * });
 */
export function useGetWardPatientProgrammeByAverageObservationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardPatientProgrammeByAverageObservationIdQuery,
    GetWardPatientProgrammeByAverageObservationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardPatientProgrammeByAverageObservationIdQuery,
    GetWardPatientProgrammeByAverageObservationIdQueryVariables
  >(GetWardPatientProgrammeByAverageObservationIdDocument, options);
}
export function useGetWardPatientProgrammeByAverageObservationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardPatientProgrammeByAverageObservationIdQuery,
    GetWardPatientProgrammeByAverageObservationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardPatientProgrammeByAverageObservationIdQuery,
    GetWardPatientProgrammeByAverageObservationIdQueryVariables
  >(GetWardPatientProgrammeByAverageObservationIdDocument, options);
}
export type GetWardPatientProgrammeByAverageObservationIdQueryHookResult =
  ReturnType<typeof useGetWardPatientProgrammeByAverageObservationIdQuery>;
export type GetWardPatientProgrammeByAverageObservationIdLazyQueryHookResult =
  ReturnType<typeof useGetWardPatientProgrammeByAverageObservationIdLazyQuery>;
export type GetWardPatientProgrammeByAverageObservationIdQueryResult =
  Apollo.QueryResult<
    GetWardPatientProgrammeByAverageObservationIdQuery,
    GetWardPatientProgrammeByAverageObservationIdQueryVariables
  >;
