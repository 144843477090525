import { Button, colors, spacing, Text } from '@bt-healthcare/ui-toolkit';

import { PublicContentCardHeader } from './PublicContentCardHeader';
import { PublicContentCardFormHeader } from './PublicContentCardFormHeader';

import { PublicContentCardWrapper } from './styles';
import { PublicContentCardPropsType } from './types';

export const PublicContentCard = ({
  Hero,
  HeaderText,
  SubHeaderText,
  TextDescription,
  ctaVariant,
  CtaText,
  ctaAction,
  loadingState,
  children,
}: PublicContentCardPropsType) => {
  const color =
    ctaVariant === 'tertiary'
      ? colors.primaryIndigo.indigo08
      : colors.base.light;
  return (
    <PublicContentCardWrapper padding={spacing.s8}>
      {!children ? (
        <>
          <PublicContentCardHeader
            Hero={Hero}
            HeaderText={HeaderText}
            TextDescription={TextDescription}
          />
          <Button
            id="public-content-card-cta"
            variant={ctaVariant}
            onClick={ctaAction}
            disabled={loadingState}
            isLoading={loadingState}
          >
            <Text fontWeight={500} color={color}>
              {CtaText}
            </Text>
          </Button>
        </>
      ) : (
        <>
          <PublicContentCardFormHeader
            Hero={Hero}
            HeaderText={HeaderText}
            SubHeaderText={SubHeaderText}
          />
          {children}
        </>
      )}
    </PublicContentCardWrapper>
  );
};
