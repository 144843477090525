import {
  BTHealthIcon,
  colors,
  Text,
  Stack,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import {
  PasswordValidatorRequirementIcon,
  PasswordValidatorRequirementItem,
} from './styles';

import { PasswordValidatorRequirementProps } from './types';

export const PasswordValidatorRequirement = ({
  id,
  isValid,
  text,
}: PasswordValidatorRequirementProps) => (
  <Stack
    space="s2"
    id={`password-validator.requirement.${id}`}
    flexDirection="row"
    alignItems="center"
  >
    <PasswordValidatorRequirementItem>
      {isValid && (
        <PasswordValidatorRequirementIcon>
          <BTHealthIcon
            icon="TickRounded"
            color={colors.notifications.light.successDark}
            size={18}
          />
        </PasswordValidatorRequirementIcon>
      )}
    </PasswordValidatorRequirementItem>
    <Text color={colors.grey.grey10} fontSize={fontSizes.sm}>
      {text}
    </Text>
  </Stack>
);
