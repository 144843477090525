import { Button } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import HealthScorePageWrapper from '../../components/HealthScorePageWrapper';
import { healthScoreHeightAndWeight } from './intl';
import { HealthScorerExplanationCard } from '../../components/Cards/HealthScorerExplanationCard';
import { useAppDispatch } from '../../context/AppContext';
import {
  HeightAndWeightFieldType,
  HeightAndWeightForm,
  HeightAndWeightDataType,
} from './types';
import { data } from './data';
import { unitsToggleDisplayName } from '../HealthInformation/intl';
import { InputWithToggle } from '../../components/InputsWithToggle';
import { useInputFieldsForUnitToggle } from '../../hooks/useInputFieldsForUnitToggle';
import { unitsDisplayName } from '../CreateObservations/intl';
import { prepareSchema } from '../../utils/yup/schema';
import { UseInputFieldsForUnitToggleInterface } from '../../hooks/useInputFieldsForUnitToggle.types';
import { weightObservationToObservationInput } from '../../formatters/observations';
import { heightFormToInput } from '../../mappers/healthInformation';

export const HealthScoreHeightAndWeight = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { height: heightData, weight: weightData } = data(intl);

  const heightToggleData = useInputFieldsForUnitToggle({
    unitSystems: heightData.unitSystems,
    fields: heightData.fields,
    unitsIntl: unitsToggleDisplayName,
  });

  const weightToggleData = useInputFieldsForUnitToggle({
    unitSystems: weightData.unitSystems,
    fields: weightData.fields,
    unitsIntl: unitsDisplayName,
  });

  const validationSchema = prepareSchema([
    ...heightToggleData.activeFields,
    ...weightToggleData.activeFields,
  ]);

  const methods = useForm<HeightAndWeightForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = methods;

  const inputWithToggleProps = (
    inputData: HeightAndWeightDataType,
    inputFieldsAndToggle: UseInputFieldsForUnitToggleInterface<HeightAndWeightFieldType>
  ) => {
    const { activeUnit, setActiveUnit, items, activeFields } =
      inputFieldsAndToggle;
    return {
      label: inputData.label,
      toggleProps: {
        activeItem: activeUnit,
        setActive: setActiveUnit,
        items,
      },
      fields: activeFields,
      register,
      errors,
    };
  };
  const onSubmit = (values: HeightAndWeightForm) => {
    const { kg, lb, st, ft, cm, inch } = values;
    const weightResult = weightObservationToObservationInput({ kg, lb, st });
    const heightResult = heightFormToInput({ ft, cm, inch });
    dispatch({
      type: 'healthScoreSetHeightAndWeight',
      payload: { height: heightResult, weight: weightResult },
    });
  };

  return (
    <FormProvider {...methods}>
      <HealthScorePageWrapper.Form onSubmit={handleSubmit(onSubmit)}>
        <HealthScorePageWrapper.Content>
          <HealthScorePageWrapper.Header>
            <FormattedMessage {...healthScoreHeightAndWeight.header} />
          </HealthScorePageWrapper.Header>
          <HealthScorePageWrapper.InputsContainer>
            <InputWithToggle<HeightAndWeightFieldType, HeightAndWeightForm>
              {...inputWithToggleProps(heightData, heightToggleData)}
            />
            <InputWithToggle<HeightAndWeightFieldType, HeightAndWeightForm>
              {...inputWithToggleProps(weightData, weightToggleData)}
            />
            <HealthScorerExplanationCard>
              <FormattedMessage
                {...healthScoreHeightAndWeight.explanationCard}
              />
            </HealthScorerExplanationCard>
          </HealthScorePageWrapper.InputsContainer>
        </HealthScorePageWrapper.Content>
        <Button
          id="health-score-button"
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          <FormattedMessage {...healthScoreHeightAndWeight.button} />
        </Button>
      </HealthScorePageWrapper.Form>
    </FormProvider>
  );
};
