import { EnvTypes, useAnalytics } from '@bt-healthcare/ui-toolkit';
import { getEnvVariable } from '../env.utils';

export const initializeAnalytics = () => {
  const { initializeMixpanel } = useAnalytics();

  initializeMixpanel({
    env: getEnvVariable('VITE_ENV') as EnvTypes,
    data: {
      token: getEnvVariable('VITE_MIXPANEL_ANALYTICS_TOKEN'),
    },
  });
};
