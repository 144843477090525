import { Card } from '@bt-healthcare/ui-toolkit';
import type { CardProps } from '@bt-healthcare/ui-toolkit/dist/dts/components/Card/types';
import { Container } from './styles';
import { ItemLeft } from './ItemLeft';
import { ItemRight } from './ItemRight';

const BaseCard = ({ children, ...props }: CardProps) => (
  <Card isListCard borderRadius={8} {...props}>
    <Container>{children}</Container>
  </Card>
);

BaseCard.ItemLeft = ItemLeft;
BaseCard.ItemRight = ItemRight;

export default BaseCard;
