import {
  ModalBody,
  Button,
  Stack,
  Modal,
  Text,
  ModalFooter,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

import { FormattedMessage } from 'react-intl';
import { HeaderText } from './FindYourLinkageInformation.styles';
import { BodyText } from './ConfirmBloodTestBookingError.styles';

export type Props = {
  isModalOpen: boolean;
  handleClose: () => void;
};

export const ConfirmBloodTestBookingError = ({
  isModalOpen,
  handleClose,
}: Props) => (
  <Modal
    onClose={handleClose}
    isModalOpen={isModalOpen}
    id="confirm-blood-test-booking.error-modal"
    width="343px"
  >
    <ModalBody>
      <Stack space="s4" id="confirmation">
        <Text color={colors.notifications.light.errorDark} fontWeight={500}>
          <HeaderText>
            <FormattedMessage
              id="confirm-blood-test-booking.error.confirm-reading.title"
              defaultMessage="There was an issue booking your appointment"
            />
          </HeaderText>
        </Text>
        <Text
          color={colors.grey.grey09}
          fontSize={fontSizes.sm}
          fontWeight={400}
        >
          <BodyText>
            <FormattedMessage
              id="confirm-blood-test-booking.error.confirm-reading.subtitle"
              defaultMessage="This may be because the time you chose is now unavailable. Please try booking again at another time."
            />
          </BodyText>
        </Text>
      </Stack>
      <ModalFooter>
        <Button id="cancel" onClick={handleClose} variant="primary">
          <FormattedMessage
            id="confirm-blood-test-booking.error.confirm-reading.button.try-again"
            defaultMessage="Try again"
          />
        </Button>
      </ModalFooter>
    </ModalBody>
  </Modal>
);
