import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import {
  Text,
  spacing,
  Stack,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { seriesChartConfigMap } from './config';
import { ObservationComponentName } from '../../services/graphql';
import { legendLabel } from './intl';

const LEGEND_RECT_SIZE = 16;

export const Legend = ({ names }: { names: ObservationComponentName[] }) => {
  const colorScale = scaleOrdinal({
    domain: names,
    range: seriesChartConfigMap.map(({ line }) => line),
  });
  return (
    <LegendOrdinal scale={colorScale}>
      {(labels) => (
        <Stack
          id="legend-items"
          flexDirection="row"
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          {labels.map((label) => (
            <LegendItem
              key={`legend-quantile-${label.text}`}
              margin={`0 ${spacing.s4}`}
              alignItems="end"
            >
              <svg width={LEGEND_RECT_SIZE + 1} height={LEGEND_RECT_SIZE + 1}>
                <rect
                  fill={label.value}
                  width={LEGEND_RECT_SIZE}
                  height={LEGEND_RECT_SIZE}
                  rx={2}
                  ry={2}
                />
              </svg>
              {label.text in legendLabel ? (
                <LegendLabel align="left" margin={`0 0 0 ${spacing.s1}`}>
                  <Text color={colors.grey.grey10} fontSize={fontSizes.xs}>
                    <FormattedMessage
                      {...legendLabel[label.text as keyof typeof legendLabel]}
                    />
                  </Text>
                </LegendLabel>
              ) : null}
            </LegendItem>
          ))}
        </Stack>
      )}
    </LegendOrdinal>
  );
};
