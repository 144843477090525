import { ErrorMessage, SpinnerLoader } from '@bt-healthcare/ui-toolkit';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';

import { Props } from './types';
import { TermsAndConditionsStatus } from '../../services/graphql';

export const UserAcceptedTermsAndConditionsRoute = ({
  redirectPath,
}: Props) => {
  const { data, loading, error } = useAuthUserProfile();

  if (loading) {
    return (
      <SpinnerLoader id="terms-conditions" data-testid="terms-conditions" />
    );
  }

  if (error) {
    return <ErrorMessage />;
  }

  if (
    data?.userPatientProfileResults.user.attributes.termsAndConditions
      ?.decision === TermsAndConditionsStatus.Accepted
  ) {
    return <Outlet />;
  }

  return <Navigate to={redirectPath} />;
};
