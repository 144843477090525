import {
  Body,
  PageNotFound,
  SpinnerLoader,
  Stack,
  SubHeader,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { Faq } from '../../components/Faq';
import { ObservationRatingsTable } from '../../components/ObservationRatingsTable';
import { mapUrlPathToObservationType } from '../../formatters/observations';
import { useKnownObservation } from '../../hooks/useKnowObservations';
import { useGetLastObservationAndBandsQuery } from '../../services/graphql';
import { Container } from './styles';
import { Props } from './types';
import { observationsRenderData } from './utils';

const ObservationRatingsComponent = ({ type }: Props) => {
  const { state } = useLocation();
  const intl = useIntl();

  const { data, loading } = useGetLastObservationAndBandsQuery({
    variables: { observationType: type },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <SpinnerLoader id="ratings" data-testid="ratings" />;
  const info = data?.observationBands?.info;

  const { selectedObservationRenderData, lastObservationRenderData } =
    observationsRenderData({
      intl,
      selectedObservation: state?.observation,
      lastObservation: data?.searchWardPatientObservations.searchResults[0],
      bands: data!.observationBands,
    });

  return (
    <Container>
      <Stack id="top-labels" space="s4">
        {selectedObservationRenderData && (
          <SubHeader>
            <FormattedMessage
              id="observation-rating.selected-rating.label"
              defaultMessage="Your reading of {value} on {date} at {time} was <h>{rating}</h>."
              values={selectedObservationRenderData}
            />
          </SubHeader>
        )}
        {lastObservationRenderData && (
          <Body>
            <FormattedMessage
              id="observation-rating.most-recent-rating.label"
              defaultMessage="Your most recent reading is <b>{rating}</b>."
              values={lastObservationRenderData}
            />
          </Body>
        )}
      </Stack>
      <ObservationRatingsTable data={data?.observationBands?.ratings || []} />
      <Faq type={type} info={info} />
    </Container>
  );
};

export const ObservationRatings = () => {
  const { type: formattedType } = useParams<{ type: string }>();
  const type = mapUrlPathToObservationType(formattedType || '');
  const isKnownObservation = useKnownObservation();
  if (!type || !isKnownObservation(type)) {
    return <PageNotFound />;
  }

  return <ObservationRatingsComponent type={type} />;
};
