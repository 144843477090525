import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ROUTE } from '../../config/routes';
import { CloseIcon, CloseIconWrapper } from './styles';
import { RecommendationExitConfirmationModal } from '../Modal/RecommendationExitConfirmationModal';
import { CloseProps } from './types';

export const Close = ({ onClose, isBreakRecommendationFlow }: CloseProps) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const defaultClose = () => {
    navigate(ROUTE.HOME);
  };
  const onCloseModal = () => {
    setModalOpen(true);
  };

  const onClick = isBreakRecommendationFlow
    ? onCloseModal
    : onClose || defaultClose;

  return (
    <>
      <CloseIconWrapper onClick={onClick}>
        <CloseIcon />
      </CloseIconWrapper>
      {isBreakRecommendationFlow && (
        <RecommendationExitConfirmationModal
          onCancel={() => {
            setModalOpen(false);
          }}
          isModalOpen={modalOpen}
        />
      )}
    </>
  );
};
