import styled from 'styled-components';
import {
  colors,
  device,
  fontCalcMixin,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { rgbaFromHexAndOpacity } from '../../utils/css';

export const ImgContainer = styled.div`
  width: 72%;
  align-self: center;
  @media ${device.tablet} {
    width: 55%;
    align-self: flex-start;
  }
  @media ${device.desktop} {
    width: 42%;
    align-self: center;
  }
`;
export const Title = styled(Text).attrs({
  color: colors.base.white,
  fontWeight: 500,
  fontSize: fontSizes.h3,
})`
  text-align: center;
  @media ${device.tablet} {
    text-align: start;
  }
  @media ${device.desktop} {
    text-align: center;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin: ${spacing.s8} 0 ${spacing.s4} 0;
  align-self: center;
  @media ${device.tablet} {
    margin: ${spacing.s14} 0 ${spacing.s8} 0;
    align-self: flex-start;
  }
  @media ${device.desktop} {
    align-self: center;
  }
`;

export const Description = styled(Text).attrs({
  color: rgbaFromHexAndOpacity(colors.base.white, 0.7),
})`
  text-align: center;
  @media ${device.tablet} {
    text-align: start;
    ${fontCalcMixin(fontSizes.h4)};
  }
`;
