import { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button, colors } from '@bt-healthcare/ui-toolkit';
import { PageWithOverlayWrapper } from '../PageWithOverlayWrapper';
import { OverlayBackground } from '../PageWithOverlayWrapper/types';
import { ContentWrapper } from '../BluePageWrapper/styles';
import {
  Header,
  HeaderWrapper,
  InputsContainer,
  InputWithInfoWrapper,
  StyledForm,
} from './styles';
import { ROUTE } from '../../config/routes';
import { ConfirmCloseHealthScore } from '../Modal/ConfirmCloseHealthScore';
import { ProgessBar } from '../ProgessBar';
import { ProgressBarProps } from '../ProgessBar/types';

const HealthScorePageWrapper = ({
  children,
  progress,
}: PropsWithChildren<ProgressBarProps>) => {
  const navigate = useNavigate();
  const [isVisibleCloseConfirmation, setIsVisibleCloseConfirmation] =
    useState(false);

  const onClose = () => {
    setIsVisibleCloseConfirmation(true);
  };

  const onCloseConfirm = () => {
    setIsVisibleCloseConfirmation(false);
    navigate(ROUTE.HOME);
  };

  const onCloseCancel = () => {
    setIsVisibleCloseConfirmation(false);
  };

  return (
    <PageWithOverlayWrapper variant={OverlayBackground.HEALTH_SCORE}>
      <ConfirmCloseHealthScore
        modalOpen={isVisibleCloseConfirmation}
        handleSuccess={onCloseConfirm}
        handleClose={onCloseCancel}
      />
      <Stack id="page-overlay-progress" space="s14" height="100%">
        <Stack
          id="page-overlay-progress-bar"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
        >
          <ProgessBar progress={progress} />
          <span>
            <Button
              id="health-score-close"
              variant="tertiary"
              onClick={onClose}
              icon={{
                icon: 'Close',
                color: colors.base.dark,
                size: 24,
              }}
            />
          </span>
        </Stack>
        {children}
      </Stack>
    </PageWithOverlayWrapper>
  );
};

export const HeaderComponent = ({ children }: PropsWithChildren) => (
  <HeaderWrapper>
    <Header>{children}</Header>
  </HeaderWrapper>
);

HealthScorePageWrapper.Content = ContentWrapper;
HealthScorePageWrapper.Header = HeaderComponent;
HealthScorePageWrapper.InputWithInfo = InputWithInfoWrapper;
HealthScorePageWrapper.InputsContainer = InputsContainer;
HealthScorePageWrapper.Form = StyledForm;

export default HealthScorePageWrapper;
