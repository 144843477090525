import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, MultipleChoiceButton } from '@bt-healthcare/ui-toolkit';
import { useAppDispatch, useAppState } from '../../context/AppContext';
import HealthScorePageWrapper from '../../components/HealthScorePageWrapper';
import { healthScoreNavigator } from '../HealthScoreNavigator/intl';
import { alcoholModerationOptions, validationSchema } from './data';
import { AlcoholModerationForm, HighUnitsInOneSetting } from './types';
import { healthScoreAlcoholModeration } from './intl';

export const HealthScoreAlcoholModeration = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { healthScore } = useAppState();

  const genderAssignedAtBirth = healthScore?.genderAssignedAtBirth;

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<AlcoholModerationForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      highUnitsInOneSetting: undefined,
    },
  });

  const onSubmit = (values: AlcoholModerationForm) => {
    const highUnitsInOneSetting =
      values?.highUnitsInOneSetting === HighUnitsInOneSetting.YES;

    dispatch({
      type: 'healthScoreSetAlcoholModeration',
      payload: {
        highUnitsInOneSetting,
      },
    });
  };

  return (
    <HealthScorePageWrapper.Form>
      <HealthScorePageWrapper.Content>
        <HealthScorePageWrapper.Header>
          <FormattedMessage
            {...healthScoreAlcoholModeration.header(genderAssignedAtBirth)}
          />
        </HealthScorePageWrapper.Header>
        <MultipleChoiceButton
          options={alcoholModerationOptions(intl)}
          size="lg"
          label=""
          variant="fullWidth"
          {...register('highUnitsInOneSetting')}
        />
      </HealthScorePageWrapper.Content>
      <Button
        id="health-score-button"
        variant="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={!isValid}
      >
        <FormattedMessage {...healthScoreNavigator.button} />
      </Button>
    </HealthScorePageWrapper.Form>
  );
};
