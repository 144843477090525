import styled from 'styled-components';
import { colors, RequestCard, Text } from '@bt-healthcare/ui-toolkit';

export const StyledRequestCard = styled(RequestCard).attrs({
  iconColor: colors.grey.grey10,
  borderRadius: 8,
})`
  cursor: pointer;
`;

export const TitleWrapper = styled.span`
  display: flex;
  flex-flow: column;
`;

export const Value = styled(Text).attrs({
  color: colors.grey.grey10,
  fontWeight: 500,
})``;
