import { PolicyType } from './types';

export const markdownURL = (type: PolicyType) => {
  const host = window.location.origin;
  switch (type) {
    case PolicyType.COOKIE:
      return `${host}/cookie-policy.md`;
    case PolicyType.TERMS_AND_CONDITIONS:
      return `${host}/terms-and-conditions.md`;
    case PolicyType.PRIVACY:
      return `${host}/privacy-policy.md`;
    default:
      return '';
  }
};
