import { IntlShape } from 'react-intl';
import { object, string } from 'yup';
import { healthScoreAlcoholModeration } from './intl';
import { HighUnitsInOneSetting } from './types';

export const validationSchema = object({
  highUnitsInOneSetting: string()
    .oneOf(Object.values(HighUnitsInOneSetting))
    .required(),
});

export const alcoholModerationOptions = (intl: IntlShape) => [
  {
    value: HighUnitsInOneSetting.YES,
    label: intl.formatMessage(
      healthScoreAlcoholModeration.highUnitsInOneSetting.options[
        HighUnitsInOneSetting.YES
      ]
    ),
  },
  {
    value: HighUnitsInOneSetting.NO,
    label: intl.formatMessage(
      healthScoreAlcoholModeration.highUnitsInOneSetting.options[
        HighUnitsInOneSetting.NO
      ]
    ),
  },
];
