import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Header,
  PageNotFound,
  Row,
} from '@bt-healthcare/ui-toolkit';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { mapUrlPathToObservationType } from '../../formatters/observations';
import { ObservationType } from '../../services/graphql';
import {
  Description,
  GuideContainer,
  HeaderWrapper,
  IconWrapper,
  ItemsContainer,
  ItemWrapper,
  TextWrapper,
  Title,
} from './styles';
import { getData } from './data';
import { useTracking } from '../../hooks/useTracking';

export const CreateObservationsGuideComponent = () => {
  const intl = useIntl();
  const data = getData(intl);
  const navigate = useNavigate();

  if (!data) return <PageNotFound />;
  const { header, button, items } = data;

  return (
    <>
      <HeaderWrapper>
        <Header>{header}</Header>
      </HeaderWrapper>
      <GuideContainer>
        <Row>
          <Col colEnd={{ xxs: 'span 4', md: 'span 6', xl: 'span 5' }}>
            <ItemsContainer>
              {items.map(({ title, description, icon: Icon }) => (
                <ItemWrapper key={title}>
                  <IconWrapper>
                    <Icon />
                  </IconWrapper>
                  <TextWrapper>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                  </TextWrapper>
                </ItemWrapper>
              ))}
            </ItemsContainer>
          </Col>
        </Row>
        <Row>
          <Col colEnd={{ xxs: 'span 4', md: 'span 6', xl: 'span 5' }}>
            <Button
              id="submit-reading-btn"
              variant="primary"
              size="maximum"
              onClick={() => navigate(-1)}
            >
              {button}
            </Button>
          </Col>
        </Row>
      </GuideContainer>
    </>
  );
};

export const CreateObservationsGuide = () => {
  const { trackPageWithAppName } = useTracking();
  const { type: formattedType } = useParams<{ type: string }>();
  const type = mapUrlPathToObservationType(formattedType || '');
  useEffect(() => {
    trackPageWithAppName(`HowTo - ${type} - Patient - HealthyYou`);
  }, []);

  if (type !== ObservationType.BloodPressure) {
    return <PageNotFound />;
  }
  return <CreateObservationsGuideComponent />;
};
