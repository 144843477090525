import styled from 'styled-components';
import { colors, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import { defaultStyles } from '@visx/tooltip';

import type { TooltipTextProps } from './types';

export const styles = {
  ...defaultStyles,
  paddingTop: spacing.s1,
  paddingBottom: spacing.s1,
  paddingLeft: spacing.s2,
  paddingRight: spacing.s2,
  transform: 'translate(-50%, 0)',
  borderRadius: '4px',
};

export const TooltipText = styled.span<TooltipTextProps>`
  text-align: center;
  display: block;
  position: relative;
  white-space: nowrap;
  color: ${({ primary }) =>
    primary ? colors.grey.grey10 : colors.grey.grey08};
  font-weight: ${({ primary }) => (primary ? 500 : 400)};
  font-size: ${({ primary }) => !primary && fontSizes.xs};
  margin-bottom: ${spacing.s1};

  &:last-child {
    margin-bottom: ${spacing.none};
  }
`;
