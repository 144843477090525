import { PropsWithChildren } from 'react';
import { colors, Text } from '@bt-healthcare/ui-toolkit';
import { ReactComponent as Img } from '../../../assets/clinician.svg';
import { LeftItem, RightItem, StyledCard } from './styles';

export const HealthScorerExplanationCard = ({
  children,
}: PropsWithChildren) => (
  <StyledCard id="health-score-explanation-card">
    <LeftItem>
      <Text color={colors.grey.grey10}>{children}</Text>
    </LeftItem>
    <RightItem>
      <Img />
    </RightItem>
  </StyledCard>
);
