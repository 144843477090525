import { defineMessage, defineMessages } from 'react-intl';

export const healthScoreAlcoholFrequency = {
  header: defineMessage({
    id: 'health-score-alcohol-frequency.header',
    defaultMessage:
      'In a typical week how many days do you drink <indigo08>alcohol?</indigo08>',
  }),
  options: defineMessages({
    none: {
      id: 'health-score-smoking.options.none',
      defaultMessage: 'None',
    },
    dayAWeek: {
      id: 'health-score-smoking.options.day-a-week',
      defaultMessage: '{days, plural, =1 {1 day} other {# days}} a week',
    },
  }),
};
