import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';

export const Container = styled.div`
  display: grid;
  gap: ${spacing.s8};

  @media ${device.desktop} {
    width: 680px;
    gap: ${spacing.s10};
  }
`;

export const TableWrapper = styled.div`
  display: grid;
  gap: ${spacing.s2};
  @media ${device.tablet} {
    gap: ${spacing.s4};
  }
`;

export const TableDescriptionLabel = styled.p`
  font-size: ${fontSizes.xs};
  line-height: ${spacing.s4};
  font-weight: 400;
  color: ${colors.grey.grey08};
`;

export const FaqWrapper = styled.div`
  display: grid;
  gap: ${spacing.s6};
  @media ${device.tablet} {
    gap: ${spacing.s8};
  }
`;
