import type { IntlShape } from 'react-intl';
import { BodyWeightUnitInput, HeightUnit } from '../../services/graphql';
import { healthScoreHeightAndWeight } from './intl';
import { HeightAndWeightDataType } from './types';
import { weightValidation } from '../CreateObservations/data';
import { heightValidation } from '../HealthInformation/data';

export const height = (intl: IntlShape): HeightAndWeightDataType => ({
  unitSystems: [HeightUnit.Inch, HeightUnit.Cm],
  label: intl.formatMessage(healthScoreHeightAndWeight.fields.height.label),
  fields: [
    {
      name: 'cm',
      placeholder: intl.formatMessage(
        healthScoreHeightAndWeight.fields.height.centimetresPlaceholder
      ),
      validationRule: heightValidation.cm,
      unitSystem: HeightUnit.Cm,
      inputMode: 'decimal',
    },
    {
      name: 'ft',
      placeholder: intl.formatMessage(
        healthScoreHeightAndWeight.fields.height.feetPlaceholder
      ),
      validationRule: heightValidation.ft,
      unitSystem: HeightUnit.Inch,
    },
    {
      name: 'inch',
      placeholder: intl.formatMessage(
        healthScoreHeightAndWeight.fields.height.inchesPlaceholder
      ),
      validationRule: heightValidation.inch,
      unitSystem: HeightUnit.Inch,
    },
  ],
});

const weight = (intl: IntlShape): HeightAndWeightDataType => ({
  label: intl.formatMessage(healthScoreHeightAndWeight.fields.weight.label),
  unitSystems: [BodyWeightUnitInput.Lb, BodyWeightUnitInput.Kg],
  fields: [
    {
      placeholder: intl.formatMessage(
        healthScoreHeightAndWeight.fields.weight.kilogramsPlaceholder
      ),
      validationRule: weightValidation.kg,
      name: 'kg',
      unitSystem: BodyWeightUnitInput.Kg,
      inputMode: 'decimal',
    },
    {
      placeholder: intl.formatMessage(
        healthScoreHeightAndWeight.fields.weight.stonePlaceholder
      ),
      validationRule: weightValidation.st,
      name: 'st',
      unitSystem: BodyWeightUnitInput.Lb,
    },
    {
      placeholder: intl.formatMessage(
        healthScoreHeightAndWeight.fields.weight.poundsPlaceholder
      ),
      validationRule: weightValidation.lb,
      name: 'lb',
      unitSystem: BodyWeightUnitInput.Lb,
    },
  ],
});

export const data = (intl: IntlShape) => ({
  height: height(intl),
  weight: weight(intl),
});
