import styled from 'styled-components';
import {
  colors,
  device,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';

export const HeaderWrapper = styled.div``;

export const GuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  @media ${device.tablet} {
    height: auto;
    justify-content: unset;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s6};
  margin-top: ${spacing.s10};
  margin-bottom: ${spacing.s14};
  @media ${device.desktop} {
    margin-top: ${spacing.s12};
  }
`;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${spacing.s6};
  min-height: 90px;
  height: auto;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-column: 0 / 1;
  align-self: center;
  width: 80px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 2 / 5;
`;

export const Title = styled(Text).attrs({
  color: colors.grey.grey10,
  fontSize: fontSizes.sm,
  fontWeight: 500,
})``;

export const Description = styled(Text).attrs({
  fontSize: fontSizes.sm,
  color: colors.grey.grey08,
})`
  align-self: stretch;
`;
