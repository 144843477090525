import type { TickFormatter, TickRendererProps } from '@visx/axis';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Group } from '@visx/group';
import { GridRows } from '@visx/grid';
import { Line } from '@visx/shape';

import { timeFormat, utcParse } from 'd3-time-format';
import { extent } from 'd3-array';
import { ScaleTime } from 'd3-scale';
import { colors, fontSizes } from '@bt-healthcare/ui-toolkit';
import { isEmpty } from 'ramda';
import { RectClipPath } from '@visx/clip-path';
import {
  BOTTOM_TICK_LENGTH,
  bottomTickFormat,
  DATE_FORMAT,
  LINE_CHART_HEIGHT,
  LINE_CHART_WIDTH,
  MARGIN,
  ObservationTypeMap,
  ScaleOffsetMap,
  GLYPH_SIZE,
  CLIP_OFFSET,
} from './config';
import { getTickValues } from './utils';
import { LineChartProps, TimeParser } from './types';
import { DataLine } from './DataLine';
import { AxisBottomTick } from './AxisBottomTick';
import { Legend } from './Legend';
import { LineChartWrapper } from './styles';
import { GraphTooltip } from '../GraphTooltip';
import { useGraphTooltip } from '../GraphTooltip/hooks';

export const LineGraph = ({
  chartData,
  dateRange,
  observationType,
  width = LINE_CHART_WIDTH,
  height = LINE_CHART_HEIGHT,
  precedingDataPoint,
  dateFilter,
}: LineChartProps) => {
  const chartWidth = width - MARGIN.left - MARGIN.right;
  const chartHeight = height - MARGIN.top - MARGIN.bottom;

  const labelFormat = bottomTickFormat({ width, dateFilter });
  const parseDate = utcParse(DATE_FORMAT) as TimeParser;
  const tickValues = getTickValues(dateRange, dateFilter);

  const seriesData = chartData.flatMap((d) =>
    d.series.map(({ value }) => value)
  );
  const { min, max } = ObservationTypeMap[observationType]!;

  const [yMinValue = min, yMaxValue = max] = extent(seriesData);

  const yScale = scaleLinear({
    domain: [
      Math.max(yMinValue - ScaleOffsetMap[observationType]!, min),
      Math.min(yMaxValue + ScaleOffsetMap[observationType]!, max),
    ],
    range: [chartHeight, 0],
  });

  const {
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    handleTooltipPosition,
    setPointData,
    tooltipOffsetTop,
    dataPointType,
    tooltipLineFrom,
    tooltipLineTo,
  } = useGraphTooltip({ dateFilter, yScale });

  const xScale: ScaleTime<any, any, never> = scaleTime()
    .domain([dateRange.startDate, dateRange.endDate] as [Date, Date])
    .range([0, chartWidth]);

  const formatTime: TickFormatter<any> = timeFormat(labelFormat);

  const isMultiComponent = chartData[0]?.series.length > 1;

  const renderTickComponent = (props: TickRendererProps) => (
    <AxisBottomTick {...props} />
  );

  const handleLeave = () => {
    setPointData(undefined);
  };

  return (
    <LineChartWrapper>
      <svg
        width={width}
        height={height}
        onTouchStart={(e) => handleTooltipPosition(e)}
        onMouseMove={(e) => handleTooltipPosition(e)}
      >
        <Group transform={`translate(${MARGIN.left},${MARGIN.top})`}>
          <GridRows
            scale={yScale}
            width={chartWidth}
            height={chartHeight}
            stroke={colors.grey.grey02}
            numTicks={6}
          />
          <AxisBottom
            scale={xScale}
            top={chartHeight}
            tickStroke={colors.grey.grey02}
            tickTransform={`translate(0, -${BOTTOM_TICK_LENGTH})`}
            tickLength={BOTTOM_TICK_LENGTH}
            tickFormat={formatTime}
            tickValues={tickValues}
            tickComponent={renderTickComponent}
            stroke={colors.grey.grey02}
            labelOffset={30}
            labelProps={{
              fontSize: fontSizes.xs,
              textAnchor: 'middle',
              fontWeight: 500,
              fill: colors.primaryIndigo.indigo08,
            }}
          />
          <AxisLeft
            scale={yScale}
            hideTicks
            hideAxisLine
            numTicks={5}
            tickFormat={(series) => `${series}`}
            tickLabelProps={() => ({
              textAnchor: 'end',
              fontSize: fontSizes.xs,
              fill: colors.grey.grey08,
            })}
          />

          <RectClipPath
            id="line-clip-path"
            x={-GLYPH_SIZE}
            y={-CLIP_OFFSET}
            width={chartWidth + GLYPH_SIZE}
            height={chartHeight + CLIP_OFFSET}
          />
          <Group clipPath="url(#line-clip-path)">
            {!isEmpty(chartData) &&
              chartData[0].series.map((__, idx: number) => (
                <DataLine
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  idx={idx}
                  chartData={chartData}
                  parseDate={parseDate}
                  xScale={xScale}
                  yScale={yScale}
                  observationType={observationType}
                  precedingDataPoint={precedingDataPoint}
                  tooltipOpen={tooltipOpen}
                  tooltipData={tooltipData}
                  handleOnPointOver={setPointData}
                  handlePointLeave={handleLeave}
                />
              ))}
          </Group>
          {!isEmpty(chartData) && tooltipOpen && (
            <Group clipPath="url(#line-clip-path)">
              <Line
                from={tooltipLineFrom}
                to={tooltipLineTo}
                stroke={colors.grey.grey10}
                strokeWidth={2}
                pointerEvents="none"
              />
            </Group>
          )}
        </Group>
      </svg>
      {isMultiComponent && (
        <Legend names={chartData[0].series.map(({ name }) => name)} />
      )}
      <GraphTooltip
        tooltipOpen={tooltipOpen}
        tooltipData={tooltipData}
        tooltipLeft={tooltipLeft}
        tooltipTop={tooltipTop}
        observationType={observationType}
        dataPointType={dataPointType}
        offsetTop={tooltipOffsetTop}
      />
    </LineChartWrapper>
  );
};
