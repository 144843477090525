import { FormattedMessage } from 'react-intl';

import { Button, colors, Spacer, Text } from '@bt-healthcare/ui-toolkit';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LandingBackgroundDesktop } from '../../assets/landing-bg-desktop.svg';
import { ReactComponent as LandingBackgroundMobile } from '../../assets/landing-bg-mobile.svg';
import { ReactComponent as LandingHero } from '../../assets/landing-hero.svg';
import { ROUTE } from '../../config/routes';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { PublicLayoutFooter } from '../../Layouts/GridLayout/PublicLayoutFooter';
import { PublicLayoutNavbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import {
  ButtonWrapper,
  Container,
  Content,
  FooterContainer,
  Header,
  HeroContainer,
  LandingBackground,
  LandingBackgroundDesktopContainer,
  LandingBackgroundMobileContainer,
  SubtitleText,
  Wrapper,
} from './styles';
import type { LandingPagePropType } from './types';

export const LandingPage = ({ onLoggingIn }: LandingPagePropType) => {
  const flags = useFeatureFlags();
  const navigate = useNavigate();

  // TODO - Refactor this page to use the CSS grid system instead of the flexbox

  return (
    <>
      <Wrapper>
        <PublicLayoutNavbar />
      </Wrapper>
      <Container>
        <Content>
          <Header>
            <Text color={colors.grey.grey10} fontWeight={400}>
              <FormattedMessage
                id="login.heading"
                defaultMessage="Become the expert of your own health"
              />
            </Text>
          </Header>

          <SubtitleText>
            <Text color={colors.grey.grey08}>
              <FormattedMessage
                id="login.subtitle"
                defaultMessage="Get the insight on what’s going on inside. Unlock the tools you need to take your health into your own hands with real-time vitals tracking."
              />
            </Text>
          </SubtitleText>

          <HeroContainer>
            <LandingHero />
          </HeroContainer>
          <FooterContainer>
            <ButtonWrapper>
              <Button
                id="login"
                variant="primary"
                onClick={onLoggingIn}
                data-testid="get-started"
              >
                <Text color={colors.base.white}>
                  <FormattedMessage
                    id="login.cta.button.text"
                    defaultMessage="Log in"
                  />
                </Text>
              </Button>
            </ButtonWrapper>
            {flags.NAVIGATION_STANDALONE && (
              <ButtonWrapper>
                <Button
                  id="self-register"
                  variant="secondary"
                  onClick={() => {
                    navigate(ROUTE.ONBOARDING_EMAIL_ADDRESS);
                  }}
                >
                  <Text color={colors.primaryIndigo.indigo08}>
                    <FormattedMessage
                      id="register.cta"
                      defaultMessage="Create an account"
                    />
                  </Text>
                </Button>
              </ButtonWrapper>
            )}
          </FooterContainer>
        </Content>
      </Container>
      <LandingBackgroundDesktopContainer>
        <LandingBackgroundDesktop />
      </LandingBackgroundDesktopContainer>
      <LandingBackground />
      <LandingBackgroundMobileContainer>
        <LandingBackgroundMobile />
      </LandingBackgroundMobileContainer>
      <Wrapper>
        <Spacer size="s6" />
        <PublicLayoutFooter />
        <Spacer size="s6" />
      </Wrapper>
    </>
  );
};
