import {
  device,
  colors,
  spacing,
  fontCalcMixin,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const RisksCardsWrapper = styled.div`
  display: flex;
  height: 100%;
  background: linear-gradient(263deg, #02062d 5.54%, #351460 86.16%);
  padding: ${spacing.s8};
  border-radius: ${spacing.s6};
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    padding: ${spacing.l1};
    justify-content: space-evenly;
    flex-direction: row;
  }
`;

export const RisksCardsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  margin: 0;
`;
export const RisksCardsListHeader = styled.li<{ background: string }>`
  border-radius: 8px 8px 0px 0px;
  background: ${({ background }) => background}};
  display: flex;
  width: 143px;
  height: ${spacing.s11};  
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  font-family: 'BT-Curve', sans-serif;
  font-size: ${spacing.s4};
  font-style: normal;
  font-weight: 500;
  line-height: ${spacing.s6};
  color: ${colors.base.white};

  @media ${device.tablet} {
    width: 164px;
    height: ${spacing.s14};
  
  }
`;

export const RisksCardsListItem = styled.li<{ background: string }>`
  display: flex;
  background: ${({ background }) => background}};
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-family: 'BT-Curve', sans-serif;
  font-size: ${spacing.s4};
  font-style: normal;
  font-weight: 500;
  line-height: ${spacing.s6}
`;

export const MillimetersOfMercury = styled.span`
  color: ${colors.secondaryBlue.blue04};
  font-family: 'BT-Curve', sans-serif;
  font-size: ${spacing.s6};
  font-style: normal;
  font-weight: 500;
  line-height: ${spacing.s9};
  text-decoration: underline;
  underline-offset: ${spacing.s1};
`;

export const Description = styled.h4`
  position: relative;

  color: ${colors.base.white};
  font-family: 'BT-Curve', sans-serif;
  font-size: ${spacing.s5};
  font-style: normal;
  font-weight: 500;
  ${fontCalcMixin(fontSizes.h3)};

  @media ${device.tablet} {
    font-size: ${spacing.s6};
    line-height: ${spacing.s9};
  }
`;

export const RisksCardsHeader = styled.p`
  color: ${colors.base.white};
  font-family: 'BT-Curve', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: ${spacing.s6};
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;
