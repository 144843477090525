import * as R from 'ramda';
import { FormattedMessage } from 'react-intl';
import {
  StyledColumn,
  StyledGrid,
  HeaderRow,
  StyledRow,
  RangeIndicator,
  RangeWrapper,
  StyledText,
} from './styles';
import { Props } from './types';
import { componentName, indicators } from './intl';
import { isKeyOf } from '../../utils';
import { formatRangeBound } from './formatter';

export const ObservationRatingsTable = ({ data }: Props) => {
  const componentsAmount = data[0].ranges.length;
  const rowAmount = 1 + data.length;
  const categoryGridColumnNumber = 1;
  const componentGridColumnNumber = (idx: number) => idx + 2;
  return (
    <StyledGrid componentsNumber={componentsAmount} rowAmount={rowAmount}>
      <HeaderRow componentsAmount={componentsAmount}>
        <StyledColumn gridColumn={categoryGridColumnNumber}>
          <StyledText>
            <FormattedMessage
              id="observation-ratings.col-headers.category"
              defaultMessage="Category"
            />
          </StyledText>
        </StyledColumn>
        {R.uniq(
          data.flatMap((rating) => rating.ranges.map(({ type }) => type))
        ).map((name, idx) => (
          <StyledColumn
            gridColumn={componentGridColumnNumber(idx)}
            key={`${name}-header`}
          >
            <StyledText>
              {isKeyOf(componentName, name) ? (
                <FormattedMessage {...componentName[name]} />
              ) : (
                name
              )}
            </StyledText>
          </StyledColumn>
        ))}
      </HeaderRow>
      {[...data]
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(({ lightColourTheme, displayName, ranges }, index) => {
          const isLowestBand = index === data.length - 1;
          const isHighestBand = index === 0;
          return (
            <StyledRow
              componentsAmount={componentsAmount}
              levelColors={lightColourTheme}
              key={displayName}
            >
              <StyledColumn gridColumn={categoryGridColumnNumber}>
                <RangeWrapper>
                  <StyledText>{displayName}</StyledText>
                </RangeWrapper>
              </StyledColumn>
              {ranges.map(({ type, minDecimal, maxDecimal, step }, idx) => (
                <StyledColumn
                  gridColumn={componentGridColumnNumber(idx)}
                  key={`${type}-${minDecimal}-${maxDecimal}`}
                >
                  <StyledText levelColors={lightColourTheme}>
                    <RangeWrapper>
                      {isLowestBand && (
                        <RangeIndicator>
                          <FormattedMessage {...indicators.less} />
                        </RangeIndicator>
                      )}
                      {isHighestBand && (
                        <RangeIndicator>
                          <FormattedMessage {...indicators.more} />
                        </RangeIndicator>
                      )}
                      <span>
                        {!isLowestBand &&
                          `${formatRangeBound(
                            isHighestBand ? minDecimal - step : minDecimal,
                            step
                          )}`}
                        {isLowestBand || isHighestBand ? '' : '-'}
                        {!isHighestBand &&
                          maxDecimal &&
                          `${formatRangeBound(
                            !isLowestBand ? maxDecimal - step : maxDecimal,
                            step
                          )}`}
                      </span>
                    </RangeWrapper>
                  </StyledText>
                </StyledColumn>
              ))}
            </StyledRow>
          );
        })}
    </StyledGrid>
  );
};
