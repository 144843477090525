import { useAuth0 } from '@auth0/auth0-react';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { useGetUserPatientProfileQuery } from '../services/graphql';

export const useAuthUserProfile = ({
  fetchPolicy = 'cache-first',
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
} = {}) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { data, error, loading, refetch } = useGetUserPatientProfileQuery({
    skip: !isAuthenticated || !user,
    fetchPolicy: fetchPolicy || 'cache-first',
  });

  const userProfile =
    data?.userPatientProfileResults.__typename === 'UserPatientProfile'
      ? data.userPatientProfileResults
      : undefined;
  const nonCompliantProfile =
    data?.userPatientProfileResults.__typename === 'ProfileNonCompliant'
      ? data.userPatientProfileResults
      : undefined;

  return {
    data,
    userProfile,
    nonCompliantProfile,
    loading: isLoading || loading,
    error,
    refetch,
  };
};
