import * as Sentry from '@sentry/react';
import { Button, ErrorMessage, Stack } from '@bt-healthcare/ui-toolkit';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useApolloClient } from '@apollo/client';

import { encodeMailtoURL } from '../../formatters/mailto';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';

import { ApolloErrorContext } from './context';
import type { ApolloError } from './types';
import { support } from './utils';
import { QueryErrorMessage } from '../QueryErrorMessage';
import { QueryErrorMessageProps } from '../QueryErrorMessage/types';

export const ApolloErrorBoundary = ({
  children,
  page,
}: PropsWithChildren<{
  page?: Omit<QueryErrorMessageProps, 'onTryAgain'> | true;
}>): React.ReactElement => {
  const [error, setError] = useState<ApolloError | null>(null);
  const { pushHandler, popHandler } = useContext(ApolloErrorContext) || {};
  useEffect(() => {
    pushHandler?.(setError);

    return () => {
      popHandler?.();
    };
  }, [pushHandler, popHandler]);
  const { userProfile: data } = useAuthUserProfile();
  const client = useApolloClient();

  const isConflictError =
    (error?.graphQLErrors?.[0]?.extensions?.response as Response)?.status ===
    409;

  if (error && !isConflictError) {
    if (page && error.operation) {
      const onTryAgain = async () => {
        try {
          await client.query(error.operation);
        } catch (e: any) {
          console.error(e);
        }

        setError(null);
      };

      return (
        <QueryErrorMessage
          {...(page === true ? undefined : page)}
          onTryAgain={onTryAgain}
        />
      );
    }

    return (
      <ErrorMessage>
        <Stack id="buttons" space="s4">
          <a href="/">
            <Button id="error.go-home" variant="primary">
              <FormattedMessage
                id="error.message.button.home"
                defaultMessage="Let's take you home"
              />
            </Button>
          </a>
          <a
            href={encodeMailtoURL(
              support({
                date: error?.date || new Date(),
                careSetting: data?.careSetting.attributes.name,
                sentryId: Sentry.lastEventId(),
              })
            )}
          >
            <Button id="error.contact-us" variant="secondary">
              <FormattedMessage
                id="error.message.button.contact-us"
                defaultMessage="Contact us"
              />
            </Button>
          </a>
        </Stack>
      </ErrorMessage>
    );
  }
  return children as React.ReactElement;
};
