import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { colors, Text, Stack } from '@bt-healthcare/ui-toolkit';

import { ReactComponent as ErrorSVG } from '../../assets/error.svg';
import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { ROUTE } from '../../config/routes';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';
import { SUPPORT_EMAIL } from '../../App.constants';

export const OnboardingError = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onTryAgain = () => {
    navigate(ROUTE.ONBOARDING_EMAIL_ADDRESS);
  };

  return (
    <>
      <Navbar />
      <GridPageCenteredSection>
        <PublicContentCard
          Hero={<ErrorSVG width="100%" height="100%" />}
          HeaderText={
            <FormattedMessage
              id="onboarding-error.header"
              defaultMessage={location.state?.title}
            />
          }
          TextDescription={[
            <Stack space="none" id="get-in-touch-wrapper" textAlign="center">
              <Text color={colors.grey.grey08}>
                <FormattedMessage
                  id="onboarding-error.text-description"
                  defaultMessage="Tap the button below to try again. If you keep seeing this error, contact us for help:"
                />
              </Text>
              <Link to={`mailto:${SUPPORT_EMAIL}`}>
                <Text color={colors.primaryIndigo.indigo08}>
                  {SUPPORT_EMAIL}
                </Text>
              </Link>
            </Stack>,
          ]}
          ctaVariant="primary"
          CtaText={
            <FormattedMessage
              id="onboarding-error.resend-text"
              defaultMessage="Try again"
            />
          }
          ctaAction={onTryAgain}
        />
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </>
  );
};
