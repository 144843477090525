import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const GetInTouchContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: ${spacing.s6};

  @media ${device.desktop} {
    flex-direction: row;
    gap: ${spacing.s1};
    margin-top: ${spacing.s15};
  }
`;
