import { ROUTE } from './config/routes';

export const EMPTY_VALUE = '--';

export const PRIVACY_POLICY_URL = `/#${ROUTE.PRIVACY}`;

export const sideNavMainNavItems = [
  {
    label: 'Home',
    icon: 'Home',
    path: '/#',
  },
  {
    label: 'Account',
    icon: 'Person',
    path: `/#${ROUTE.ACCOUNT}`,
  },
  {
    label: 'History',
    icon: 'History',
    path: `/#${ROUTE.HISTORICAL_READINGS}`,
  },
] as const;

export const appName = 'HealthyYou';
export const basePageName = ` - ${appName}`;

export const TERMS_AND_CONDITIONS_VERSION = 'VERSION_1';
export const NOOP = () => {
  // This is intentional
};
export const onboardingErrorLinkExpired = 'Your activation link has expired';
export const onboardingErrorGeneral = 'Something went wrong';
export const USER_REGISTRATION_ID = 'hyp_encryptedId';
export const SUPPORT_EMAIL = 'support.healthyyou@bt.com';
export const REGISTRATION_EMAIL = 'registration@healthyyou.bt.com';
export const SUPPORT_PHONE_NO = '+447599719117';
export const WARD_PATIENT_ID = 'encrypted-wpid';
export const CARE_SETTING_NAME = 'csName';
export const STEP_UP_NOT_VERIFIED = 'stepUpNotVerified';

export const APP_STORE_LINKS = {
  iOS: 'https://apps.apple.com/us/app/healthyyou/id1644078401',
  android: 'https://play.google.com/store/apps/details?id=com.bt.healthyyou',
};
