import { defineMessage, defineMessages } from 'react-intl';
import { SmokingDummy } from './types';

export const healthScoreSmoking = {
  header: defineMessage({
    id: 'health-score-smoking.header',
    defaultMessage:
      'Do you smoke  <indigo08>cigarettes, tobacco or cigars?</indigo08>',
  }),
  explanationCard: defineMessage({
    id: 'health-score-smoking.explanation-card',
    defaultMessage:
      "We don't currently include vaping due to limited medical research on long-term health effects.",
  }),
  smokingOptions: defineMessages({
    [SmokingDummy.YES]: {
      id: 'health-score-smoking.options.yes',
      defaultMessage: 'Yes, I currently smoke',
    },
    [SmokingDummy.NEVER_SMOKED]: {
      id: 'health-score-smoking.options.never',
      defaultMessage: 'No, I’ve never smoked',
    },
    [SmokingDummy.QUIT_OVER_20]: {
      id: 'health-score-smoking.options.over-20',
      defaultMessage: 'No, I quit over 20 years ago',
    },
    [SmokingDummy.QUIT_OVER_15]: {
      id: 'health-score-smoking.options.over-15',
      defaultMessage: 'No, I quit over 15 years ago',
    },
    [SmokingDummy.QUIT_OVER_10]: {
      id: 'health-score-smoking.options.over-10',
      defaultMessage: 'No, I quit over 10 years ago',
    },
    [SmokingDummy.QUIT_OVER_5]: {
      id: 'health-score-smoking.options.over-5',
      defaultMessage: 'No, I quit over 5 years ago',
    },
    [SmokingDummy.QUIT_LESS_5]: {
      id: 'health-score-smoking.options.less-5',
      defaultMessage: 'No, I quit less than 5 years ago',
    },
  }),
};
