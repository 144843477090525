import { NextLink, Operation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import { StatusCodes } from 'http-status-codes';
import { HTTP_HEADER } from '../../../services/getHttpHeaders';
import { ErrorCode } from '../../../services/graphql';
import { createURL } from '../../../utils';
import { ROUTE } from '../../../config/routes';
import { ApolloError } from '../../ApolloErrorBoundary/types';
import { obfuscateExceptionMessage } from '../../../services/sentry';

const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';

const handleNetworkError = (
  networkError: NetworkError,
  operation: Operation
) => {
  const correlation =
    operation.getContext().headers?.[HTTP_HEADER.apigwTracking];
  Sentry.captureException(networkError, {
    tags: {
      type: 'network',
      'x-correlation-id': correlation,
    },
  });
};

export const handleGraphQLErrors = (
  graphQLErrors: GraphQLErrors,
  operation: Operation,
  forward: NextLink,
  logout: (returnTo?: string) => void
  // eslint-disable-next-line consistent-return
) => {
  const isRetryException = graphQLErrors.some(
    (err) =>
      err.extensions?.code === INTERNAL_SERVER_ERROR &&
      (err.message.includes('ECONNRESET') ||
        err.extensions?.status === StatusCodes.SERVICE_UNAVAILABLE)
  );

  const graphQLErrorsObfuscated = graphQLErrors.map((error) => {
    const exception = error;
    if (exception.message) {
      exception.message = obfuscateExceptionMessage(exception.message);
    }

    return exception;
  });

  const correlation =
    operation.getContext().headers?.[HTTP_HEADER.apigwTracking];
  Sentry.captureMessage(`[GQL]${operation.operationName}`, {
    extra: { errors: JSON.stringify(graphQLErrorsObfuscated) },
    tags: {
      type: 'graphql',
      'x-correlation-id': correlation,
    },
  });

  const isPatientDischarged = graphQLErrors.some(
    (err) => err.extensions?.code === ErrorCode.PatientDischarged
  );

  if (isPatientDischarged) {
    logout(createURL(ROUTE.DISCHARGED));
    return;
  }

  if (isRetryException) {
    forward(operation);
  }
};

export const errorLink = ({
  handleError,
  logout,
}: {
  handleError: (arg0: ApolloError) => void;
  logout: (returnTo?: string) => void;
}) =>
  onError((response) => {
    const { graphQLErrors, networkError, operation, forward } = response;
    handleError({ ...response, date: new Date() });
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError?.statusCode === StatusCodes.UNAUTHORIZED
    ) {
      logout();
    } else if (networkError) {
      handleNetworkError(networkError, operation);
    } else if (graphQLErrors) {
      handleGraphQLErrors(graphQLErrors, operation, forward, logout);
    }
  });
