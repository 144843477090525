import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';

export const AccordionWrapper = styled.div`
  line-height: ${spacing.s5};
  font-size: ${fontSizes.sm};
  @media ${device.tablet} {
    font-size: ${fontSizes.base};
    line-height: ${spacing.s6};
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.s2};
  cursor: pointer;
`;
export const HeaderTitle = styled.p<{ open: boolean }>`
  color: ${({ open }) =>
    open ? colors.primaryIndigo.indigo08 : colors.grey.grey10};
  font-weight: ${({ open }) => (open ? 500 : 400)};
`;

export const Text = styled.p`
  margin-top: ${spacing.s4};
  white-space: pre-line;
`;
