import { useIntl } from 'react-intl';
import { BlueCard } from '../BlueCard';
import BaseCard from '../BaseCard';
import { BrightIcon, BrightWrapper } from '../RecommendationLinkCard/styles';
import { RatingBadge } from '../../RatingBadge';
import { ObservationType } from '../../../services/graphql';
import { DateLabel, ValueLabel } from './styles';
import { formatDateAndHourToString } from '../../../formatters/date';
import { formatNumber } from '../../../formatters/observations';
import { RecommendationHistoricalListAverageCardProps } from './types';

export const RecommendationHistoricalListBPAverageCard = ({
  date,
  value,
}: RecommendationHistoricalListAverageCardProps) => {
  const intl = useIntl();
  const observationType = ObservationType.BloodPressure;
  if (value.__typename !== 'BloodPressure') return null;
  const { systolic, diastolic, rating } = value;
  return (
    <BlueCard
      id="recommendation-historical-observations-average-card"
      data-testid="recommendation-historical-observations-average-card"
    >
      <BaseCard.ItemLeft flexDirection="row" gap={16}>
        <DateLabel>{formatDateAndHourToString(new Date(date))}</DateLabel>
        <ValueLabel>
          {formatNumber({
            intl,
            values: { systolic, diastolic },
            type: observationType,
          })}
        </ValueLabel>
        <BrightWrapper>
          <BrightIcon />
        </BrightWrapper>
      </BaseCard.ItemLeft>
      <BaseCard.ItemRight>
        <RatingBadge type={observationType} patientRating={rating} />
      </BaseCard.ItemRight>
    </BlueCard>
  );
};
