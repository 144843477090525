export const recommendationObservationList = {
  header: {
    id: 'recommendation-observation-list.header',
    defaultMessage: 'Avg. blood pressure readings',
  },
  errorTitle: {
    id: 'recommendation-observation-list.error-title',
    defaultMessage: 'We’re unable to load your readings',
  },
};
