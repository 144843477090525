import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { HashRouter as Router } from 'react-router-dom';
import './wdyr';

import {
  ErrorBoundary,
  GlobalStyles,
  lightTheme,
} from '@bt-healthcare/ui-toolkit';
import { ThemeProvider } from 'styled-components';

import { getEnvVariable } from './env.utils';

import { AuthApolloProvider } from './components/AuthApolloProvider';
import { AuthProvider } from './components/AuthProvider';
import { defaultRichElements } from './components/Typography/RTF';
import { initializeAnalytics } from './services/analytics';
import { initializeSentry } from './services/sentry';

import App from './App';
import {
  ApolloErrorBoundary,
  ApolloErrorProvider,
} from './components/ApolloErrorBoundary';
import { SmartAppBanner } from './components/SmartAppBanner';
import { ThirdPartyUserIdentifier } from './components/ThirdPartyUserIdentifier';
import { AppProvider } from './context/AppProvider';
import { OutageMonitor } from './components/OutageMonitor';

if (
  import.meta.env.NODE_ENV === 'development' &&
  getEnvVariable('VITE_ENV') === 'mock'
) {
  /* eslint-disable global-require */
  const { worker } = require('./mocks/browser');
  /* eslint-enable global-require */
  worker.start();
}

initializeSentry();
initializeAnalytics();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <IntlProvider
    locale="en"
    defaultLocale="en"
    defaultRichTextElements={defaultRichElements}
  >
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <ErrorBoundary>
        <StrictMode>
          <Router>
            <OutageMonitor>
              <ApolloErrorProvider>
                <AuthProvider>
                  <AuthApolloProvider>
                    <ApolloErrorBoundary>
                      <AppProvider>
                        <FlagsmithProvider
                          options={{
                            environmentID:
                              getEnvVariable('VITE_FLAGSMITH_ENV_KEY') ?? '',
                          }}
                          flagsmith={flagsmith}
                        >
                          <ThirdPartyUserIdentifier>
                            <SmartAppBanner />
                            <App />
                          </ThirdPartyUserIdentifier>
                        </FlagsmithProvider>
                      </AppProvider>
                    </ApolloErrorBoundary>
                  </AuthApolloProvider>
                </AuthProvider>
              </ApolloErrorProvider>
            </OutageMonitor>
          </Router>
        </StrictMode>
      </ErrorBoundary>
    </ThemeProvider>
  </IntlProvider>
);
