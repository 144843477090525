import { ref, string } from 'yup';
import { PasswordValidatorRequirement } from './types';

export const PASSWORD_REQUIREMENT_MINIMUM_CHARACTERS = {
  id: 'minimum-8-characters',
  text: 'Minimum 8 characters',
  regex: /^.{8,}$/,
} as PasswordValidatorRequirement;

export const PASSWORD_REQUIREMENT_UPPERCASE_CHARACTER = {
  id: 'uppercase-character',
  text: 'An uppercase letter',
  regex: /[A-Z]/,
} as PasswordValidatorRequirement;

export const PASSWORD_REQUIREMENT_LOWERCASE_CHARACTER = {
  id: 'lowercase-character',
  text: 'A lowercase letter',
  regex: /[a-z]/,
} as PasswordValidatorRequirement;

export const PASSWORD_REQUIREMENT_NUMBER_CHARACTER = {
  id: 'number',
  text: 'A number',
  regex: /\d/,
} as PasswordValidatorRequirement;

export const convertRequirementsToSchema = (
  requirements: PasswordValidatorRequirement[]
) => {
  let passwordSchema = string().label('Password').required(' ');

  requirements.forEach((requirement) => {
    passwordSchema = passwordSchema.matches(requirement.regex, ' ');
  });

  return {
    password: passwordSchema,
    confirmPassword: string()
      .label('Confirm password')
      .required()
      .oneOf([ref('password'), null], 'Both passwords must match'),
  };
};
