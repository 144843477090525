import { defineMessages } from 'react-intl';

export const collateralLandingHeader = defineMessages({
  title: {
    defaultMessage: 'HealthyYou',
    id: 'collateralLanding.header.title',
  },
  titleSpan: {
    defaultMessage: 'remote care service',
    id: 'collateralLanding.header.title-span',
  },
  subTitle: {
    defaultMessage:
      'Track your vitals in real time while getting personalised, clinical advice to support a healthier lifestyle.',
    id: 'collateralLanding.header.sub-title',
  },
});
