import { device } from '@bt-healthcare/ui-toolkit';

// TODO: remove this util (maxMobileWidth) and use the one from the ui-toolkit as soon as it's available.
export const maxMobileWidth = `(max-width: ${device.largeMobile.replace(
  /^\D+/g,
  ''
)}`;

export const createURL = (path: string) => `${window.location.origin}/#${path}`;

export const maxTabletWidth = `(max-width: ${device.tablet.replace(
  /^\D+/g,
  ''
)}`;

export const identifyRoute = (route: string) =>
  window.location.href.includes(route);

// TODO: use the URLSearchParams or useSearchParams from 'react-router-dom' in getEncryptedId
export const getEncryptedId = () =>
  window.location.href.split('userRegistrationId=')[1];

export const isKeyOf = <T extends object>(
  obj: T,
  key: PropertyKey
): key is keyof T => key in obj;

export const getAndroidOrIOS = () => {
  const {userAgent} = navigator;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  return 'iOS';
};
