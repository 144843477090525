import { defineMessages } from 'react-intl';
import {
  BodyWeightUnitInput,
  CholesterolUnit,
  ObservationType,
} from '../services/graphql';
import { Messages } from '../i18n/messages/types';

export const messagesWithoutUnit = defineMessages({
  [ObservationType.BloodPressure]: {
    id: 'reading.value.without-unit.bloodpressure',
    defaultMessage: '{systolic}/{diastolic}',
  },
});
export const messages: Messages = defineMessages({
  [ObservationType.Pulse]: {
    id: 'reading.value.pulse',
    defaultMessage: '{value} bpm',
  },
  [ObservationType.Spo2]: {
    id: 'reading.value.spo2',
    defaultMessage: '{value}%',
  },
  [ObservationType.BloodPressure]: {
    id: 'reading.value.bloodpressure',
    defaultMessage: '{systolic}/{diastolic} mmHg',
  },
  [ObservationType.FastingBloodGlucose]: {
    id: 'reading.value.fasting-blood-glucose',
    defaultMessage: '{value} mmol/L',
  },
  [`${ObservationType.Weight}-${BodyWeightUnitInput.Kg}`]: {
    id: 'reading.value.weight.kg',
    defaultMessage: '{kg} kg',
  },
  [`${ObservationType.Weight}-${BodyWeightUnitInput.Lb}`]: {
    id: 'reading.value.weight.lb',
    defaultMessage: '{st}st {lb}lbs',
  },
  [`${ObservationType.Cholesterol}-${CholesterolUnit.MilligramPerDeciliter}`]: {
    id: 'reading.value.cholesterol.mg',
    defaultMessage: '{value} mg/dL',
  },
  [`${ObservationType.Cholesterol}-${CholesterolUnit.MillimolePerLiter}`]: {
    id: 'reading.value.cholesterol.mmol',
    defaultMessage: '{value} mmol/L',
  },
});

export const readingNames: Messages = defineMessages({
  [ObservationType.Pulse]: {
    id: 'reading.reading-name.pulse',
    defaultMessage: 'Pulse',
  },
  [ObservationType.Spo2]: {
    id: 'reading.reading-name.spo2',
    defaultMessage: 'SpO2',
  },
  [ObservationType.BloodPressure]: {
    id: 'reading.reading-name.bloodpressure',
    defaultMessage: 'Blood pressure',
  },
  [ObservationType.Weight]: {
    id: 'reading.reading-name.weight',
    defaultMessage: 'Weight',
  },
  [`${ObservationType.Cholesterol}-total`]: {
    id: 'reading.reading-name.cholesterol.total',
    defaultMessage: 'Total cholesterol',
  },
  [`${ObservationType.Cholesterol}-hdl`]: {
    id: 'reading.reading-name.cholesterol.hdl',
    defaultMessage: 'HDL cholesterol',
  },
  [ObservationType.FastingBloodGlucose]: {
    id: 'reading.reading-name.fasting-blood-glucose',
    defaultMessage: 'Fasting blood glucose',
  },
});
