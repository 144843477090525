import {
  colors,
  fontCalcMixin,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const CommonlyAskedQuestionContainer = styled.div`
  border-top: 1px solid ${colors.grey.grey04};
  padding-top: ${spacing.s4};
`;

export const CommonlyAskedQuestionTitle = styled.p`
  font-size: 20px;
  line-height: 30px;
`;

export const CommonlyAskedQuestionText = styled.p`
  ${fontCalcMixin(fontSizes.base)};
`;
