import { boolean, object } from 'yup';

export const schema = object().shape({
  agreeTerms: boolean().required().isTrue(),
  agreeShareData: boolean().required().isTrue(),
});

export type TermsAndConditionsFormValues = {
  agreeTerms: boolean;
  agreeShareData: boolean;
};
