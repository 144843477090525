import { Vo2TableVariants } from './types';

export const vo2RangesData = {
  [Vo2TableVariants.MEN]: [
    {
      minAge: 20,
      maxAge: 29,
      categories: {
        poor: {
          min: 0,
          max: 29.4,
        },
        fair: {
          min: 29.5,
          max: 37.7,
        },
        good: {
          min: 37.8,
          max: 42.3,
        },
        excellent: {
          min: 42.4,
          max: 46.7,
        },
        superior: {
          min: 46.8,
          max: null,
        },
      },
    },
    {
      minAge: 30,
      maxAge: 39,
      categories: {
        poor: {
          min: 0,
          max: 27.9,
        },
        fair: {
          min: 28.0,
          max: 36.6,
        },
        good: {
          min: 36.7,
          max: 40.9,
        },
        excellent: {
          min: 41.0,
          max: 54.2,
        },
        superior: {
          min: 45.3,
          max: null,
        },
      },
    },
    {
      minAge: 40,
      maxAge: 49,
      categories: {
        poor: {
          min: 0,
          max: 26.5,
        },
        fair: {
          min: 26.6,
          max: 34.4,
        },
        good: {
          min: 34.5,
          max: 38.5,
        },
        excellent: {
          min: 38.6,
          max: 43.0,
        },
        superior: {
          min: 43.1,
          max: null,
        },
      },
    },
    {
      minAge: 50,
      maxAge: 59,
      categories: {
        poor: {
          min: 0,
          max: 24.5,
        },
        fair: {
          min: 24.6,
          max: 31.3,
        },
        good: {
          min: 31.4,
          max: 35.1,
        },
        excellent: {
          min: 35.2,
          max: 38.7,
        },
        superior: {
          min: 38.8,
          max: null,
        },
      },
    },
    {
      minAge: 60,
      maxAge: 69,
      categories: {
        poor: {
          min: 0,
          max: 22.9,
        },
        fair: {
          min: 23.0,
          max: 28.7,
        },
        good: {
          min: 28.8,
          max: 32.2,
        },
        excellent: {
          min: 32.3,
          max: 35.8,
        },
        superior: {
          min: 35.9,
          max: null,
        },
      },
    },
    {
      minAge: 70,
      maxAge: 79,
      categories: {
        poor: {
          min: 0,
          max: 21.4,
        },
        fair: {
          min: 21.5,
          max: 27.5,
        },
        good: {
          min: 27.6,
          max: 29.7,
        },
        excellent: {
          min: 29.8,
          max: 32.4,
        },
        superior: {
          min: 32.5,
          max: null,
        },
      },
    },
  ],
  [Vo2TableVariants.WOMEN]: [
    {
      minAge: 20,
      maxAge: 29,
      categories: {
        poor: {
          min: 0,
          max: 34.6,
        },
        fair: {
          min: 34.7,
          max: 43.8,
        },
        good: {
          min: 43.9,
          max: 48.4,
        },
        excellent: {
          min: 48.5,
          max: 53.9,
        },
        superior: {
          min: 54.0,
          max: null,
        },
      },
    },
    {
      minAge: 30,
      maxAge: 39,
      categories: {
        poor: {
          min: 0,
          max: 33.7,
        },
        fair: {
          min: 33.8,
          max: 42.3,
        },
        good: {
          min: 42.4,
          max: 46.9,
        },
        excellent: {
          min: 47.0,
          max: 51.6,
        },
        superior: {
          min: 51.7,
          max: null,
        },
      },
    },
    {
      minAge: 40,
      maxAge: 49,
      categories: {
        poor: {
          min: 0,
          max: 32.2,
        },
        fair: {
          min: 32.3,
          max: 40.0,
        },
        good: {
          min: 40.1,
          max: 44.8,
        },
        excellent: {
          min: 44.9,
          max: 49.5,
        },
        superior: {
          min: 49.6,
          max: null,
        },
      },
    },
    {
      minAge: 50,
      maxAge: 59,
      categories: {
        poor: {
          min: 0,
          max: 29.3,
        },
        fair: {
          min: 29.4,
          max: 37.0,
        },
        good: {
          min: 37.1,
          max: 41.7,
        },
        excellent: {
          min: 41.8,
          max: 46.7,
        },
        superior: {
          min: 46.8,
          max: null,
        },
      },
    },
    {
      minAge: 60,
      maxAge: 69,
      categories: {
        poor: {
          min: 0,
          max: 25.5,
        },
        fair: {
          min: 25.6,
          max: 33.7,
        },
        good: {
          min: 33.8,
          max: 38.2,
        },
        excellent: {
          min: 38.3,
          max: 42.6,
        },
        superior: {
          min: 42.7,
          max: null,
        },
      },
    },
    {
      minAge: 70,
      maxAge: 79,
      categories: {
        poor: {
          min: 0,
          max: 22.9,
        },
        fair: {
          min: 23.0,
          max: 30.8,
        },
        good: {
          min: 30.9,
          max: 35.1,
        },
        excellent: {
          min: 35.2,
          max: 39.4,
        },
        superior: {
          min: 39.5,
          max: null,
        },
      },
    },
  ],
};
