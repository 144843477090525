import { defineMessages } from 'react-intl';

export const publicContentModal = defineMessages({
  header: {
    id: 'public-content-modal.header',
    defaultMessage: 'Are you sure you want to unsubscribe?',
  },
  textDescription: {
    id: 'public-content-modal.text-description',
    defaultMessage:
      'Choosing to unsubscribe will mean you will not receive communications such as appointment or vital reading reminders.',
  },
  error: {
    id: 'public-content-modal.error',
    defaultMessage:
      'There was a problem updating your preferences. Please check your internet connection or try again later.',
  },
  primaryCTA: {
    id: 'public-content-modal.primary-cta',
    defaultMessage: 'Back to preferences',
  },
  secondaryCTA: {
    id: 'public-content-modal.secondary-cta',
    defaultMessage: 'Yes, unsubscribe',
  },
});

export const deleteMyAccountModal = defineMessages({
  header: {
    id: 'delete-my-account-modal.header',
    defaultMessage: 'Are you sure you want to delete your account?',
  },
  textDescription: {
    id: 'delete-my-account-modal.text-description',
    defaultMessage:
      'If you choose yes, you will lose access to your account and all of your health data',
  },
  error: {
    id: 'delete-my-account-modal.error',
    defaultMessage:
      'There was a problem updating your preferences. Please check your internet connection or try again later.',
  },
  primaryCTA: {
    id: 'delete-my-account-modal.primary-cta',
    defaultMessage: 'No, go back',
  },
  secondaryCTA: {
    id: 'delete-my-account-modal.secondary-cta',
    defaultMessage: 'Yes, delete my account',
  },
});

export const unlinkGPModal = defineMessages({
  header: {
    id: 'unlink-gp-modal.header',
    defaultMessage: 'Are you sure you want to unlink your GP practice?',
  },
  textDescription: {
    id: 'unlink-gp-modal.text-description',
    defaultMessage:
      'You will lose all access to GP services if you choose to unlink your practice. You will also need to re-link your practice in the future to access services.',
  },
  notificationWarning: {
    id: 'unlink-gp-modal.text-warning',
    defaultMessage:
      'Please note, unlinking your practice will not cancel any existing appointments.',
  },
  checkboxLabel: {
    id: 'unlink-gp-modal.checkbox',
    defaultMessage: 'I understand the above',
  },
  error: {
    id: 'unlink-gp-modal.error',
    defaultMessage:
      'There was a problem unlinking your GP. Please check your internet connection or try again later.',
  },
  primaryCTA: {
    id: 'unlink-gp-modal.secondary-cta',
    defaultMessage: 'Continue and unlink',
  },
  secondaryCTA: {
    id: 'unlink-gp-modal.primary-cta',
    defaultMessage: 'Go back',
  },
  spinnerModal: {
    id: 'unlink-gp-modal.spinner-modal-text',
    defaultMessage: 'Unlinking your practice',
  },
});
