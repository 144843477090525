import type { IntlShape } from 'react-intl';
import { object, string } from 'yup';
import { WardPatientProgrammeType } from '../../services/graphql';
import { recommendationConfirmYourPlan } from './intl';

export const confirmYourPlanForm = (intl: IntlShape) => ({
  fields: [
    {
      name: 'WardPatientProgrammeType',
      validationRule: object().shape({
        value: string()
          .oneOf(Object.values(WardPatientProgrammeType))
          .required(),
        label: string(),
        helperLabel: string(),
        id: string(),
      }),
      options: [
        [4, 2, WardPatientProgrammeType.StandaloneHypertension_4Day],
        [8, 1, WardPatientProgrammeType.StandaloneHypertension_8Day],
      ].map(
        ([days, readings, value]): {
          label: string;
          helperLabel: string;
          value: string;
          id: string;
        } => ({
          label: intl.formatMessage(
            recommendationConfirmYourPlan.radioButtonGroup.options.label,
            { days }
          ),
          helperLabel: intl.formatMessage(
            recommendationConfirmYourPlan.radioButtonGroup.options.helper,
            { readings }
          ),
          value: value.toString(),
          id: value.toString(),
        })
      ),
    },
  ],
});
