import { defineMessage } from 'react-intl';

export const recommendationSuccess = {
  title: defineMessage({
    id: 'recommendation.success.title',
    defaultMessage: 'Today marks the first day!',
  }),
  description: defineMessage({
    id: 'recommendation.success.description',
    defaultMessage:
      "You're good to go! Keep uploading your blood pressure readings to receive your personalised average and health recommendations.",
  }),
  button: defineMessage({
    id: 'recommendation.success.continue',
    defaultMessage: 'OK, I understand',
  }),
};
