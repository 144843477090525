import { assoc, clone, groupBy, isEmpty } from 'ramda';
import { formatDateToLocaleDateString } from '../../formatters/date';
import { GroupByDateType, SearchResultType } from './types';

export const groupByDate = (
  array: GroupByDateType[]
): Partial<Record<string, GroupByDateType[]>> =>
  groupBy((item) => item.displayDate, array);

const makeArrayOfObjects = (obj: Object) =>
  Object.entries(obj).map(([key, value]) => ({ [key]: value }));

const addDisplayDate = (
  dateObject: Date,
  observationRecord: SearchResultType
): SearchResultType & Record<'displayDate', string> =>
  assoc(
    'displayDate',
    formatDateToLocaleDateString(dateObject),
    clone(observationRecord)
  );

export const groupSearchResultsByDay = (
  observationsArray: SearchResultType[]
) => {
  if (!observationsArray || isEmpty(observationsArray)) return [];
  const array = clone(observationsArray);
  return makeArrayOfObjects(
    groupByDate(
      array.map((observationRecord) => {
        const date = new Date(observationRecord.recordedDateTime);
        return addDisplayDate(date, observationRecord);
      })
    )
  );
};
