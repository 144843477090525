import { FormattedMessage } from 'react-intl';
import { Stack, Header as HeaderText } from '@bt-healthcare/ui-toolkit';
import { ReactComponent as Hello } from '../../assets/home-hello.svg';

import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';

export const Header = () => {
  const { userProfile: data } = useAuthUserProfile();

  return (
    <Stack id="greeting" flexDirection="row" space="s6" alignItems="center">
      <HeaderText>
        <FormattedMessage
          id="dashboard.greeting.heading"
          defaultMessage={`Hello, ${data?.person?.attributes.personalInformation.firstName}`}
        />
      </HeaderText>
      <Hello />
    </Stack>
  );
};
