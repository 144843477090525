import { DOMAttributes } from 'react';
import { IntlShape } from 'react-intl';
import { BodyWeightUnitInput, ObservationType } from '../../services/graphql';
import * as yup from '../../utils/yup/extension';
import { CreateObservationData, CreateObservationDataWithUnits } from './types';
import {
  bloodPressureMessages,
  cholesterolMessages,
  fastingBloodGlucoseMessages,
  pulseMessages,
  spo2Messages,
  unitFieldLabel,
  weightMessages,
} from './intl';

export const disallowExponentialInputs = (): {
  onKeyDown: DOMAttributes<HTMLInputElement>['onKeyDown'];
} => ({
  onKeyDown: (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault(),
});

export const UNIT_SEARCH_PARAM_NAME = 'unit';

const spo2 = (intl: IntlShape): CreateObservationData => ({
  header: intl.formatMessage(spo2Messages.header),
  subtitle: intl.formatMessage(spo2Messages.subheader),
  fields: [
    {
      label: intl.formatMessage(spo2Messages.label),
      name: 'spo2',
      unitLabel: intl.formatMessage(unitFieldLabel.percent),
      validationRule: yup
        .number()
        .decimal({
          min: 1,
          max: 100,
          name: intl.formatMessage(spo2Messages.label),
        })
        .required(),
    },
  ],
});

const pulse = (intl: IntlShape): CreateObservationData => ({
  header: intl.formatMessage(pulseMessages.header),
  subtitle: intl.formatMessage(pulseMessages.subheader),
  fields: [
    {
      label: intl.formatMessage(pulseMessages.label),
      unitLabel: intl.formatMessage(unitFieldLabel.bpm),
      validationRule: yup
        .number()
        .decimal({
          min: 1,
          max: 300,
          name: intl.formatMessage(pulseMessages.label),
        })
        .required(),
      name: 'pulse',
    },
  ],
});

const bloodPressure = (intl: IntlShape): CreateObservationData => ({
  header: intl.formatMessage(bloodPressureMessages.header),
  fields: [
    {
      label: intl.formatMessage(bloodPressureMessages.systolicLabel),
      name: 'systolic',
      unitLabel: intl.formatMessage(unitFieldLabel.mmhg),
      validationRule: yup
        .number()
        .decimal({
          max: 250,
          min: 50,
          name: intl.formatMessage(bloodPressureMessages.systolicLabel),
        })
        .required()
        .validateWith({
          dependentFieldName: 'diastolic',
          validationCallback: ({ dependentFieldValue, fieldValue }) => {
            if (dependentFieldValue >= fieldValue) {
              return {
                status: 'error',
                errorMessage: intl.formatMessage(
                  bloodPressureMessages.systolicErrorMessage
                ),
              };
            }
            return { status: 'ok' };
          },
        }),
    },
    {
      label: intl.formatMessage(bloodPressureMessages.diastolicLabel),
      name: 'diastolic',
      unitLabel: intl.formatMessage(unitFieldLabel.mmhg),
      validationRule: yup
        .number()
        .decimal({
          max: 200,
          min: 30,
          name: intl.formatMessage(bloodPressureMessages.diastolicLabel),
        })
        .required()
        .validateWith({
          dependentFieldName: 'systolic',
          validationCallback: ({ dependentFieldValue, fieldValue }) => {
            if (dependentFieldValue <= fieldValue) {
              return {
                status: 'error',
                errorMessage: intl.formatMessage(
                  bloodPressureMessages.diastolicErrorMessage
                ),
              };
            }
            return { status: 'ok' };
          },
        }),
    },
    ...pulse(intl).fields,
  ],
});

const cholesterol = (intl: IntlShape): CreateObservationData => ({
  header: intl.formatMessage(cholesterolMessages.header),
  subtitle: intl.formatMessage(cholesterolMessages.subheader),
  fields: [
    {
      label: intl.formatMessage(cholesterolMessages.totalLabel),
      name: `totalMmol`,
      unitLabel: intl.formatMessage(unitFieldLabel.mmoll),
      validationRule: yup
        .number()
        .decimal({
          max: 17.5,
          min: 0.2,
          name: 'total',
          decimalDigits: 1,
        })
        .required(),
      tooltip: 'totalCholesterol',
      inputMode: 'decimal',
    },
    {
      label: intl.formatMessage(cholesterolMessages.hdlLabel),
      name: `hdlMmol`,
      unitLabel: intl.formatMessage(unitFieldLabel.mmoll),
      validationRule: yup
        .number()
        .decimal({
          max: 5.9,
          min: 0.1,
          name: 'hdl',
          decimalDigits: 1,
        })
        .required(),
      tooltip: 'hdlCholesterol',
      inputMode: 'decimal',
    },
  ],
});

export const weightValidation = {
  kg: yup
    .number()
    .decimal({
      min: 30,
      max: 200,
      name: 'kg',
      decimalDigits: 1,
    })
    .required(),
  st: yup
    .number()
    .decimal({
      min: 4,
      max: 31,
      name: 'st',
    })
    .required(),
  lb: yup
    .number()
    .decimal({
      min: 0,
      max: 13,
      name: 'lb',
    })
    .required(),
};

const weight = (intl: IntlShape): CreateObservationDataWithUnits => ({
  header: intl.formatMessage(weightMessages.header),
  subtitle: intl.formatMessage(weightMessages.subheader),
  unitSystems: [BodyWeightUnitInput.Lb, BodyWeightUnitInput.Kg],
  fields: [
    {
      label: intl.formatMessage(weightMessages.kilogramsLabel),
      validationRule: weightValidation.kg,
      name: 'kg',
      unitSystem: BodyWeightUnitInput.Kg,
      inputMode: 'decimal',
    },
    {
      label: intl.formatMessage(weightMessages.stoneLabel),
      validationRule: weightValidation.st,
      name: 'st',
      unitSystem: BodyWeightUnitInput.Lb,
    },
    {
      label: intl.formatMessage(weightMessages.poundLabel),
      validationRule: weightValidation.lb,
      name: 'lb',
      unitSystem: BodyWeightUnitInput.Lb,
    },
  ],
});

const fastingBloodGlucose = (intl: IntlShape): CreateObservationData => ({
  header: intl.formatMessage(fastingBloodGlucoseMessages.header),
  subtitle: intl.formatMessage(fastingBloodGlucoseMessages.subheader),
  fields: [
    {
      label: intl.formatMessage(fastingBloodGlucoseMessages.label),
      unitLabel: intl.formatMessage(unitFieldLabel.mmoll),
      validationRule: yup
        .number()
        .decimal({
          min: 0.7,
          max: 34,
          name: 'fasting blood glucose',
          decimalDigits: 1,
        })
        .required(),
      name: 'bloodGlucose',
      inputMode: 'decimal',
    },
  ],
});

export const getData = (
  type: ObservationType,
  intl: IntlShape
): CreateObservationData | CreateObservationDataWithUnits | null => {
  switch (type) {
    case ObservationType.Spo2:
      return spo2(intl);
    case ObservationType.Pulse:
      return pulse(intl);
    case ObservationType.BloodPressure:
      return bloodPressure(intl);
    case ObservationType.Weight:
      return weight(intl);
    case ObservationType.Cholesterol:
      return cholesterol(intl);
    case ObservationType.FastingBloodGlucose:
      return fastingBloodGlucose(intl);
    default:
      return null;
  }
};
