import {
  CvdFamilyInformation,
  DiabetesStatus,
  SmokingStatus,
} from '../../services/graphql';

export enum HealthInformationType {
  HEIGHT = 'HEIGHT',
  SMOKING = 'SMOKING',
  BLOOD_PRESSURE_MEDICATION = 'BLOOD_PRESSURE_MEDICATION',
  CHRONIC_KIDNEY_DISEASE = 'CHRONIC_KIDNEY_DISEASE',
  CARDIOVASCULAR_DISEASE = 'CARDIOVASCULAR_DISEASE',
  DIABETES = 'DIABETES',
}

export interface HeightForm {
  cm?: number;
  inch?: number;
  ft?: number;
}

interface SmokingForm {
  smokingStatus: {
    value: SmokingStatus;
    label: string;
  };
}

interface BloodPressureMedicationForm {
  hasBPMedication: string;
  hadBPMedication: string;
}

interface ChronicKidneyDiseaseForm {
  ckdStatus: string;
}

interface CardiovascularDiseaseForm {
  hasCvd: string;
  familyHadCvd: {
    value: CvdFamilyInformation;
    label: string;
  };
}

interface DiabetesForm {
  diabetesStatus: {
    value: DiabetesStatus;
    label: string;
  };
}

export type Form = HeightForm &
  SmokingForm &
  BloodPressureMedicationForm &
  ChronicKidneyDiseaseForm &
  CardiovascularDiseaseForm &
  DiabetesForm;
