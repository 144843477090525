import styled from 'styled-components';
import {
  BTHealthIcon,
  colors,
  device,
  fontCalcMixin,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { rgbaFromHexAndOpacity } from '../../utils/css';

export const ListDescription = styled(Text).attrs({
  fontSize: fontSizes.sm,
  fontWeight: 500,
  color: colors.secondaryBlue.blue02,
})`
  margin: ${spacing.s8} 0 ${spacing.s3} 0;
  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.base)}
  }
`;

export const Warning = styled(Text).attrs({
  fontSize: fontSizes.xs,
  color: rgbaFromHexAndOpacity(colors.base.white, 0.7),
})`
  margin: ${spacing.s4} 0 0 0;
  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.sm)}
  }
`;

export const ArrowIcon = styled(BTHealthIcon).attrs({
  icon: 'ArrowFat',
  size: 24,
  color: colors.secondaryBlue.blue02,
})``;

export const CheckIcon = styled(BTHealthIcon).attrs({
  icon: 'Check',
  size: 20,
  color: colors.base.white,
})``;
