import { FormattedMessage } from 'react-intl';
import { SimpleModalProps } from '../types';
import BaseModal from '../BaseModal';
import { confirmCloseHealthScoreModal } from './intl';

export const ConfirmCloseHealthScore = ({
  handleClose,
  modalOpen,
  handleSuccess,
}: SimpleModalProps) => (
  <BaseModal
    onCancel={handleClose}
    isModalOpen={modalOpen}
    id="confirm-health-score-close-modal"
    maxWidth="563px"
  >
    <BaseModal.Header>
      <FormattedMessage {...confirmCloseHealthScoreModal.header} />
    </BaseModal.Header>
    <BaseModal.Description>
      <FormattedMessage {...confirmCloseHealthScoreModal.description} />
    </BaseModal.Description>
    <BaseModal.ButtonsWrapper>
      <BaseModal.PrimaryRedButton
        id="recommendation-confirm-plan-error-modal.primary-button"
        onClick={handleSuccess}
      >
        <FormattedMessage {...confirmCloseHealthScoreModal.primaryButton} />
      </BaseModal.PrimaryRedButton>
      <BaseModal.SecondaryButton
        onClick={handleClose}
        id="recommendation-confirm-plan-error-modal.secondary-button"
      >
        <FormattedMessage {...confirmCloseHealthScoreModal.secondaryButton} />
      </BaseModal.SecondaryButton>
    </BaseModal.ButtonsWrapper>
  </BaseModal>
);
