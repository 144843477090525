import styled from 'styled-components';
import { colors, device, spacing } from '@bt-healthcare/ui-toolkit';

export const StyledCard = styled.div`
  background-color: ${colors.base.white};
  box-shadow: 0px 4px 10px 1px ${colors.grey.grey04};
  position: relative;
  height: auto;
  border-radius: 16px;
  padding-left: ${spacing.s6};
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 96px;

  @media ${device.tablet} {
    height: 168px;
  }
`;

export const PlusContainer = styled.div`
  border-radius: 4px;
  background: ${colors.secondaryBlue.blue01};

  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeroContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-top: ${spacing.s1};
  @media ${device.tablet} {
    padding-top: ${spacing.s4};
  }
`;
