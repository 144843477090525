import { colors } from '@bt-healthcare/ui-toolkit';

export const Bold = (chunks: React.ReactNode[]) => <strong>{chunks}</strong>;

export const Highlight = (chunks: React.ReactNode[]) => (
  <span style={{ color: colors.primaryIndigo.indigo08 }}>{chunks}</span>
);

export const Break = () => <br />;

export const Blue02 = (chunks: React.ReactNode[]) => (
  <span style={{ color: colors.secondaryBlue.blue02 }}>{chunks}</span>
);

export const Indigo02 = (chunks: React.ReactNode[]) => (
  <span style={{ color: colors.primaryIndigo.indigo02 }}>{chunks}</span>
);

export const Indigo08 = (chunks: React.ReactNode[]) => (
  <span style={{ color: colors.primaryIndigo.indigo08 }}>{chunks}</span>
);
export const defaultRichElements = {
  b: Bold,
  h: Highlight,
  br: Break,
  blue02: Blue02,
  indigo02: Indigo02,
  indigo08: Indigo08,
};
