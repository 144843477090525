import { useEffect, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import {
  BTHealthIcon,
  Button,
  Col,
  colors,
  Input,
  Row,
} from '@bt-healthcare/ui-toolkit';
import { submitButton, tooltipMessages } from './intl';
import { disallowExponentialInputs } from './data';
import { prepareSchema } from '../../utils/yup/schema';
import { CreateObservationFormRHF, CreateObservationsFormProps } from './types';
import {
  ButtonWrapper,
  GuideLinkContainer,
  InputsContainer,
  StyledForm,
} from './styles';

export const CreateObservationsForm = ({
  fields,
  onSubmit,
  initialValues,
  handleFieldChange,
  navigateToGuide,
}: CreateObservationsFormProps) => {
  const intl = useIntl();
  const schema = prepareSchema(fields);
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
    getValues,
  } = useForm<CreateObservationFormRHF>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    reset({});
  }, [fields]);

  const submit = handleSubmit((values) => {
    onSubmit(values);
  });

  const onChange = ({ target }: ChangeEvent<{ name: string }>) => {
    const key = target.name;
    const value = getValues(target.name);
    handleFieldChange(key, value);
  };

  return (
    <StyledForm>
      <Row>
        <Col colEnd={{ xxs: 'span 4', md: 'span 6', xl: 'span 5' }}>
          <InputsContainer>
            {fields.map(({ label, name, unitLabel, inputMode, tooltip }) => (
              <Input
                id={name}
                key={name}
                label={label}
                type="number"
                errorText={errors[name]?.message}
                inputMode={inputMode || 'numeric'}
                unit={unitLabel}
                tooltip={
                  tooltip
                    ? intl.formatMessage(tooltipMessages[tooltip])
                    : undefined
                }
                {...register(name, {
                  valueAsNumber: true,
                  onChange,
                })}
                {...disallowExponentialInputs()}
              />
            ))}
            {navigateToGuide && (
              <GuideLinkContainer onClick={navigateToGuide}>
                <BTHealthIcon
                  icon="CircleQuestion"
                  color={colors.secondaryBlue.blue08}
                />
                <span>How to take blood pressure for the best results</span>
              </GuideLinkContainer>
            )}
          </InputsContainer>
        </Col>
      </Row>
      <Row>
        <Col colEnd={{ xxs: 'span 4', md: 'span 6', xl: 'span 5' }}>
          <ButtonWrapper>
            <Button
              id="submit-reading-btn"
              variant="primary"
              size="maximum"
              onClick={submit}
              disabled={!isValid}
            >
              {intl.formatMessage(submitButton)}
            </Button>
          </ButtonWrapper>
        </Col>
      </Row>
    </StyledForm>
  );
};
