import styled from 'styled-components';
import { colors, fontSizes, Text } from '@bt-healthcare/ui-toolkit';
import { Link } from 'react-router-dom';
import BaseCard from '../BaseCard';

export const Title = styled(Text).attrs({
  color: colors.base.white,
  fontSize: fontSizes.sm,
})``;

export const StyledCard = styled(BaseCard).attrs({
  borderRadius: 8,
  borderColor: colors.base.white,
  background: 'transparent',
  isListCard: true,
})``;

export const StyledLink = styled(Link)`
  width: 100%;
`;
