import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Hr = styled.hr`
  height: 2px;
  width: 100%;
  background-color: ${colors.base.white};
  border: none;
`;

export const FrequentlyAskedQuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const AnchorTag = styled.a`
  color: ${colors.primaryIndigo.indigo08};
  font-family: 'BT-Curve', sans-serif;
  font-size: ${fontSizes.base};
  font-style: normal;
  font-weight: 400;
  line-height: ${spacing.s6};
  text-decoration-line: underline;
`;
