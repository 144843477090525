import { colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const InputRangeCardContainer = styled.div``;

export const CardTitle = styled.div``;

export const InputRangeCardIndicator = styled.div`
  border-radius: ${spacing.s1};
  width: ${spacing.s10};
  height: ${spacing.s8};
  background-color: ${colors.base.light};
  display: flex;
  justify-content: center;
  align-items: center;
`;
