import convert from 'convert';

const convertFeetToInches = (ft: number) =>
  Number(convert(ft, 'ft').to('in').toFixed(2));

const convertInchesToFeet = (inch: number) =>
  Number(convert(inch, 'in').to('ft').toFixed(2));

const fullFeetFromInches = (inch: number) =>
  Math.floor(convertInchesToFeet(inch));

export const getInchesFromFeetAndInches = ({
  ft,
  inch,
}: {
  ft: number;
  inch: number;
}) => inch + convertFeetToInches(ft);

export const getFullFeetAndInchesFromInches = ({
  inch,
}: {
  inch: number;
}): { inch: number; ft: number } => {
  const ft = fullFeetFromInches(inch);
  return { inch: inch - convertFeetToInches(ft), ft };
};
