import {
  Button,
  Card,
  Stack,
  Text,
  colors,
  fontSizes,
  SpinnerModal,
  noop,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReactComponent as GP } from '../../../assets/gp.svg';
import { ReactComponent as Chain } from '../../../assets/chain.svg';
import { useAuthUserProfile } from '../../../hooks/useAuthUserProfile';
import { Title } from '../InfoCard/styles';
import { ROUTE } from '../../../config/routes';
import { NewStatusChip } from '../../NewStatusChip';
import { useTracking } from '../../../hooks/useTracking';
import { UnlinkGPModal } from '../../Modal/UnlinkGP';
import { useUnlinkGeneralPracticePatientMutation } from '../../../services/graphql';
import { unlinkGPModal } from '../../Modal/intl';
import { UnlinkButtonContainer } from './styies';

export const GPInformationCard = () => {
  const { userProfile } = useAuthUserProfile();
  const { trackEvent } = useTracking();
  const navigate = useNavigate();

  const [unlinkGPModalOpen, setUnlinkGPModalOpen] = useState(false);

  const openUnlinkGPModal = () => {
    setUnlinkGPModalOpen(true);
  };
  const closeUnlinkGPModal = () => {
    setUnlinkGPModalOpen(false);
  };

  const [unlinkGeneralPracticePatient, { data, error, loading }] =
    useUnlinkGeneralPracticePatientMutation();

  const handleUnlink = () => {
    closeUnlinkGPModal();
    unlinkGeneralPracticePatient({
      variables: {
        userId: userProfile!.userId,
      },
    });
  };

  useEffect(() => {
    if (error) {
      openUnlinkGPModal();
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      navigate(ROUTE.GP_UNLINKAGE_SUCCESS);
    }
  }, [data]);

  const hasRegistration =
    !!userProfile?.person?.attributes?.generalPracticeRegistration?.odsCode;

  if (hasRegistration) {
    return (
      <>
        {loading && (
          <SpinnerModal
            text={unlinkGPModal.spinnerModal.defaultMessage}
            data-testid="unlink-gp-spinner"
            id="unlink-gp-spinner"
            isModalOpen
            color="primaryIndigo.indigo08"
            onClose={noop}
          />
        )}
        <UnlinkGPModal
          modalOpen={unlinkGPModalOpen}
          handleClose={closeUnlinkGPModal}
          handleUnlink={handleUnlink}
          error={error}
        />
        <Card id="your-gp" borderRadius={8}>
          <Stack
            id="your-gp-content"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              space="s2"
              id="your-gp-content-inner"
            >
              <GP />
              <Text
                color={colors.grey.grey10}
                fontSize={fontSizes.sm}
                fontWeight={500}
              >
                <FormattedMessage
                  id="gp-linkage-information.available"
                  defaultMessage="My GP Practice"
                />
              </Text>
              <Chain />
            </Stack>
            <UnlinkButtonContainer>
              <Button
                id="your-gp.unlink-button"
                variant="primaryRed"
                height="sm"
                size="auto"
                color={colors.notifications.light.errorDark}
                onClick={() => {
                  openUnlinkGPModal();
                  trackEvent('Unlink your practice', 'click');
                }}
              >
                <Text color={colors.base.white}>
                  <FormattedMessage
                    id="account-page.your-gp.unlink"
                    defaultMessage="Unlink"
                  />
                </Text>
              </Button>
            </UnlinkButtonContainer>
          </Stack>
        </Card>
      </>
    );
  }

  return (
    <Card id="your-gp" borderRadius={8}>
      <Stack space="s4" id="your-gp-content">
        <Stack flexDirection="row" space="s2" id="your-gp-header">
          <Text
            fontSize={fontSizes.base}
            color={colors.grey.grey10}
            fontWeight={500}
          >
            <FormattedMessage
              id="account-page.your-gp.header"
              defaultMessage="Link your GP practice"
            />
          </Text>
          <NewStatusChip />
        </Stack>
        <Title>
          <FormattedMessage
            id="account-page.your-gp.description"
            defaultMessage="Linking your practice allows you to access services from the HealthyYou app."
          />
        </Title>

        <Link to={ROUTE.GP_LINKAGE_GET_READY}>
          <Button
            id="your-gp.linkage"
            variant="secondary"
            height="sm"
            size="auto"
            onClick={() => trackEvent('Link your practice', 'click')}
            icon={{
              icon: 'Link',
              color: colors.primaryIndigo.indigo08,
              size: 20,
            }}
          >
            <FormattedMessage
              id="account-page.your-gp.link"
              defaultMessage="Link your practice"
            />
          </Button>
        </Link>
      </Stack>
    </Card>
  );
};
