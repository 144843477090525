import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useState, ChangeEvent, useEffect } from 'react';
import BluePageWrapper from '../../components/BluePageWrapper';
import { recommendationConfirmYourPlan } from './intl';
import { RadioButtonGroupDescription, StyledRadioButtonGroup } from './styles';
import { ROUTE } from '../../config/routes';
import { confirmYourPlanForm } from './data';
import {
  useCreateProgrammeMutation,
  WardPatientProgrammeType,
} from '../../services/graphql';
import { useTracking } from '../../hooks/useTracking';
import { RecommendationConfirmPlanErrorModal } from '../../components/Modal/RecommendationConfrimPlanErrorModal';

export const RecommendationConfirmYourPlan = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { trackPage, trackTapped, track } = useTracking();

  useEffect(() => {
    trackPage('Confirm ABP insight plan');
  }, []);

  const [programmeValue, setProgrammeValue] = useState<
    WardPatientProgrammeType | undefined
  >();
  const [createProgramme, { loading, error, data }] =
    useCreateProgrammeMutation({
      context: { disableErrorHandler: true },
    });
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const { fields } = confirmYourPlanForm(intl);

  const radioButtonProps = {
    name: fields[0].name,
    options: fields[0].options.map(({ label, helperLabel, id, value }) => ({
      label,
      helperLabel,
      value,
      id,
    })),
  };

  useEffect(() => {
    if (error) {
      setModalOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      track('Created programme', { type: programmeValue || '' });
      navigate(ROUTE.RECOMMENDATION_SUCCESS);
    }
  }, [data]);

  const onChange = (event: ChangeEvent<any>) => {
    const { value: inputValue } = event.target;
    setProgrammeValue(inputValue as WardPatientProgrammeType);
  };

  const handleCreateProgramme = async () => {
    if (programmeValue) {
      await createProgramme({
        variables: { input: { type: programmeValue } },
      });
    }
  };

  const onSubmit = async () => {
    trackTapped('Confirm ABP insight plan - continue');
    await handleCreateProgramme();
  };

  const tryAgain = async () => {
    setModalOpen(false);
    await handleCreateProgramme();
  };

  return (
    <BluePageWrapper>
      <BluePageWrapper.Content>
        <BluePageWrapper.Close isBreakRecommendationFlow />
        <BluePageWrapper.Header>
          <FormattedMessage {...recommendationConfirmYourPlan.header} />
        </BluePageWrapper.Header>
        <RadioButtonGroupDescription>
          <FormattedMessage
            {...recommendationConfirmYourPlan.radioButtonGroup.description}
          />
        </RadioButtonGroupDescription>
        <BluePageWrapper.FullWidthContainer>
          <StyledRadioButtonGroup {...radioButtonProps} onChange={onChange} />
        </BluePageWrapper.FullWidthContainer>
      </BluePageWrapper.Content>
      <BluePageWrapper.Bottom>
        <BluePageWrapper.Button
          onClick={onSubmit}
          disabled={!programmeValue}
          isLoading={loading}
        >
          <FormattedMessage {...recommendationConfirmYourPlan.confirm} />
        </BluePageWrapper.Button>
      </BluePageWrapper.Bottom>
      {error && (
        <RecommendationConfirmPlanErrorModal
          modalOpen={modalOpen}
          handleClose={() => {
            setModalOpen(false);
          }}
          tryAgain={tryAgain}
        />
      )}
    </BluePageWrapper>
  );
};
