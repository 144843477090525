import * as R from 'ramda';
import { startOfDay, endOfDay } from 'date-fns';
import { useKnownObservation } from '../../hooks/useKnowObservations';
import {
  useGetPatientProgrammeInitiativeQuery,
  useGetWardPatientObservationsQuery,
} from '../../services/graphql';
import { UseTodayPatientObservationsTypes } from './types';

const FIRST_PAGE_COUNT = 4;
const NEXT_PAGE_COUNT = 20;

export const todayRange = (): {
  recordedDateTimeStart: string;
  recordedDateTimeEnd: string;
} => ({
  recordedDateTimeStart: startOfDay(new Date()).toISOString(),
  recordedDateTimeEnd: endOfDay(new Date()).toISOString(),
});

export const useTodayPatientObservations =
  (): UseTodayPatientObservationsTypes => {
    const isKnown = useKnownObservation();
    const {
      data: fullData,
      loading,
      error,
      refetch,
      fetchMore,
    } = useGetWardPatientObservationsQuery({
      variables: {
        ...todayRange(),
        limit: FIRST_PAGE_COUNT,
        exclusiveStartKey: null,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      context: { disableErrorHandler: true },
    });

    const lastEvaluatedKey =
      fullData?.wardPatientObservationsPaged.attributes.lastEvaluatedKey;

    const nextPage = () => {
      fetchMore({
        variables: {
          limit: NEXT_PAGE_COUNT,
          exclusiveStartKey: lastEvaluatedKey,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const lens = R.lensPath<typeof previousResult>([
            'wardPatientObservationsPaged',
            'attributes',
            'records',
          ]);
          const mergedRecords = [previousResult, fetchMoreResult]
            .map(R.view(lens))
            .flat();
          return R.set(lens, mergedRecords, fetchMoreResult);
        },
      });
    };

    const list =
      fullData?.wardPatientObservationsPaged.attributes.records?.filter(
        (observation) => isKnown(observation.attributes.observationType)
      );

    return {
      data: list,
      loading,
      error,
      hasMore: !!lastEvaluatedKey,
      refetch,
      fetchMore: nextPage,
    };
  };

export const useHypertensionHomeBannerStatus = () => {
  const { data, loading } = useGetPatientProgrammeInitiativeQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (data?.userPatientProfileResults.__typename !== 'UserPatientProfile') {
    return { data: null, loading };
  }

  return {
    data: data.userPatientProfileResults.programmeInitiative
      .hypertensionHomeBanner,
    loading,
  };
};
