import { useEffect } from 'react';
import {
  Button,
  Header,
  Paragraph,
  Spacer,
  Stack,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Hero } from '../../assets/hero.svg';
import { Content, CtaContainer, HeroContainer } from './styles';
import { useTracking } from '../../hooks/useTracking';
import { ROUTE } from '../../config/routes';

export const GPLinkageSuccess = () => {
  const navigate = useNavigate();
  const { trackPageWithAppName } = useTracking();

  useEffect(() => {
    trackPageWithAppName('GP linkage - success');
  }, []);

  return (
    <Stack
      id="gp-linkage.success.container"
      justifyContent="center"
      alignItems="center"
      space="none"
    >
      <Content>
        <HeroContainer>
          <Hero width="100%" height="100%" />
        </HeroContainer>
        <Spacer size="s6" />
        <Header>
          <FormattedMessage
            id="gp-linkage.success.title"
            defaultMessage="You're all set!"
          />
        </Header>
        <Spacer size="s4" />
        <Paragraph textAlign="center">
          <FormattedMessage
            id="gp-linkage.success.body"
            defaultMessage="We’ve successfully managed to link your GP practice to your HealthyYou account."
          />
        </Paragraph>
        <Spacer size="s4" />
        <CtaContainer>
          <Button
            id="finish-set-up"
            onClick={() => navigate(ROUTE.ACCOUNT)}
            variant="primary"
            name="finish-set-up"
          >
            <FormattedMessage
              id="gp-linkage.success.finish-set-up.button"
              defaultMessage="Finish set-up"
            />
          </Button>
        </CtaContainer>
      </Content>
    </Stack>
  );
};
