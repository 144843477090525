import { Kind, OperationTypeNode } from 'graphql';
import type { ApolloError } from './types';

export const shouldShowOperationError = (error: ApolloError) => {
  const isQuery = !!error.operation.query.definitions.find(
    (node) =>
      node.kind === Kind.OPERATION_DEFINITION &&
      node.operation === OperationTypeNode.QUERY
  );
  const context = error.operation.getContext();
  return isQuery && !context.disableErrorHandler;
};

export const support = ({
  date,
  careSetting,
  sentryId,
}: {
  date?: Date;
  careSetting?: string;
  sentryId?: string;
}) => {
  const bodyProps = [
    { key: 'Support ID', value: sentryId },
    { key: 'Care Setting', value: careSetting || 'Not known' },
    { key: 'Date and Time', value: date?.toISOString() },
  ];
  const body = bodyProps
    .filter(({ value }) => !!value)
    .reduce((acc, { key, value }) => acc.concat(`• ${key}: ${value}\n`), '');
  return {
    recipient: 'support.healthyyou@bt.com',
    body,
    subject: 'HealthyYou technical support',
  };
};
