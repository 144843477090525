import {
  ModalBody,
  Button,
  Stack,
  Text,
  Modal,
  ModalFooter,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SomethingWentWrong } from '../../assets/something-went-wrong.svg';
import { ButtonsWrapper } from './styles';
import { BodyText, HeaderText } from './FindYourLinkageInformation.styles';
import { Props } from './FindYourLinkageInformation';
import { ROUTE } from '../../config/routes';
import { useTracking } from '../../hooks/useTracking';

export const ErrorGPLinkage = ({
  isModalOpen,
  handleClose,
  id,
  header,
  subtitle,
}: Props & { id: string; header: string; subtitle: string }) => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();

  return (
    <Modal onClose={handleClose} isModalOpen={isModalOpen} id={id}>
      <ModalBody>
        <Stack space="s4" id="info" alignItems="center">
          <SomethingWentWrong />
          <Text color={colors.base.dark} fontWeight={500}>
            <HeaderText>
              <FormattedMessage id={`${id}.header`} defaultMessage={header} />
            </HeaderText>
          </Text>
          <Text
            color={colors.grey.grey08}
            fontSize={fontSizes.base}
            fontWeight={400}
          >
            <BodyText>
              <FormattedMessage
                id={`${id}.subtitle`}
                defaultMessage={subtitle}
              />
            </BodyText>
          </Text>
        </Stack>
        <ModalFooter>
          <ButtonsWrapper>
            <Button
              id="setup-later"
              onClick={() => {
                trackEvent(`Set up later - ${id}`, 'click');
                navigate(ROUTE.ACCOUNT);
              }}
              height="sm"
              variant="tertiary"
            >
              <Text color={colors.primaryIndigo.indigo08}>
                <FormattedMessage
                  id={`${id}.setup-later`}
                  defaultMessage="Set up later"
                />
              </Text>
            </Button>
            <Button
              id="back"
              onClick={() => {
                trackEvent(`Try again - ${id}`, 'click');
                handleClose();
              }}
              variant="primary"
            >
              <FormattedMessage
                id={`${id}.try-again`}
                defaultMessage="Try again"
              />
            </Button>
          </ButtonsWrapper>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
