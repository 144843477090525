import { setContext } from '@apollo/client/link/context';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getHttpHeaders } from '../../../services/getHttpHeaders';

export const createAuthLink = ({
  attachBearer,
  getAccessTokenSilently,
  getIdTokenClaims,
}: { attachBearer: boolean } & Pick<
  Auth0ContextInterface,
  'getAccessTokenSilently' | 'getIdTokenClaims'
>) =>
  setContext(async (_, { headers, ...rest }) => {
    const requestHttpHeaders = { ...headers, ...getHttpHeaders() };

    if (!attachBearer) {
      return {
        ...rest,
        headers: requestHttpHeaders,
      };
    }
    let token;
    try {
      await getAccessTokenSilently();
      token = await getIdTokenClaims();
    } catch (error) {
      console.error(error);
    }

    if (!token) return { headers: requestHttpHeaders, ...rest };

    return {
      ...rest,
      headers: {
        ...requestHttpHeaders,
        // eslint-disable-next-line no-underscore-dangle
        authorization: `Bearer ${token.__raw}`,
      },
    };
  });
