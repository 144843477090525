import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  colors,
  fontSizes,
  Modal,
  ModalBody,
  Stack,
  spacing,
  Text,
  Timestamp,
  BTHealthIcon,
  Spacer,
  SpinnerModal,
} from '@bt-healthcare/ui-toolkit';

import { UpcomingEventCardProps, BookingCancellationProps } from './types';
import {
  ButtonWrapper,
  UpcomingEventSectionWrapper,
  UpcomingEventsDateWrapper,
  UpcomingEventsTimeWrapper,
} from './styles';
import { useCancelGeneralPracticeAppointmentForPatientMutation } from '../../services/graphql';
import { NOOP } from '../../App.constants';
import { ROUTE } from '../../config/routes';

// TODO: remove UpcomingEventCard and all the styled components related as soon as it gets merged in the ui-toolkit
const UpcomingEventCard = ({
  appointmentDetails,
}: UpcomingEventCardProps): ReactElement => (
  <UpcomingEventSectionWrapper
    border={`1px solid ${colors.grey.grey03}`}
    borderRadius={spacing.s4}
  >
    <Stack space="none" id="upcoming-event-card-container">
      <UpcomingEventSectionWrapper padding={spacing.s5}>
        <Stack
          space="none"
          id="upcoming-event-card-date"
          flexDirection="row"
          justifyContent="space-between"
        >
          <UpcomingEventsDateWrapper>
            <Timestamp
              date={appointmentDetails?.startTime}
              type="dateDayNthMonth"
            />{' '}
          </UpcomingEventsDateWrapper>
          <UpcomingEventsTimeWrapper>
            <Timestamp date={appointmentDetails?.startTime} type="time" />
          </UpcomingEventsTimeWrapper>
        </Stack>
      </UpcomingEventSectionWrapper>
      <UpcomingEventSectionWrapper
        padding={spacing.s5}
        background={colors.grey.grey01}
        borderRadius={spacing.s4}
      >
        <Stack
          space="none"
          id="upcoming-event-card-location"
          flexDirection="row"
          justifyContent="center"
        >
          <BTHealthIcon icon="Pin" color="blue" />
          <Spacer size="s2" />
          <Text color={colors.grey.grey10}>
            {appointmentDetails?.practiceName}
          </Text>
        </Stack>
      </UpcomingEventSectionWrapper>
    </Stack>
  </UpcomingEventSectionWrapper>
);

type ErrorModalProps = {
  modalOpen: boolean;
  handleClose: () => void;
  handlePrimaryCta: () => void;
  handleSecondaryCta?: () => void;
  modalId: string;
  modalHeader: string;
  modalParagraph: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
};

// TODO: Remove ErrorModal as soon as it gets merged in the ui-toolkit
const ErrorModal = ({
  modalOpen,
  handleClose,
  handlePrimaryCta,
  handleSecondaryCta,
  modalId,
  modalHeader,
  modalParagraph,
  primaryButtonText,
  secondaryButtonText,
}: ErrorModalProps) => (
  <Modal
    isModalOpen={modalOpen}
    onClose={handleClose}
    id={modalId}
    maxWidth="343px"
  >
    <ModalBody>
      <Stack space="none" id="">
        <Stack
          space="s4"
          id="confirmation"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            as="h3"
            fontSize={fontSizes.h3}
            color={colors.rag.light.red}
            style={{ textAlign: 'center' }}
          >
            <FormattedMessage
              id="modal.error.header"
              defaultMessage={modalHeader}
            />
          </Text>
          <Text color={colors.grey.grey08}>
            <FormattedMessage
              id="modal.error.message"
              defaultMessage={modalParagraph}
            />
          </Text>

          <Spacer />
        </Stack>

        <Stack space="s4" id="error-modal-ctas-wrapper">
          <ButtonWrapper>
            <Button
              variant="primary"
              onClick={handlePrimaryCta}
              id="error-modal-primary-button"
            >
              <FormattedMessage
                id="modal.error.primary.button"
                defaultMessage={primaryButtonText}
              />
            </Button>
          </ButtonWrapper>
          {secondaryButtonText && (
            <ButtonWrapper>
              <Button
                variant="tertiary"
                onClick={handleSecondaryCta}
                id="error-modal-secondary-button"
                color={colors.primaryIndigo.indigo07}
              >
                <FormattedMessage
                  id="modal.error.secondary.button"
                  defaultMessage={secondaryButtonText}
                />
              </Button>
            </ButtonWrapper>
          )}
        </Stack>
      </Stack>
    </ModalBody>
  </Modal>
);

export const BookingCancellation = ({
  appointmentDetails,
  modalOpen,
  isCancelationConfirmed,
  handleClose,
  handleCancel,
}: BookingCancellationProps) => {
  const navigate = useNavigate();

  const [
    cancelGeneralPracticeAppointmentForPatientMutation,
    {
      data: isCancelationRecorded,
      loading: isCancelationLoading,
      error: cancelationError,
      reset,
    },
  ] = useCancelGeneralPracticeAppointmentForPatientMutation({
    variables: {
      patientIdentifier: appointmentDetails?.patientIdentifier || '',
      appointmentId: appointmentDetails?.appointmentId || '',
    },
  });

  useEffect(() => {
    if (isCancelationRecorded?.cancelGeneralPracticeAppointmentForPatient) {
      navigate(ROUTE.CANCELLATION_SUCCESS);
    }
  }, [isCancelationRecorded]);

  const handleErrorCta = () => {
    handleClose();
    reset();
  };

  return (
    <>
      {!isCancelationLoading && !cancelationError && (
        <Modal
          isModalOpen={modalOpen}
          onClose={handleClose}
          id="booking-cancellation"
          maxWidth="343px"
        >
          {!isCancelationLoading && (
            <ModalBody>
              <Stack space="none" id="">
                <Stack
                  space="s4"
                  id="confirmation"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Text
                    as="h3"
                    fontSize={fontSizes.h3}
                    color={colors.grey.grey10}
                    style={{ textAlign: 'center' }}
                  >
                    <FormattedMessage
                      id="modal.booking-cancellation.header"
                      defaultMessage={
                        isCancelationConfirmed
                          ? 'Are you sure you want to cancel your appointment?'
                          : 'Upcoming appointment'
                      }
                    />
                  </Text>
                  {!isCancelationConfirmed && (
                    <Text color={colors.grey.grey08}>
                      <FormattedMessage
                        id="modal.booking-cancellation.message"
                        defaultMessage="The appointment will take 15 minutes."
                      />
                    </Text>
                  )}

                  <Spacer />
                </Stack>
                <Spacer size="s4" />
                {!isCancelationConfirmed && (
                  <>
                    <UpcomingEventCard
                      appointmentDetails={appointmentDetails}
                    />
                    <Spacer size="s4" />
                  </>
                )}

                <Stack space="s4" id="ctas-and-warning-wrapper">
                  <ButtonWrapper>
                    {isCancelationConfirmed && (
                      <Button
                        variant="primaryRed"
                        onClick={() =>
                          cancelGeneralPracticeAppointmentForPatientMutation()
                        }
                        id="cancel-appointment-cta"
                        autoFocus
                      >
                        <FormattedMessage
                          id="modal.booking-cancellation.primary.button"
                          defaultMessage="Yes, cancel my appointment"
                        />
                      </Button>
                    )}
                    {!isCancelationConfirmed && (
                      <Button
                        variant="primaryRed"
                        onClick={handleCancel}
                        id="cancel-appointment-cta"
                        autoFocus
                      >
                        <FormattedMessage
                          id="modal.booking-cancellation.cancel.button"
                          defaultMessage="Cancel appointment"
                        />
                      </Button>
                    )}
                  </ButtonWrapper>

                  <ButtonWrapper>
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      id="go-back-cta"
                    >
                      <FormattedMessage
                        id="modal.booking-cancellation.secondary.button"
                        defaultMessage={
                          isCancelationConfirmed ? 'No, go back' : 'Go back'
                        }
                      />
                    </Button>
                  </ButtonWrapper>
                </Stack>
              </Stack>
            </ModalBody>
          )}
        </Modal>
      )}
      {isCancelationLoading && (
        <SpinnerModal
          text="Cancelling"
          id="booking-cancellation-spinner"
          data-testid="booking-cancellation-spinner"
          size="lg"
          isModalOpen
          color="primaryIndigo.indigo08"
          onClose={NOOP}
        />
      )}
      {cancelationError && (
        <ErrorModal
          modalOpen={modalOpen}
          handleClose={handleClose}
          handlePrimaryCta={handleErrorCta}
          modalId="booking-cancellation"
          modalHeader="We were unable to cancel your appointment"
          modalParagraph="Please check your internet connection and try again. If this persists, please get in touch with your GP practice."
          primaryButtonText="Go back"
          secondaryButtonText="Set up later"
        />
      )}
    </>
  );
};
