import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { BTHealthIcon, Spacer, Stack, colors } from '@bt-healthcare/ui-toolkit';

import ReceiveYourPlan from '../../assets/receive-your-plan.png';
import LogBloodPressure from '../../assets/log-blood-pressure.png';
import CheckYourInbox from '../../assets/check-your-inbox.png';

import { whatToDoNext } from './intl';
import {
  ArrowButton,
  ContentWrapper,
  DesktopSlides,
  MobileSlides,
  ProgressBar,
  ProgressBarWrapper,
  SlideWrapper,
  StyledContainer,
  StyledDescription,
  StyledNumber,
  StyledTextContainer,
  StyledTitle,
  Wrapper,
  Image,
  Header,
} from './styles';

export const WhatToDoNextCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const slideWidth = 100 / 3;
  const purpleSide = slideWidth * currentSlide;
  const greySide = 100 - purpleSide;

  const slide = Object.keys(whatToDoNext) as Array<keyof typeof whatToDoNext>;
  const imageSources = [
    {},
    { imageSource: CheckYourInbox, testId: 'check-your-inbox' },
    { imageSource: LogBloodPressure, testId: 'log-blood-pressure' },
    { imageSource: ReceiveYourPlan, testId: 'receive-your-plan' },
  ];
  const notationsBackground = [
    '',
    colors.primaryIndigo.indigo01,
    colors.notifications.light.successLight,
    colors.secondaryBlue.blue02,
  ];

  return (
    <Wrapper>
      <Stack id="">
        <Header>
          <FormattedMessage {...whatToDoNext.carousel.title} />
        </Header>
      </Stack>
      <Spacer size="s10" />
      <ArrowButton
        data-testid="left-arrow"
        onClick={() => setCurrentSlide(currentSlide > 1 ? currentSlide - 1 : 1)}
      >
        <BTHealthIcon
          icon="ChevronLeft"
          color={currentSlide > 1 ? colors.grey.grey09 : colors.grey.grey06}
        />
      </ArrowButton>
      <ArrowButton
        data-testid="right-arrow"
        onClick={() => setCurrentSlide(currentSlide < 3 ? currentSlide + 1 : 3)}
        right
      >
        <BTHealthIcon
          icon="ChevronRight"
          color={currentSlide < 3 ? colors.grey.grey09 : colors.grey.grey06}
        />
      </ArrowButton>
      <ContentWrapper>
        <SlideWrapper>
          <ProgressBarWrapper>
            <ProgressBar
              width={purpleSide}
              height={2}
              color={colors.primaryIndigo.indigo08}
              opposite={greySide}
            />
            <ProgressBar
              width={greySide}
              height={1}
              color={colors.grey.grey04}
              opposite={purpleSide}
            />
          </ProgressBarWrapper>
          <MobileSlides>
            <Stack id="description">
              <StyledContainer>
                <StyledNumber
                  backgroundColor={notationsBackground[currentSlide]}
                >
                  {currentSlide}
                </StyledNumber>
                <StyledTextContainer>
                  <StyledTitle>
                    <FormattedMessage
                      {...whatToDoNext[slide[currentSlide]].title}
                    />
                  </StyledTitle>
                  <StyledDescription>
                    <FormattedMessage
                      {...whatToDoNext[slide[currentSlide]].description}
                    />
                  </StyledDescription>
                </StyledTextContainer>
              </StyledContainer>{' '}
            </Stack>
          </MobileSlides>
          <DesktopSlides>
            <Stack id="description">
              <StyledContainer onClick={() => setCurrentSlide(1)}>
                <StyledNumber backgroundColor={notationsBackground[1]}>
                  {1}
                </StyledNumber>
                <StyledTextContainer>
                  <StyledTitle>
                    <FormattedMessage {...whatToDoNext[slide[1]].title} />
                  </StyledTitle>
                  <StyledDescription>
                    <FormattedMessage {...whatToDoNext[slide[1]].description} />
                  </StyledDescription>
                </StyledTextContainer>
              </StyledContainer>{' '}
            </Stack>
            <Stack id="description">
              <StyledContainer onClick={() => setCurrentSlide(2)}>
                <StyledNumber backgroundColor={notationsBackground[2]}>
                  {2}
                </StyledNumber>
                <StyledTextContainer>
                  <StyledTitle>
                    <FormattedMessage {...whatToDoNext[slide[2]].title} />
                  </StyledTitle>
                  <StyledDescription>
                    <FormattedMessage {...whatToDoNext[slide[2]].description} />
                  </StyledDescription>
                </StyledTextContainer>
              </StyledContainer>{' '}
            </Stack>
            <Stack id="description">
              <StyledContainer onClick={() => setCurrentSlide(3)}>
                <StyledNumber backgroundColor={notationsBackground[3]}>
                  {3}
                </StyledNumber>
                <StyledTextContainer>
                  <StyledTitle>
                    <FormattedMessage {...whatToDoNext[slide[3]].title} />
                  </StyledTitle>
                  <StyledDescription>
                    <FormattedMessage {...whatToDoNext[slide[3]].description} />
                  </StyledDescription>
                </StyledTextContainer>
              </StyledContainer>{' '}
            </Stack>
          </DesktopSlides>
        </SlideWrapper>
        <Stack id="service-image" alignItems="center">
          <Image
            imageSource={imageSources[currentSlide].imageSource || ''}
            data-testid={imageSources[currentSlide].testId || ''}
          />
        </Stack>
      </ContentWrapper>
    </Wrapper>
  );
};
