import { Button, Modal, Spacer } from '@bt-healthcare/ui-toolkit';
import { useIntl } from 'react-intl';
import { submissionFailed } from './intl';
import { ButtonWrapper, ContentWrapper, Description, Title } from './styles';

export const SubmissionFailedModal = ({
  isModalOpen,
  onTryAgain,
  onCancel,
}: {
  isModalOpen: boolean;
  onTryAgain: () => void;
  onCancel: () => void;
}) => {
  const intl = useIntl();
  return (
    <Modal
      isModalOpen={isModalOpen}
      onClose={onCancel}
      id="submission-failed-modal"
      data-testid="submission-failed-modal"
    >
      <ContentWrapper>
        <Title>{intl.formatMessage(submissionFailed.header)}</Title>
        <Spacer size="s4" />
        <Description>
          {intl.formatMessage(submissionFailed.description)}
        </Description>
        <Spacer size="s6" />
        <ButtonWrapper>
          <Button id="submit" onClick={onTryAgain} variant="primary">
            {intl.formatMessage(submissionFailed.tryAgainButton)}
          </Button>
          <Button id="cancel" onClick={onCancel} variant="secondary">
            {intl.formatMessage(submissionFailed.cancelButton)}
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </Modal>
  );
};
