import { colors, fontSizes } from '@bt-healthcare/ui-toolkit';
import type { TickRendererProps } from '@visx/axis';
import { Group } from '@visx/group';
import { format } from 'date-fns';

export const AxisBottomTick = ({ x, y, formattedValue }: TickRendererProps) => {
  const splitDate = formattedValue?.split(' ');
  let label;
  let content;
  switch (splitDate?.length) {
    case 2:
      [label, content] = splitDate!;
      break;
    case 3:
      label = `${splitDate[0]} ${splitDate[1]}`;
      [, , content] = splitDate;
      break;
    default:
      label = formattedValue;
      break;
  }

  const isTime = splitDate?.length === 1;
  const fill =
    !isTime && formattedValue === format(new Date(), 'EEE d MMM')
      ? colors.primaryIndigo.indigo08
      : colors.grey.grey08;
  const style = {
    textAnchor: 'middle' as const,
    fontSize: fontSizes.xs,
  };
  return (
    <Group>
      <text x={x} y={y} dy="1em" fill={fill} style={style}>
        <tspan>{label}</tspan>
        {!isTime && (
          <tspan x={x} dy="1em">
            {content}
          </tspan>
        )}
      </text>
    </Group>
  );
};
