import { colors } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { SimpleModalProps } from '../types';
import BaseModal from '../BaseModal';
import { rgbaFromHexAndOpacity } from '../../../utils/css';
import { recommendationConfirmPlanErrorModal } from './intl';

export const RecommendationConfirmPlanErrorModal = ({
  modalOpen,
  handleClose,
  tryAgain,
}: SimpleModalProps & { tryAgain: () => void }) => (
  <BaseModal
    isModalOpen={modalOpen}
    onCancel={handleClose}
    id="recommendation-confirm-plan-error-modal"
    overlayColor={rgbaFromHexAndOpacity(colors.grey.grey10, 0.8).toString()}
  >
    <BaseModal.Header color={colors.notifications.light.errorDark}>
      <FormattedMessage {...recommendationConfirmPlanErrorModal.header} />
    </BaseModal.Header>
    <BaseModal.Description>
      <FormattedMessage {...recommendationConfirmPlanErrorModal.description} />
    </BaseModal.Description>
    <BaseModal.ButtonsWrapper>
      <BaseModal.PrimaryButton
        id="recommendation-confirm-plan-error-modal.primary-button"
        onClick={tryAgain}
      >
        <FormattedMessage
          {...recommendationConfirmPlanErrorModal.primaryButton}
        />
      </BaseModal.PrimaryButton>
      <BaseModal.SecondaryButton
        onClick={handleClose}
        id="recommendation-confirm-plan-error-modal.secondary-button"
      >
        <FormattedMessage
          {...recommendationConfirmPlanErrorModal.secondaryButton}
        />
      </BaseModal.SecondaryButton>
    </BaseModal.ButtonsWrapper>
  </BaseModal>
);
