import { HealthInformationType } from '../../pages/HealthInformation/types';
import { HealthInformation } from '../../services/graphql';

export const getHealthInformationNameFromType = (
  type: HealthInformationType
): keyof HealthInformation | undefined => {
  switch (type) {
    case HealthInformationType.SMOKING:
      return 'smokingInformation';
    case HealthInformationType.HEIGHT:
      return 'heightInformation';
    case HealthInformationType.BLOOD_PRESSURE_MEDICATION:
      return 'bloodPressureMedicationInformation';
    default:
      return undefined;
  }
};
