import { Col, colors, Header, Row, Text } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';

import { useEffect } from 'react';
import { LinkCard } from '../../components/Cards/LinkCard';
import { ROUTE } from '../../config/routes';
import { observationPath } from '../../formatters/observations';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useKnownObservation } from '../../hooks/useKnowObservations';
import { useTracking } from '../../hooks/useTracking';
import { observationName } from './intl';
import { HeaderContainer, HeaderWrapper, StyledGrid } from './styles';

export const CreateObservationsList = () => {
  const { trackPageWithAppName } = useTracking();
  const isKnownObservation = useKnownObservation();

  const { userProfile: data } = useAuthUserProfile();
  const flags = useFeatureFlags();

  useEffect(() => {
    trackPageWithAppName('CreateObservationsList list');
  }, []);

  const observationTypes =
    data?.wardPatient?.attributes?.observationTypes || [];

  return (
    <>
      <HeaderContainer>
        <Col from={1}>
          <HeaderWrapper>
            <Header>
              <FormattedMessage
                id="take-observation.list.header"
                defaultMessage="Let's get started"
              />
            </Header>
          </HeaderWrapper>
        </Col>
        <Col from={1}>
          <Text color={colors.grey.grey08}>
            <FormattedMessage
              id="take-observation.list.subtitle"
              defaultMessage="Choose from the below:"
            />
          </Text>
        </Col>
      </HeaderContainer>
      <StyledGrid>
        {observationTypes?.map((observationType) => {
          if (!observationType || !isKnownObservation(observationType)) {
            return null;
          }

          return (
            <Row key={observationType}>
              <Col colEnd={{ xxs: 'span 4', md: 'span 5' }}>
                <LinkCard
                  path={observationPath(
                    observationType,
                    ROUTE.CREATE_OBSERVATION_NEW
                  )}
                  colorTitle="grey.grey10"
                  dataTestId={observationType}
                  title={
                    <FormattedMessage {...observationName[observationType]} />
                  }
                />
              </Col>
            </Row>
          );
        })}
        {flags.VO2 && (
          <Row>
            <Col colEnd={{ xxs: 'span 4', md: 'span 5' }}>
              <LinkCard
                path={ROUTE.CREATE_OBSERVATION_NEW_VO2}
                colorTitle="grey.grey10"
                dataTestId="vo2"
                title={<FormattedMessage {...observationName.VO2} />}
              />
            </Col>
          </Row>
        )}
      </StyledGrid>
    </>
  );
};
