import { useState } from 'react';
import {
  Portal,
  Spacer,
  Stack,
  SubHeader,
  colors,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';

import { InfoCard } from '../Cards/InfoCard';
import { EMPTY_VALUE } from '../../App.constants';
import { LinkCard } from '../Cards/LinkCard';
import { ROUTE } from '../../config/routes';
import { SettingCard } from '../Cards/SettingCard';
import { ethnicityOptions } from '../../pages/Ethnicity/intl';
import { LinkCardTitle } from '../Cards/LinkCard/styles';
import { DeleteMyAccountModal } from '../Modal/DeleteMyAccount';

import { RequestCardButton, StyledRequestCard } from './styles';
import { accountDetails, support } from './intl';
import { AccountDetailsSectionProps } from './types';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';

export const AccountDetailsSection = ({
  deletionRequested,
  primaryEmailAddress,
  primaryContactNumber,
  ethnicity,
}: AccountDetailsSectionProps) => {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const { userProfile } = useAuthUserProfile();
  const isStandalonePatient = userProfile?.user.attributes?.isStandalonePatient;

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Stack space="s2" id="account-details">
      <SubHeader>
        <FormattedMessage {...accountDetails.title} />
      </SubHeader>
      <Spacer size="s2" />
      <InfoCard
        title={<FormattedMessage {...accountDetails.mobile} />}
        value={primaryContactNumber || EMPTY_VALUE}
        dataTestId="mobile-number"
      />
      <InfoCard
        title={<FormattedMessage {...accountDetails.email} />}
        value={primaryEmailAddress || EMPTY_VALUE}
        dataTestId="email-address"
      />
      <SettingCard
        title={<FormattedMessage {...accountDetails.ethnicity} />}
        id="account-details-ethnicity"
        path={ROUTE.ACCOUNT_ETHNICITY}
        variation="small"
        {...(ethnicity && {
          values: [intl.formatMessage(ethnicityOptions[ethnicity])],
        })}
      />
      <LinkCard
        title={<FormattedMessage {...accountDetails.healthInformation} />}
        path={ROUTE.ACCOUNT_HEALTH_INFORMATION}
        dataTestId="health-information"
        colorTitle="grey.grey10"
      />
      <>
        <Portal>
          <DeleteMyAccountModal
            modalOpen={modalOpen}
            handleClose={closeModal}
          />
        </Portal>
        {isStandalonePatient && !deletionRequested && (
          <RequestCardButton onClick={openModal}>
            <StyledRequestCard
              cardTitle={
                <LinkCardTitle color={colors.rag.light.red}>
                  <FormattedMessage {...support.deleteAccount} />
                </LinkCardTitle>
              }
              id="delete-account"
            />
          </RequestCardButton>
        )}
      </>
    </Stack>
  );
};
