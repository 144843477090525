import { defineMessages } from 'react-intl';
import { QuestionAnswerMessages } from './types';

export const frequentlyAskedQuestions: QuestionAnswerMessages = {
  questionOne: defineMessages({
    question: {
      defaultMessage: 'Why have I been asked to join remote care service?',
      id: 'frequency-asked-questions.question-one.question',
    },
    answer: {
      defaultMessage:
        'We’ve reached out to you because we know that reducing and maintaining a healthy blood pressure can be difficult, and we want to offer you as much support as we can. There is no pressure to take part in the service if you don’t want to.',
      id: 'frequency-asked-questions.question-one.answer',
    },
  }),
  questionTwo: defineMessages({
    question: {
      defaultMessage: 'How will my care team monitor my blood pressure?',
      id: 'frequency-asked-questions.question-two.question',
    },
    answer: {
      defaultMessage:
        'You will be asked to submit blood pressure readings once or twice a day over a 1 to 2 week period, after which a clinician will review your readings. ',
      id: 'frequency-asked-questions.question-two.answer',
    },
  }),
  questionThree: defineMessages({
    question: {
      defaultMessage: 'How often should I take my blood pressure?',
      id: 'frequency-asked-questions.question-three.question',
    },
    answer: {
      defaultMessage:
        'We recommend taking one reading in the morning and one in the evening and waiting an hour after drinking caffeine, smoking and eating before you take it. {br} {br} If you are self-monitoring we recommend taking your blood pressure at least once or twice a week so that you can keep track of it.',
      id: 'frequency-asked-questions.question-three.answer',
    },
  }),
  questionFour: defineMessages({
    question: {
      defaultMessage:
        'How will my care team communicate with me about my blood pressure?',
      id: 'frequency-asked-questions.question-four.question',
    },
    answer: {
      defaultMessage:
        'Your care team may communicate with you via phone to discuss your readings and any necessary changes to your care plan.',
      id: 'frequency-asked-questions.question-four.answer',
    },
  }),
  questionFive: defineMessages({
    question: {
      defaultMessage: 'How long will I be part of the remote care service?',
      id: 'frequency-asked-questions.question-five.question',
    },
    answer: {
      defaultMessage:
        'The duration of remote monitoring will vary based on your individual health needs and treatment plan. This will be determined by your care team.',
      id: 'frequency-asked-questions.question-five.answer',
    },
  }),
  questionSix: defineMessages({
    question: {
      defaultMessage: 'How will my privacy and security be protected?',
      id: 'frequency-asked-questions.question-six.question',
    },
    answer: {
      defaultMessage:
        'Remote monitoring devices and platforms are designed with strict security and privacy protocols to protect patient data, and your care team will take steps to ensure that your information is kept secure and confidential.',
      id: 'frequency-asked-questions.question-six.answer',
    },
  }),
  questionSeven: defineMessages({
    question: {
      defaultMessage: 'How do I access HealthyYou?',
      id: 'frequency-asked-questions.question-seven.question',
    },
    answer: {
      defaultMessage:
        'To start using HealthyYou please click on the link that has been sent to you from {email} {br} {br} If you have already created your account, {LogInHere}',
      id: 'frequency-asked-questions.question-seven.answer',
    },
  }),
  questionEight: defineMessages({
    question: {
      defaultMessage: 'How do I contact the remote care team?',
      id: 'frequency-asked-questions.question-eight.question',
    },
    answer: {
      defaultMessage:
        'You can contact the remote care team by calling them on {phoneNumber} there working hours are 9am-5pm Monday to Friday.',
      id: 'frequency-asked-questions.question-eight.answer',
    },
  }),
};
