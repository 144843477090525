import { object, string } from 'yup';
import { dateOfBirthSchema } from '../../components/RHFDateOfBirthInput/schema';

export const getValidationSchema = () =>
  object().shape({
    firstName: string()
      .max(50, 'First name must be between 1-50 characters in length.')
      .matches(
        /^(?![-].*)(?!.*[-]$)[a-zA-Z-\s]*$/,
        'First name can only contain alphabetical characters, hyphens and spaces'
      )
      .required('Please enter a first name.'),
    lastName: string()
      .max(50, 'Last name must be between 1-50 characters in length.')
      .matches(
        /^(?![-'].*)(?!.*[-']$)[a-zA-Z-'\s]*$/,
        'Last name can only contain alphabetical characters, apostrophes, hyphens and spaces'
      )
      .required('Please enter a last name.'),
    ...dateOfBirthSchema,
  });
