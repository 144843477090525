import { colors, device, fontSizes } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const HeaderTitle = styled.h2`
  text-align: center;
  font-family: 'BT-Curve', sans-serif;
  font-style: normal;
  font-weight: 500;

  color: ${colors.grey.grey09};
  font-size: ${fontSizes.h2};
  text-align: 'center';

  @media ${device.tablet} {
    font-size: ${fontSizes.h1};
  }
`;
export const GradientText = styled.span`
  background: linear-gradient(
    63deg,
    #448aff -0.36%,
    #472ecd 54.21%,
    #5514b4 100.81%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;
