import { Link } from 'react-router-dom';
import { colors, RequestCard } from '@bt-healthcare/ui-toolkit';
import type { LinkCardProps } from './types';
import { LinkCardTitle } from './styles';
import { NOOP } from '../../../App.constants';

export const LinkCard = ({
  path,
  title,
  children,
  dataTestId,
  isTargetSelf = true,
  colorTitle = 'grey.grey08',
  onClick,
}: LinkCardProps) => (
  <Link
    to={path}
    target={isTargetSelf ? '_self' : '_blank'}
    rel="noopener noreferrer"
    data-testid={dataTestId}
    onClick={onClick || NOOP} // TODO - NOOP to be removed once the trackEvent is applied to all instances of the LinkCard component
  >
    <RequestCard
      id={`${dataTestId}-CardButton`}
      borderRadius={8}
      icon="ChevronRight"
      iconColor={colors.grey.grey10}
      cardTitle={
        <LinkCardTitle color={colorTitle}>{title || children}</LinkCardTitle>
      }
    />
  </Link>
);
