import { GlyphDot } from '@visx/glyph';

import { Fragment } from 'react';
import { seriesChartConfigMap } from './config';
import type { DataPointProps, ObservationData } from './types';

export const DataPoint = ({
  chartData,
  xScale,
  yScale,
  idx,
  parseDate,
  handleOnPointOver,
  handlePointLeave,
}: DataPointProps) => (
  <>
    {chartData.map((d: ObservationData) => (
      <Fragment key={d.date}>
        <GlyphDot
          cx={xScale(parseDate(d.date))}
          cy={yScale(d.series[idx].value)}
          r={4}
          fill={seriesChartConfigMap[idx].glyph}
          stroke={seriesChartConfigMap[idx].glyph}
          onTouchStart={() => handleOnPointOver(d)}
          onMouseOver={() => handleOnPointOver(d)}
          onMouseMove={() => handleOnPointOver(d)}
          onMouseLeave={handlePointLeave}
          strokeWidth={2}
        />
      </Fragment>
    ))}
  </>
);
