import { colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const PasswordValidatorRequirementItem = styled.div`
  border-radius: ${spacing.s3};
  width: ${spacing.s4};
  height: ${spacing.s4};
  border: 1px solid ${colors.grey.grey07};
  position: relative;
`;

export const PasswordValidatorRequirementIcon = styled.div`
  top: -2px;
  left: -2px;
  position: absolute;
  display: flex;
`;
