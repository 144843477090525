import styled from 'styled-components';
import { device, Text, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';

const IMG_DESKTOP_WIDTH = '323px';
export const QueryErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.l2} 0 0 0;
  height: 100%;
  @media ${device.tablet} {
    height: unset;
  }
  @media ${device.desktop} {
    height: unset;
    margin: ${spacing.l3} 0 0 0;
  }
`;

export const Title = styled(Text).attrs({
  fontSize: fontSizes.h4,
})`
  text-align: center;
`;

export const Description = styled(Text).attrs({
  fontSize: fontSizes.sm,
})`
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  width: 100%;
  max-width: ${IMG_DESKTOP_WIDTH};
`;

export const BodyErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.s4};
  margin-bottom: ${spacing.s6};
  @media ${device.tablet} {
    margin-bottom: ${spacing.s10};
  }
  @media ${device.desktop} {
    margin-bottom: ${spacing.s14};
  }
`;

export const ImgContainer = styled.div`
  height: 200px;
  width: 217px;
  @media ${device.desktop} {
    height: 300px;
    width: ${IMG_DESKTOP_WIDTH};
  }
  svg {
    @media ${device.desktop} {
      height: 300px;
      width: ${IMG_DESKTOP_WIDTH};
    }
  }
`;
