import { PropsWithChildren } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import { getEnvVariable } from '../../env.utils';

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => (
  <Auth0Provider
    domain={getEnvVariable('VITE_AUTH_AUTHORITY')}
    clientId={getEnvVariable('VITE_AUTH_CLIENT_ID')}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    {children}
  </Auth0Provider>
);
