import { useIntl } from 'react-intl';
import { Button, colors } from '@bt-healthcare/ui-toolkit';
import * as Sentry from '@sentry/react';
import {
  BodyErrorWrapper,
  ButtonWrapper,
  Description,
  ImgContainer,
  QueryErrorWrapper,
  Title,
} from './styles';
import { queryErrorMessage } from './intl';
import { ReactComponent as Img } from '../../assets/query-error.svg';
import { ReactComponent as BlueScreenImg } from '../../assets/blue-screen-query-error.svg';
import { QueryErrorMessageProps, QueryErrorMessageVariantsEnum } from './types';
import { rgbaFromHexAndOpacity } from '../../utils/css';
import { encodeMailtoURL } from '../../formatters/mailto';
import { support } from '../ApolloErrorBoundary/utils';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';

const variantArgs = {
  [QueryErrorMessageVariantsEnum.DEFAULT]: {
    secondaryButtonProps: {},
    titleColor: colors.grey.grey10,
    descriptionColor: colors.grey.grey08,
    imgComponent: Img,
  },
  [QueryErrorMessageVariantsEnum.BLUE_SCREEN]: {
    secondaryButtonProps: {
      transparent: true,
      style: {
        color: colors.base.white,
        borderColor: colors.base.white,
      },
    },
    titleColor: colors.base.white,
    descriptionColor: rgbaFromHexAndOpacity(colors.base.white, 0.87),
    imgComponent: BlueScreenImg,
  },
};

export const QueryErrorMessage = ({
  onTryAgain,
  title,
  description,
  tryAgainText,
  contactUsText,
  variant = QueryErrorMessageVariantsEnum.DEFAULT,
}: QueryErrorMessageProps) => {
  const intl = useIntl();
  const {
    titleColor,
    descriptionColor,
    secondaryButtonProps,
    imgComponent: ImgComponent,
  } = variantArgs[variant];

  const { userProfile: data } = useAuthUserProfile();
  const careSetting = data?.careSetting.attributes.name;

  return (
    <QueryErrorWrapper>
      <BodyErrorWrapper>
        <ImgContainer>
          <ImgComponent />
        </ImgContainer>
        <Title color={titleColor}>
          {title || intl.formatMessage(queryErrorMessage.title)}
        </Title>
        <Description color={descriptionColor}>
          {description || intl.formatMessage(queryErrorMessage.description)}
        </Description>
      </BodyErrorWrapper>
      <ButtonWrapper>
        <Button
          id="assess-error-try-again"
          variant="primary"
          size="maximum"
          onClick={onTryAgain}
        >
          {tryAgainText || intl.formatMessage(queryErrorMessage.tryAgainButton)}
        </Button>

        <a
          href={encodeMailtoURL(
            support({ sentryId: Sentry.lastEventId(), careSetting })
          )}
        >
          <Button
            id="assess-error-contact-us"
            variant="secondary"
            size="maximum"
            {...secondaryButtonProps}
          >
            {contactUsText ||
              intl.formatMessage(queryErrorMessage.contactUsButton)}
          </Button>
        </a>
      </ButtonWrapper>
    </QueryErrorWrapper>
  );
};
