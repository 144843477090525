import styled from 'styled-components';
import { device, fontSizes, spacing, Text } from '@bt-healthcare/ui-toolkit';

export const Container = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  font-size: ${fontSizes.sm};
  padding: ${spacing.s2} ${spacing.s4};
  border-radius: 100px;
  background-color: ${({ bgColor }) => bgColor};
`;

export const StyledText = styled(Text).attrs(({ color }) => ({
  color,
  fontWeight: 500,
}))<{ variant?: 'primary' | 'insightBar' }>`
  white-space: nowrap;
  font-size: ${({ variant }) =>
    variant === 'insightBar' ? fontSizes.xs : 'inherit'};
  @media ${device.tablet} {
    font-size: inherit;
  }
`;
