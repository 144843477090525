import { defineMessage } from 'react-intl';

export const recommendationHowDoesItWorksPage = {
  header: defineMessage({
    id: 'recommendation.how-does-it-works.header',
    defaultMessage: 'How does it work?',
  }),
  howDoesItWorksList: [
    defineMessage({
      id: 'recommendation.how-does-it-works.list.readings-amount',
      defaultMessage:
        'Take a minimum of 8 blood pressure readings over a set time period you decide',
    }),
    defineMessage({
      id: 'recommendation.how-does-it-works.list.frequency',
      defaultMessage:
        'You can take a maximum of 2 readings per day to reach the goal quicker',
    }),
    defineMessage({
      id: 'recommendation.how-does-it-works.list.additional-reading',
      defaultMessage:
        'Any additional readings will be included in your average calculation',
    }),
    defineMessage({
      id: 'recommendation.how-does-it-works.list.auto-cancel',
      defaultMessage:
        'If you skip 2 or more days in a row, the plan will be cancelled',
    }),
  ],
  whatYouGetTitle: defineMessage({
    id: 'recommendation.how-does-it-works.what-you-get.title',
    defaultMessage: '<blue02>What you’ll get:</blue02>',
  }),
  whatYouGetList: [
    defineMessage({
      id: 'recommendation.how-does-it-works.what-you-get.list.average',
      defaultMessage:
        'Your average blood pressure reading, along with a record of your past readings that you can share with your GP',
    }),
    defineMessage({
      id: 'recommendation.how-does-it-works.what-you-get.list.guidance',
      defaultMessage: 'Guidance on whether to seek medical advice',
    }),
    defineMessage({
      id: 'recommendation.how-does-it-works.what-you-get.list.understanding',
      defaultMessage: 'A better understanding of your health',
    }),
  ],
  warning: defineMessage({
    id: 'recommendation.how-does-it-works.warning',
    defaultMessage:
      'This is not a medical diagnosis and if I am experiencing an emergency I should seek urgent medical help',
  }),
  continue: defineMessage({
    id: 'recommendation.how-does-it-works.continue',
    defaultMessage: 'Continue',
  }),
  takeAReading: defineMessage({
    id: 'recommendation.how-does-it-works.take-a-reading',
    defaultMessage: 'Take a reading',
  }),
};
