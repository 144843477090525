import { useState } from 'react';

import { BTHealthIcon, colors, Divider } from '@bt-healthcare/ui-toolkit';
import { AccordionWrapper, HeaderContainer, HeaderTitle, Text } from './styles';
import { AccordionTypes } from './types';

export const Accordion = ({ headerText, text, testId }: AccordionTypes) => {
  const [open, setOpen] = useState(false);
  return (
    <AccordionWrapper>
      <HeaderContainer
        onClick={() => setOpen(!open)}
        data-testid={`${testId}-accordion-header`}
      >
        <HeaderTitle open={open}>{headerText}</HeaderTitle>
        <BTHealthIcon
          color={open ? colors.primaryIndigo.indigo08 : colors.grey.grey10}
          icon={open ? 'ChevronUp' : 'ChevronDown'}
          size={12}
        />
      </HeaderContainer>
      <Divider />
      {open && <Text> {text}</Text>}
    </AccordionWrapper>
  );
};
