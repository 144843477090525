import {
  GetUserPatientProfileQuery,
  PersonAttributes,
} from '../services/graphql';

type UserProfile = Extract<
  GetUserPatientProfileQuery['userPatientProfileResults'],
  { __typename?: 'UserPatientProfile' }
>;

export const getPersonAttribute = (
  data: UserProfile | undefined,
  personAttribute: keyof PersonAttributes
) => {
  const attributes = data?.person.attributes as PersonAttributes;
  return attributes[personAttribute];
};

export const getPersonId = (data?: UserProfile) => data?.person.id;
