import {
  Button,
  colors,
  device,
  fontCalcMixin,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 135px;
  overflow: hidden;
  z-index: -2;
  @media ${device.tablet} {
    height: 223px;
  }
  @media ${device.desktop} {
    display: none;
  }
`;

export const TopSection = styled.div`
  padding: 64px 16px 24px;
  gap: ${spacing.s6};
  width: 100vw;
  height: max-content;
  margin: -68px -16px 0px;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    padding: 64px 32px 40px;
    gap: ${spacing.s12};
  }
  @media ${device.desktop} {
    margin: -120px 0px 0px -136px;
    padding-top: 90px;
    padding-left: 136px;
    padding-bottom: 32px;
    width: 100%;
    gap: ${spacing.s14};
  }
`;

export const GradientBackground = styled.div`
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: linear-gradient(
      -90deg,
      rgba(41, 121, 255, 0.12) 15.34%,
      rgba(50, 0, 138, 0.1) 82.23%
    ),
    var(--Colour-Base-Dark, #02062d);
  @media ${device.tablet} {
    height: 385px;
  }
  @media ${device.desktop} {
    height: 415px;
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const GridItem = styled.div`
  grid-column: span 4;
  @media ${device.tablet} {
    grid-column: span 6;
  }
  @media ${device.desktop} {
    grid-column: span 5;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${spacing.s6};
  gap: ${spacing.s8};
  @media ${device.tablet} {
    margin-top: ${spacing.s8};
    margin-inline: ${spacing.s4};
  }
  @media ${device.desktop} {
    margin-inline: unset;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.tablet} {
    align-items: start;
    gap: ${spacing.s1};
  }
  @media ${device.desktop} {
    gap: ${spacing.s2};
  }
`;

export const HeaderText = styled(Text).attrs({
  color: colors.base.white,
  fontWeight: 500,
  as: 'h2',
})`
  color: ${colors.base.white};
  font-weight: 500;
  font-family: inherit;
  ${fontCalcMixin(fontSizes.h4)};
  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.h2)};
  }
`;

export const SubHeading = styled(Text).attrs({
  color: colors.base.white,
})`
  opacity: 0.87;
  ${fontCalcMixin(fontSizes.sm)};
  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.h4)};
  }
`;

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  align-items: center;
  @media ${device.tablet} {
    align-items: start;
    width: max-content;
    gap: ${spacing.s6};
  }
  @media ${device.desktop} {
    gap: 27px;
  }
`;

export const BannerTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.s4};
  align-items: center;
  @media ${device.tablet} {
    align-items: end;
  }
  @media ${device.desktop} {
    align-items: center;
  }
`;

export const BannerTitle = styled(Text).attrs({
  color: colors.base.white,
  fontWeight: 500,
  as: 'h2',
})`
  color: ${colors.base.white};
  font-weight: 500;
  font-family: inherit;
  white-space: nowrap;
  ${fontCalcMixin(fontSizes.sm)};
  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.h4)};
    margin-bottom: ${spacing.s1};
  }
`;

export const BannerDescription = styled(Text).attrs({
  color: colors.base.white,
})`
  opacity: 0.87;
  ${fontCalcMixin(fontSizes.sm)};
  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.base)};
  }
`;

export const GetStartedButton = styled(Button).attrs({
  variant: 'primary',
})`
  border-radius: 8px;
  padding: 8px 16px;
  min-height: 48px;
  background: linear-gradient(
    23deg,
    #9848ff -0.36%,
    rgba(111, 0, 255, 0.7) 54.21%,
    #893cfb 100.81%
  );
`;
