import styled from 'styled-components';
import { colors, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';

export const Title = styled.div`
  font-size: ${fontSizes.xs};
  font-weight: 400;
  margin-bottom: ${spacing.s1};
`;

export const Value = styled.div`
  font-size: ${fontSizes.sm};
  font-weight: 500;
  color: ${colors.grey.grey10};
`;
