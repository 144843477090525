import { colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { GridSectionProps } from './types';

// TODO - Remove the position: absolute; as when https://jira.tvac.bt.co.uk/browse/BTHDFD-844 is play

export const GridPageCenteredSection = styled.section`
  grid-area: 1 / 1 / 2 / 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin-bottom: 30vh;

  @media ${device.tablet} {
    grid-area: 1 / 3 / 2 / 7;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    height: 100vh;
  }
  @media ${device.largeTablet} {
    grid-area: 1 / 5 / 2 / 9;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    height: 100vh;
  }
`;

export const GridPageFullWidthSection = styled.section`
  grid-area: 1 / 1 / 3 / 5;
  height: 100vh;

  @media ${device.tablet} {
    grid-area: 1 / 1 / 2 / 13;
  }
  @media ${device.largeTablet} {
    grid-area: 1 / 1 / 3 / 1;
  }
`;

export const PublicLayoutNavbarWrapper = styled.nav`
  position: absolute;
  top: ${spacing.s4};
  display: flex;
  align-items: end;
  display: flex;
  height: ${spacing.s12};

  @media ${device.tablet} {
    position: relative;
  }
  @media ${device.desktop} {
    position: relative;
  }
`;

export const PublicLayoutFooterWrapper = styled.footer`
  @media ${device.tablet} {
    display: block;
    width: 100%;
    height: ${spacing.s17};
  }
`;

export const ContentWrapper = styled.div`
  display: block;
  min-height: 100%;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }
`;

const gridMaxWidth = '1400px';

export const Grid = styled.div`
  display: grid;
  grid-template-rows: max-content;
  width: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  column-gap: ${spacing.s4};
  grid-template-columns: repeat(4, 1fr);
  row-gap: ${spacing.s16};

  @media ${device.tablet} {
    gap: ${spacing.s6};
    row-gap: ${spacing.s16};
    grid-template-columns: repeat(8, 1fr);
  }

  @media ${device.largeTablet} {
    gap: ${spacing.s6};
    grid-template-columns: repeat(12, 1fr);
    max-width: ${gridMaxWidth};
    margin: 0 auto;
    row-gap: ${spacing.l2};
  }
`;

export const Nav = styled.nav<{ isScrolled: boolean }>`
  z-index: 1;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  transition: background-color 0.3s ease 0s;
  background-color: transparent;
  width: 100%;
  background-color: ${(props) =>
    props.isScrolled ? colors.base.white : 'transparent'};
`;

export const NavContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto;
  max-width: 1400px;
  padding: 12px;
`;

export const FooterStyling = styled.footer`
  padding: ${spacing.s4};
  background-color: ${colors.base.white};
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);

  @media ${device.tablet} {
    padding: ${spacing.s6};
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.s4};

  @media ${device.tablet} {
    align-items: start;
  }
`;

export const Bla = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacing.s4};

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: ${gridMaxWidth};
    margin: 0 auto;
  }
`;
export const GridSection = styled.section<GridSectionProps>`
  grid-area: ${({ row, rowSpan }) =>
    `${row} / 1 / ${rowSpan || (row && row + 1)} / 5`};

  @media ${device.tablet} {
    grid-area: ${({ row, rowSpan }) =>
      `${row} / 1 / ${rowSpan || (row && row + 1)} / 9`};
  }
  @media ${device.largeTablet} {
    grid-area: ${({ row, column, rowSpan, columnSpan }) =>
      `${row} / ${column} / ${rowSpan || (row && row + 1)} / ${columnSpan}`};
  }
`;
