import { defineMessage, defineMessages } from 'react-intl';

export const bloodPressureIntl = {
  header: defineMessage({
    id: 'take-observation-guide.blood-pressure.header',
    defaultMessage: 'How to take your blood pressure',
  }),
  button: defineMessage({
    id: 'take-observation-guide.blood-pressure.button',
    defaultMessage: 'OK, I understand',
  }),
  hints: {
    relax: defineMessages({
      title: {
        id: 'take-observation-guide.blood-pressure.hint1.title',
        defaultMessage: 'Relax and breathe normally',
      },
      description: {
        id: 'take-observation-guide.blood-pressure.hint1.description',
        defaultMessage:
          'Sit comfortably and take a breath. You could also put on some music that will help you relax.',
      },
    }),
    dontCrossLegs: defineMessages({
      title: {
        id: 'take-observation-guide.blood-pressure.hint2.title',
        defaultMessage: 'Don’t cross your feet or legs',
      },
      description: {
        id: 'take-observation-guide.blood-pressure.hin2.description',
        defaultMessage:
          'Sit upright with your back against the chair, keeping both feet flat on the floor.',
      },
    }),
    takeReading: defineMessages({
      title: {
        id: 'take-observation-guide.blood-pressure.hin3.title',
        defaultMessage: 'Take your reading twice',
      },
      description: {
        id: 'take-observation-guide.blood-pressure.hin3.description',
        defaultMessage:
          'First readings are often higher than your actual blood pressure, try and take it at least twice with one minute between.',
      },
    }),
    sameArm: defineMessages({
      title: {
        id: 'take-observation-guide.blood-pressure.hin4.title',
        defaultMessage: 'Always use the same arm',
      },
      description: {
        id: 'take-observation-guide.blood-pressure.hin4.description',
        defaultMessage:
          'This helps keep your readings as consistent as possible. Make sure to relax your hand and arm, whilst resting them on a table. ',
      },
    }),
  },
};
