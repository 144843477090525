import { colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { ProgressBarProps } from './types';

export const ProgressBar = styled.div`
  height: 6px;
  border-radius: ${spacing.s1};
  background: #d4bfec;
  width: 100%;
  overflow: hidden;
`;

export const ProgressBarInner = styled.div<ProgressBarProps>`
  ${({ progress }) => `width: ${progress}%`};
  background: linear-gradient(
    63.19deg,
    #448aff -0.36%,
    #472ecd 54.21%,
    ${colors.primaryIndigo.indigo08} 100.81%
  );
  height: 100%;
  transition: width 1s ease-in-out;
`;
