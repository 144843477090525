import type { AppActions, State } from './types';
import { ROUTE } from '../config/routes';

export const initialAppState: State = {
  healthScore: { currentPage: ROUTE.HEALTH_SCORE_PERSONAL_INFO },
};

export const appReducer = (_app: State, action: AppActions): State => {
  switch (action.type) {
    case 'resetState': {
      return initialAppState;
    }
    case 'healthScoreSetPersonalInformation': {
      return {
        ..._app,
        healthScore: {
          ..._app.healthScore,
          ...action.payload,
          currentPage: ROUTE.HEALTH_SCORE_STRENGTH,
        },
      };
    }
    case 'healScoreSetAlcoholFrequency': {
      return {
        ..._app,
        healthScore: {
          ..._app.healthScore,
          ...action.payload,
          currentPage: ROUTE.HEALTH_SCORE_ALCOHOL_MODERATION,
        },
      };
    }
    case 'healthScoreSetAlcoholModeration': {
      return {
        ..._app,
        healthScore: {
          ..._app.healthScore,
          ...action.payload,
          currentPage: ROUTE.HEALTH_SCORE_SMOKING,
        },
      };
    }
    case 'healthScoreSetSmoking': {
      return {
        ..._app,
        healthScore: {
          ..._app.healthScore,
          ...action.payload,
          currentPage: ROUTE.HEALTH_SCORE_HEIGHT_AND_WEIGHT,
        },
      };
    }
    case 'healthScoreSetStrengthInformation': {
      return {
        ..._app,
        healthScore: {
          ..._app.healthScore,
          ...action.payload,
          currentPage: ROUTE.HEALTH_SCORE_ALCOHOL_FREQUENCY,
        },
      };
    }
    case 'healthScoreSetCurrentPage': {
      return {
        ..._app,
        healthScore: {
          ..._app.healthScore,
          currentPage: action.payload.currentPage,
        },
      };
    }
    case 'healthScoreSetHeightAndWeight': {
      return {
        ..._app,
        healthScore: {
          ..._app.healthScore,
          ...action.payload,
          currentPage: ROUTE.HOME,
        },
      };
    }
    default: {
      throw Error('Unknown action');
    }
  }
};
