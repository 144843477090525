import {
  Grid,
  Row,
  Skeleton as SkeletonComponent,
} from '@bt-healthcare/ui-toolkit';
import { GridItem, TasksContainer } from './styles';

const N = 2;

export const Skeleton = () => (
  <TasksContainer>
    <Grid data-testid="loading-skeleton">
      <Row>
        <SkeletonComponent
          id="today-readings-skeleton-title"
          data-testid="today-readings-skeleton-title"
          rounded
        />
      </Row>
      {[...Array(N).keys()].map((i) => (
        <Row key={i}>
          <GridItem>
            <SkeletonComponent
              id={`today-readings-skeleton-item-${i}`}
              data-testid={`today-readings-skeleton-item-${i}`}
              height={53}
              variant="rectangular"
              rounded
            />
          </GridItem>
        </Row>
      ))}
    </Grid>
  </TasksContainer>
);
