import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useEffect } from 'react';

import { ROUTE } from '../../config/routes';
import {
  useCreateUserRegistrationRecordMutation,
  UserAlreadyExistsValidationError,
} from '../../services/graphql';

import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { useTracking } from '../../hooks/useTracking';

import { OnboardingEmailAddressForm } from './OnboardingEmailAddressForm';
import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';

export const OnboardingEmailAddress = () => {
  const navigate = useNavigate();
  const { trackPageWithAppName } = useTracking();

  useEffect(() => {
    trackPageWithAppName('Onboarding - email-address');
  }, []);

  const [
    createUserRegistrationRecord,
    { data: dataRegistration, loading, error },
  ] = useCreateUserRegistrationRecordMutation();

  const validationError =
    dataRegistration?.createUserRegistrationRecordMutationAnon as UserAlreadyExistsValidationError;
  const isEmailInUse =
    validationError?.__typename === 'UserAlreadyExistsValidationError';

  useEffect(() => {
    if (error) {
      navigate(ROUTE.ONBOARDING_ERROR, {
        state: {
          title: 'We’re having trouble sending you a verification email',
        },
      });
    }
  }, [error]);

  return (
    <>
      <Navbar />
      <GridPageCenteredSection>
        <PublicContentCard
          HeaderText={
            <FormattedMessage
              id="onboarding-email-address.header"
              defaultMessage="Join HealthyYou"
            />
          }
          SubHeaderText={
            <FormattedMessage
              id="onboarding-email-address.subtitle"
              defaultMessage="Create your account to monitor your health and for personalised lifestyle advice"
            />
          }
        >
          <OnboardingEmailAddressForm
            createUserRegistrationRecord={createUserRegistrationRecord}
            isEmailInUse={isEmailInUse}
            data={dataRegistration}
            loading={loading}
          />
        </PublicContentCard>
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </>
  );
};
