import {
  ModalBody,
  Button,
  Stack,
  Text,
  Modal,
  ModalFooter,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Info } from '../../assets/gp-linkage-info.svg';
import { ButtonsWrapper } from './styles';
import {
  BodyText,
  CloseButtonContainer,
  HeaderText,
} from './FindYourLinkageInformation.styles';

export type Props = {
  isModalOpen: boolean;
  handleClose: () => void;
};

export const FindYourLinkageInformation = ({
  isModalOpen,
  handleClose,
}: Props) => (
  <Modal
    onClose={handleClose}
    isModalOpen={isModalOpen}
    id="gp-linkage-connect-page-modal"
  >
    <ModalBody>
      <Stack space="s4" id="info" alignItems="center">
        <Info />
        <Text color={colors.base.dark} fontWeight={500}>
          <HeaderText>
            <FormattedMessage
              id="gp-linkage-connect-page-modal.header"
              defaultMessage="Finding your GP linkage information"
            />
          </HeaderText>
        </Text>
        <Text
          color={colors.grey.grey08}
          fontSize={fontSizes.base}
          fontWeight={400}
        >
          <BodyText>
            <FormattedMessage
              id="gp-linkage-connect-page-modal.subtitle"
              defaultMessage="Your Account ID, Organisation Code and Passphrase should be present on the email or letter that your GP has sent you."
            />
          </BodyText>
        </Text>
        <Text
          color={colors.grey.grey08}
          fontSize={fontSizes.base}
          fontWeight={400}
        >
          <BodyText>
            <FormattedMessage
              id="gp-linkage-connect-page-modal.subtitle2"
              defaultMessage="If you have not received a letter or email, you will need to request one from your GP practice before accessing services through this app."
            />
          </BodyText>
        </Text>
      </Stack>
      <ModalFooter>
        <ButtonsWrapper>
          <CloseButtonContainer>
            <Button
              id="okay"
              onClick={() => {
                handleClose();
              }}
              variant="primary"
            >
              <FormattedMessage
                id="gp-linkage-connect-page-modal.okay"
                defaultMessage="Okay"
              />
            </Button>
          </CloseButtonContainer>
        </ButtonsWrapper>
      </ModalFooter>
    </ModalBody>
  </Modal>
);
