import { FormattedMessage, useIntl } from 'react-intl';
import { object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button } from '@bt-healthcare/ui-toolkit';
import { useAppDispatch } from '../../context/AppContext';
import HealthScorePageWrapper from '../../components/HealthScorePageWrapper';
import { RHFDropDown } from '../../components/RHFDropDown';
import { healthScoreNavigator } from '../HealthScoreNavigator/intl';
import { data } from './data';
import { AlcoholFrequencyForm } from './types';
import { healthScoreAlcoholFrequency } from './intl';

export const HealthScoreAlcoholFrequency = () => {
  const intl = useIntl();
  const { alcoholFrequency: alcoholFrequencyData } = data(intl);
  const dispatch = useAppDispatch();

  const validationSchema = object({
    alcoholFrequency: alcoholFrequencyData.validationRule,
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<AlcoholFrequencyForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values: AlcoholFrequencyForm) => {
    const alcoholFrequency = values.alcoholFrequency?.value;
    if (alcoholFrequency) {
      dispatch({
        type: 'healScoreSetAlcoholFrequency',
        payload: { alcoholFrequency },
      });
    }
  };

  return (
    <HealthScorePageWrapper.Form>
      <HealthScorePageWrapper.Content>
        <HealthScorePageWrapper.Header>
          <FormattedMessage {...healthScoreAlcoholFrequency.header} />
        </HealthScorePageWrapper.Header>
        <RHFDropDown
          label=""
          options={alcoholFrequencyData.options}
          fieldName="alcoholFrequency"
          control={control}
        />
      </HealthScorePageWrapper.Content>
      <Button
        id="health-score-button"
        variant="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={!isValid}
      >
        <FormattedMessage {...healthScoreNavigator.button} />
      </Button>
    </HealthScorePageWrapper.Form>
  );
};
