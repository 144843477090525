import { device, Spacer } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

export const DesktopSpacer = styled(Spacer)`
  display: none;

  @media ${device.desktop} {
    display: initial;
  }
`;
