import {
  BTHealthIcon,
  colors,
  device,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { ZIndex } from '../../const.styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: ${colors.base.light};
  z-index: ${ZIndex.SMARTAPP_BANNER};
  flex-direction: row
  align-items: center;

  padding: ${spacing.s4};
  gap: ${spacing.s4};

  @media ${device.desktop} {
    display: none;
  }
`;

export const CloseIcon = styled(BTHealthIcon).attrs({
  icon: 'Close',
  size: 14,
  color: colors.grey.grey10,
})``;

export const CloseWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;
