import styled from 'styled-components';
import { colors, device, Grid, spacing, Text } from '@bt-healthcare/ui-toolkit';

export const HeaderContainer = styled.div`
  margin-bottom: ${spacing.s10};
  display: flex;
  flex-direction: column;
`;

export const DescriptionContainer = styled(Grid)`
  gap: ${spacing.s6}
  margin-top: ${spacing.s4};
  @media ${device.desktop} {
    margin-top: ${spacing.s6};
  }
`;

export const BulletTitle = styled(Text).attrs({
  color: colors.primaryIndigo.indigo08,
})`
  margin-left: ${spacing.s4};
`;
export const BulletExplanation = styled(Text).attrs({
  color: colors.grey.grey08,
})`
  margin-left: ${spacing.s8};
`;
