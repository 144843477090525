import { Col, EmptyResults, Header, Row } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'ramda';

import { useEffect } from 'react';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { observationName } from '../CreateObservationsList/intl';
import { observationPath } from '../../formatters/observations';
import { ROUTE } from '../../config/routes';
import { HeaderContainer, StyledGrid } from './styles';
import { useTracking } from '../../hooks/useTracking';
import { useKnownObservation } from '../../hooks/useKnowObservations';
import { LinkCard } from '../../components/Cards/LinkCard';

export const HistoricalObservations = () => {
  const { trackPageWithAppName } = useTracking();
  const { userProfile: data } = useAuthUserProfile({
    fetchPolicy: 'cache-and-network',
  });
  const isKnownObservation = useKnownObservation();

  useEffect(() => {
    trackPageWithAppName('History - Patient - HealthyYou');
  }, []);

  const historicalReadingsTypes =
    data?.wardPatient?.attributes.submittedObservationTypes;

  return (
    <>
      <HeaderContainer>
        <Header>
          <FormattedMessage
            id="historical.readings.title"
            defaultMessage="Historical readings"
          />
        </Header>
      </HeaderContainer>
      <StyledGrid>
        {(!historicalReadingsTypes || isEmpty(historicalReadingsTypes)) && (
          <EmptyResults />
        )}
        {historicalReadingsTypes
          ?.filter(isKnownObservation)
          .map((observationType) => (
            <Row key={observationType}>
              <Col colEnd={{ xxs: 'span 4', md: 'span 5' }}>
                <LinkCard
                  path={observationPath(
                    observationType,
                    ROUTE.HISTORICAL_READINGS_TYPE
                  )}
                  dataTestId={observationType}
                  title={
                    <FormattedMessage {...observationName[observationType]} />
                  }
                  colorTitle="grey.grey10"
                />
              </Col>
            </Row>
          ))}
      </StyledGrid>
    </>
  );
};
