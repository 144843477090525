import {
  Button,
  Col,
  Header,
  Notification,
  Row,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ethnicity } from './data';
import {
  ButtonWrapper,
  StyledForm,
  StyledGrid,
} from '../../components/HealthInformationForm/styles';
import { ethnicityError, ethnicityOptions, submitButton } from './intl';
import { RHFDropDown } from '../../components/RHFDropDown';
import { prepareSchema } from '../../utils/yup/schema';
import type { EthnicityForm } from './types';
import { ROUTE } from '../../config/routes';
import { PersonInput, useUpdatePersonMutation } from '../../services/graphql';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { HeaderContainer } from './styles';
import { useTracking } from '../../hooks/useTracking';

export const Ethnicity = () => {
  const intl = useIntl();
  const { trackPageWithAppName } = useTracking();
  const { userProfile: data } = useAuthUserProfile();
  const navigate = useNavigate();
  const [addHealthInformationMutation, { loading, error }] =
    useUpdatePersonMutation();

  useEffect(() => {
    trackPageWithAppName('Account - Ethnicity');
  }, []);

  const initialEthnicity =
    data?.person?.attributes.personalInformation?.ethnicity || undefined;

  const initialValues = initialEthnicity && {
    ethnicity: {
      label: intl.formatMessage(ethnicityOptions[initialEthnicity]),
      value: initialEthnicity,
    },
  };

  const ethnicityData = ethnicity(intl);
  const colEnd = { xxs: 'span 4', md: 'span 5' };
  const schema = prepareSchema(ethnicityData.fields);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<EthnicityForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    ...(initialValues && { defaultValues: initialValues }),
  });

  const onSubmit = handleSubmit(async (values) => {
    const input = {
      personalInformation: { ethnicity: values.ethnicity.value },
    } as PersonInput;
    await addHealthInformationMutation({ variables: { input } });
    navigate(ROUTE.ACCOUNT);
  });

  return (
    <>
      <HeaderContainer>
        <Header>{ethnicityData.header}</Header>
      </HeaderContainer>
      <StyledForm onSubmit={onSubmit}>
        <StyledGrid>
          {ethnicityData.fields.map((field) => (
            <Row key={field.name}>
              <Col colEnd={colEnd}>
                <RHFDropDown
                  options={field.options}
                  label={field.label}
                  fieldName={field.name}
                  control={control}
                />
              </Col>
            </Row>
          ))}
          {error && (
            <Row>
              <Col colEnd={colEnd}>
                <Notification type="softWarning">
                  <FormattedMessage {...ethnicityError.mutation} />
                </Notification>
              </Col>
            </Row>
          )}
        </StyledGrid>
        <Row>
          <Col colEnd={colEnd}>
            <ButtonWrapper>
              <Button
                id="submit-reading-btn"
                variant="primary"
                size="maximum"
                onClick={onSubmit}
                disabled={!isValid || loading}
              >
                <FormattedMessage {...submitButton} />
              </Button>
            </ButtonWrapper>
          </Col>
        </Row>
      </StyledForm>
    </>
  );
};
