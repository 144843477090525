import styled from 'styled-components';
import {
  colors,
  fontCalcMixin,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';

export const BodyText = styled.p`
  ${fontCalcMixin(fontSizes.base)};
`;

export const TimeText = styled.p`
  ${fontCalcMixin(fontSizes.h3)};
  margin-top: ${spacing.s1};
`;

export const Card = styled.div`
  border-radius: 16px;
  border: 1px solid ${colors.grey.grey02};
`;

export const LocationContainer = styled.div`
  background-color: ${colors.grey.grey01};
  padding: ${spacing.s4} ${spacing.s5};
`;

export const PinIconContainer = styled.div`
  padding: 0 ${spacing.s2};
`;

export const TimeContainer = styled.div`
  padding: ${spacing.s5};
`;
