import styled from 'styled-components';

import { colors } from '@bt-healthcare/ui-toolkit';

export const GradientBackground = styled.div`
  height: 20vh;
  width: 100%;
  background-image: linear-gradient(to top, ${colors.base.light}, transparent);
  bottom: 0;
  position: sticky;
`;

export const Form = styled.form`
  width: 100%;
`;
