import { Col, Grid, Header, Row } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect } from 'react';
import { healthInformationList } from './intl';
import { HealthInformationType } from '../HealthInformation/types';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { SettingCard } from '../../components/Cards/SettingCard';
import { toHealthInformationDisplay } from '../../formatters/healthInformation';
import { useTracking } from '../../hooks/useTracking';
import { uppercaseToPathName } from '../../formatters/string';

const healthInformations = [
  HealthInformationType.HEIGHT,
  HealthInformationType.SMOKING,
  HealthInformationType.BLOOD_PRESSURE_MEDICATION,
  HealthInformationType.CARDIOVASCULAR_DISEASE,
  HealthInformationType.CHRONIC_KIDNEY_DISEASE,
  HealthInformationType.DIABETES,
];

export const HealthInformationList = () => {
  const { userProfile: data } = useAuthUserProfile();
  const intl = useIntl();
  const { trackPageWithAppName } = useTracking();

  useEffect(() => {
    trackPageWithAppName(`Health Information`);
  }, []);

  const latestHealthInformation =
    data?.person?.attributes?.latestHealthInformation || undefined;

  return (
    <Grid>
      <Col from={1}>
        <Header>
          <FormattedMessage {...healthInformationList.header} />
        </Header>
      </Col>

      {healthInformations.map((healthInformation) => (
        <Row key={healthInformation}>
          <Col colEnd={{ xxs: 'span 4', md: 'span 5' }}>
            <SettingCard
              title={intl.formatMessage(
                healthInformationList[healthInformation]
              )}
              id={`${uppercaseToPathName(healthInformation)}-link-card`}
              path={uppercaseToPathName(healthInformation)}
              values={toHealthInformationDisplay({
                healthInformation,
                latestHealthInformation,
                intl,
              })}
            />
          </Col>
        </Row>
      ))}
    </Grid>
  );
};
