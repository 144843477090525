import { Header, noop, PageNotFound } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  Container,
  StyledCol,
  StyledGrid,
} from '../HistoricalObservationsList/styles';
import { ObservationsList } from '../../components/ObservationsList';
import { recommendationObservationList } from './intl';
import { useGetWardPatientObservationsForProgrammeQuery } from '../../services/graphql';
import { ApolloErrorBoundary } from '../../components/ApolloErrorBoundary';

export const RecommendationObservationListComponent = ({
  programmeId,
}: {
  programmeId: string;
}) => {
  const intl = useIntl();
  const { data, loading, fetchMore } =
    useGetWardPatientObservationsForProgrammeQuery({
      variables: {
        programmeId,
        offset: 0,
        size: 10,
      },
      fetchPolicy: 'cache-and-network',
    });
  const wardPatientObservationsForProgramme =
    data?.wardPatientObservationsForProgramme;
  const loadedLength =
    wardPatientObservationsForProgramme?.searchResults.length || 0;

  const loadNext = useCallback(() => {
    fetchMore({ variables: { offset: loadedLength } }).catch(noop);
  }, [fetchMore, loadedLength]);

  return (
    <Container>
      <StyledGrid>
        <StyledCol>
          <Header>
            <FormattedMessage {...recommendationObservationList.header} />
          </Header>
        </StyledCol>
        <ApolloErrorBoundary
          page={{
            title: intl.formatMessage(recommendationObservationList.errorTitle),
          }}
        >
          <StyledCol>
            {wardPatientObservationsForProgramme && (
              <ObservationsList
                data={wardPatientObservationsForProgramme}
                loading={loading}
                loadNext={loadNext}
              />
            )}
          </StyledCol>
        </ApolloErrorBoundary>
      </StyledGrid>
    </Container>
  );
};

export const RecommendationObservationList = () => {
  const { programmeId } = useParams<{ programmeId: string }>();
  if (!programmeId) {
    console.warn('Missing Programme Id');
    return <PageNotFound />;
  }
  return <RecommendationObservationListComponent programmeId={programmeId} />;
};
