import styled from 'styled-components';
import {
  BTHealthIcon,
  Text,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.base.white};
  font-size: ${fontSizes.sm};
`;

export const ChevronRightIcon = styled(BTHealthIcon).attrs({
  icon: 'ChevronRight',
  size: 16,
  color: colors.base.white,
})``;

export const BrightIcon = styled(BTHealthIcon).attrs({
  icon: 'Bright',
  size: 18,
  color: colors.base.white,
})``;

export const BrightWrapper = styled.div`
  align-self: flex-start;
`;

export const TextWrapper = styled.div`
  width: 100%;
`;

export const Label = styled(Text).attrs({
  color: colors.base.white,
  fontSize: fontSizes.sm,
})``;
