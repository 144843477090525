import { defineMessages } from 'react-intl';
import { ObservationComponentName } from '../../services/graphql';

export const indicators = defineMessages({
  less: {
    defaultMessage: 'Less than',
    id: 'observation-rating-table.less-indicator',
  },
  more: {
    defaultMessage: 'More than',
    id: 'observation-rating-table.more-indicator',
  },
});

export const componentName = defineMessages({
  [ObservationComponentName.SystolicBloodPressure]: {
    id: 'observation-rating-table.header.blood-pressure.systolic',
    defaultMessage: 'Systolic',
  },
  [ObservationComponentName.DiastolicBloodPressure]: {
    id: 'observation-rating-table.header.blood-pressure.diastolic',
    defaultMessage: 'Diastolic',
  },
  [ObservationComponentName.Pulse]: {
    id: 'observation-rating-table.header.Pulse',
    defaultMessage: 'Pulse',
  },
  [ObservationComponentName.OxygenSaturation]: {
    id: 'observation-rating-table.header.oxygen-saturation',
    defaultMessage: 'SpO2',
  },
  [ObservationComponentName.FastingBloodGlucose]: {
    id: 'observation-rating-table.header.fasting-blood-glucose',
    defaultMessage: 'mmol/L',
  },
});
