import { defineMessages } from 'react-intl';
import {
  ObservationType,
  ObservationWarningLevel,
} from '../../../services/graphql';

export const warnings = {
  [ObservationType.Pulse]: defineMessages({
    [ObservationWarningLevel.SevereLow]: {
      id: 'create-observations-confirm.warning.pulse.very-low',
      description: 'Create Observation Confirm page very low pulse warning',
      defaultMessage:
        '<b>Your pulse is much lower than the normal range.</b> If you are yet to inform a clinician about your low reading, please seek medical advice.',
    },
    [ObservationWarningLevel.SoftLow]: {
      id: 'create-observations-confirm.warning.pulse.low',
      description: 'Create Observation Confirm page low pulse warning',
      defaultMessage:
        '<b>Your pulse is slightly lower than the normal range.</b> If it is continuously low, please seek medical advice.',
    },
    [ObservationWarningLevel.SoftHigh]: {
      id: 'create-observations-confirm.warning.pulse.elevated',
      description: 'Create Observation Confirm page elevated pulse warning',
      defaultMessage:
        '<b>Your pulse is slightly higher than the normal range.</b> If it is continuously above elevated, please seek medical advice.',
    },
    [ObservationWarningLevel.SevereHigh]: {
      id: 'create-observations-confirm.warning.pulse.high',
      description: 'Create Observation Confirm page high pulse warning',
      defaultMessage:
        '<b>Your pulse is much higher than the normal range.</b> If you are yet to inform a clinician about your high reading, please seek medical advice.',
    },
  }),
  [ObservationType.BloodPressure]: defineMessages({
    [ObservationWarningLevel.SevereLow]: {
      id: 'create-observations-confirm.warning.blood-pressure.very-low',
      description:
        'Create Observation Confirm page very low blood pressure warning',
      defaultMessage:
        '<b>Your blood pressure is much lower than the normal range.</b> If you are yet to inform a clinician about your low reading, please seek medical advice.',
    },
    [ObservationWarningLevel.SevereHigh]: {
      id: 'create-observations-confirm.warning.blood-pressure.high',
      description:
        'Create Observation Confirm page high blood pressure warning',
      defaultMessage:
        '<b>Your blood pressure is much higher than the normal range.</b> If you are yet to inform a clinician about your high reading, please seek medical advice.',
    },
  }),
  [ObservationType.Spo2]: defineMessages({
    [ObservationWarningLevel.SevereLow]: {
      id: 'create-observations-confirm.warning.spo2.very-low',
      description: 'Create Observation Confirm page very low spo2 warning',
      defaultMessage:
        '<b>Your SpO2 is much lower than the normal range.</b> If you are yet to inform a clinician about your low reading, please seek medical advice.',
    },
  }),
  [ObservationType.FastingBloodGlucose]: defineMessages({
    [ObservationWarningLevel.SevereHigh]: {
      id: 'create-observations-confirm.warning.fasting-blood-glucose.severe-high',
      description:
        'Create Observation Confirm page severe high fasting blood glucose warning',
      defaultMessage:
        '<b>Your fasting blood glucose is much higher than the normal range.</b> If you are yet to inform a clinician about your high reading, please seek medical advice.',
    },
    [ObservationWarningLevel.SevereLow]: {
      id: 'create-observations-confirm.warning.fasting-blood-glucose.severe-low',
      description:
        'Create Observation Confirm page severe low fasting blood glucose warning',
      defaultMessage:
        '<b>Your fasting blood glucose is much lower than the normal range.</b> If you are yet to inform a clinician about your low reading, please seek medical advice.',
    },
  }),
};
