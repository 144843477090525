import * as yup from 'yup';
import { isBefore, isValid, parse } from 'date-fns';
import {
  FUTURE_DATE_MESSAGE,
  INVALID_DATE_MESSAGE,
  INVALID_YEAR,
  REQUIRED_TEXT,
  UNDER_18_DATE_MESSAGE,
} from './intl';
import { maxLifeSpanYear, under18 } from './utils';

const dateValidation = yup
  .number()
  .typeError('Must be a number.')
  .required(REQUIRED_TEXT);

const yearValidation = dateValidation
  .min(maxLifeSpanYear(), INVALID_YEAR)
  .test({
    name: 'validDate',
    message: INVALID_DATE_MESSAGE,
    test: (_, ctx) => {
      const { dayOfBirth, monthOfBirth, yearOfBirth } = ctx.parent;
      return isValid(
        parse(
          `${dayOfBirth}/${monthOfBirth}/${yearOfBirth}`,
          'dd/MM/yyyy',
          new Date()
        )
      );
    },
  })
  .test({
    name: 'before',
    message: FUTURE_DATE_MESSAGE,
    test: (_, ctx) => {
      const { dayOfBirth, monthOfBirth, yearOfBirth } = ctx.parent;
      if (!yearOfBirth || !monthOfBirth || !dayOfBirth) {
        return true;
      }
      return isBefore(
        new Date(yearOfBirth, monthOfBirth - 1, dayOfBirth),
        new Date()
      );
    },
  })
  .test({
    name: 'under18',
    message: UNDER_18_DATE_MESSAGE,
    test: (_, ctx) => {
      const { dayOfBirth, monthOfBirth, yearOfBirth } = ctx.parent;
      if (!yearOfBirth || !monthOfBirth || !dayOfBirth) {
        return true;
      }
      return !under18({ dayOfBirth, monthOfBirth, yearOfBirth });
    },
  });

export const dateOfBirthSchema = {
  dayOfBirth: dateValidation,
  monthOfBirth: dateValidation,
  yearOfBirth: yearValidation,
};
