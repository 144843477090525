import {
  MainWrapper,
  Maintenance as MaintenanceComponent,
} from '@bt-healthcare/ui-toolkit';
import { isAfter, isBefore } from 'date-fns';
import { PropsWithChildren, useEffect, useState } from 'react';
import { getEnvVariable } from '../../env.utils';
import { type OutageProps } from './types';

const POLLING = 60000;

const startPollingOutage = async (
  callback: (arg0: OutageProps | null) => void
) => {
  try {
    const res = await fetch(
      `${getEnvVariable(
        'VITE_SERVICE_DOCS'
      )}/healthyyou-patient/web/metadata.json`
    );
    const json = await res.json();
    const outage = json?.data?.attributes?.outage;
    const now = new Date();

    const isOutage =
      outage &&
      isAfter(now, new Date(outage?.from)) &&
      isBefore(now, new Date(outage?.to));
    callback(isOutage ? { message: outage?.message } : null);
  } catch (e) {
    console.error('Maintenance page polling error', e);
  }
  setTimeout(() => {
    startPollingOutage(callback);
  }, POLLING);
};

export const OutageMonitor = ({ children }: PropsWithChildren<{}>) => {
  const [outage, setOutageProps] = useState<OutageProps | null>(null);
  useEffect(() => {
    startPollingOutage(setOutageProps);
  }, [setOutageProps]);

  return outage ? (
    <MainWrapper>
      <MaintenanceComponent {...outage} />
    </MainWrapper>
  ) : (
    <>{children}</>
  );
};
