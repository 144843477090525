import { defineMessage, defineMessages } from 'react-intl';

export const healthScoreHeightAndWeight = {
  header: defineMessage({
    id: 'health-score.height-and-weight.header',
    defaultMessage:
      'Adding your <indigo08>height and weight</indigo08> helps us fine-tune your score',
  }),
  explanationCard: defineMessage({
    id: 'health-score.height-and-weight.explanation-card',
    defaultMessage:
      'We get that weight can be a personal thing, however, we need this data to provide an accurate score for you.',
  }),
  button: defineMessage({
    id: 'health-score.height-and-weight.continue-button',
    defaultMessage: 'Calculate my score',
  }),
  fields: {
    height: defineMessages({
      label: {
        id: 'health-score.height-and-weight.height-label',
        defaultMessage: 'Height',
      },
      feetPlaceholder: {
        id: 'health-score.height-and-weight.feet-placeholder',
        defaultMessage: 'Feet',
      },
      inchesPlaceholder: {
        id: 'health-score.height-and-weight.inches-placeholder',
        defaultMessage: 'Inches',
      },
      centimetresPlaceholder: {
        id: 'health-score.height-and-weight.centimetres-placeholder',
        defaultMessage: 'Centimetres',
      },
    }),
    weight: defineMessages({
      label: {
        id: 'health-score.height-and-weight.weight-label',
        defaultMessage: 'Weight',
      },
      stonePlaceholder: {
        id: 'health-score.height-and-weight.stone-placeholder',
        defaultMessage: 'Stone',
      },
      poundsPlaceholder: {
        id: 'health-score.height-and-weight.pounds-placeholder',
        defaultMessage: 'Pounds',
      },
      kilogramsPlaceholder: {
        id: 'health-score.height-and-weight.kilograms-placeholder',
        defaultMessage: 'Kilograms',
      },
    }),
  },
};
