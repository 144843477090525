import { defineMessage, defineMessages } from 'react-intl';

export const vo2Intl = {
  header: defineMessage({
    id: 'vo2.header',
    defaultMessage: 'Cardio fitness (VO2 Max)',
  }),
  description: defineMessage({
    id: 'vo2.description',
    defaultMessage:
      'VO2 max is the maximum volume (V) of oxygen (O2) your body is able to use during exercise. Use the calculator below to predict your VO2 Max score.',
  }),
  label: defineMessage({
    id: 'vo2.label',
    defaultMessage: 'How long does it take you to run 2.4km?',
  }),
  fields: defineMessages({
    minutes: {
      id: 'vo2.input.minutes',
      defaultMessage: 'Minutes',
    },
    seconds: {
      id: 'vo2.input.seconds',
      defaultMessage: 'Seconds',
    },
  }),
  resultCard: defineMessage({
    id: 'vo2.result-card',
    defaultMessage: 'Predicted VO2 Max',
  }),
  explanation: defineMessage({
    id: 'vo2.explanation',
    defaultMessage: 'Please note this is not saved within the app.',
  }),
  button: defineMessage({
    id: 'vo2.button',
    defaultMessage: 'Back to home',
  }),
  tablesDescription: defineMessage({
    id: 'vo2.tables.description',
    defaultMessage: 'VO2 Max by Age, Sex, Fitness',
  }),
  tablesSource: defineMessage({
    id: 'vo2.tables.source',
    defaultMessage: 'Source: MayoClinProc. November2015;90(11):1515-1523',
  }),
};
