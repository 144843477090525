import { defineMessage, defineMessages } from 'react-intl';
import {
  GenderAssignedAtBirth,
  GenderIdentifiedAs,
} from '../../services/graphql';

export const healthScorePersonalInfo = {
  header: defineMessage({
    id: 'health-score-personal-info.header',
    defaultMessage:
      'Let’s start with adding your <indigo08>date of birth</indigo08> and <indigo08>gender</indigo08>',
  }),
  fields: {
    dateOfBirth: defineMessages({
      label: {
        id: 'health-score-personal-info.fields.date-of-birth.label',
        defaultMessage: 'Date of birth',
      },
    }),
    genderAssignedAtBirth: {
      label: defineMessage({
        id: 'health-score-personal-info.fields.gender-assigned-at-birth.label',
        defaultMessage: 'Gender assigned at birth',
      }),
      options: defineMessages({
        [GenderAssignedAtBirth.Male]: {
          id: 'health-score-personal-info.fields.gender-assigned-at-birth.option.male',
          defaultMessage: 'Male',
        },
        [GenderAssignedAtBirth.Female]: {
          id: 'health-score-personal-info.fields.gender-assigned-at-birth.option.female',
          defaultMessage: 'Female',
        },
      }),
    },
    genderIdentity: {
      label: defineMessage({
        id: 'health-score-personal-info.fields.gender-identity.label',
        defaultMessage: 'Gender identity (optional)',
      }),
      options: defineMessages({
        [GenderIdentifiedAs.Male]: {
          id: 'health-score-personal-info.fields.gender-identity.option.male',
          defaultMessage: 'Man/boy',
        },
        [GenderIdentifiedAs.Female]: {
          id: 'health-score-personal-info.fields.gender-identity.option.female',
          defaultMessage: 'Woman/girl',
        },
        [GenderIdentifiedAs.NonBinary]: {
          id: 'health-score-personal-info.fields.gender-identity.option.non-binary',
          defaultMessage: 'Non-binary/Gender fluid',
        },
        [GenderIdentifiedAs.PreferNone]: {
          id: 'health-score-personal-info.fields.gender-identity.prefer-none',
          defaultMessage: 'Prefer not to say',
        },
        [GenderIdentifiedAs.Other]: {
          id: 'health-score-personal-info.fields.gender-identity.option.other',
          defaultMessage: 'Other',
        },
        [GenderIdentifiedAs.Transwoman]: {
          id: 'health-score-personal-info.fields.gender-identity.option.trans-female',
          defaultMessage: 'Transwoman/Transgirl',
        },
        [GenderIdentifiedAs.Transman]: {
          id: 'health-score-personal-info.fields.gender-identity.option.trans-male',
          defaultMessage: 'Transman/Transboy',
        },
        [GenderIdentifiedAs.None]: {
          id: 'health-score-personal-info.fields.gender-identity.option.none',
          defaultMessage: '',
        },
      }),
    },
  },
  genderExplanation: defineMessage({
    id: 'health-score-personal-info.gender-explanation',
    defaultMessage:
      'Telling us your gender assigned at birth allows us to personalise your health recommendations.',
  }),
  preFilled: defineMessage({
    id: 'health-score-personal-info.pre-filled',
    defaultMessage: 'We’ve pre-filled some details to match your account.',
  }),
  age: defineMessage({
    id: 'health-score-personal-info.year-calculation',
    defaultMessage:
      'We make you <b>{age}</b>, which is a great age to optimise your health',
  }),
};
