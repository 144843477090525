import { useEffect, useMemo } from 'react';
import {
  Body,
  Col,
  Header,
  PageNotFound,
  Row,
} from '@bt-healthcare/ui-toolkit';
import { useIntl } from 'react-intl';
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { getData } from './data';
import { useTracking } from '../../hooks/useTracking';
import { CreatObservationsBlurb, HeaderContainer } from './styles';
import { ROUTE } from '../../config/routes';
import { useKnownObservation } from '../../hooks/useKnowObservations';
import {
  mapUrlPathToObservationType,
  observationPathWithSearchParams,
} from '../../formatters/observations';
import {
  CreateObservationFormRHF,
  CreateObservationsComponentProps,
} from './types';
import { CreateObservationsForm } from './CreateObservationsForm';
import { CreateObservationsFormWithToggle } from './CreateObservationsFormWithToggle';
import { hasGuide, urlSearchParamsToObservationFormInput } from './utils';
import { ObservationType } from '../../services/graphql';

const CreateObservationsComponent = ({
  type,
  initialValues,
  initialActiveUnit,
}: CreateObservationsComponentProps) => {
  const { trackPageWithAppName } = useTracking();
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (type) {
      trackPageWithAppName(type);
    }
  }, []);

  const { header, subtitle, fields, unitSystems } = useMemo(
    () => getData(type, intl),
    []
  )!;

  const setActiveUnit = (activeUnit: string) => {
    setSearchParams(createSearchParams({ unit: activeUnit }));
  };

  const handleFieldChange = (key: string, value: number) => {
    searchParams.set(key, value.toString());
    setSearchParams(searchParams);
  };

  const handleSubmit = (values: CreateObservationFormRHF) => {
    // for back navigation purpose - updating URL
    Object.entries(values).forEach(([key, value]) => {
      searchParams.set(key, value.toString());
    });
    setSearchParams(searchParams);
    navigate(
      observationPathWithSearchParams(
        type,
        ROUTE.CREATE_OBSERVATION_CONFIRM,
        searchParams
      )
    );
  };

  const navigateToGuide = hasGuide(type)
    ? () => {
        if (type === ObservationType.BloodPressure) {
          navigate(
            observationPathWithSearchParams(
              type,
              ROUTE.CREATE_OBSERVATION_GUIDE,
              searchParams
            )
          );
        }
      }
    : undefined;

  return (
    <>
      <HeaderContainer>
        <div>
          <Header>{header}</Header>
        </div>
        {subtitle && (
          <Row>
            <Col colEnd={{ xxs: 'span 4', md: 'span 7', xl: 'span 6' }}>
              <Body>
                <CreatObservationsBlurb data-testid="new-vital-blurb">
                  {subtitle}
                </CreatObservationsBlurb>
              </Body>
            </Col>
          </Row>
        )}
      </HeaderContainer>
      {unitSystems ? (
        <CreateObservationsFormWithToggle
          fields={fields}
          onSubmit={handleSubmit}
          unitSystems={unitSystems}
          initialValues={initialValues}
          initialActiveUnit={initialActiveUnit}
          handleActiveUnit={setActiveUnit}
          handleFieldChange={handleFieldChange}
          navigateToGuide={navigateToGuide}
        />
      ) : (
        <CreateObservationsForm
          fields={fields}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          handleFieldChange={handleFieldChange}
          navigateToGuide={navigateToGuide}
        />
      )}
    </>
  );
};

export const CreateObservations = () => {
  const { type: formattedType } = useParams<{ type: string }>();
  const type = mapUrlPathToObservationType(formattedType || '');
  const isKnownObservation = useKnownObservation();
  const [searchParams] = useSearchParams();

  const { searchParamsCreatedObservations, searchParamsUnit } = useMemo(
    () => urlSearchParamsToObservationFormInput(searchParams),
    [searchParams]
  );

  if (!type || !isKnownObservation(type)) {
    return <PageNotFound />;
  }
  return (
    <CreateObservationsComponent
      type={type}
      initialValues={searchParamsCreatedObservations}
      initialActiveUnit={searchParamsUnit}
    />
  );
};
