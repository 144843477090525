import styled from 'styled-components';
import { colors, fontSizes } from '@bt-healthcare/ui-toolkit';

export const DateLabel = styled.div`
  color: ${colors.base.white};
  font-size: ${fontSizes.sm};
`;

export const ValueLabel = styled.div`
  color: ${colors.base.white};
  font-size: ${fontSizes.sm};
  font-weight: 500;
`;
