import { Modal } from '@bt-healthcare/ui-toolkit';
import {
  ButtonsWrapper,
  ContentWrapper,
  Description,
  Header,
  PrimaryButton,
  PrimaryRedButton,
  SecondaryButton,
} from './styles';
import { BaseModalProps } from './types';

const BaseModal = ({
  children,
  onCancel,
  id,
  isModalOpen,
  overlayColor,
  maxWidth,
}: BaseModalProps) => (
  <Modal
    onClose={onCancel}
    id={id}
    isModalOpen={isModalOpen}
    overlayColor={overlayColor}
    maxWidth={maxWidth}
  >
    <ContentWrapper>{children}</ContentWrapper>
  </Modal>
);

BaseModal.ButtonsWrapper = ButtonsWrapper;
BaseModal.Header = Header;
BaseModal.Description = Description;
BaseModal.PrimaryButton = PrimaryButton;
BaseModal.PrimaryRedButton = PrimaryRedButton;
BaseModal.SecondaryButton = SecondaryButton;
export default BaseModal;
