import styled from 'styled-components';
import {
  Col,
  colors,
  device,
  Divider,
  fontSizes,
  Grid,
  Row,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: auto;

  position: relative;
`;

export const StyledGrid = styled(Grid)`
  gap: ${spacing.s6};
  height: 100%;
  grid-template-rows: 1;

  @media ${device.tablet} {
    gap: ${spacing.s8};
  }
`;
export const StyledCol = styled(Col).attrs({
  col: {
    xxs: '1 / span 4',
    md: '1 / span 8',
    lg: '2 / span 10',
    xl: '1 / span 7',
  },
})``;

export const DateLabel = styled.div`
  color: ${colors.grey.grey10};
  font-weight: 500;
  font-size: ${fontSizes.sm};
`;

export const StyledDivider = styled(Divider).attrs({
  flexColor: 'grey.grey04',
})``;

export const StyledLink = styled(Link)`
  color: ${colors.secondaryBlue.blue08};
  font-weight: 500;
  font-size: ${fontSizes.base};
  display: flex;
  align-items: center;
`;

export const StyledRow = styled(Row)`
  gap: ${spacing.s4};
`;

export const HeaderWrapper = styled.div<{ isGraphOn: boolean }>`
  padding: ${`${spacing.s4} ${spacing.s4}`};
  @media ${device.desktop} {
    padding: 0px;
    background-color: inherit;
  }
`;
