import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import BluePageWrapper from '../../components/BluePageWrapper';
import { RecommendationBulletList } from '../../components/RecommendationBulletList';
import { RecommendationBulletListItem } from '../../components/RecommendationBulletList/types';
import { ROUTE } from '../../config/routes';
import { useTracking } from '../../hooks/useTracking';
import {
  ObservationType,
  WardPatientHypertensionHomeBannerStatus,
  useGetPatientProgrammeInitiativeQuery,
} from '../../services/graphql';
import { recommendationHowDoesItWorksPage } from './intl';
import { ArrowIcon, CheckIcon, ListDescription, Warning } from './styles';
import { observationPath } from '../../formatters/observations';

export const RecommendationHowItWorks = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { trackPage, trackTapped } = useTracking();
  const { data, loading } = useGetPatientProgrammeInitiativeQuery({
    fetchPolicy: 'cache-first',
  });
  const isProgrammeActive =
    data?.userPatientProfileResults.__typename === 'UserPatientProfile' &&
    data?.userPatientProfileResults?.programmeInitiative
      .hypertensionHomeBanner ===
      WardPatientHypertensionHomeBannerStatus.InProgress;

  useEffect(() => {
    if (data && !isProgrammeActive) {
      trackPage('ABP insights how it works');
    }
  }, [isProgrammeActive, data]);

  const howDoesItWorkItems: RecommendationBulletListItem[] =
    recommendationHowDoesItWorksPage.howDoesItWorksList.map((item) => ({
      text: <p>{intl.formatMessage(item)}</p>,
      icon: ArrowIcon,
    }));

  const whatYouGetItems: RecommendationBulletListItem[] =
    recommendationHowDoesItWorksPage.whatYouGetList.map((item) => ({
      text: <p>{intl.formatMessage(item)}</p>,
      icon: CheckIcon,
    }));

  if (loading && !data) {
    return null;
  }

  const buttonLabel = isProgrammeActive
    ? recommendationHowDoesItWorksPage.takeAReading
    : recommendationHowDoesItWorksPage.continue;

  return (
    <BluePageWrapper>
      <BluePageWrapper.Content>
        <BluePageWrapper.Close isBreakRecommendationFlow={!isProgrammeActive} />
        <BluePageWrapper.Header>
          <FormattedMessage {...recommendationHowDoesItWorksPage.header} />
        </BluePageWrapper.Header>
        <RecommendationBulletList items={howDoesItWorkItems} />
        <ListDescription>
          <FormattedMessage
            {...recommendationHowDoesItWorksPage.whatYouGetTitle}
          />
        </ListDescription>
        <RecommendationBulletList items={whatYouGetItems} />
        <Warning>
          <FormattedMessage {...recommendationHowDoesItWorksPage.warning} />
        </Warning>
      </BluePageWrapper.Content>
      <BluePageWrapper.Bottom>
        <BluePageWrapper.Button
          onClick={() => {
            if (isProgrammeActive) {
              navigate(
                observationPath(
                  ObservationType.BloodPressure,
                  ROUTE.CREATE_OBSERVATION_NEW
                )
              );
              return;
            }
            navigate(ROUTE.RECOMMENDATION_CONFIRM_PLAN);
            trackTapped('ABP how it works - continue');
          }}
        >
          <FormattedMessage {...buttonLabel} />
        </BluePageWrapper.Button>
      </BluePageWrapper.Bottom>
    </BluePageWrapper>
  );
};
