import { ObservationCardLabelled, Row } from '@bt-healthcare/ui-toolkit';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { EMPTY_VALUE } from '../../App.constants';
import { ROUTE } from '../../config/routes';
import {
  observationPath,
  formatObservation,
} from '../../formatters/observations';
import { WardPatientObservation } from '../../services/graphql';
import { GridItem } from './styles';

export const Item = ({
  observation,
}: {
  observation: WardPatientObservation | null;
}) => {
  const intl = useIntl();
  const type = observation?.attributes.observationType;
  if (!type || !observation || !observation.attributes.res_observationValue) {
    return null;
  }
  const values = formatObservation(
    observation.attributes.res_observationValue,
    intl
  ).map(
    ({ label, value }) => [label || EMPTY_VALUE, value] as [string, string]
  );
  return (
    <Row>
      <GridItem>
        <Link to={observationPath(type, ROUTE.HISTORICAL_READINGS_TYPE)}>
          <ObservationCardLabelled
            id={observation.id}
            data-testid={observation.id}
            date={new Date(observation.attributes.recordedDateTime)}
            values={values}
            borderRadius={8}
          />
        </Link>
      </GridItem>
    </Row>
  );
};
