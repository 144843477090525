import { IFlagsmithFeature } from 'flagsmith/types';

export enum FeatureFlags {
  REMOTE_CARE_AND_NAVIGATION = 'web_hyp_remote_care_and_navigation',
  NAVIGATION_STANDALONE = 'web_hyp_navigation_standalone_registration',
  HEALTH_SCORE_3994 = 'web_hyp_ccare_3994',
  HEALTH_SCORE = 'web_hyp_healthscore',
  VO2 = 'web_hyp_ccare_4368',
}

export type Flags = {
  [key in FeatureFlags]: IFlagsmithFeature;
};
