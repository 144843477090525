import { MailTo, MailtoKeys } from './types';

export const encodeMailtoURL = ({ recipient, ...params }: MailTo): string =>
  `mailto:${recipient}${
    Object.keys(params).length === 0 ? '' : '?'
  }${Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')}`;

export const decodeMailtoURL = (mailto: string): MailTo => {
  const MailtoURL = new URL(mailto);
  const mailtoObject: MailTo = { recipient: MailtoURL.pathname };
  MailtoURL.searchParams.forEach((value, key) => {
    mailtoObject[key as MailtoKeys] = value;
  });
  return mailtoObject;
};
