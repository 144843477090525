import { defineMessages } from 'react-intl';

export const recommendationConfirmPlanErrorModal = defineMessages({
  header: {
    id: 'recommendation.confirm-plan-modal-error.header',
    defaultMessage: 'There was an issue confirming your plan',
  },
  description: {
    id: 'Please check your internet connection and try again.',
    defaultMessage: 'Please check your internet connection and try again.',
  },
  primaryButton: {
    id: 'recommendation.confirm-plan-modal-error.primary-button',
    defaultMessage: 'Try again',
  },
  secondaryButton: {
    id: 'recommendation.confirm-plan-modal-error.secondary-button',
    defaultMessage: 'Cancel',
  },
});
