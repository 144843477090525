import { ErrorGPLinkage } from './ErrorGPLInkage';
import { Props } from './FindYourLinkageInformation';

export const UnableToLinkGP = (props: Props) => (
  <ErrorGPLinkage
    {...props}
    id="gp-linkage-unable-to-link-page-modal"
    header={`We weren't able to link your GP practice`}
    subtitle="It’s possible that the details you entered were incorrect. Please make sure the formatting of the codes match the letter or email you received."
  />
);
