import { useEffect } from 'react';
import { Policy } from '../../components/Policy/Policy';
import { PolicyPageProps, PolicyType } from '../../components/Policy/types';
import { useTracking } from '../../hooks/useTracking';

export const PublicPolicy = ({ type }: PolicyPageProps) => {
  const { trackPageWithAppName } = useTracking();
  useEffect(() => {
    switch (type) {
      case PolicyType.COOKIE:
        trackPageWithAppName('Cookie policy');
        break;
      case PolicyType.PRIVACY:
        trackPageWithAppName('Privacy policy');
        break;
      case PolicyType.TERMS_AND_CONDITIONS:
        trackPageWithAppName('Terms and conditions');
        break;
      default:
        break;
    }
  }, [type]);

  return <Policy type={type} />;
};
