import { smokingStatusOptions } from '../pages/HealthInformation/intl';
import { getFullFeetAndInchesFromInches } from './inchFeet';
import {
  bloodPressureMedicationDisplay,
  cardiovascularDiseaseFamilyHadCvdDisplay,
  cardiovascularDiseaseHasCvdDisplay,
  chronicKidneyDiseaseDisplay,
  diabetesStatusDisplay,
  heightInformationDisplay,
} from '../pages/HealthInformationList/intl';
import { HeightUnit } from '../services/graphql';
import { ToHealthInformationDisplay } from './healthInformation.types';
import { toHealthInformationInfo } from '../mappers/healthInformation';

export const toHealthInformationDisplay = ({
  healthInformation,
  latestHealthInformation,
  intl,
}: ToHealthInformationDisplay): string[] | undefined => {
  const info = toHealthInformationInfo(
    healthInformation,
    latestHealthInformation
  );
  if (!info) return undefined;
  switch (info.__typename) {
    case 'Smoking':
      return [intl.formatMessage(smokingStatusOptions[info.status])];
    case 'Height': {
      const { unit, value } = info;
      const formattedValues =
        unit === HeightUnit.Inch && value
          ? getFullFeetAndInchesFromInches({ inch: value })
          : { cm: value };
      return [
        intl.formatMessage(heightInformationDisplay[unit], formattedValues),
      ];
    }
    case 'BloodPressureMedication': {
      const { hasBPMedication, hadBPMedication } = info;
      const hasBPMedicationDisplayValue = intl.formatMessage(
        hasBPMedication
          ? bloodPressureMedicationDisplay.hasBPMedicationYes
          : bloodPressureMedicationDisplay.hasBPMedicationNo
      );
      const hadBPMedicationDisplayValue = intl.formatMessage(
        hadBPMedication
          ? bloodPressureMedicationDisplay.hadBPMedicationYes
          : bloodPressureMedicationDisplay.hadBPMedicationNo
      );
      return [hasBPMedicationDisplayValue, hadBPMedicationDisplayValue];
    }
    case 'Ckd':
      return [
        intl.formatMessage(
          chronicKidneyDiseaseDisplay[info.ckdStatus ? 'statusYes' : 'statusNo']
        ),
      ];
    case 'Cvd': {
      const { hasCvd, familyHadCvd } = info;
      return [
        intl.formatMessage(
          hasCvd
            ? cardiovascularDiseaseHasCvdDisplay.yes
            : cardiovascularDiseaseHasCvdDisplay.no
        ),
        intl.formatMessage(
          cardiovascularDiseaseFamilyHadCvdDisplay[familyHadCvd]
        ),
      ];
    }
    case 'Diabetes': {
      const { diabetesStatus } = info;
      return [intl.formatMessage(diabetesStatusDisplay[diabetesStatus])];
    }
    default:
      return undefined;
  }
};
