import { defineMessages } from 'react-intl';

export const notificationEmailUnsubscribedSuccess = defineMessages({
  header: {
    id: 'notification-email-unsubscribed.title',
    defaultMessage: 'You’ve unsubscribed from our mailing lists',
  },
  cta: {
    id: 'notification-email-unsubscribed.cta',
    defaultMessage: 'Back home',
  },
});
