import { colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const SelectedDateAppointmentsList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${spacing.s2};

  @media ${device.desktop} {
    gap: ${spacing.s3};
    align-self: flex-start;
    width: 380px;
  }
`;

export const SelectedDateAppointmentsListItem = styled.li`
  min-width: 31%;
`;

export const SelectedDateAppointmentButton = styled.button`
  width: 100%;
  border: none;
  background: none;
  padding: 0;

  #appointments-calendar-available-time-button {
    border-radius: 8px !important;
    padding: ${spacing.s4} ${spacing.s2};

    :hover {
      background-color: ${colors.primaryIndigo.indigo01};
      cursor: pointer;
    }
  }
`;

export const MobileSubHeader = styled.div`
  @media ${device.desktop} {
    display: none;
  }
`;

export const AppointmentsCalendarWrapper = styled.div`
  @media ${device.desktop} {
    display: flex;
    justify-content: center;
    gap: ${spacing.s5};
  }
`;

export const DesktopHeader = styled.div`
  display: none;

  @media ${device.desktop} {
    display: block;
    text-align: center;
  }
`;

export const DatePickerWrapper = styled.div`
  max-height: 380px;
`;
