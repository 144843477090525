import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Spacer } from '@bt-healthcare/ui-toolkit';

import { PublicContentCardFormWrapper } from '../../components/PublicContentCard/styles';

import { schema } from './data';
import {
  OnboardingPasswordFormProps,
  OnboardingPasswordFormValues,
} from './types';
import { PasswordValidator } from '../../components/PasswordValidator';
import {
  PASSWORD_REQUIREMENT_LOWERCASE_CHARACTER,
  PASSWORD_REQUIREMENT_MINIMUM_CHARACTERS,
  PASSWORD_REQUIREMENT_NUMBER_CHARACTER,
  PASSWORD_REQUIREMENT_UPPERCASE_CHARACTER,
} from '../../components/PasswordValidator/utils';
import { useTracking } from '../../hooks/useTracking';

export const OnboardingPasswordForm = ({
  createSelfAdmittedPatient,
  loading,
  selfAdmittedPatientPayload,
  selfAdmittedPatientError,
  selfAdmittedPatientValidationError,
}: OnboardingPasswordFormProps) => {
  const { trackEvent } = useTracking();

  const form = useForm<OnboardingPasswordFormValues>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    formState: { isValid },
    handleSubmit,
  } = form;

  const submit = handleSubmit(({ password }) => {
    const input = {
      ...selfAdmittedPatientPayload,
      password,
    };

    createSelfAdmittedPatient({
      variables: {
        input,
      },
      context: {
        isAnonymous: true,
      },
    });
  });

  const error = selfAdmittedPatientError?.graphQLErrors?.[0];
  const validationErrorType = selfAdmittedPatientValidationError?.__typename;

  return (
    <PublicContentCardFormWrapper
      onSubmit={submit}
      data-testid="onboarding-password-form"
    >
      <PasswordValidator
        form={form}
        errors={
          error || validationErrorType
            ? {
                commonPassword:
                  validationErrorType ===
                    'Auth0CommonPasswordValidationError' ||
                  validationErrorType === 'Auth0UserInfoValidationError',
                userExists:
                  validationErrorType === 'Auth0ConflictValidationError',
                generic: !!error,
              }
            : undefined
        }
        requirements={[
          PASSWORD_REQUIREMENT_MINIMUM_CHARACTERS,
          PASSWORD_REQUIREMENT_UPPERCASE_CHARACTER,
          PASSWORD_REQUIREMENT_LOWERCASE_CHARACTER,
          PASSWORD_REQUIREMENT_NUMBER_CHARACTER,
        ]}
      />
      <Spacer size="s6" />
      <Button
        variant="primary"
        disabled={!isValid}
        isLoading={loading}
        id="continue"
        type="submit"
        role="button"
        tabIndex={0}
        onClick={() => trackEvent('Continue', 'click')}
      >
        <FormattedMessage
          id="onboarding-password.continue"
          defaultMessage="Continue"
        />
      </Button>
    </PublicContentCardFormWrapper>
  );
};
