import { colors } from '@bt-healthcare/ui-toolkit';
import { ObservationType } from '../../services/graphql';
import { DateFilter } from './types';

export const bottomTickFormat = ({
  width,
  dateFilter,
}: {
  width: number;
  dateFilter: DateFilter;
}) => {
  if (dateFilter === DateFilter.TODAY) {
    return '%H:%M';
  }
  if (width > 700) {
    return '%a %-d %b';
  }
  return '%-d %b';
};

export const seriesChartConfigMap = [
  {
    line: colors.secondaryBlue.blue10,
    glyph: colors.secondaryBlue.blue10,
  },
  {
    line: colors.secondaryBlue.blue06,
    glyph: colors.secondaryBlue.blue06,
  },
];

export const ObservationTypeMap: Partial<{
  [key in ObservationType]: { min: number; max: number };
}> = {
  [ObservationType.BloodPressure]: { min: 30, max: 250 },
  [ObservationType.Pulse]: { min: 1, max: 300 },
  [ObservationType.Spo2]: { min: 1, max: 100 },
};

export const ScaleOffsetMap: Partial<{
  [key in ObservationType]: number;
}> = {
  [ObservationType.BloodPressure]: 20,
  [ObservationType.Pulse]: 20,
  [ObservationType.Spo2]: 10,
};

export const MARGIN = { top: 10, right: 20, bottom: 50, left: 30 };

export const LINE_CHART_HEIGHT = 290;
export const LINE_CHART_WIDTH = 660;

export const BOTTOM_TICK_LENGTH = 8;

export const TOOLTIP_TOP_OFFSET = -72;

export const TOOLTIP_LEFT_OFFSET = MARGIN.left - MARGIN.right;

export const DATE_FORMAT = '%Y-%m-%dT%H:%M:%S.%LZ';
export const GLYPH_SIZE = 4;

// Display glyph points when they're positioned in the point 0
export const CLIP_OFFSET = 2 * GLYPH_SIZE;
