import { defineMessage } from 'react-intl';
import { Vo2TableVariants } from './types';

export const vo2Table = {
  header: defineMessage({
    id: 'vo2.table.header.header',
    defaultMessage: 'Performance group by predicted VO2 max (ml/kg/min)',
  }),
  [Vo2TableVariants.WOMEN]: defineMessage({
    id: 'vo2.table.header.women',
    defaultMessage: 'WOMEN',
  }),
  [Vo2TableVariants.MEN]: defineMessage({
    id: 'vo2.table.header.men',
    defaultMessage: 'MEN',
  }),
  columns: [
    defineMessage({
      id: 'vo2.table.column.age',
      defaultMessage: 'Age',
    }),
    defineMessage({
      id: 'vo2.table.column.poor',
      defaultMessage: 'Poor',
    }),
    defineMessage({
      id: 'vo2.table.column.fair',
      defaultMessage: 'Fair',
    }),
    defineMessage({
      id: 'vo2.table.column.good',
      defaultMessage: 'Good',
    }),
    defineMessage({
      id: 'vo2.table.column.excellent',
      defaultMessage: 'Excellent',
    }),
    defineMessage({
      id: 'vo2.table.column.Superior',
      defaultMessage: 'Superior',
    }),
  ],
};
