import styled from 'styled-components';
import { spacing } from '@bt-healthcare/ui-toolkit';

export const LineChartWrapper = styled.div`
  position: relative;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.s6};
`;
