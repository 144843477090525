import { PureObservationValue } from './types';

export const formatObservationWithoutUnit = (
  observationValue: PureObservationValue
) => {
  if (observationValue.__typename === 'Pulse')
    return observationValue.bpm.toString();
  if (observationValue.__typename === 'Spo2')
    return observationValue.oxygenSaturation.toString();
  if (observationValue.__typename === 'BloodPressure')
    return `${observationValue.systolic}/${observationValue.diastolic}`;
  return '';
};
