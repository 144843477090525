import { useIntl } from 'react-intl';
import {
  BadgeContainer,
  Description,
  InsightsBarContainer,
  ItemContainer,
  Line,
  MiddleLine,
  MobileSpan,
  Span,
  Value,
} from './styles';
import { RatingBadge } from '../RatingBadge';
import { insightsBar } from './intl';
import { InsightsBarTypes } from './types';
import { formatObservationWithoutUnit } from './utils';

export const InsightsBar = ({
  type,
  dateFilter,
  max,
  min,
  average,
}: InsightsBarTypes) => {
  const intl = useIntl();

  const minLabel = formatObservationWithoutUnit(min);
  const maxLabel = formatObservationWithoutUnit(max);
  const averageLabel = formatObservationWithoutUnit(average);
  const avgRating = 'rating' in average && average.rating;

  if (!avgRating) return null;

  return (
    <InsightsBarContainer>
      <ItemContainer>
        <Value>{averageLabel}</Value>
        <Description>
          <MobileSpan>
            {intl.formatMessage(insightsBar[`${dateFilter}-mobile`])}
          </MobileSpan>
          <Span>{intl.formatMessage(insightsBar[dateFilter])}</Span>
        </Description>
      </ItemContainer>
      <Line />
      <BadgeContainer>
        <RatingBadge
          type={type}
          patientRating={avgRating}
          variant="insightBar"
        />
      </BadgeContainer>
      <MiddleLine />
      <ItemContainer>
        <Value>{maxLabel}</Value>
        <Description>{intl.formatMessage(insightsBar.max)}</Description>
      </ItemContainer>
      <Line />
      <ItemContainer>
        <Value>{minLabel}</Value>
        <Description>{intl.formatMessage(insightsBar.min)}</Description>
      </ItemContainer>
    </InsightsBarContainer>
  );
};
