import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import {
  DatePickerCalendar,
  Timestamp,
  Card,
  SubHeader,
  Stack,
  Spacer,
  Header,
  BTHealthIcon,
  colors,
  SpinnerModal,
  SpinnerLoader,
  noop,
} from '@bt-healthcare/ui-toolkit';

import { formatISO } from 'date-fns';
import { useTracking } from '../../hooks/useTracking';
import {
  AppointmentsCalendarWrapper,
  SelectedDateAppointmentsList,
  SelectedDateAppointmentButton,
  SelectedDateAppointmentsListItem,
  MobileSubHeader,
  DesktopHeader,
  DatePickerWrapper,
} from './styles';
import { ConfirmBloodTestBooking } from '../../components/Modal/ConfirmBloodTestBooking';
import {
  useBookGeneralPracticeAppointmentMutation,
  useGetGeneralPracticeAppointmentSlotQuery,
} from '../../services/graphql';
import { ConfirmBloodTestBookingError } from '../../components/Modal/ConfirmBloodTestBookingError';
import { ROUTE } from '../../config/routes';

import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { AppointmentsCalendarState, AppointmentSlot } from './types';
import { BookingSuccessState } from '../BookingSuccess/types';

export const AppointmentsCalendar = () => {
  const { trackPageWithAppName, trackEvent } = useTracking();
  const navigate = useNavigate();
  const location = useLocation();

  const { userProfile: dataUser } = useAuthUserProfile();

  const patientRegistration =
    dataUser?.person?.attributes.generalPracticeRegistration;

  const patientIdentifier = patientRegistration?.patientIdentifier as string;

  const [isOpenConfirmBookingModal, setIsOpenConfirmBookingModal] =
    useState<boolean>(false);

  const [isOpenConfirmBookingErrorModal, setIsOpenConfirmBookingErrorModal] =
    useState<boolean>(false);

  const [bookGeneralPracticeAppointmentMutation, { loading, data, error }] =
    useBookGeneralPracticeAppointmentMutation();

  const { data: appointments, loading: isLoadingAppointments } =
    useGetGeneralPracticeAppointmentSlotQuery({
      variables: {
        patientIdentifier: patientIdentifier!,
        days: 30,
      },
      skip: !patientIdentifier,
      context: { disableErrorHandler: true },
    });

  const { type } = location.state as AppointmentsCalendarState;

  const appointmentSlots = appointments?.appointments?.filter(
    (appointment) => appointment?.type === type
  )?.[0] as AppointmentSlot;

  const selectedTypeAppointmentSlots = [
    ...(appointmentSlots?.appointmentSlots || []),
  ];

  const availableDates = [
    ...new Set(
      selectedTypeAppointmentSlots.map(
        (appointmentSlot) =>
          appointmentSlot?.attributes?.startTime.toString().split('T')[0]
      )
    ),
  ].map((date) => new Date(`${date}`));

  const [selectedDate, setSelected] = useState<Date | undefined>();

  useEffect(() => {
    if (isLoadingAppointments === false) {
      setSelected(availableDates[0]);
    }
  }, [isLoadingAppointments]);

  useEffect(() => {
    trackPageWithAppName('Appointments calendar');
  }, []);

  const onCloseConfirmBookingModal = useCallback(() => {
    setIsOpenConfirmBookingModal(false);
  }, [setIsOpenConfirmBookingModal]);

  const appointmentSlotsForSelectedDate = selectedDate
    ? selectedTypeAppointmentSlots?.filter((appointmentSlot) =>
        new Date(appointmentSlot!.attributes.startTime)
          ?.toISOString()
          .includes(formatISO(selectedDate, { representation: 'date' }))
      )
    : [];

  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState<
    NonNullable<AppointmentSlot>['appointmentSlots'][number]
  >(appointmentSlotsForSelectedDate[0]);

  const onConfirmBookingSuccess = useCallback(() => {
    trackEvent('Confirm and book', 'click');
    onCloseConfirmBookingModal();
    const {
      attributes: { appointmentSlotId, startTime, endTime, locationName },
    } = selectedAppointmentSlot;

    const contactInformation = dataUser?.person?.attributes?.contactInformation;

    const personId = dataUser?.person?.id as string;

    bookGeneralPracticeAppointmentMutation({
      variables: {
        input: {
          appointmentSlotId,
          startTime,
          endTime,
          personId,
          practiceName: locationName as string,
          telephoneContactType: 'MOBILE',
          telephoneNumber: contactInformation?.primaryContactNumber as string,
          emailAddress: contactInformation?.primaryEmailAddress as string,
          bookingReason: 'Referred by GP',
          slotTypeName: selectedAppointmentSlot?.attributes.slotTypeName,
          gpOdsCode: patientRegistration?.odsCode as string,
        },
        patientIdentifier,
      },
    });
  }, [
    onCloseConfirmBookingModal,
    selectedAppointmentSlot,
    dataUser,
    bookGeneralPracticeAppointmentMutation,
  ]);

  useEffect(() => {
    if (data && selectedAppointmentSlot) {
      navigate(ROUTE.BOOKING_SUCCESS, {
        state: {
          date: new Date(selectedAppointmentSlot.attributes.startTime),
          location: selectedAppointmentSlot.attributes.locationName,
        } as BookingSuccessState,
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setIsOpenConfirmBookingErrorModal(true);
    }
  }, [error]);

  return (
    <>
      <DesktopHeader>
        <Stack space="none" id="">
          <Header>{type}</Header>
          <Spacer size="s2" />
          <Stack
            space="none"
            id="appointments-calendar-header-wrapper"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <BTHealthIcon icon="Time" color={colors.grey.grey08} />{' '}
            <FormattedMessage
              id="appointments-calendar.header"
              defaultMessage="15 minute appointment"
            />
          </Stack>
          <Spacer size="s14" />
        </Stack>
      </DesktopHeader>
      <AppointmentsCalendarWrapper data-testid="appointments-calendar-date-picker">
        <DatePickerWrapper>
          <DatePickerCalendar
            selected={selectedDate}
            startDate={availableDates}
            onChange={(date) => setSelected(new Date(date))}
            datesToInclude={availableDates}
            id=""
          />
        </DatePickerWrapper>
        <MobileSubHeader>
          <Stack space="s4" id="appointments-calendar-subheader-wrapper">
            <Spacer size="s4" />
            <Stack
              space="none"
              id="appointments-calendar-subheader-content"
              flexDirection="row"
              justifyContent="space-between"
            >
              <SubHeader>
                <FormattedMessage
                  id="appointments-calendar.subheader"
                  defaultMessage="Available times"
                />
              </SubHeader>
              {selectedDate ? (
                <Timestamp
                  type="dateDayNthMonth"
                  date={new Date(selectedDate)}
                  color="grey.grey08"
                />
              ) : (
                <SpinnerLoader
                  data-testid="selected-date-spinner"
                  id="selected-date-spinner"
                />
              )}
            </Stack>
            <Spacer />
          </Stack>
        </MobileSubHeader>
        <SelectedDateAppointmentsList>
          {appointmentSlotsForSelectedDate?.map((appointmentSlot) => (
            <SelectedDateAppointmentsListItem key={uuidv4()}>
              <SelectedDateAppointmentButton
                type="button"
                onClick={() => {
                  trackEvent('Appointment time', 'click');
                  setSelectedAppointmentSlot(appointmentSlot);
                  setIsOpenConfirmBookingModal(true);
                }}
              >
                <Card id="appointments-calendar-available-time-button">
                  <Timestamp
                    date={appointmentSlot.attributes.startTime}
                    type="time"
                  />
                </Card>
              </SelectedDateAppointmentButton>
            </SelectedDateAppointmentsListItem>
          ))}
        </SelectedDateAppointmentsList>
      </AppointmentsCalendarWrapper>
      {selectedAppointmentSlot && (
        <ConfirmBloodTestBooking
          isModalOpen={isOpenConfirmBookingModal}
          handleCancel={() => {
            trackEvent(`Go back`, 'click');
            onCloseConfirmBookingModal();
          }}
          handleSuccess={() => {
            trackEvent(`Confirm and Book`, 'click');
            onConfirmBookingSuccess();
          }}
          date={new Date(selectedAppointmentSlot.attributes.startTime)}
          location={selectedAppointmentSlot.attributes.locationName as string}
        />
      )}
      <ConfirmBloodTestBookingError
        isModalOpen={isOpenConfirmBookingErrorModal}
        handleClose={() => {
          trackEvent(`Try again`, 'click');
          setIsOpenConfirmBookingErrorModal(false);
        }}
      />
      {loading && (
        <SpinnerModal
          text="Booking"
          data-testid="appointments-calendar-spinner"
          id="appointments-calendar-spinner"
          size="lg"
          isModalOpen
          color="primaryIndigo.indigo08"
          onClose={noop}
        />
      )}
    </>
  );
};
