import type { NumberValue, ScaleLinear, ScaleTime } from 'd3-scale';
import {
  ObservationType,
  ObservationComponentName,
} from '../../services/graphql';

export type VisxTooltipEvent =
  | React.TouchEvent<SVGSVGElement | SVGCircleElement>
  | React.MouseEvent<SVGSVGElement | SVGCircleElement, MouseEvent>;

export type TimeParser = (dateString: string) => Date | NumberValue;

export type ObservationDataPoint = {
  name: ObservationComponentName;
  value: number;
};

export type ObservationData = {
  date: string;
  series: ObservationDataPoint[];
};

export type LineChartProps = {
  chartData: ObservationData[];
  dateRange: DateRange;
  height?: number;
  width?: number;
  observationType: ObservationType;
  precedingDataPoint?: ObservationData;
  dateFilter: DateFilter;
};

export type DataLineProps = Pick<
  LineChartProps,
  'chartData' | 'precedingDataPoint'
> & {
  xScale: ScaleTime<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  parseDate: TimeParser;
  idx: number;
  observationType: ObservationType;
  handleOnPointOver: (data: ObservationData) => void;
  handlePointLeave: () => void;
} & Omit<VisxTooltipProps, 'hideTooltip' | 'tooltipTop' | 'tooltipLeft'>;

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

export type DataPointProps = DataLineProps;

export type ObservationTypeUnit = 'bpm' | '%' | 'mmHg';

export type ObservationTypeLineChartProps = {
  wardPatientId: string;
  admissionDateTime: Date;
  observationType: ObservationType;
};

export enum DateFilter {
  MONTHLY = 'Month',
  TODAY = 'Today',
  WEEK = 'Week',
}

export type ObservationGraphProps = {
  type: ObservationType;
};

export type VisxTooltipProps = {
  tooltipOpen: boolean;
  tooltipData?: ObservationData;
  tooltipLeft: number;
  tooltipTop: number;
  hideTooltip: () => void;
};
