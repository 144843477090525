import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${device.tablet} {
    width: 412px;
  }
`;

export const HeroContainer = styled.div`
  margin-top: ${spacing.s6};
  width: 216px;
  height: 140px;

  @media ${device.tablet} {
    width: 318px;
    height: 180px;
  }

  @media ${device.desktop} {
    width: 413px;
    height: 210px;
  }
`;
