import {
  Grid,
  Col,
  Header,
  Text,
  colors,
  fontSizes,
  SubHeader,
  Stack,
  Card,
  BTHealthIcon,
  Button,
} from '@bt-healthcare/ui-toolkit';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useTracking } from '../../hooks/useTracking';
import {
  BodyText,
  CardContent,
  Container,
  Content,
  SetupLaterContainer,
} from './styles';
import { ROUTE } from '../../config/routes';

const ChecklistItem = ({ text, id }: { text: string; id: string }) => (
  <Stack flexDirection="row" space="s3" id={id} alignItems="center">
    <BTHealthIcon
      icon="TickRounded"
      size={18}
      color={colors.primaryIndigo.indigo08}
    />
    <Text color={colors.grey.grey08}>
      <FormattedMessage
        id={`gp-linkage-get-ready-page.card.checklist-${id}`}
        defaultMessage={text}
      />
    </Text>
  </Stack>
);

export const GetReadyGPLinkage = () => {
  const { trackPageWithAppName, trackEvent } = useTracking();
  const navigate = useNavigate();

  useEffect(() => {
    trackPageWithAppName('GP linkage - get ready');
  }, []);

  return (
    <Container>
      <Content>
        <Grid>
          <Col colEnd={{ xxs: 'span 12' }}>
            <Stack space="s8" id="gp-linkage-content">
              <Header>
                <FormattedMessage
                  id="gp-linkage-get-ready-page.header"
                  defaultMessage="Link your GP practice"
                />
              </Header>

              <Text color={colors.grey.grey08} fontSize={fontSizes.base}>
                <BodyText>
                  <FormattedMessage
                    id="gp-linkage-get-ready-page.subtitle"
                    defaultMessage="We need to collect some details before you can book blood tests at your GP practice."
                  />
                </BodyText>
              </Text>

              <Card id="gp-linkage-get-ready-page.card" borderRadius={8}>
                <CardContent>
                  <Stack id="gp-linkage-get-ready-page.card-stack" space="s4">
                    <SubHeader>
                      <FormattedMessage
                        id="gp-linkage-get-ready-page.card.header"
                        defaultMessage="What you’ll need"
                      />
                    </SubHeader>
                    <Text color={colors.grey.grey08}>
                      <BodyText>
                        <FormattedMessage
                          id="gp-linkage-get-ready-page.card.description"
                          defaultMessage="A letter or email from your GP practice containing the following:"
                        />
                      </BodyText>
                    </Text>

                    <Stack
                      id="gp-linkage-get-ready-page.card-checklist"
                      space="s2"
                    >
                      <ChecklistItem
                        id="organisiation_code"
                        text="Your Organisation Code"
                      />
                      <ChecklistItem id="account_id" text="Your Account ID" />
                      <ChecklistItem id="passphrase" text="Your Passphrase" />
                    </Stack>

                    <Link to={ROUTE.GP_LINKAGE_CONNECT}>
                      <Button
                        id="get-started"
                        variant="primary"
                        onClick={() => trackEvent('Get started', 'click')}
                      >
                        <FormattedMessage
                          id="gp-linkage-get-ready-page.card.action"
                          defaultMessage="Get started"
                        />
                      </Button>
                    </Link>
                  </Stack>
                </CardContent>
              </Card>
              <SetupLaterContainer>
                <Button
                  id="setup-=later"
                  variant="tertiary"
                  onClick={() => {
                    trackEvent('Set up later', 'click');
                    navigate(ROUTE.ACCOUNT);
                  }}
                >
                  <Text color={colors.primaryIndigo.indigo08} fontWeight={500}>
                    <FormattedMessage
                      id="gp-linkage-get-ready-page.setup-later"
                      defaultMessage="Set up later"
                    />
                  </Text>
                </Button>
              </SetupLaterContainer>
            </Stack>
          </Col>
        </Grid>
      </Content>
    </Container>
  );
};
