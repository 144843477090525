import {
  Card,
  Notification,
  Skeleton,
  Spacer,
  Toggle,
} from '@bt-healthcare/ui-toolkit';
import type { ToggleItem } from '@bt-healthcare/ui-toolkit/dist/dts/components/Toggle/types';
import { ParentSize } from '@visx/responsive';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ObservationDataPointAggregation,
  useGetObservationDataPointsQuery,
} from '../../services/graphql';
import { LINE_CHART_HEIGHT } from './config';
import { LineGraph } from './LineGraph';
import { DateFilter, ObservationGraphProps } from './types';
import { dateFilterIntl, graph } from './intl';
import { observationToGraphData } from './utils';
import { HeaderContainer } from './styles';
import { days, useDateRange } from './useDateRange';
import { InsightsBar } from '../InsightsBar';

export const ObservationGraph = ({ type }: ObservationGraphProps) => {
  const intl = useIntl();
  const { startDate, endDate, setDateFilter, dateFilter } = useDateRange();

  const items: ToggleItem[] = [
    {
      value: DateFilter.TODAY,
      displayName: intl.formatMessage(dateFilterIntl[DateFilter.TODAY]),
    },
    {
      value: DateFilter.WEEK,
      displayName: intl.formatMessage(dateFilterIntl[DateFilter.WEEK]),
    },
    {
      value: DateFilter.MONTHLY,
      displayName: intl.formatMessage(dateFilterIntl[DateFilter.MONTHLY]),
    },
  ];

  const { data, loading, error } = useGetObservationDataPointsQuery({
    variables: {
      input: {
        aggregation:
          dateFilter === DateFilter.MONTHLY
            ? ({
                interval: 'day',
              } as ObservationDataPointAggregation)
            : null,
        filters: {
          startDate: startDate?.toISOString() || '',
          endDate: endDate.toISOString(),
          observationType: type,
        },
        size: null,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !startDate,
  });

  if (loading && !data) {
    return (
      <Skeleton
        id="line-chart-skeleton"
        data-testid="line-chart-skeleton"
        variant="rectangular"
        height={300}
        rounded
      />
    );
  }

  if (error) {
    return (
      <Notification type="softWarning">
        <FormattedMessage {...graph.genericError} />
      </Notification>
    );
  }

  const chartData =
    data?.observationDataPoints.searchResults.map(observationToGraphData) || [];
  const preceding = data?.observationDataPoints.precedingDataPoint
    ? observationToGraphData(data.observationDataPoints.precedingDataPoint)
    : undefined;

  const { average, min, max } = data?.observationDataPoints || {};

  return (
    <>
      <Card id="graph">
        <HeaderContainer>
          <Toggle
            items={items}
            activeItem={dateFilter}
            setActive={(filter: string) => setDateFilter(filter as DateFilter)}
          />
        </HeaderContainer>
        {!loading && data?.observationDataPoints.hits === 0 && (
          <>
            <Spacer size="s2" />
            <Notification
              type="softWarning"
              data-testid="notification-no-readings-chart"
            >
              <FormattedMessage
                {...graph.noReadingsLastNDays}
                values={{ days: days(dateFilter) }}
              />
            </Notification>
          </>
        )}
        <Spacer size="s8" />
        <ParentSize>
          {({ width }) => (
            <LineGraph
              observationType={type}
              chartData={chartData}
              dateRange={{
                startDate,
                endDate,
              }}
              width={width}
              height={LINE_CHART_HEIGHT}
              precedingDataPoint={preceding}
              dateFilter={dateFilter}
            />
          )}
        </ParentSize>
      </Card>
      {min && max && average && (
        <InsightsBar
          type={type}
          dateFilter={dateFilter}
          average={average}
          min={min}
          max={max}
        />
      )}
    </>
  );
};
