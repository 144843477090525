import {
  Body,
  Button,
  Col,
  colors,
  Header,
  Input,
  noop,
  Row,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { isNil } from 'ramda';
import {
  ButtonWrapper,
  CreatObservationsBlurb,
  HeaderContainer,
  InputsContainer,
  StyledForm,
} from '../CreateObservations/styles';
import {
  Label,
  LabelWrapper,
  ResultDescription,
  ResultValue,
  TablesSource,
  Vo2TablesWrapper,
} from './styles';
import { data } from './data';
import { vo2Intl } from './intl';
import { prepareSchema } from '../../utils/yup/schema';
import { disallowExponentialInputs } from '../CreateObservations/data';
import { ROUTE } from '../../config/routes';
import BaseCard from '../../components/Cards/BaseCard';
import { Vo2Form } from './types';
import { vo2 } from './utils';
import { Vo2Table } from '../../components/Vo2Table';
import { Vo2TableVariants } from '../../components/Vo2Table/types';

export const VO2 = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { fields } = data(intl);

  const schema = prepareSchema(fields);

  const {
    formState: { errors, isValid },
    register,
    watch,
  } = useForm<Vo2Form>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });
  const [minutes, seconds] = watch(['minutes', 'seconds']);

  const vo2Value: string | undefined = useMemo(() => {
    if (
      (minutes === 0 && !seconds) ||
      isNil(minutes) ||
      Number.isNaN(minutes)
    ) {
      return undefined;
    }
    return vo2(minutes, Number.isNaN(seconds) || isNil(seconds) ? 0 : seconds);
  }, [minutes, seconds, isValid]);

  return (
    <>
      <HeaderContainer>
        <Header>
          <FormattedMessage {...vo2Intl.header} />
        </Header>
        <Row>
          <Col colEnd={{ xxs: 'span 4', md: 'span 7', xl: 'span 6' }}>
            <Body>
              <CreatObservationsBlurb data-testid="new-vital-blurb">
                <FormattedMessage {...vo2Intl.description} />
              </CreatObservationsBlurb>
            </Body>
          </Col>
        </Row>
      </HeaderContainer>
      <LabelWrapper>
        <Label>
          <FormattedMessage {...vo2Intl.label} />
        </Label>
      </LabelWrapper>
      <StyledForm onSubmit={noop}>
        <Row>
          <Col colEnd={{ xxs: 'span 4', md: 'span 6', xl: 'span 5' }}>
            <InputsContainer>
              {fields.map(({ name, label }) => (
                <Input
                  id={name}
                  key={name}
                  label={label}
                  type="number"
                  errorText={errors[name]?.message}
                  {...register(name, {
                    valueAsNumber: true,
                  })}
                  {...disallowExponentialInputs()}
                />
              ))}
              {vo2Value && (
                <>
                  <BaseCard id="vo2Card">
                    <BaseCard.ItemLeft flexDirection="column" gap={16}>
                      <ResultValue>{vo2Value}</ResultValue>
                      <ResultDescription>
                        <FormattedMessage {...vo2Intl.resultCard} />
                      </ResultDescription>
                    </BaseCard.ItemLeft>
                  </BaseCard>
                  <ResultDescription>
                    <FormattedMessage {...vo2Intl.explanation} />
                  </ResultDescription>
                </>
              )}
            </InputsContainer>
          </Col>
        </Row>
      </StyledForm>
      {vo2Value && (
        <Row>
          <Col colEnd={{ xxs: 'span 4', md: 'span 6', xl: 'span 5' }}>
            <ButtonWrapper>
              <Button
                id="submit-reading-btn"
                variant="primary"
                size="maximum"
                onClick={() => {
                  navigate(ROUTE.HOME);
                }}
              >
                <FormattedMessage {...vo2Intl.button} />
              </Button>
            </ButtonWrapper>
          </Col>
        </Row>
      )}
      <Row>
        <Col colEnd={{ xxs: 'span 4', md: 'span 6', xl: 'span 5' }}>
          <Vo2TablesWrapper>
            <Text color={colors.grey.grey10} fontWeight={500}>
              <FormattedMessage {...vo2Intl.tablesDescription} />
            </Text>
            <Vo2Table variant={Vo2TableVariants.WOMEN} />
            <Vo2Table variant={Vo2TableVariants.MEN} />
            <TablesSource>
              <FormattedMessage {...vo2Intl.tablesSource} />
            </TablesSource>
          </Vo2TablesWrapper>
        </Col>
      </Row>
    </>
  );
};
