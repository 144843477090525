import { Stack, Text, colors } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import {
  CommonlyAskedQuestionContainer,
  CommonlyAskedQuestionText,
  CommonlyAskedQuestionTitle,
} from './CommonlyAskedQuestion.styles';
import { CommonlyAskedQuestionProps } from './types';

export const CommonlyAskedQuestion = ({
  text,
  title,
  id,
}: CommonlyAskedQuestionProps) => (
  <CommonlyAskedQuestionContainer>
    <Stack id={`${id}-stack`} space="s4">
      <Text color={colors.grey.grey10}>
        <CommonlyAskedQuestionTitle>{title}</CommonlyAskedQuestionTitle>
      </Text>
      <Text color={colors.grey.grey07}>
        <CommonlyAskedQuestionText>
          <FormattedMessage
            id={`${id}-text`}
            defaultMessage={text.message}
            values={text.values}
          />
        </CommonlyAskedQuestionText>
      </Text>
    </Stack>
  </CommonlyAskedQuestionContainer>
);
