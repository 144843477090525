import styled from 'styled-components';
import { device, Grid, spacing } from '@bt-healthcare/ui-toolkit';

export const StyledGrid = styled(Grid)`
  gap: ${spacing.s4};
`;

export const HeaderWrapper = styled.div`
  margin-bottom: ${spacing.s4};
  @media ${device.tablet} {
    margin-bottom: ${spacing.s6};
  }
  @media ${device.desktop} {
    margin-bottom: ${spacing.s8};
  }
`;

export const HeaderContainer = styled.div`
  margin-bottom: ${spacing.s6};
  @media ${device.tablet} {
    margin-bottom: ${spacing.s12};
  }
`;
