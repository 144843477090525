import {
  ModalBody,
  Button,
  Stack,
  Modal,
  Text,
  ModalFooter,
  colors,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { maxTabletWidth } from '../../utils';
import { ButtonsWrapper } from './styles';
import { AppointmentCardProps } from '../Cards/AppointmentCard/types';
import { AppointmentCard } from '../Cards/AppointmentCard';
import { HeaderText } from './FindYourLinkageInformation.styles';
import { ConfirmBloodTestBookingProps } from './types';

export const ConfirmBloodTestBooking = ({
  date,
  location,
  isModalOpen,
  handleCancel,
  handleSuccess,
}: ConfirmBloodTestBookingProps & AppointmentCardProps) => (
  <Modal
    onClose={handleCancel}
    isModalOpen={isModalOpen}
    id="confirm-blood-test-booking-modal"
    width="523px"
    maxWidth="523px"
  >
    <ModalBody>
      <Stack space="s4" id="confirmation">
        <Text
          color={colors.grey.grey10}
          fontWeight={500}
          data-testid="confirm-booking-header"
        >
          <HeaderText>
            <FormattedMessage
              id="confirm-blood-test-booking.confirm-reading.title"
              defaultMessage="Confirm your blood test booking"
            />
          </HeaderText>
        </Text>
        <AppointmentCard date={date} location={location} />
      </Stack>
      <ModalFooter>
        <ButtonsWrapper maxMobileWidth={maxTabletWidth}>
          <Button id="cancel" onClick={handleCancel} variant="secondary">
            <FormattedMessage
              id="confirm-blood-test-booking.confirm-reading.button.go-back"
              defaultMessage="Go Back"
            />
          </Button>
          <Button id="submit" onClick={handleSuccess} variant="primary">
            <FormattedMessage
              id="confirm-blood-test-booking.confirm-reading.button.confirm-and-book"
              defaultMessage="Confirm and book"
            />
          </Button>
        </ButtonsWrapper>
      </ModalFooter>
    </ModalBody>
  </Modal>
);
