import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { join } from 'ramda';
import { Outlet, useLocation } from 'react-router-dom';

import { PatientPage, Profile, SpinnerLoader } from '@bt-healthcare/ui-toolkit';

import { ROUTE } from '../../config/routes';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { useLogout } from '../../hooks/useLogout';
import { useSideMenuNavItems } from '../../hooks/useSideMenuNavItems';
import { createURL } from '../../utils';
import { ApolloErrorBoundary } from '../ApolloErrorBoundary';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export const WardPatientRoute = () => {
  const { logout, user } = useAuth0();
  const client = useApolloClient();
  const { userProfile: data, loading } = useAuthUserProfile();
  const handleLogout = useLogout();
  const flags = useFeatureFlags();

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const wardPatientId = data?.wardPatient?.id;

  const displayName = data
    ? join(' ', [
        data.person?.attributes.personalInformation.firstName,
        data.person?.attributes.personalInformation.surname,
      ])
    : '';

  const profile: Profile = {
    mail: user?.email || '',
    organisation: data?.careSetting?.attributes.name || 'BT Health',
    organisationShortName: data?.careSetting?.attributes.shortName || 'BH',
    displayName,
  };

  if (loading && !data) {
    return (
      <SpinnerLoader id="ward-patient-route" data-testid="ward-patient-route" />
    );
  }
  if (!wardPatientId) {
    client.clearStore();
    logout({
      logoutParams: {
        returnTo: createURL(ROUTE.DISCHARGED),
      },
    });
    return null;
  }
  const menuConfig = useSideMenuNavItems();

  return (
    <PatientPage
      profile={profile}
      showThemeToggle={false}
      menuConfig={menuConfig}
      onLogout={handleLogout}
      onDarkBackground={isHomePage && flags.HEALTH_SCORE}
    >
      <ApolloErrorBoundary>
        <Outlet />
      </ApolloErrorBoundary>
    </PatientPage>
  );
};
