import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import HealthScorePageWrapper from '../../components/HealthScorePageWrapper';
import { useAppState } from '../../context/AppContext';
import { ROUTE } from '../../config/routes';

const healthScorePageSteps = [
  ROUTE.HEALTH_SCORE_PERSONAL_INFO,
  ROUTE.HEALTH_SCORE_STRENGTH,
  ROUTE.HEALTH_SCORE_ALCOHOL_FREQUENCY,
  ROUTE.HEALTH_SCORE_ALCOHOL_MODERATION,
  ROUTE.HEALTH_SCORE_SMOKING,
  ROUTE.HEALTH_SCORE_HEIGHT_AND_WEIGHT,
];

export const HealthScoreNavigator = () => {
  const state = useAppState();
  const navigate = useNavigate();
  const currentPage = state.healthScore?.currentPage;

  useEffect(() => {
    if (!currentPage) {
      return;
    }
    navigate(currentPage);
  }, [currentPage]);

  const currentStep = healthScorePageSteps.indexOf(currentPage as ROUTE);
  const currentProgress =
    ((currentStep + 1) / healthScorePageSteps.length) * 100;

  return (
    <HealthScorePageWrapper progress={currentProgress}>
      <Outlet />
    </HealthScorePageWrapper>
  );
};
