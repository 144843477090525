import { MutationResult } from '@apollo/client';
import { Response } from '../types/response';

const getStatusFromGraphqlErrorResponse = <GraphqlResponse>(
  response: MutationResult<GraphqlResponse>
) => {
  const error = response?.error?.graphQLErrors?.[0];

  if (error) {
    return (error?.extensions?.response as Response)?.status;
  }

  return undefined;
};

export const isGraphQLError4xx = <GraphqlResponse>(
  response: MutationResult<GraphqlResponse>
) => {
  const status = getStatusFromGraphqlErrorResponse(response);

  if (status) {
    return status >= 400 && status < 500;
  }

  return false;
};

export const isGraphQLError5xx = <GraphqlResponse>(
  response: MutationResult<GraphqlResponse>
) => {
  const status = getStatusFromGraphqlErrorResponse(response);

  if (status) {
    return status >= 500 && status < 600;
  }

  return false;
};
