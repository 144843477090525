import {
  Col,
  Grid,
  Header,
  Notification,
  Spacer,
  Stack,
  SubHeader,
} from '@bt-healthcare/ui-toolkit';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { EMPTY_VALUE } from '../../App.constants';
import { ReactComponent as Img } from '../../assets/account-image.svg';
import { AccountDetailsSection } from '../../components/AccountDetailsSection';
import { accountDetails } from '../../components/AccountDetailsSection/intl';
import { GPInformationCard } from '../../components/Cards/GPInformationCard';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useLogout } from '../../hooks/useLogout';
import { useTracking } from '../../hooks/useTracking';
import { AboutSection } from './AboutSection';
import { SettingsSection } from './Settings';
import { SupportSection } from './SupportSection';
import {
  HeaderContainer,
  ImgContainer,
  LogOutButton,
  LogOutWrapper,
  SectionSpacer,
} from './styles';

export const Account = () => {
  const { trackPageWithAppName } = useTracking();
  const { userProfile: data, refetch } = useAuthUserProfile();
  const flags = useFeatureFlags();
  const handleLogout = useLogout();

  const { attributes } = data?.person || {};
  const { firstName, surname } = attributes?.personalInformation || {};
  const deletionRequested = data?.user?.attributes.deletionRequested;
  const { primaryEmailAddress, primaryContactNumber } =
    data?.person?.attributes?.contactInformation || {};
  const { ethnicity } = data?.person?.attributes?.personalInformation || {};

  useEffect(() => {
    trackPageWithAppName('Account');
    refetch();
  }, []);

  return (
    <Grid>
      <Col colEnd={{ xxs: 'span 5' }}>
        <Header>
          <HeaderContainer>
            <ImgContainer>
              <Img width="100%" height="100%" />
            </ImgContainer>
            <span data-testid="user-name">
              {firstName || EMPTY_VALUE} {surname || EMPTY_VALUE}
            </span>
          </HeaderContainer>
        </Header>

        <Stack space="none" id="account">
          <Spacer size="s6" md="s8" />
          {deletionRequested && (
            <>
              <Notification type="warning">
                <FormattedMessage {...accountDetails.deleteAccount} />
              </Notification>
              <Spacer size="s6" md="s8" />
            </>
          )}
          {flags.REMOTE_CARE_AND_NAVIGATION && (
            <>
              <Stack space="none" id="account-details">
                <Spacer size="s8" />
                <SubHeader>Your GP</SubHeader>
                <Spacer size="s4" />
                <GPInformationCard />
              </Stack>
              <SectionSpacer />
            </>
          )}
          <AccountDetailsSection
            deletionRequested={deletionRequested}
            primaryEmailAddress={primaryEmailAddress}
            primaryContactNumber={primaryContactNumber}
            ethnicity={ethnicity}
          />
          <SectionSpacer />
          <SupportSection />
          <SectionSpacer />
          <SettingsSection />
          <SectionSpacer />
          <AboutSection />
          <LogOutWrapper>
            <SectionSpacer />
            <LogOutButton id="account-log-out" onClick={handleLogout}>
              <FormattedMessage defaultMessage="Log out" id="account.log-out" />
            </LogOutButton>
          </LogOutWrapper>
        </Stack>
      </Col>
    </Grid>
  );
};
