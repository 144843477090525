import { IntlShape } from 'react-intl';
import { object, string } from 'yup';
import { sortArrayByGivenOrder } from '../../utils/array.utils';
import { AlcoholFrequencyDummy } from './types';
import { healthScoreAlcoholFrequency } from './intl';

const alcoholFrequencyOrder = [
  AlcoholFrequencyDummy.NONE,
  AlcoholFrequencyDummy.ONE_DAY_A_WEEK,
  AlcoholFrequencyDummy.TWO_DAYS_A_WEEK,
  AlcoholFrequencyDummy.THREE_DAYS_A_WEEK,
  AlcoholFrequencyDummy.FOUR_DAYS_A_WEEK,
  AlcoholFrequencyDummy.FIVE_DAYS_A_WEEK,
  AlcoholFrequencyDummy.SIX_DAYS_A_WEEK,
  AlcoholFrequencyDummy.SEVEN_DAYS_A_WEEK,
];

const alcoholFrequency = (intl: IntlShape) => ({
  name: 'alcoholFrequency',
  validationRule: object()
    .shape({
      value: string().oneOf(Object.values(AlcoholFrequencyDummy)).required(),
      label: string(),
    })
    .required(),

  options: sortArrayByGivenOrder(
    Object.values(AlcoholFrequencyDummy),
    alcoholFrequencyOrder
  ).map((value, index) => ({
    value,
    label: intl.formatMessage(
      value === AlcoholFrequencyDummy.NONE
        ? healthScoreAlcoholFrequency.options.none
        : healthScoreAlcoholFrequency.options.dayAWeek,
      { days: index }
    ),
  })),
});
export const data = (intl: IntlShape) => ({
  alcoholFrequency: alcoholFrequency(intl),
});
