import { Container, StyledText } from './styles';
import {
  FhirRating,
  ObservationType,
  useGetObservationBandsQuery,
} from '../../services/graphql';
import { findRating } from '../../pages/ObservationRatings/utils';

export const RatingBadge = ({
  patientRating,
  type,
  variant = 'primary',
}: {
  patientRating: FhirRating | string;
  type: ObservationType;
  variant?: 'primary' | 'insightBar';
}) => {
  const { data, error } = useGetObservationBandsQuery({
    variables: { observationType: type },
  });
  if (!data || error) {
    return null;
  }

  const { displayName, lightColourTheme } =
    findRating(patientRating, data.observationBands) || {};

  return (
    <Container
      data-testid="observation-band"
      bgColor={lightColourTheme?.backgroundColour || ''}
    >
      <StyledText color={lightColourTheme?.foregroundColour} variant={variant}>
        {displayName}
      </StyledText>
    </Container>
  );
};
