export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}

export const sortArrayByGivenOrder = <T>(arrayToSort: T[], sortingOrder: T[]) =>
  [...arrayToSort].sort((a, b) => {
    const aIndex = sortingOrder.findIndex((item) => item === a);
    const bIndex = sortingOrder.findIndex((item) => item === b);
    const aReturn = aIndex < 0 ? sortingOrder.length : aIndex;
    const bReturn = bIndex < 0 ? sortingOrder.length : bIndex;
    return aReturn - bReturn;
  });
