export enum QueryErrorMessageVariantsEnum {
  DEFAULT = 'default',
  BLUE_SCREEN = 'blue-screen',
}

export type QueryErrorMessageProps = {
  onTryAgain: () => void;
  title?: string;
  description?: string;
  tryAgainText?: string;
  contactUsText?: string;
  variant?: QueryErrorMessageVariantsEnum;
};
