import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Input,
  Spacer,
  Stack,
  Text,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';

import { PublicContentCardFormWrapper } from '../../components/PublicContentCard/styles';
import { ROUTE } from '../../config/routes';
import { useTracking } from '../../hooks/useTracking';

import { RHFDateOfBirthInput } from '../../components/RHFDateOfBirthInput';

import { formatISO8061Date } from '../../formatters/date';
import { PersonalDetailsFormValues } from './types';
import { getValidationSchema } from './validationSchema';

export const OnboardingPersonalDetailsForm = () => {
  const { trackTapped } = useTracking();

  const intl = useIntl();
  const navigate = useNavigate();
  const schema = getValidationSchema();

  const methods = useForm<PersonalDetailsFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods;

  const submit = handleSubmit(
    ({
      yearOfBirth,
      monthOfBirth,
      dayOfBirth,
      firstName,
      lastName,
      genderAssignedAtBirth,
    }) => {
      const personalDetailsFormValues = {
        dateOfBirth: formatISO8061Date({
          year: yearOfBirth,
          month: monthOfBirth,
          day: dayOfBirth,
        }),
        firstName,
        genderAssignedAtBirth: genderAssignedAtBirth?.value,
        surname: lastName,
      };

      navigate(ROUTE.ONBOARDING_PASSWORD, {
        state: personalDetailsFormValues,
      });
    }
  );

  return (
    <PublicContentCardFormWrapper
      onSubmit={submit}
      data-testid="onboarding-personal-details-form"
    >
      <FormProvider {...methods}>
        <Stack space="s2" id="">
          <Input
            id="firstName"
            label={intl.formatMessage({
              id: 'onboarding-personal-details.form.first-name',
              defaultMessage: 'First name',
            })}
            type="text"
            errorText={errors?.firstName?.message}
            inputMode="text"
            markAsRequired
            {...register('firstName')}
          />
          <Input
            id="lastName"
            label={intl.formatMessage({
              id: 'onboarding-personal-details.form.last-name',
              defaultMessage: 'Last name',
            })}
            errorText={errors?.lastName?.message}
            type="text"
            inputMode="text"
            markAsRequired
            {...register('lastName')}
          />
          <RHFDateOfBirthInput />
          <Text color={colors.grey.grey08} fontSize={fontSizes.sm}>
            <FormattedMessage
              id="onboarding-personal-details.form.sex-assigned-at-birth-guide"
              defaultMessage="This needs to match your GP record"
            />
          </Text>
        </Stack>
        <Spacer size="s6" />
        <Button
          variant="primary"
          disabled={!isValid}
          id="continue"
          type="submit"
          role="button"
          tabIndex={0}
          onClick={() =>
            trackTapped('Continue', 'Onboarding - personal-details')
          }
        >
          <FormattedMessage
            id="onboarding-personal-details.continue"
            defaultMessage="Continue"
          />
        </Button>
      </FormProvider>
    </PublicContentCardFormWrapper>
  );
};
