import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { ReactComponent as Success } from '../../assets/success.svg';
import { useTracking } from '../../hooks/useTracking';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';
import { getEnvVariable } from '../../env.utils';

export const OnboardingSuccess = () => {
  const { trackTapped, trackPage } = useTracking();

  useEffect(() => {
    trackPage('Onboarding - Success');
  }, []);

  return (
    <>
      <Navbar />
      <GridPageCenteredSection>
        {' '}
        <PublicContentCard
          Hero={<Success width="100%" height="100%" />}
          HeaderText={
            <FormattedMessage
              id="onboarding-success.header"
              defaultMessage="Log in to finish signing up"
            />
          }
          TextDescription={[
            <FormattedMessage
              id="onboarding-success.text-description.p1"
              defaultMessage="Great news, your account is activated and you’re ready to start tracking your vitals with HealthyYou."
            />,
            <FormattedMessage
              id="onboarding-success.text-description.p2"
              defaultMessage="The final step is to log in and add your mobile number to keep your account secure."
            />,
          ]}
          ctaVariant="primary"
          CtaText={
            <FormattedMessage
              id="onboarding-success.cta-text"
              defaultMessage="Continue to log in"
            />
          }
          loadingState={false}
          ctaAction={() => {
            trackTapped('Continue to log in', 'Onboarding - Success');
            window.location.href = getEnvVariable('VITE_APP_LINK_LOGIN');
          }}
        />
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </>
  );
};
