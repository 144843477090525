import { useIntl } from 'react-intl';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { defaultPersonalInfoValues } from './utils';

export const usePersonalInfoDefaultValues = () => {
  const { userProfile } = useAuthUserProfile();
  const intl = useIntl();

  const { dateOfBirth, genderAssignedAtBirth, genderIdentifiedAs } =
    userProfile?.person.attributes?.personalInformation || {};
  return defaultPersonalInfoValues({
    genderAssignedAtBirth,
    genderIdentifiedAs,
    dateOfBirth,
    intl,
  });
};
