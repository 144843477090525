import { defineMessages } from 'react-intl';

export const confirmCloseHealthScoreModal = defineMessages({
  header: {
    id: 'confirm-health-score-close.header',
    defaultMessage:
      "You're about to leave the questionnaire and lose your progress",
  },
  description: {
    id: 'confirm-health-score-close.description',
    defaultMessage: 'If you leave now, your answers will not be saved.',
  },
  primaryButton: {
    id: 'confirm-health-score-close.header.primary-button',
    defaultMessage: 'Leave and lose progress',
  },
  secondaryButton: {
    id: 'confirm-health-score-close.header.secondary-button',
    defaultMessage: 'Cancel',
  },
});
