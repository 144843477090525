import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Header, noop, PageNotFound } from '@bt-healthcare/ui-toolkit';
import { useTracking } from '../../hooks/useTracking';
import {
  ObservationType,
  useGetSearchWardPatientObservationsQuery,
} from '../../services/graphql';
import { observationName } from '../CreateObservationsList/intl';
import {
  formatObservationType,
  mapUrlPathToObservationType,
} from '../../formatters/observations';

import { Container, StyledCol, StyledGrid } from './styles';
import { shouldDisplayGraph } from './utils';
import { useKnownObservation } from '../../hooks/useKnowObservations';
import { Graph } from './Graph';
import { ObservationsList } from '../../components/ObservationsList';

export const HistoricalObservationsList = () => {
  const { trackPageWithAppName } = useTracking();
  const isKnownObservation = useKnownObservation();
  const { type: rawType } = useParams<{ type: string }>();
  const type = rawType ? mapUrlPathToObservationType(rawType) : null;
  const types =
    type === ObservationType.BloodPressure
      ? [type!, ObservationType.AverageBloodPressure]
      : [type!];

  useEffect(() => {
    if (!type) return;
    trackPageWithAppName(
      `${formatObservationType(type)} - History - Patient - HealthyYou`
    );
  }, []);

  const { data, loading, fetchMore } = useGetSearchWardPatientObservationsQuery(
    {
      variables: {
        types,
        offset: 0,
        size: 10,
      },
      fetchPolicy: 'cache-and-network',
      skip: !type,
    }
  );

  const searchWardPatientObservations = data?.searchWardPatientObservations;

  const loadedLength = searchWardPatientObservations?.searchResults.length || 0;
  const loadNext = useCallback(() => {
    fetchMore({ variables: { offset: loadedLength } }).catch(noop);
  }, [fetchMore, loadedLength]);

  if (!type || !isKnownObservation(type)) {
    return <PageNotFound />;
  }
  const isGraphOn = shouldDisplayGraph(type);

  return (
    <Container>
      <StyledGrid>
        <StyledCol>
          <Header>
            <FormattedMessage {...observationName[type]} />
          </Header>
        </StyledCol>
        {isGraphOn && <Graph type={type} />}
        <StyledCol>
          <ObservationsList
            data={searchWardPatientObservations}
            loadNext={loadNext}
            loading={loading}
          />
        </StyledCol>
      </StyledGrid>
    </Container>
  );
};
