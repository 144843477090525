import { IntlShape } from 'react-intl';
import * as yup from '../../utils/yup/extension';
import { vo2Intl } from './intl';
import { Vo2Data } from './types';

export const data = (intl: IntlShape): Vo2Data => ({
  fields: [
    {
      name: 'minutes',
      label: intl.formatMessage(vo2Intl.fields.minutes),
      validationRule: yup
        .number()
        .decimal({
          min: 0,
          max: 9999999,
          name: intl.formatMessage(vo2Intl.fields.minutes),
        })
        .required(),
    },
    {
      name: 'seconds',
      label: intl.formatMessage(vo2Intl.fields.seconds),
      validationRule: yup.number().decimal({
        min: 0,
        max: 59,
        name: intl.formatMessage(vo2Intl.fields.seconds),
      }),
    },
  ],
});
