import { defineMessage, defineMessages } from 'react-intl';
import { FhirRating } from '../../services/graphql';

export const recommendationAverageResult = {
  header: defineMessage({
    id: 'recommendationAverageResult.header',
    defaultMessage: 'Your <blue02>blood pressure</blue02> insights',
  }),
  card: defineMessage({
    id: 'recommendationAverageResult.card',
    defaultMessage: 'Avg. blood pressure',
  }),
  linkHistoryReadingsCard: defineMessage({
    id: 'recommendationAverageResult.link-history-readings',
    defaultMessage:
      'Based on {observationsCount} readings over {daysCount} days',
  }),
  recommendationsHeader: defineMessage({
    id: 'recommendationAverageResult.recommendation-header',
    defaultMessage: 'Our recommendation',
  }),
  recommendations: {
    level: defineMessage({
      id: 'recommendationAverageResult.recommendation.level',
      defaultMessage: 'Your blood pressure average is {level}',
    }),
    exercises: defineMessage({
      id: 'recommendationAverageResult.recommendation.exercises',
      defaultMessage:
        'Incorporating regular exercise, maintaining a balanced diet, and limiting salt intake will help to achieve a healthy blood pressure and minimise the risk of heart attack or stroke',
    }),
    track: defineMessage({
      id: 'recommendationAverageResult.recommendation.track',
      defaultMessage:
        'Continue to track your blood pressure in app to understand health triggers and track your progress over time',
    }),
    information: defineMessage({
      id: 'recommendationAverageResult.recommendation.information',
      defaultMessage:
        'Find out more information about blood pressure on the {link}',
    }),
    high: defineMessage({
      id: 'recommendationAverageResult.recommendation.high.information',
      defaultMessage:
        'We recommend you seek the advice of a medical professional, take your phone with you to show them your readings history',
    }),
    nonHigh: defineMessage({
      id: 'recommendationAverageResult.recommendation.non-high.information',
      defaultMessage:
        "Your average doesn't indicate a need for medical intervention; if you’re concerned, consult your GP",
    }),
  },
  nhsLink: defineMessage({
    id: 'recommendationAverageResult.recommendation.information.link',
    defaultMessage: 'NHS website',
  }),
  levelNames: defineMessages({
    [FhirRating.Low]: {
      id: 'recommendationAverageResult.recommendation.level.low',
      defaultMessage: 'low',
    },
    [FhirRating.Normal]: {
      id: 'recommendationAverageResult.recommendation.level.normal',
      defaultMessage: 'normal',
    },
    [FhirRating.High]: {
      id: 'recommendationAverageResult.recommendation.level.elevated',
      defaultMessage: 'elevated',
    },
    [FhirRating.SignificantlyHigh]: {
      id: 'recommendationAverageResult.recommendation.level.high',
      defaultMessage: 'high',
    },
  }),
  errorTitle: defineMessage({
    defaultMessage: 'We’re unable to load your insights',
    id: 'recommendationAverageResult.recommendation.error-title',
  }),
};
