import { colors, Input, Text, Toggle } from '@bt-healthcare/ui-toolkit';
import { FieldValues, useFormContext, Path } from 'react-hook-form';
import { disallowExponentialInputs } from '../../pages/CreateObservations/data';
import HealthScorePageWrapper from '../HealthScorePageWrapper';
import { HeaderWrapper } from './styles';
import { InputWithToggleProps } from './types';

export const InputWithToggle = <
  T extends {
    name: Path<U>;
    placeholder?: string;
    inputMode?: 'decimal' | 'numeric';
  },
  U extends FieldValues
>({
  label,
  toggleProps,
  fields,
}: InputWithToggleProps<T, U>) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<U>();
  return (
    <HealthScorePageWrapper.InputWithInfo>
      <HeaderWrapper>
        <Text color={colors.grey.grey10}>{label}</Text>
        <Toggle {...toggleProps} />
      </HeaderWrapper>
      {fields.map(({ name, placeholder, inputMode }) => {
        const error = errors[name];
        const errorText = error?.message?.toString();
        return (
          <Input
            id={name}
            key={name}
            type="number"
            placeholder={placeholder}
            inputMode={inputMode || 'numeric'}
            {...(errorText && { errorText })}
            {...register(name, { valueAsNumber: true })}
            {...disallowExponentialInputs()}
          />
        );
      })}
    </HealthScorePageWrapper.InputWithInfo>
  );
};
