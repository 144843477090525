import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  Stack,
  colors,
  Text,
  Spacer,
  EventCard,
  Portal,
} from '@bt-healthcare/ui-toolkit';

import {
  GeneralPracticeBookedAppointmentSlot,
  useGetGeneralPracticeBookedAppointmentsByUserQuery,
} from '../../services/graphql';

import { UpcomingAppointmentListItem } from './styles';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { BookingCancellation } from '../Modal/BookingCancellation';
import { AppointmentDetailsType } from '../Modal/types';

export const UpcomingAppointmentButtonsList = () => {
  const { userProfile: dataUser } = useAuthUserProfile();
  const [modalOpen, setModalOpen] = useState(false);
  const [isCancelationConfirmed, setCancelConfirmation] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] =
    useState<AppointmentDetailsType>();
  const closeModal = () => {
    setModalOpen(false);
    setCancelConfirmation(false);
  };
  const confirmCancelation = () => setCancelConfirmation(true);

  const patientRegistration =
    dataUser?.person?.attributes.generalPracticeRegistration;

  const patientIdentifier = patientRegistration?.patientIdentifier;

  const { data, loading, error } =
    useGetGeneralPracticeBookedAppointmentsByUserQuery({
      variables: {
        patientIdentifier: patientIdentifier!,
      },
      skip: !patientIdentifier,
      fetchPolicy: 'cache-and-network',
    });

  if (loading || error || !data?.bookedAppointments?.length) {
    return null;
  }

  const handleCancelation = (
    bookedAppointment: GeneralPracticeBookedAppointmentSlot
  ) => {
    setModalOpen(true);
    setAppointmentToCancel({
      startTime: bookedAppointment?.attributes.start,
      practiceName: bookedAppointment?.attributes?.address
        ?.buildingName as string,
      appointmentId: bookedAppointment?.attributes.appointmentId,
      patientIdentifier: patientIdentifier!,
    });
  };

  return (
    <Stack space="none" id="upcoming-appointment-buttons-list">
      <Stack
        space="s2"
        id="upcoming-appointment-buttons-list-header"
        flexDirection="row"
      >
        <Text color={colors.base.dark}>
          <FormattedMessage
            id="upcoming-appointment-buttons-list.subheader"
            defaultMessage="Upcoming appointments"
          />
        </Text>
      </Stack>
      <Spacer size="s5" />
      <Stack
        space="s2"
        id="upcoming-appointment-buttons-list-content"
        overflowX="scroll"
        flexDirection="row"
      >
        {data?.bookedAppointments?.map((bookedAppointment) => (
          <UpcomingAppointmentListItem
            key={bookedAppointment?.id}
            onClick={() => handleCancelation(bookedAppointment!)}
          >
            <Link
              to="/"
              state={{
                data: bookedAppointment,
              }}
            >
              <EventCard
                date={new Date(bookedAppointment?.attributes?.start as string)}
                type={bookedAppointment?.attributes?.appointmentType as string}
                id={bookedAppointment?.type || ''}
              />
            </Link>
          </UpcomingAppointmentListItem>
        ))}
      </Stack>
      <Portal>
        <BookingCancellation
          modalOpen={modalOpen}
          isCancelationConfirmed={isCancelationConfirmed}
          appointmentDetails={appointmentToCancel}
          handleClose={closeModal}
          handleCancel={confirmCancelation}
        />
      </Portal>
    </Stack>
  );
};
