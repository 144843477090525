import { FormattedMessage } from 'react-intl';

import {
  Header,
  Spacer,
  Stack,
  Text,
  colors,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';

import { ReactComponent as SafeHands } from '../../assets/safe-hands.svg';
import { ReactComponent as ClinicalRecommendations } from '../../assets/clinical-recommendations.svg';
import { ReactComponent as TripToGP } from '../../assets/trip-to-gp.svg';

import { benefitsOfRemoteCare, benefitsOfRemoteCareCards } from './intl';
import { BenefitsCardWrapper, BenefitsCardsList } from './styles';

export const BenefitsCards = () => {
  const imageSources = [
    <SafeHands />,
    <ClinicalRecommendations />,
    <TripToGP />,
  ];
  const cards = Object.keys(benefitsOfRemoteCareCards) as Array<
    keyof typeof benefitsOfRemoteCareCards
  >;

  return (
    <Stack id="" gap={spacing.s14}>
      <Header>
        <FormattedMessage {...benefitsOfRemoteCare.title} />
      </Header>
      <Stack id="" flexDirection="row" gap={spacing.s6}>
        <BenefitsCardsList>
          {cards.map((card, index) => (
            <li key={card}>
              <BenefitsCardWrapper>
                {imageSources[index]}
                <Spacer size="s10" />
                <Text
                  color={colors.grey.grey09}
                  fontSize={fontSizes.h4}
                  fontWeight={500}
                  style={{
                    textAlign: 'start',
                    width: '100%',
                  }}
                >
                  <FormattedMessage
                    {...benefitsOfRemoteCareCards[card].title}
                  />
                </Text>
                <Spacer size="s4" />
                <Text color={colors.grey.grey09}>
                  <FormattedMessage
                    {...benefitsOfRemoteCareCards[card].description}
                  />
                </Text>
              </BenefitsCardWrapper>
            </li>
          ))}
        </BenefitsCardsList>
      </Stack>
    </Stack>
  );
};
