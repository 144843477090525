import { defineMessages } from 'react-intl';

export const accountDetails = defineMessages({
  title: {
    defaultMessage: 'Account details',
    id: 'account.account-details.title',
  },
  mobile: {
    defaultMessage: 'Mobile number',
    id: 'account.account-details.mobile',
  },
  email: {
    defaultMessage: 'Email address',
    id: 'account.account-details.email',
  },
  ethnicity: {
    defaultMessage: 'Ethnicity',
    id: 'account.account-details.ethnicity',
  },
  healthInformation: {
    defaultMessage: 'Health information',
    id: 'account.account-details.health-information',
  },
  deleteAccount: {
    defaultMessage:
      'Your account has been flagged for deletion and will be inaccessible within 30 days.',
    id: 'account.account-details.delete-account',
  },
});

export const support = defineMessages({
  title: {
    defaultMessage: 'Support',
    id: 'account.support.title',
  },
  contact: {
    defaultMessage: 'Contact your care team',
    id: 'account.support.contact',
  },
  help: {
    defaultMessage: 'Get help with a technical issue',
    id: 'account.support.help',
  },
  feedback: {
    defaultMessage: 'Give us feedback',
    id: 'account.support.feedback',
  },
  deleteAccount: {
    defaultMessage: 'Delete my account',
    id: 'account.support.delete-account',
  },
});
