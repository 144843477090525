import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useFlagsmith } from 'flagsmith/react';
import { ReactElement, ReactNode, useEffect } from 'react';
import { useGetUserPatientProfileQuery } from '../../services/graphql';
import { SentryTags } from '../../services/sentry';

export const ThirdPartyUserIdentifier = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement => {
  const flagsmith = useFlagsmith();
  const { user, isAuthenticated } = useAuth0();
  const { data } = useGetUserPatientProfileQuery({
    skip: !isAuthenticated || !user,
  });

  const id = data?.userPatientProfileResults?.userId;
  const careSettingId =
    data?.userPatientProfileResults.user.attributes.defaultCareSettingId;

  useEffect(() => {
    Sentry.setUser(isAuthenticated ? { id } : null);
    Sentry.setTag(SentryTags.USER_ID, isAuthenticated ? id : 'LOGGED OUT');

    if (id) {
      flagsmith.identify(id, {
        careSettingId: careSettingId ?? '',
        userId: id ?? '',
      });
    } else if (flagsmith.identity) {
      flagsmith.logout();
    }
  }, [id, careSettingId, isAuthenticated]);

  return <>{children}</>;
};
