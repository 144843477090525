import { defineMessages } from 'react-intl';
import { BenefitsOfRemoteCareCards } from './types';

export const benefitsOfRemoteCare = defineMessages({
  title: {
    defaultMessage: 'Benefits of the remote care service',
    id: 'benefits-of-remote-care.title',
  },
});

export const benefitsOfRemoteCareCards: BenefitsOfRemoteCareCards = {
  cardOne: defineMessages({
    title: {
      defaultMessage: 'You’re in safe hands',
      id: 'benefits-of-remote-care.card-one.title',
    },
    description: {
      defaultMessage:
        'Our expert team of remote clinicians will analyse your readings and conduct an initial review of your condition.',
      id: 'benefits-of-remote-care.card-one.description',
    },
  }),

  cardTwo: defineMessages({
    title: {
      defaultMessage: 'Clinical recommendations',
      id: 'benefits-of-remote-care.card-two.title',
    },
    description: {
      defaultMessage:
        'You will receive personalised clinical recommendations for medication management and lifestyle changes.',
      id: 'benefits-of-remote-care.card-two.description',
    },
  }),

  cardThree: defineMessages({
    title: {
      defaultMessage: 'Skip the trip to the GP',
      id: 'benefits-of-remote-care.card-three.title',
    },
    description: {
      defaultMessage:
        'Being part of HealthyYou helps avoid going out of your way to see your GP about your condition.',
      id: 'benefits-of-remote-care.card-three.description',
    },
  }),
};
