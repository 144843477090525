import { ObservationType } from '../../services/graphql';

export const shouldDisplayRatingBadge = (type: ObservationType) => {
  switch (type) {
    case ObservationType.BloodPressure:
    case ObservationType.AverageBloodPressure:
    case ObservationType.Pulse:
    case ObservationType.Spo2:
    case ObservationType.FastingBloodGlucose:
      return true;
    default:
      return false;
  }
};
