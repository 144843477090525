import { colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  text-align: center;
`;

export const HeroWrapper = styled.div`
  max-width: 167px;
  margin: 0 auto;
`;

export const Container = styled.div`
  max-width: 520px;
  margin: 0 auto;
  padding: ${spacing.s6};
  background: ${colors.base.white};
  border-radius: ${spacing.s4};
`;
