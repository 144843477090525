import styled, { css } from 'styled-components';
import { Col, device, Grid, Row, spacing } from '@bt-healthcare/ui-toolkit';
import { ZIndex } from '../../const.styles';
import { OverlayBackground } from './types';

export const PAGE_PADDING = spacing.s4;
export const PAGE_PADDING_TABLET_HORIZONTAL = spacing.s8;
export const PAGE_PADDING_TABLET_TOP = spacing.l2;
export const PAGE_PADDING_DESKTOP_HORIZONTAL = spacing.l4;

const blueBackground = css`
  background: linear-gradient(
      90deg,
      rgba(41, 121, 255, 0.12) 15.34%,
      rgba(50, 0, 138, 0.1) 82.23%
    ),
    #02062d;
`;

const healthScoreBackground = css`
  background: linear-gradient(
      163deg,
      rgba(204, 174, 225, 0.2) 25.27%,
      rgba(112, 165, 255, 0.08) 57.26%,
      rgba(242, 244, 249, 0) 76.25%
    ),
    #f2f4f9;
`;

const background = {
  [OverlayBackground.BLUE]: blueBackground,
  [OverlayBackground.HEALTH_SCORE]: healthScoreBackground,
};

export const Overlay = styled.div<{ variant: OverlayBackground }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  left: 0;
  ${({ variant }) => background[variant]}
  transform: translate3d(0, 0, 0);

  z-index: ${ZIndex.OVERLAY};
`;

export const StyledGrid = styled(Grid)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${ZIndex.OVERLAY_CONTENT};
  grid-template-rows: none;
  padding: ${PAGE_PADDING};
  @media ${device.tablet} {
    padding: ${PAGE_PADDING_TABLET_TOP} ${PAGE_PADDING_TABLET_HORIZONTAL}
      ${PAGE_PADDING};
    justify-content: flex-start;
  }
  @media ${device.tablet} {
    padding: ${PAGE_PADDING_TABLET_TOP} ${PAGE_PADDING_DESKTOP_HORIZONTAL}
      ${PAGE_PADDING};
  }
`;

export const StyledRow = styled(Row)`
  height: 100%;
  grid-template-rows: none;
`;

export const StyledColumn = styled(Col).attrs({
  colStart: { xxs: '1', md: '1', xl: '4' },
  colEnd: { xxs: '5', md: '7', xl: '10' },
})`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.tablet} {
    justify-content: flex-start;
  }
  @media ${device.desktop} {
    align-items: center;
  }
  width: 100%;
  min-height: 100%;
  height: auto;
  background: transparent;
`;
