import { defineMessage } from 'react-intl';

export const recommendationAverageBloodPressureInfo = {
  title: defineMessage({
    id: 'recommendation.average-blood-pressure-info.title',
    defaultMessage:
      'Discover <indigo02>personalised insights</indigo02> by unlocking your average blood pressure',
  }),
  card: defineMessage({
    id: 'recommendation.average-blood-pressure-info.card',
    defaultMessage:
      '<blue02>Completely free</blue02> with no strings attached!',
  }),
  description: defineMessage({
    id: 'recommendation.average-blood-pressure-info.description',
    defaultMessage:
      "As your blood pressure is above the normal range, we’re offering to calculate your average blood pressure in-app, allowing you to stay one step ahead. <br></br> <br></br>Doctors usually request a week of readings when consulting on blood pressure; we'll guide you to whether an appointment is necessary.",
  }),
  button: defineMessage({
    id: 'recommendation.average-blood-pressure-info.continue',
    defaultMessage: 'Continue',
  }),
};
