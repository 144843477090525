import styled from 'styled-components';
import { spacing } from '@bt-healthcare/ui-toolkit';

export const StyledCard = styled.div`
  display: flex;
  background: rgba(219, 232, 255, 0.5);
  border-radius: 8px;
`;

export const LeftItem = styled.div`
  padding: ${spacing.s4} 0 ${spacing.s4} ${spacing.s4};
`;
export const RightItem = styled.div`
  align-self: flex-end;
`;
