import { Link } from 'react-router-dom';
import { BlueCard } from '../BlueCard';
import {
  BrightIcon,
  BrightWrapper,
  ChevronRightIcon,
  Label,
  TextWrapper,
} from './styles';
import { RecommendationLinkCardProps } from './types';
import BaseCard from '../BaseCard';

export const RecommendationLinkCard = ({
  text,
  withChevron = true,
  path,
  onClick,
}: RecommendationLinkCardProps) => (
  <Link
    to={path}
    rel="noopener noreferrer"
    data-testid="recommendation-link"
    onClick={onClick}
  >
    <BlueCard
      id="recommendation-link-card"
      data-testid="recommendation-link-card"
    >
      <BaseCard.ItemLeft gap={12}>
        <BrightWrapper>
          <BrightIcon />
        </BrightWrapper>
        <TextWrapper>
          <Label>{text}</Label>
        </TextWrapper>
      </BaseCard.ItemLeft>
      {withChevron && (
        <BaseCard.ItemRight>
          <ChevronRightIcon />
        </BaseCard.ItemRight>
      )}
    </BlueCard>
  </Link>
);
