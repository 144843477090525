import styled from 'styled-components';
import {
  colors,
  device,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';

export const LabelWrapper = styled.div`
  margin: 0 0 ${spacing.s10} 0;
`;

export const Label = styled(Text).attrs({
  color: colors.grey.grey10,
  fontWeight: 500,
})`
  @media ${device.tablet} {
    font-size: ${fontSizes.h4};
  }
`;

export const ResultValue = styled(Text).attrs({
  color: colors.grey.grey10,
  fontWeight: 500,
})``;

export const ResultDescription = styled(Text).attrs({
  color: colors.grey.grey08,
  fontSize: fontSizes.sm,
})``;

export const Vo2TablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.s10};
  margin: ${spacing.s10} 0 ${spacing.s4} 0;
`;

export const TablesSource = styled(Text).attrs({
  color: colors.base.dark,
})`
  font-size: 10px;
  align-self: flex-start;
`;
