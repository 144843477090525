import { PropsWithChildren } from 'react';
import { BTHealthIcon, colors, Text } from '@bt-healthcare/ui-toolkit';
import BaseCard from '../BaseCard';

export const HealthScoreInfoCard = ({ children }: PropsWithChildren) => (
  <BaseCard
    id="health-score-info-card"
    background="linear-gradient(63deg, rgba(68, 138, 255, 0.10) -0.36%, rgba(71, 46, 205, 0.10) 54.21%, rgba(85, 20, 180, 0.10) 100.81%)"
  >
    <BaseCard.ItemLeft gap={8}>
      <BTHealthIcon icon="Bright" color={colors.primaryIndigo.indigo08} />
      <Text color={colors.grey.grey10}>{children}</Text>
    </BaseCard.ItemLeft>
  </BaseCard>
);
