import { BTHealthIcon, Stack, colors, Text } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import {
  Card,
  BodyText,
  LocationContainer,
  TimeContainer,
  TimeText,
  PinIconContainer,
} from './styles';
import { AppointmentCardProps } from './types';

export const AppointmentCard = ({ date, location }: AppointmentCardProps) => (
  <Card>
    <Stack space="none" id="appointment-card-content">
      <TimeContainer>
        <Stack
          space="none"
          id="appointment-card-content-header"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={colors.grey.grey10} fontWeight={500}>
            <BodyText>
              <FormattedMessage
                id="appointment-card.date"
                defaultMessage={format(date, 'EEEE do LLLL')}
              />
            </BodyText>
          </Text>
          <Text color={colors.grey.grey10} fontWeight={500}>
            <TimeText>
              <FormattedMessage
                id="appointment-card.time"
                defaultMessage={format(date, 'h:mmaaa')}
              />
            </TimeText>
          </Text>
        </Stack>
      </TimeContainer>
      <LocationContainer>
        <Stack
          space="none"
          id="appointment-card-content-footer"
          flexDirection="row"
          alignItems="center"
        >
          <PinIconContainer>
            <BTHealthIcon
              icon="Pin"
              color={colors.secondaryBlue.blue06}
              size={16}
            />
          </PinIconContainer>

          <Text color={colors.grey.grey10}>
            <BodyText>
              <FormattedMessage
                id="appointment-card.location"
                defaultMessage={location}
              />
            </BodyText>
          </Text>
        </Stack>
      </LocationContainer>
    </Stack>
  </Card>
);
