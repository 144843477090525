import styled from 'styled-components';
import hexRgb from 'hex-rgb';
import {
  colors,
  fontSizes,
  device,
  Text,
  BTHealthIcon,
  spacing,
  fontCalcMixin,
} from '@bt-healthcare/ui-toolkit';
import BaseCard from '../../components/Cards/BaseCard';
import { rgbaFromHexAndOpacity } from '../../utils/css';

export const TitleWrapper = styled.div`
  margin: ${spacing.s10} 0 ${spacing.s6} 0;
`;
export const Title = styled(Text).attrs({
  color: colors.base.white,
  fontSize: fontSizes.h4,
  fontWeight: 500,
})`
  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.h3)}
  }
`;

export const DescriptionWrapper = styled.div`
  margin: ${spacing.s12} 0 0 0;
`;

export const Description = styled(Text).attrs({
  color: rgbaFromHexAndOpacity(colors.base.white, 0.7),
  fontSize: fontSizes.base,
  fontWeight: 500,
})`
  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.h4)}
  }
`;

export const ImgContainer = styled.div`
  align-self: center;
  width: 74%;
  @media ${device.desktop} {
    width: 52%;
  }
`;

export const CardWrapper = styled.div`
  width: 100%;
  @media ${device.desktop} {
    width: 70%;
  }
`;
export const StyledCard = styled(BaseCard).attrs({
  id: 'recommendation-average-blood-pressure-info-card',
  background: hexRgb(colors.base.white, { alpha: 0.2, format: 'css' }),
  borderRadius: 8,
  isListCard: true,
})``;

export const CardLabel = styled(Text).attrs({
  color: colors.base.white,
  fontWeight: 500,
  fontSize: fontSizes.sm,
})`
  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.base)}
  }
`;

export const GiftIcon = styled(BTHealthIcon).attrs({
  icon: 'Gift',
  size: 20,
  color: colors.base.white,
})``;
