import { FormattedMessage } from 'react-intl';

import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { HeaderText, HeaderWrapper, SubHeading } from './styles';

export const GreetingHeader = () => {
  const { userProfile: data } = useAuthUserProfile();

  return (
    <HeaderWrapper>
      <HeaderText>
        <FormattedMessage
          id="dashboard.greeting.heading"
          defaultMessage={`Hello ${data?.person?.attributes.personalInformation.firstName}`}
        />
      </HeaderText>
      <SubHeading>
        <FormattedMessage
          id="dashboard.greeting.subheading"
          defaultMessage="How are you today?"
        />
      </SubHeading>
    </HeaderWrapper>
  );
};
