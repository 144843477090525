export enum AlcoholFrequencyDummy {
  NONE = 'NONE',
  ONE_DAY_A_WEEK = 'ONE_DAY_A_WEEK',
  TWO_DAYS_A_WEEK = 'TWO_DAYS_A_WEEK',
  THREE_DAYS_A_WEEK = 'THREE_DAYS_A_WEEK',
  FOUR_DAYS_A_WEEK = 'FOUR_DAYS_A_WEEK',
  FIVE_DAYS_A_WEEK = 'FIVE_DAYS_A_WEEK',
  SIX_DAYS_A_WEEK = 'SIX_DAYS_A_WEEK',
  SEVEN_DAYS_A_WEEK = 'SEVEN_DAYS_A_WEEK',
}

export type AlcoholFrequencyForm = {
  alcoholFrequency?: {
    value: AlcoholFrequencyDummy;
    label: string;
  };
};
