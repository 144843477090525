import { ObservationType } from '../../services/graphql';

const GRAPH_OBSERVATION_TYPES = [
  ObservationType.BloodPressure,
  ObservationType.Spo2,
  ObservationType.Pulse,
];

export const shouldDisplayGraph = (type: ObservationType) =>
  GRAPH_OBSERVATION_TYPES.includes(type);
