import {
  device,
  colors,
  spacing,
  fontCalcMixin,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import styled, { keyframes, css } from 'styled-components';
import type { ProgressBarProps } from './types';

export const createHeightAnimation = (
  fromHeight: string,
  toHeight: string
) => keyframes`
    from { height: ${fromHeight}; }
    to { height: ${toHeight}; }
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  background-color: ${({ color }) => color};
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}px;
  transition: width 1s ease-in-out;

  @media ${device.tablet} {
    width: ${({ height }) => height}px;

    ${({ width, opposite }) => {
      const heightAnimation = createHeightAnimation(
        `${opposite === 0 ? 100 : opposite}%`,
        `${width}%`
      );
      return css`
        animation: ${heightAnimation} 1s ease-in-out forwards;
      `;
    }}
  }
`;

export const ArrowButton = styled.button<{ right?: boolean }>`
  position: absolute;
  top: 60%;
  border: none;
  background: ${colors.grey.grey01};
  width: fit-content;
  display: ali;
  display: flex;
  padding: ${spacing.s2};
  border-radius: 100%;

  ${({ right }) => right && `right: ${spacing.s4}`};

  @media ${device.tablet} {
    display: none;
  }
`;

export const Header = styled.h2`
  color: ${colors.grey.grey10};
  font-family: 'BT-Curve', sans-serif;
  font-size: ${spacing.s6};
  font-weight: 500;
  line-height: ${spacing.s9};
  padding-top: ${spacing.s8};

  @media ${device.tablet} {
    padding-top: ${spacing.s10};
    text-align: center;
    font-size: ${spacing.s9};
    line-height: ${spacing.s13};
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  gap: ${spacing.s6};
  border-radius: ${spacing.s4};
  background: ${colors.base.white};
  padding: ${spacing.s6} 0 0 0;
  margin-bottom: ${spacing.s4};
  min-height: 176px;

  @media ${device.tablet} {
    min-height: 150px;
  }
`;

export const StyledNumber = styled.p<{ backgroundColor: string }>`
  min-width: ${spacing.s8};
  height: ${spacing.s8};
  border-radius: 100%;
  background: var(--indigo-01, ${({ backgroundColor }) => backgroundColor});
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTextContainer = styled.div``;

export const StyledTitle = styled.p`
  font-family: 'BT-Curve', sans-serif;
  font-size: ${spacing.s5};
  font-weight: 500;
  ${fontCalcMixin(fontSizes.base)};
  margin-bottom: ${spacing.s2};
`;

export const Image = styled.div<{ imageSource: string }>`
  width: 222px;
  height: 327px;
  background: ${(props) => `url(${props.imageSource})`};
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    width: 290px;
    height: 518.2px;
  }
`;

export const StyledDescription = styled.p``;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  margin-left: -${spacing.s4};
  margin-right: -${spacing.s4};
  padding: ${spacing.s8}${spacing.s4} 0 ${spacing.s4};
  border-radius: ${spacing.s6};
  padding: 0 ${spacing.s4};

  @media ${device.tablet} {
    margin: 0;
  }
`;

export const SlideWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    gap: ${spacing.s6};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const MobileSlides = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

export const DesktopSlides = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
    max-width: 400px;
  }
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  margin: 0;

  @media ${device.tablet} {
    flex-direction: column;
    padding-top: ${spacing.s6};
    padding-bottom: ${spacing.l1};
  }
`;

export const GradientBackground = styled.div`
  height: 100%;
  background: red;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background: linear-gradient(
    359deg,
    rgba(242, 244, 249, 0.1) -44.13%,
    rgba(225, 224, 245, 0) -1.3%,
    rgba(161, 151, 228, 0.1) 21.12%,
    rgba(71, 46, 205, 0.1) 37.46%,
    rgba(82, 26, 185, 0.1) 83.18%,
    rgba(85, 20, 180, 0.1) 107.21%
  );
`;
