import styled, { css } from 'styled-components';
import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';

const MOBILE_FONT_SIZE = '9px';
const TABLET_FONT_SIZE = fontSizes.xs;
const ROW_HEIGHT = {
  DESCRIPTION: '24px',
  COLUMN_HEADERS: '40px',
  DATA_COLUMN: '20px',
};

const fontRule = css`
  font-size: ${MOBILE_FONT_SIZE};
  @media ${device.tablet} {
    font-size: ${TABLET_FONT_SIZE};
  }
`;

export const Vo2Grid = styled.div`
  display: grid;
  grid-template-rows: ${ROW_HEIGHT.DESCRIPTION} ${ROW_HEIGHT.COLUMN_HEADERS} repeat(
      6,
      ${ROW_HEIGHT.DATA_COLUMN}
    );
  width: 100%;
`;

export const Vo2Row = styled.div<{ isWhite?: boolean }>`
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: repeat(6, 1fr);
  background: ${({ isWhite = false }) =>
    isWhite ? colors.base.white : 'transparent'};
`;

export const Vo2Column = styled.div<{ order: number }>`
  height: 100%;
  grid-column: ${({ order }) => `${order + 1} / ${order + 2}`};
`;

export const RangeCell = styled.div`
  font-size: ${MOBILE_FONT_SIZE};
  line-height: ${ROW_HEIGHT.DATA_COLUMN};
  text-align: center;
`;

export const AgeCell = styled(RangeCell)`
  font-weight: 500;
`;

export const HeaderCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  ${fontRule};
  text-align: center;
  height: ${ROW_HEIGHT.COLUMN_HEADERS};
`;

export const GenderTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 2;
  height: ${ROW_HEIGHT.DESCRIPTION};
  width: 100%;
  background: ${colors.base.dark};
  color: ${colors.base.white};
  font-size: ${fontSizes.xs};
  @media ${device.tablet} {
    font-size: ${fontSizes.base};
  }
`;

export const HeaderDescriptionContainer = styled.div`
  grid-column: 2 / 7;
  display: flex;
  align-items: center;
  margin: 0 0 0 ${spacing.s2};
`;

export const HeaderDescription = styled.div`
  ${fontRule};
`;
