import { matchPath, useLocation } from 'react-router-dom';
import { sideNavMainNavItems } from '../App.constants';
import { ROUTE } from '../config/routes';

const mapPathnameToRoute = (path: string) =>
  Object.values(ROUTE).find((elem) => !!matchPath(elem, path));

type LabelNavItem = (typeof sideNavMainNavItems)[number]['label'];

const mapRouteToSideNavItemLabel = (route?: ROUTE): LabelNavItem => {
  switch (route) {
    case ROUTE.ACCOUNT:
    case ROUTE.ACCOUNT_HEALTH_INFORMATION:
    case ROUTE.ACCOUNT_HEALTH_INFORMATION_INPUT:
    case ROUTE.APPOINTMENTS_CALENDAR:
    case ROUTE.BOOKING_SUCCESS:
    case ROUTE.GP_LINKAGE_CONNECT:
    case ROUTE.GP_LINKAGE_GET_READY:
    case ROUTE.GP_LINKAGE_SUCCESS:
      return 'Account';
    case ROUTE.HISTORICAL_READINGS:
    case ROUTE.HISTORICAL_READINGS_TYPE:
    case ROUTE.OBSERVATION_RATINGS:
      return 'History';
    default:
      return 'Home';
  }
};

export const useSideMenuNavItems = () => {
  const { pathname } = useLocation();
  const route = mapPathnameToRoute(pathname);
  const label = mapRouteToSideNavItemLabel(route);
  return sideNavMainNavItems.map((item) => ({
    ...item,
    active: item.label === label,
  }));
};
