import convert from 'convert';

const convertStoneToPounds = (stone: number) =>
  Number(convert(stone, 'stone').to('pounds').toFixed(2));

const convertPoundsToStone = (pounds: number) =>
  Number(convert(pounds, 'pounds').to('stone').toFixed(2));

const fullStoneFromPounds = (pounds: number) =>
  Math.floor(convertPoundsToStone(pounds));

export const getPoundsFromStoneAndPounds = ({
  st,
  lb,
}: {
  st: number;
  lb: number;
}) => lb + convertStoneToPounds(st);

export const getFullStoneAndPoundsFromPounds = ({
  lb,
}: {
  lb: number;
}): { lb: number; st: number } => {
  const st = fullStoneFromPounds(lb);
  return { lb: lb - convertStoneToPounds(st), st };
};
