import { Card, colors, Stack, Text } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { InputRange } from '../InputRange';
import { InputRangeCardIndicator, InputRangeCardContainer } from './styles';
import { InputRangeCardPropTypes } from './types';

export const InputRangeCard = ({
  id,
  title,
  ...props
}: InputRangeCardPropTypes) => {
  const { value } = props;
  return (
    <InputRangeCardContainer>
      <Card id={`${id}-card`} borderRadius={8}>
        <Stack id={`${id}-card-stack`} space="s2" flexDirection="column">
          <Stack
            id={`${id}-card-stack-header`}
            space="s2"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text color={colors.grey.grey08} fontSize="16px">
              <FormattedMessage
                id={`${id}-card-title`}
                defaultMessage={title}
              />
            </Text>
            <InputRangeCardIndicator>
              <Text
                color={colors.primaryIndigo.indigo07}
                fontSize="16px"
                fontWeight={500}
              >
                {value}
              </Text>
            </InputRangeCardIndicator>
          </Stack>
          <InputRange {...props} />
        </Stack>
      </Card>
    </InputRangeCardContainer>
  );
};
