import styled from 'styled-components';
import {
  colors,
  Text,
  spacing,
  RadioButtonGroup,
} from '@bt-healthcare/ui-toolkit';
import { rgbaFromHexAndOpacity } from '../../utils/css';

const radioButtonGroupColors = {
  background: rgbaFromHexAndOpacity(colors.base.white, 0.1),
  divider: rgbaFromHexAndOpacity(colors.base.white, 0.3),
};

const radioButtonColors = {
  helper: colors.secondaryBlue.blue02,
  label: rgbaFromHexAndOpacity(colors.base.white, 0.87),
  button: colors.base.white,
  checked: colors.base.white,
  hover: 'none',
};

export const StyledRadioButtonGroup = styled(RadioButtonGroup).attrs({
  radioButtonGroupColors,
  radioButtonColors,
})`
  width: 100%;
`;
export const RadioButtonGroupDescription = styled(Text).attrs({
  color: colors.base.white,
})`
  margin: 0 0 ${spacing.s4} 0;
`;
