import { PageNotFound } from '@bt-healthcare/ui-toolkit';
import { Navigate, Route, Routes } from 'react-router-dom';

import { BaseLayout } from './Layouts/BaseLayout';
import { GridLayout } from './Layouts/GridLayout';
import { GPLinkageSuccess } from './components/GPLinkageSuccess';
import { GPUnlinkageSuccess } from './components/GPUnlinkageSuccess';
import { PolicyType } from './components/Policy/types';
import { ReadingSubmitted } from './components/ReadingSubmitted';
import { UserAcceptedTermsAndConditionsRoute } from './components/UserAcceptedTermsAndConditionsRoute';
import { UserAuthorizedRoute } from './components/UserAuthorizedRoute';
import { UserVerifiedRoute } from './components/UserVerifiedRoute';
import { WardPatientRoute } from './components/WardPatientRoute';
import { ROUTE } from './config/routes';
import { useFeatureFlags } from './hooks/useFeatureFlags';
import { Account } from './pages/Account';
import { AppointmentsCalendar } from './pages/AppointmentsCalendar';
import { BookingSuccess } from './pages/BookingSuccess';
import { CancellationSuccess } from './pages/CancellationSuccess';
import { CollateralLanding } from './pages/CollateralLanding';
import { ConnectGPLinkage } from './pages/ConnectGPLinkage';
import { CreateObservations } from './pages/CreateObservations';
import { CreateObservationsConfirm } from './pages/CreateObservationsConfirm';
import { CreateObservationsGuide } from './pages/CreateObservationsGuide';
import { CreateObservationsList } from './pages/CreateObservationsList';
import { Discharged } from './pages/Discharged';
import { Ethnicity } from './pages/Ethnicity';
import { GetReadyGPLinkage } from './pages/GetReadyGPLinkage';
import { HealthInformation } from './pages/HealthInformation';
import { HealthInformationList } from './pages/HealthInformationList';
import { HealthScoreAlcoholFrequency } from './pages/HealthScoreAlcoholFrequency';
import { HealthScoreAlcoholModeration } from './pages/HealthScoreAlcoholModeration';
import { HealthScoreHeightAndWeight } from './pages/HealthScoreHeightAndWeight';
import { HealthScoreNavigator } from './pages/HealthScoreNavigator';
import { HealthScorePersonalInfo } from './pages/HealthScorePersonalInfo';
import { HealthScoreSmoking } from './pages/HealthScoreSmoking';
import { HealthScoreStrength } from './pages/HealthScoreStrength';
import { HistoricalObservations } from './pages/HistoricalObservations';
import { HistoricalObservationsList } from './pages/HistoricalObservationsList';
import { Home } from './pages/Home/Home';
import { Login } from './pages/Login';
import { EmailPreferences } from './pages/NotificationEmailPreferences';
import { EmailUnsubscribed } from './pages/NotificationEmailUnsubscribed';
import { NotificationSettingsPage } from './pages/NotificationSettings';
import { ObservationRatings } from './pages/ObservationRatings';
import { OnboardingCheckEmail } from './pages/OnboardingCheckEmail';
import { OnboardingEmailAddress } from './pages/OnboardingEmailAddress/OnboardingEmailAddress';
import { OnboardingError } from './pages/OnboardingError';
import { OnboardingPassword } from './pages/OnboardingPassword/OnboardingPassword';
import { OnboardingPersonalDetails } from './pages/OnboardingPersonalDetails/OnboardingPersonalDetails';
import { OnboardingSuccess } from './pages/OnboardingSuccess';
import { OnboardingVerifySuccess } from './pages/OnboardingVerifySuccess';
import { PublicPolicy } from './pages/PublicPolicy';
import { RecommendationAverageBloodPressureInfo } from './pages/RecommendationAverageBloodPressureInfo';
import { RecommendationAverageResult } from './pages/RecommendationAverageResult';
import { RecommendationConfirmYourPlan } from './pages/RecommendationConfirmYourPlan';
import { RecommendationHowItWorks } from './pages/RecommendationHowItWorks';
import { RecommendationObservationList } from './pages/RecommendationObservationList';
import { RecommendationSuccess } from './pages/RecommendationSuccess';
import { TermsAndConditions } from './pages/TermsAndConditions';
import { UserActivation } from './pages/UserActivation';
import { VO2 } from './pages/VO2';
import { StepUpInvitation } from './pages/StepUpInvitation';
import { StepUpSuccess } from './pages/StepUpSuccess';
import { StepUpInvalid } from './pages/StepUpInvalid';

const App = () => {
  const flags = useFeatureFlags();

  return (
    <Routes>
      <Route path="/" element={<BaseLayout />}>
        <Route path={ROUTE.LOGIN} element={<Login />} />

        <Route path={ROUTE.DISCHARGED} element={<Discharged />} />
        <Route
          path={ROUTE.COOKIES}
          element={<PublicPolicy type={PolicyType.COOKIE} />}
        />
        <Route
          path={ROUTE.PRIVACY}
          element={<PublicPolicy type={PolicyType.PRIVACY} />}
        />
        <Route
          path={ROUTE.TERMS_AND_CONDITIONS}
          element={<PublicPolicy type={PolicyType.TERMS_AND_CONDITIONS} />}
        />

        <Route path={ROUTE.COLLATERAL_LANDING} element={<GridLayout />}>
          <Route
            path={ROUTE.REMOTE_CARE_SERVICE}
            element={<CollateralLanding />}
          />
        </Route>
        <Route path={ROUTE.NOTIFICATION} element={<GridLayout />}>
          <Route
            path={ROUTE.NOTIFICATION_EMAIL_PREFERENCES}
            element={<EmailPreferences />}
          />
          <Route path={ROUTE.UNSUBSCRIBED} element={<EmailUnsubscribed />} />
        </Route>
        <Route path={ROUTE.ONBOARDING} element={<GridLayout />}>
          <Route
            path={ROUTE.ONBOARDING_EMAIL_ADDRESS}
            element={<OnboardingEmailAddress />}
          />
          <Route
            path={ROUTE.ONBOARDING_CHECK_EMAIL}
            element={<OnboardingCheckEmail />}
          />
          <Route
            path={ROUTE.ONBOARDING_VERIFY_SUCCESS}
            element={<OnboardingVerifySuccess />}
          />
          <Route
            path={ROUTE.ONBOARDING_PERSONAL_DETAILS}
            element={<OnboardingPersonalDetails />}
          />
          <Route
            path={ROUTE.ONBOARDING_PASSWORD}
            element={<OnboardingPassword />}
          />
          <Route
            path={ROUTE.ONBOARDING_SUCCESS}
            element={<OnboardingSuccess />}
          />
          <Route path={ROUTE.ONBOARDING_ERROR} element={<OnboardingError />} />
        </Route>
        <Route element={<UserAuthorizedRoute />}>
          <Route
            path={ROUTE.ACCEPT_TERMS_AND_CONDITIONS}
            element={<TermsAndConditions />}
          />
          <Route
            element={
              <UserAcceptedTermsAndConditionsRoute
                redirectPath={ROUTE.ACCEPT_TERMS_AND_CONDITIONS}
              />
            }
          >
            <Route path={ROUTE.ACTIVATE} element={<UserActivation />} />
            <Route
              element={<UserVerifiedRoute redirectPath={ROUTE.ACTIVATE} />}
            >
              <Route
                path={ROUTE.STEP_UP_INVITATION}
                element={<StepUpInvitation />}
              />
              <Route path={ROUTE.STEP_UP_SUCCESS} element={<StepUpSuccess />} />
              <Route path={ROUTE.STEP_UP_INVALID} element={<StepUpInvalid />} />
              <Route element={<WardPatientRoute />}>
                <Route index element={<Home />} />
                <Route
                  path={ROUTE.CREATE_OBSERVATION}
                  element={<CreateObservationsList />}
                />
                <Route
                  path={ROUTE.CREATE_OBSERVATION_NEW}
                  element={<CreateObservations />}
                />
                {flags.VO2 && (
                  <Route
                    path={ROUTE.CREATE_OBSERVATION_NEW_VO2}
                    element={<VO2 />}
                  />
                )}
                <Route
                  path={ROUTE.CREATE_OBSERVATION_CONFIRM}
                  element={<CreateObservationsConfirm />}
                />
                <Route
                  path={ROUTE.CREATE_OBSERVATION_GUIDE}
                  element={<CreateObservationsGuide />}
                />

                <Route
                  path={ROUTE.CREATE_OBSERVATION_SUCCESS}
                  element={<ReadingSubmitted />}
                />
                <Route path={ROUTE.ACCOUNT} element={<Account />} />
                <Route path={ROUTE.ACCOUNT_ETHNICITY} element={<Ethnicity />} />
                <Route
                  path={ROUTE.ACCOUNT_HEALTH_INFORMATION}
                  element={<HealthInformationList />}
                />
                <Route
                  path={ROUTE.ACCOUNT_CONTACT_PREFERENCES}
                  element={<NotificationSettingsPage />}
                />
                <Route
                  path={ROUTE.ACCOUNT_HEALTH_INFORMATION_INPUT}
                  element={<HealthInformation />}
                />
                <Route
                  path={ROUTE.APPOINTMENTS_CALENDAR}
                  element={<AppointmentsCalendar />}
                />
                <Route
                  path={ROUTE.BOOKING_SUCCESS}
                  element={<BookingSuccess />}
                />
                <Route
                  path={ROUTE.GP_LINKAGE_GET_READY}
                  element={<GetReadyGPLinkage />}
                />
                <Route
                  path={ROUTE.GP_LINKAGE_CONNECT}
                  element={<ConnectGPLinkage />}
                />
                <Route
                  path={ROUTE.GP_LINKAGE_SUCCESS}
                  element={<GPLinkageSuccess />}
                />
                <Route
                  path={ROUTE.GP_UNLINKAGE_SUCCESS}
                  element={<GPUnlinkageSuccess />}
                />
                <Route
                  path={ROUTE.HISTORICAL_READINGS}
                  element={<HistoricalObservations />}
                />
                <Route
                  path={ROUTE.HISTORICAL_READINGS_TYPE}
                  element={<HistoricalObservationsList />}
                />
                <Route
                  path={ROUTE.CANCELLATION_SUCCESS}
                  element={<CancellationSuccess />}
                />
                <Route
                  path={ROUTE.OBSERVATION_RATINGS}
                  element={<ObservationRatings />}
                />

                <Route
                  path={ROUTE.RECOMMENDATION_INFO}
                  element={<RecommendationAverageBloodPressureInfo />}
                />
                <Route
                  path={ROUTE.RECOMMENDATION_CONFIRM_PLAN}
                  element={<RecommendationConfirmYourPlan />}
                />
                <Route
                  path={ROUTE.RECOMMENDATION_SUCCESS}
                  element={<RecommendationSuccess />}
                />
                <Route
                  path={ROUTE.RECOMMENDATION_HOW_IT_WORKS}
                  element={<RecommendationHowItWorks />}
                />
                <Route
                  path={ROUTE.RECOMMENDATION_PROGRAMME}
                  element={<RecommendationAverageResult />}
                />
                <Route
                  path={ROUTE.RECOMMENDATION_PROGRAMME_OBSERVATIONS}
                  element={<RecommendationObservationList />}
                />
                {flags.HEALTH_SCORE_3994 && (
                  <Route element={<HealthScoreNavigator />}>
                    <Route
                      element={<HealthScorePersonalInfo />}
                      path={ROUTE.HEALTH_SCORE_PERSONAL_INFO}
                    />
                    <Route
                      element={<HealthScoreStrength />}
                      path={ROUTE.HEALTH_SCORE_STRENGTH}
                    />
                    <Route
                      element={<HealthScoreSmoking />}
                      path={ROUTE.HEALTH_SCORE_SMOKING}
                    />
                    <Route
                      element={<HealthScoreAlcoholFrequency />}
                      path={ROUTE.HEALTH_SCORE_ALCOHOL_FREQUENCY}
                    />
                    <Route
                      element={<HealthScoreAlcoholModeration />}
                      path={ROUTE.HEALTH_SCORE_ALCOHOL_MODERATION}
                    />
                    <Route
                      element={<HealthScoreHeightAndWeight />}
                      path={ROUTE.HEALTH_SCORE_HEIGHT_AND_WEIGHT}
                    />
                  </Route>
                )}
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path={ROUTE.NOT_FOUND} element={<PageNotFound />} />
        <Route path="/*" element={<Navigate to={ROUTE.NOT_FOUND} />} />
      </Route>
    </Routes>
  );
};

export default App;
