import { IntlShape } from 'react-intl';
import { object, string } from 'yup';
import { sortArrayByGivenOrder } from '../../utils/array.utils';
import { healthScorePersonalInfo } from './intl';
import {
  GenderAssignedAtBirth,
  GenderIdentifiedAs,
} from '../../services/graphql';

const genderIdentityOrder = [
  GenderIdentifiedAs.Female,
  GenderIdentifiedAs.Male,
  GenderIdentifiedAs.Transwoman,
  GenderIdentifiedAs.Transman,
  GenderIdentifiedAs.NonBinary,
  GenderIdentifiedAs.PreferNone,
  GenderIdentifiedAs.Other,
];

export const genderIdentity = (intl: IntlShape) => ({
  name: 'genderIdentity',
  label: intl.formatMessage(
    healthScorePersonalInfo.fields.genderIdentity.label
  ),
  validationRule: object()
    .shape({
      value: string().oneOf(Object.values(GenderIdentifiedAs)),
      label: string(),
    })
    .typeError(''),
  options: sortArrayByGivenOrder(
    Object.values(GenderIdentifiedAs),
    genderIdentityOrder
  )
    .filter((item) => item !== GenderIdentifiedAs.None)
    .map((value) => ({
      value,
      label: intl.formatMessage(
        healthScorePersonalInfo.fields.genderIdentity.options[value]
      ),
    })),
});

export const genderAssignedAtBirth = (intl: IntlShape) => ({
  name: 'genderAssignedAtBirth',
  label: intl.formatMessage(
    healthScorePersonalInfo.fields.genderAssignedAtBirth.label
  ),
  validationRule: string().required(),
  options: [
    {
      value: GenderAssignedAtBirth.Male,
      label: intl.formatMessage(
        healthScorePersonalInfo.fields.genderAssignedAtBirth.options[
          GenderAssignedAtBirth.Male
        ]
      ),
    },
    {
      value: GenderAssignedAtBirth.Female,
      label: intl.formatMessage(
        healthScorePersonalInfo.fields.genderAssignedAtBirth.options[
          GenderAssignedAtBirth.Female
        ]
      ),
    },
  ],
});
export const data = (intl: IntlShape) => ({
  genderIdentity: genderIdentity(intl),
  genderAssignedAtBirth: genderAssignedAtBirth(intl),
});
