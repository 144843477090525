import { differenceInYears } from 'date-fns';

export const under18 = ({
  dayOfBirth,
  monthOfBirth,
  yearOfBirth,
}: {
  dayOfBirth: number;
  monthOfBirth: number;
  yearOfBirth: number;
}) =>
  differenceInYears(
    new Date(),
    new Date(yearOfBirth, monthOfBirth - 1, dayOfBirth)
  ) < 18;

export const maxLifeSpanYear = () => new Date().getFullYear() - 200;
