import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import BluePageWrapper from '../../components/BluePageWrapper';
import { ReactComponent as Img } from '../../assets/blue-screen-info.svg';
import BaseCard from '../../components/Cards/BaseCard';
import { ROUTE } from '../../config/routes';
import { useTracking } from '../../hooks/useTracking';
import { recommendationAverageBloodPressureInfo } from './intl';
import {
  CardLabel,
  CardWrapper,
  Description,
  DescriptionWrapper,
  GiftIcon,
  ImgContainer,
  StyledCard,
  Title,
  TitleWrapper,
} from './styles';

export const RecommendationAverageBloodPressureInfo = () => {
  const navigate = useNavigate();
  const { trackPage } = useTracking();
  useEffect(() => {
    trackPage('Offer ABP insights');
  }, []);
  return (
    <BluePageWrapper>
      <BluePageWrapper.Content>
        <BluePageWrapper.Close isBreakRecommendationFlow />
        <ImgContainer>
          <Img width="100%" height="100%" />
        </ImgContainer>
        <TitleWrapper>
          <Title>
            <FormattedMessage
              {...recommendationAverageBloodPressureInfo.title}
            />
          </Title>
        </TitleWrapper>
        <CardWrapper>
          <StyledCard>
            <BaseCard.ItemLeft gap={12}>
              <GiftIcon />
              <CardLabel>
                <FormattedMessage
                  {...recommendationAverageBloodPressureInfo.card}
                />
              </CardLabel>
            </BaseCard.ItemLeft>
          </StyledCard>
        </CardWrapper>
        <DescriptionWrapper>
          <Description>
            <FormattedMessage
              {...recommendationAverageBloodPressureInfo.description}
            />
          </Description>
        </DescriptionWrapper>
      </BluePageWrapper.Content>
      <BluePageWrapper.Bottom>
        <BluePageWrapper.Button
          onClick={() => {
            navigate(ROUTE.RECOMMENDATION_HOW_IT_WORKS);
          }}
        >
          <FormattedMessage
            {...recommendationAverageBloodPressureInfo.button}
          />
        </BluePageWrapper.Button>
      </BluePageWrapper.Bottom>
    </BluePageWrapper>
  );
};
