import { IntlShape } from 'react-intl';
import {
  GenderAssignedAtBirth,
  GenderIdentifiedAs,
} from '../../services/graphql';
import { healthScorePersonalInfo } from './intl';
import { dayMonthYearFromISO8061Date } from '../../formatters/date';

export const defaultPersonalInfoValues = ({
  dateOfBirth,
  genderAssignedAtBirth,
  genderIdentifiedAs,
  intl,
}: {
  dateOfBirth?: string | null;
  genderAssignedAtBirth?: GenderAssignedAtBirth | null;
  genderIdentifiedAs?: GenderIdentifiedAs | null;
  intl: IntlShape;
}) => {
  const dobObject = dateOfBirth && dayMonthYearFromISO8061Date(dateOfBirth);
  return {
    ...(dobObject && {
      dob: {
        dayOfBirth: dobObject.day,
        monthOfBirth: dobObject.month,
        yearOfBirth: dobObject.year,
      },
    }),
    ...(genderIdentifiedAs &&
      genderIdentifiedAs !== GenderIdentifiedAs.None && {
        genderIdentity: {
          value: genderIdentifiedAs,
          label: intl.formatMessage(
            healthScorePersonalInfo.fields.genderIdentity.options[
              genderIdentifiedAs
            ]
          ),
        },
      }),
    ...(genderAssignedAtBirth && { genderAssignedAtBirth }),
  };
};
