import { DateInput } from '@bt-healthcare/ui-toolkit';
import { useFormContext } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { BirthDateParams, RHFDateOfBirthInputProps } from './types';

export const RHFDateOfBirthInput = ({
  defaultValues,
  ...props
}: RHFDateOfBirthInputProps) => {
  const intl = useIntl();
  const dateInputRef = useRef<any>();
  const {
    register,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext<BirthDateParams>();
  const { ref: dayRef, ...dayRest } = register('dayOfBirth');
  const { ref: monthRef, ...monthRest } = register('monthOfBirth');
  const { ref: yearRef, ...yearRest } = register('yearOfBirth');

  const [dayOfBirth, monthOfBirth, yearOfBirth] = watch([
    'dayOfBirth',
    'monthOfBirth',
    'yearOfBirth',
  ]);

  useEffect(() => {
    if (!dayOfBirth || !monthOfBirth || !yearOfBirth) {
      return;
    }
    trigger('yearOfBirth');
  }, [dayOfBirth, monthOfBirth, yearOfBirth]);

  dateInputRef.current = { dayRef, monthRef, yearRef };
  const inputParams = {
    dayInput: {
      id: 'dayOfBirth',
      'data-testid': 'day-of-birth',
      'aria-label': 'Day of birth',
      errorText: errors.dayOfBirth?.message?.toString(),
      defaultValue: defaultValues?.dayOfBirth,
      ...dayRest,
    },
    monthInput: {
      id: 'monthOfBirth',
      'data-testid': 'month-of-birth',
      'aria-label': 'Month of birth',
      defaultValue: defaultValues?.monthOfBirth,
      errorText: errors?.monthOfBirth?.message?.toString(),
      ...monthRest,
    },
    yearInput: {
      id: 'yearOfBirth',
      'data-testid': 'year-of-birth',
      'aria-label': 'year of birth',
      defaultValue: defaultValues?.yearOfBirth,
      errorText: errors?.yearOfBirth?.message?.toString(),
      ...yearRest,
    },
  };
  return (
    <DateInput
      inputParams={inputParams}
      ref={dateInputRef}
      id="dateOfBirth"
      label={intl.formatMessage({
        id: 'date-of-birth.field.label',
        defaultMessage: 'Date of birth',
      })}
      markAsRequired
      {...props}
    />
  );
};
