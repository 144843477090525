import { IntlShape } from 'react-intl';
import {
  ObservationComponentName,
  ObservationType,
} from '../../services/graphql';
import { formatNumber } from '../../formatters/observations';
import { ObservationData } from '../ObservationGraph/types';
import { DataPointType } from './types';

export const getTooltipDate = (
  data: ObservationData,
  dataPointType: DataPointType
) => {
  switch (dataPointType) {
    case DataPointType.DAILY_AVERAGE: {
      return new Date(data.date).toLocaleDateString(undefined, {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });
    }
    case DataPointType.SINGLE_OBSERVATION:
    default: {
      const hours = String(new Date(data.date).getHours()).padStart(2, '0');
      const minutes = String(new Date(data.date).getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    }
  }
};

export const formatObservationDataForTooltip = (
  data: ObservationData,
  type: ObservationType,
  intl: IntlShape
) => {
  if (type === ObservationType.BloodPressure) {
    const systolic = data.series.find(
      (item) => item.name === ObservationComponentName.SystolicBloodPressure
    )?.value;
    const diastolic = data.series.find(
      (item) => item.name === ObservationComponentName.DiastolicBloodPressure
    )?.value;
    return (
      systolic &&
      diastolic &&
      formatNumber({
        values: { systolic, diastolic },
        intl,
        type,
        withUnit: false,
      })
    );
  }
  return formatNumber({
    values: { value: data.series[0].value } || undefined,
    intl,
    type,
  });
};
