import {
  Stack,
  Checkbox,
  Spacer,
  Button,
  Notification,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { CustomLabel } from './CustomLabel';
import { notificationEmailPreferences } from './intl';
import {
  ContactExclusionPreferences,
  ContactExclusionPreferencesInput,
  useGetContactExclusionPreferencesQuery,
  useUpdateContactExclusionPreferencesMutation,
} from '../../services/graphql';
import { Form } from './styles';
import { useContactExclusionPreferencesFormStateHook } from '../../hooks/useContactExclusionPreferencesFormState';

export const EmailPreferencesForm = () => {
  const [updateContactExclusionPreferencesMutation, { error, loading, data }] =
    useUpdateContactExclusionPreferencesMutation();

  const { personId } = useParams<{ personId: string }>();

  const { data: userData } = useGetContactExclusionPreferencesQuery({
    variables: { personId: personId || '' },
    context: {
      isAnonymous: true,
    },
  });

  const {
    reminders,
    setReminders,
    recommendations,
    setRecommendations,
    updates,
    setUpdates,
  } = useContactExclusionPreferencesFormStateHook(
    userData?.contactExclusionPreferences as ContactExclusionPreferences[]
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    updateContactExclusionPreferencesMutation({
      variables: {
        input: {
          CONTACTEXCL_EMAIL_ADVISE: !recommendations,
          personId: personId as string,
          CONTACTEXCL_EMAIL_LATEST_NEWS_UPDATES: !updates,
          CONTACTEXCL_EMAIL_REMINDER: !reminders,
        } as ContactExclusionPreferencesInput,
      },
      context: {
        isAnonymous: true,
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Stack id="email-preferences-form">
        <Checkbox
          id="reminders"
          position="leftTop"
          checked={reminders}
          onChange={() => setReminders(!reminders)}
          label={
            <CustomLabel
              header={
                <FormattedMessage
                  {...notificationEmailPreferences.checkboxRemindersTitle}
                />
              }
              text={
                <FormattedMessage
                  {...notificationEmailPreferences.checkboxRemindersDescription}
                />
              }
            />
          }
        />
        <Spacer size="s6" />

        <Checkbox
          id="recommendations"
          position="leftTop"
          checked={recommendations}
          onChange={() => setRecommendations(!recommendations)}
          label={
            <CustomLabel
              header={
                <FormattedMessage
                  {...notificationEmailPreferences.checkboxRecommendationsTitle}
                />
              }
              text={
                <FormattedMessage
                  {...notificationEmailPreferences.checkboxRecommendationsDescription}
                />
              }
            />
          }
        />
        <Spacer size="s6" />

        <Checkbox
          id="updates"
          position="leftTop"
          checked={updates}
          onChange={() => setUpdates(!updates)}
          label={
            <CustomLabel
              header={
                <FormattedMessage
                  {...notificationEmailPreferences.checkboxUpdatesTitle}
                />
              }
              text={
                <FormattedMessage
                  {...notificationEmailPreferences.checkboxUpdatesDescription}
                />
              }
            />
          }
        />
        <Spacer size="s6" />
        {data && (
          <Notification type="positive">
            <FormattedMessage
              {...notificationEmailPreferences.notificationPositive}
            />
          </Notification>
        )}
        {error && (
          <Notification type="warning">
            <FormattedMessage
              {...notificationEmailPreferences.notificationWarning}
            />
          </Notification>
        )}
        <Spacer size="s6" />

        <Button
          id="notifications-email-preferences-form-submit-button"
          isLoading={!!loading}
          disabled={!reminders && !recommendations && !updates}
        >
          <FormattedMessage {...notificationEmailPreferences.submitButton} />
        </Button>
      </Stack>
    </Form>
  );
};
