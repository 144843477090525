import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';

import {
  Stack,
  Header,
  Spacer,
  Accordion,
  AccordionItem,
  colors,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import { AnchorTag, FrequentlyAskedQuestionsWrapper, Hr } from './styles';
import { frequentlyAskedQuestions } from './intl';
import { REGISTRATION_EMAIL, SUPPORT_PHONE_NO } from '../../App.constants';

export const FrequentlyAskedQuestions = () => {
  const { loginWithRedirect } = useAuth0();
  const questions = Object.keys(frequentlyAskedQuestions) as Array<
    keyof typeof frequentlyAskedQuestions
  >;
  return (
    <FrequentlyAskedQuestionsWrapper>
      <Stack id="frequently-asked-questions" minWidth="328px">
        <Header>Frequently asked questions</Header>
      </Stack>
      <Spacer size="s8" />
      <Accordion id="single">
        {questions.map((question, index) => (
          <Stack
            id=""
            flexDirection="column"
            key={frequentlyAskedQuestions[question].question.id}
          >
            <Spacer size="s4" />
            <AccordionItem
              color={colors.primaryIndigo.indigo09}
              title={frequentlyAskedQuestions[question].question.defaultMessage}
              id="question"
            >
              <FormattedMessage
                {...frequentlyAskedQuestions[question].answer}
                values={{
                  br: <br />,
                  email: (
                    <AnchorTag href={`mailto:${REGISTRATION_EMAIL}`}>
                      {REGISTRATION_EMAIL}
                    </AnchorTag>
                  ),
                  LogInHere: (
                    <button
                      type="button"
                      color={colors.primaryIndigo.indigo08}
                      onClick={() => loginWithRedirect()}
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: '0',
                        font: 'inherit',
                        cursor: 'pointer',
                        outline: 'inherit',
                        color: colors.primaryIndigo.indigo08,
                        fontFamily: "'BT-Curve', sans-serif",
                        fontSize: fontSizes.base,
                        fontWeight: 400,
                        lineHeight: spacing.s6,
                        textDecorationLine: 'underline',
                      }}
                    >
                      log in here
                    </button>
                  ),
                  phoneNumber: (
                    <AnchorTag href={`tel:${SUPPORT_PHONE_NO}`}>
                      {SUPPORT_PHONE_NO}
                    </AnchorTag>
                  ),
                }}
              />
            </AccordionItem>
            <Spacer size="s8" />
            {index < questions.length - 1 && <Hr />}
          </Stack>
        ))}
      </Accordion>
      <Spacer size="s10" />
    </FrequentlyAskedQuestionsWrapper>
  );
};
