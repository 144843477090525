import { FormattedMessage, IntlShape } from 'react-intl';
import { StyledComponent } from 'styled-components';
import { Link } from 'react-router-dom';
import React from 'react';
import { ArrowIcon, WarningIcon } from './styles';
import { RecommendationBulletListItem } from '../../components/RecommendationBulletList/types';
import { recommendationAverageResult } from './intl';
import { FhirRating } from '../../services/graphql';
import { isKeyOf } from '../../utils';

const NHS_LINK = 'https://www.nhs.uk/conditions/blood-pressure-test/';
export const AVERAGE_OBSERVATION_ID_SEARCH_PARAM = 'averageObservationId';

export const createBulletItem = ({
  key,
  intl,
  values,
  icon = ArrowIcon,
}: {
  key: keyof typeof recommendationAverageResult.recommendations;
  intl: IntlShape;
  values?: { [name: string]: any };
  icon?: StyledComponent<any, any>;
}): RecommendationBulletListItem => ({
  text: (
    <p>
      {intl.formatMessage(
        recommendationAverageResult.recommendations[key],
        values
      )}
    </p>
  ),
  icon,
});

export const createBulletsList = (
  rating: FhirRating,
  intl: IntlShape
): RecommendationBulletListItem[] => {
  const levelItem = createBulletItem({
    key: 'level',
    intl,
    values: {
      level: isKeyOf(recommendationAverageResult.levelNames, rating)
        ? intl.formatMessage(recommendationAverageResult.levelNames[rating])
        : null,
    },
  });
  const keyAssessItem =
    rating === FhirRating.SignificantlyHigh ? 'high' : 'nonHigh';
  const assessItem = createBulletItem({
    key: keyAssessItem,
    intl,
    icon: rating === FhirRating.SignificantlyHigh ? WarningIcon : undefined,
  });
  const link = (
    <Link to={NHS_LINK}>
      <u>
        <FormattedMessage {...recommendationAverageResult.nhsLink} />
      </u>
    </Link>
  );
  const informationItem = createBulletItem({
    key: 'information',
    intl,
    values: { link },
  });
  const exercisesItem = createBulletItem({ key: 'exercises', intl });
  const trackItem = createBulletItem({ key: 'track', intl });
  return [levelItem, assessItem, exercisesItem, trackItem, informationItem];
};
