import { Skeleton } from '@bt-healthcare/ui-toolkit';
import BaseCard from '../BaseCard';

export const ObservationLoadingCard = () => (
  <BaseCard id="loading-card" data-testid="loading-card">
    <BaseCard.ItemLeft flexDirection="column" gap={8}>
      <Skeleton
        id="loading-card-left-up-skeleton"
        data-testid="loading-card-left-up-skeleton"
        width={150}
        height={13}
        variant="text"
        rounded
      />
      <Skeleton
        id="loading-card-down-up-skeleton"
        data-testid="loading-card-left-down-skeleton"
        width={50}
        height={13}
        variant="text"
        rounded
      />
    </BaseCard.ItemLeft>
    <BaseCard.ItemRight alignSelf="center">
      <Skeleton
        id="loading-card-right-skeleton"
        data-testid="loading-card-right-skeleton"
        height={32}
        width={32}
        variant="circular"
        rounded
      />
    </BaseCard.ItemRight>
  </BaseCard>
);
