import { defineMessages } from 'react-intl';
import { Messages } from '../../i18n/messages/types';
import { HealthInformationType } from '../HealthInformation/types';
import {
  CvdFamilyInformation,
  DiabetesStatus,
  HeightUnit,
} from '../../services/graphql';

export const healthInformationList: Messages = defineMessages({
  header: {
    id: 'health-information.header',
    defaultMessage: 'Health information',
  },
  [HealthInformationType.HEIGHT]: {
    id: 'health-information.height',
    defaultMessage: 'Height',
  },
  [HealthInformationType.SMOKING]: {
    id: 'health-information.smoking',
    defaultMessage: 'Smoking status',
  },
  [HealthInformationType.BLOOD_PRESSURE_MEDICATION]: {
    id: 'health-information.blood-pressure-medication',
    defaultMessage: 'Blood pressure medication',
  },
  [HealthInformationType.CHRONIC_KIDNEY_DISEASE]: {
    id: 'health-information.chronic-kidney-disease',
    defaultMessage: 'Chronic kidney disease status',
  },
  [HealthInformationType.CARDIOVASCULAR_DISEASE]: {
    id: 'health-information.cardiovascular-disease',
    defaultMessage: 'Cardiovascular disease',
  },
  [HealthInformationType.DIABETES]: {
    id: 'health-information.diabetes',
    defaultMessage: 'Diabetes status',
  },
});

export const heightInformationDisplay: Messages = defineMessages({
  [HeightUnit.Cm]: {
    id: 'health-information.height.display.cm',
    defaultMessage: '{cm} cm',
  },
  [HeightUnit.Inch]: {
    id: 'health-information.height.display.inch',
    defaultMessage: '{ft}ft {inch}in',
  },
});

export const bloodPressureMedicationDisplay: Messages = defineMessages({
  hasBPMedicationYes: {
    id: 'health-information.blood-pressure-medication.display.has-bp-medication-yes',
    defaultMessage: 'Currently on medication',
  },
  hasBPMedicationNo: {
    id: 'health-information.blood-pressure-medication.display.has-bp-medication-no',
    defaultMessage: 'Not on medication',
  },
  hadBPMedicationYes: {
    id: 'health-information.blood-pressure-medication.display.had-bp-medication-yes',
    defaultMessage: 'Taken medication before',
  },
  hadBPMedicationNo: {
    id: 'health-information.blood-pressure-medication.display.had-bp-medication-no',
    defaultMessage: 'Not taken medication before',
  },
});

export const chronicKidneyDiseaseDisplay: Messages = defineMessages({
  statusYes: {
    id: 'health-information.chronic-kidney-disease.display.yes',
    defaultMessage: 'Yes',
  },
  statusNo: {
    id: 'health-information.chronic-kidney-disease.display.no',
    defaultMessage: 'None',
  },
});

export const cardiovascularDiseaseHasCvdDisplay: Messages = defineMessages({
  yes: {
    id: 'health-information.cardiovascular-disease.has-cvd.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'health-information.cardiovascular-disease.has-cvd.no',
    defaultMessage: 'None',
  },
});

export const cardiovascularDiseaseFamilyHadCvdDisplay: Messages =
  defineMessages({
    [CvdFamilyInformation.Yes]: {
      id: 'health-information.cardiovascular-disease.family-had-cvd.yes',
      defaultMessage: 'Family history of CVD',
    },
    [CvdFamilyInformation.No]: {
      id: 'health-information.cardiovascular-disease.family-had-cvd.no',
      defaultMessage: 'No family history',
    },
    [CvdFamilyInformation.PreferNotToSay]: {
      id: 'health-information.cardiovascular-disease.family-had-cvd.prefer-not-to-say',
      defaultMessage: 'Prefer not to say family history',
    },
    [CvdFamilyInformation.DontKnow]: {
      id: 'health-information.cardiovascular-disease.family-had-cvd.dont-know',
      defaultMessage: 'I don’t know my family history',
    },
  });

export const diabetesStatusDisplay: Messages = defineMessages({
  [DiabetesStatus.PreferNotToSay]: {
    id: 'health-information.diabetes.status.display.prefer-not-say',
    defaultMessage: 'Prefer not to say',
  },
  [DiabetesStatus.No]: {
    id: 'health-information.diabetes.status.display.no',
    defaultMessage: 'None',
  },
  [DiabetesStatus.Type_1]: {
    id: 'health-information.diabetes.status.display.type-1',
    defaultMessage: 'Type 1',
  },
  [DiabetesStatus.Type_2]: {
    id: 'health-information.diabetes.status.options.type-2',
    defaultMessage: 'Type 2',
  },
});
