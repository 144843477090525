import { useAuth0 } from '@auth0/auth0-react';
import { SpinnerLoader } from '@bt-healthcare/ui-toolkit';
import { Outlet, useSearchParams } from 'react-router-dom';

import { LandingPage } from '../../pages/Landing';
import {
  CARE_SETTING_NAME,
  STEP_UP_NOT_VERIFIED,
  WARD_PATIENT_ID,
} from '../../App.constants';

export const UserAuthorizedRoute = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [params] = useSearchParams();

  if (params.size > 0 && params.get('encrypted-wpid')) {
    localStorage.setItem(WARD_PATIENT_ID, params.get('encrypted-wpid') ?? '');
    localStorage.setItem(CARE_SETTING_NAME, params.get('csName') ?? '');
    localStorage.setItem(STEP_UP_NOT_VERIFIED, 'true');

    if (!isAuthenticated) {
      loginWithRedirect();
      return null;
    }
  }

  if (isLoading) {
    return (
      <SpinnerLoader
        id="user-authorized-route"
        data-testid="user-authorized-route"
      />
    );
  }

  if (isAuthenticated) {
    return <Outlet />;
  }
  return <LandingPage onLoggingIn={() => loginWithRedirect()} />;
};
