import { defineMessages } from 'react-intl';

export const about = defineMessages({
  title: {
    defaultMessage: 'About',
    id: 'account.legal-and-privacy.title',
  },
  version: {
    defaultMessage: 'Version {version}',
    id: 'account.legal-and-privacy.version',
  },
  termsAndConditions: {
    defaultMessage: 'Terms & conditions',
    id: 'account.legal-and-privacy.terms-and-conditions',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy policy',
    id: 'account.legal-and-privacy.privacy-policy',
  },
  cookiePolicy: {
    defaultMessage: 'Cookie policy',
    id: 'account.legal-and-privacy.cookie-policy',
  },
});

export const settings = defineMessages({
  title: {
    defaultMessage: 'Settings',
    id: 'account.settings.title',
  },
  notifications: {
    defaultMessage: 'Notifications',
    id: 'account.settings.notifications',
  },
});
