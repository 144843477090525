import styled from 'styled-components';
import { colors, fontSizes, spacing, Text } from '@bt-healthcare/ui-toolkit';
import { ObservationWarningLevel } from '../../../services/graphql';

export const StyledCard = styled.div`
  background-color: ${colors.base.white};
  border-radius: 8px;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: ${spacing.s4} ${spacing.s6};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  padding-left: ${spacing.s2};
`;

export const ObservationValueLabel = styled(Text).attrs({
  color: colors.grey.grey10,
  fontSize: fontSizes.base,
  fontWeight: 500,
})`
  white-space: nowrap;
`;

export const ObservationTypeLabel = styled(Text).attrs({
  color: colors.grey.grey08,
  fontSize: fontSizes.sm,
})`
  white-space: nowrap;
`;

const warningColors = {
  [ObservationWarningLevel.SevereHigh]: colors.notifications.light.errorLight,
  [ObservationWarningLevel.SoftLow]: colors.notifications.light.infoLight,
  [ObservationWarningLevel.SoftHigh]: colors.notifications.light.infoLight,
  [ObservationWarningLevel.SevereLow]: colors.notifications.light.errorLight,
};

export const WarningContainer = styled.div<{
  warningLevel: ObservationWarningLevel;
}>`
  background-color: ${({ warningLevel }) => warningColors[warningLevel]};
  color: ${colors.grey.grey10};
  padding: ${spacing.s4};
  border-radius: 0 0 8px 8px;
`;

export const BadgeSkeletonWrapper = styled.div`
  width: auto;
`;
