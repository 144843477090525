import styled from 'styled-components';
import { colors, spacing } from '@bt-healthcare/ui-toolkit';

export const InputRangeContainer = styled.div``;

export const InputRangeStep = styled.div`
  min-height: ${spacing.s1};
  min-width: ${spacing.s1};
  border-radius: ${spacing.s1};
  background-color: ${colors.primaryIndigo.indigo04};
`;

export const InputRangeStepContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;

  @-moz-document url-prefix() {
    margin-top: -18px;
  }
`;

export const InputRangeInput = styled.input<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>`
  &[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
  }

  &[type='range']:focus {
    outline: none;
  }

  &[type='range']::-webkit-slider-runnable-track {
    background-color: ${colors.primaryIndigo.indigo02};
    border-radius: ${spacing.s1};
    height: ${spacing.s1};
  }

  &[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -${spacing.s2};

    background-color: ${colors.primaryIndigo.indigo08};
    border-radius: ${spacing.s5};
    height: ${spacing.s5};
    width: ${spacing.s5};
  }

  &[type='range']::-moz-range-track {
    background-color: ${colors.primaryIndigo.indigo02};
    border-radius: ${spacing.s1};
    height: ${spacing.s1};
  }

  &[type='range']::-moz-range-thumb {
    border: none;
    border-radius: 0;
    background-color: ${colors.primaryIndigo.indigo08};
    border-radius: ${spacing.s5};
    height: ${spacing.s5};
    width: ${spacing.s5};
  }
`;
