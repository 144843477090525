import styled from 'styled-components';
import { device, Grid, spacing } from '@bt-healthcare/ui-toolkit';

export const HeaderContainer = styled.div`
  margin-bottom: ${spacing.s8};
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    margin-bottom: ${spacing.s10};
  }
`;

export const SubheaderWrapper = styled.div`
  margin-top: ${spacing.s4};
  @media ${device.tablet} {
    margin-top: ${spacing.s6};
  }
  @media ${device.desktop} {
    margin-top: ${spacing.s8};
  }
`;

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media ${device.tablet} {
    height: unset;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${spacing.s10};
  @media ${device.tablet} {
    margin-top: ${spacing.s14};
  }
`;

export const CardsContainer = styled(Grid)`
  gap: ${spacing.s4};
`;
