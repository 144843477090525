import {
  device,
  fontCalcMixin,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const BookingConfirmedContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: ${spacing.s6};
  flex: 1;
  display: flex;
`;

export const HeroContainer = styled.div`
  margin-top: ${spacing.s6};
  width: 153px;
  height: 180px;

  @media ${device.tablet} {
    width: 272px;
    height: 320px;
  }
`;

export const BodyText = styled.p`
  ${fontCalcMixin(fontSizes.base)};
  text-align: center;
`;

export const HeaderText = styled.p`
  ${fontCalcMixin(fontSizes.h3)};
`;

export const BookingSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacing.s8};

  @media ${device.largeTablet} {
    flex-direction: row;
  }

  @media ${device.largeMobile} {
    gap: 8%;
    padding-left: 8%;
    padding-right: 8%;
  }
`;

export const BookingConfirmedContent = styled.div`
  width: 100%;
`;
