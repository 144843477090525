import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

export const useLogout = () => {
  const client = useApolloClient();
  const { logout } = useAuth0();

  return () => {
    client.clearStore();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
};
