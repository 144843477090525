import * as yup from 'yup';

declare module 'yup' {
  interface NumberSchema {
    decimal(options: {
      min: number;
      max: number;
      name: string;
      decimalDigits?: number;
    }): NumberSchema;

    validateWith(options: {
      dependentFieldName: string;
      validationCallback: ({
        dependentFieldValue,
        fieldValue,
      }: {
        dependentFieldValue: number;
        fieldValue: number;
      }) => {
        status: 'ok' | 'error';
        errorMessage?: string;
      };
    }): NumberSchema;
  }
}

yup.addMethod(
  yup.number,
  'decimal',
  function decimal({ min, max, name, decimalDigits }) {
    let schema = this.label(name).transform((value) =>
      Number.isNaN(value) ? undefined : value
    );

    schema = schema
      .test(`isRequired`, 'Readings must be numeric', (value, ctx) => {
        if (ctx.schema.spec.presence === 'required' && value === undefined) {
          return ctx.createError({ message: 'Readings must be numeric' });
        }
        return true;
      })
      .min(min, ({ label }) => `${label} reading cannot be less than ${min}`)
      .max(max, ({ label }) => `${label} reading cannot be more than ${max}`);

    if (!decimalDigits) {
      schema = schema.integer(
        'Readings must be a whole number, please enter without decimal places'
      );
    }

    if (decimalDigits) {
      schema = schema.test(
        `${decimalDigits}-digit`,
        `Reading can have only ${decimalDigits} digit${
          decimalDigits > 1 ? 's' : ''
        } after the decimal point`,
        (value) => {
          if (value === undefined || value === null) {
            return true;
          }
          const decimalPart = value.toString().split('.')[1];
          return !decimalPart || decimalPart.length <= decimalDigits;
        }
      );
    }

    return schema;
  }
);

yup.addMethod(
  yup.number,
  'validateWith',
  function validateWith({ dependentFieldName, validationCallback }) {
    return this.test((value, ctx) => {
      const dependentFieldValue = ctx.parent[dependentFieldName];
      const validationReturn = validationCallback({
        dependentFieldValue,
        fieldValue: value,
      });
      if (validationReturn.status === 'error') {
        return ctx.createError({
          path: ctx.path,
          message: validationReturn.errorMessage,
        });
      }
      return true;
    });
  }
);

export * from 'yup';
