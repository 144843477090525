import { StatusChip } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';

export const NewStatusChip = () => (
  <StatusChip
    chipColor="secondaryBlue.blue01"
    textColor="secondaryBlue.blue08"
    id="appointment-buttons-list-header-chip"
  >
    <FormattedMessage id="appointment-buttons-list.chip" defaultMessage="New" />
  </StatusChip>
);
