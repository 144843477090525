import { LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';

import { type ObservationData, DataLineProps } from './types';
import { seriesChartConfigMap } from './config';
import { DataPoint } from './DataPoint';

export const DataLine = ({
  chartData,
  xScale,
  yScale,
  idx,
  parseDate,
  observationType,
  precedingDataPoint,
  tooltipOpen,
  tooltipData,
  handleOnPointOver,
  handlePointLeave,
}: DataLineProps) => {
  const data = chartData.slice();
  if (precedingDataPoint) {
    data.unshift(precedingDataPoint);
  }

  return (
    <>
      {chartData.length > 1 && (
        <LinePath
          data={data}
          x={(d: ObservationData) => xScale(parseDate(d.date))}
          y={(d: ObservationData) => yScale(d.series[idx].value)}
          strokeWidth={2}
          stroke={seriesChartConfigMap[idx].line}
          curve={curveMonotoneX}
          style={{ pointerEvents: 'none', touchAction: 'none' }}
        />
      )}
      <DataPoint
        chartData={chartData}
        xScale={xScale}
        yScale={yScale}
        idx={idx}
        parseDate={parseDate}
        observationType={observationType}
        tooltipOpen={tooltipOpen}
        tooltipData={tooltipData}
        handleOnPointOver={handleOnPointOver}
        handlePointLeave={handlePointLeave}
      />
    </>
  );
};
