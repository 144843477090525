import {
  Button,
  Col,
  Header,
  PageNotFound,
  Row,
  SpinnerLoader,
  Text,
  colors,
} from '@bt-healthcare/ui-toolkit';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { isEmpty } from 'ramda';
import { SubmitObservationCard } from '../../components/Cards/SubmitObservationCard';
import { SubmissionFailedModal } from '../../components/Modal/SubmissionFailedModal';
import { ROUTE } from '../../config/routes';
import {
  mapUrlPathToObservationType,
  rHFObservationToObservationInput,
  submitCardInfoData,
} from '../../formatters/observations';
import {
  ObservationType,
  useCreateWardPatientObservationMutation,
  useGetAssessObservationQuery,
} from '../../services/graphql';

import { ApolloErrorBoundary } from '../../components/ApolloErrorBoundary';
import { RatingBadge } from '../../components/RatingBadge';
import { useKnownObservation } from '../../hooks/useKnowObservations';
import { useTracking } from '../../hooks/useTracking';
import { urlSearchParamsToObservationFormInput } from '../CreateObservations/utils';
import { createObservationsConfirm } from './intl';
import {
  ButtonWrapper,
  CardsContainer,
  HeaderContainer,
  SubheaderWrapper,
  ValuesContainer,
} from './styles';
import { CreateObservationsConfirmComponentProps } from './types';

export const ASSESS_OBSERVATION_TYPES = [
  ObservationType.Pulse,
  ObservationType.BloodPressure,
  ObservationType.Spo2,
  ObservationType.FastingBloodGlucose,
];
export const CreateObservationsConfirmComponent = ({
  type,
  createdObservations,
}: CreateObservationsConfirmComponentProps) => {
  const observationInput = useMemo(
    () => rHFObservationToObservationInput(createdObservations, type),
    [createdObservations]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const intl = useIntl();
  const { trackPageWithAppName } = useTracking();
  const [addWardPatientObservationsMutation, { loading, error, data }] =
    useCreateWardPatientObservationMutation();
  const { data: assessObservationData, loading: assessLoading } =
    useGetAssessObservationQuery({
      skip: !ASSESS_OBSERVATION_TYPES.includes(type),
      variables: {
        input: observationInput,
      },
    });

  useEffect(() => {
    trackPageWithAppName(`${type} - Confirm`);
  }, []);

  useEffect(() => {
    if (data) {
      navigate(ROUTE.CREATE_OBSERVATION_SUCCESS);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setIsModalOpen(true);
    }
  }, [error]);

  const formatObservationInputInfo = submitCardInfoData(observationInput, intl);

  const onSubmit = async () => {
    await addWardPatientObservationsMutation({
      variables: { input: observationInput },
    });
  };

  const onTryAgain = async () => {
    await onSubmit();
    setIsModalOpen(false);
  };

  return (
    <>
      <HeaderContainer>
        <Header>
          <FormattedMessage {...createObservationsConfirm.header} />
        </Header>
        <SubheaderWrapper>
          <Text color={colors.grey.grey08}>
            <FormattedMessage {...createObservationsConfirm.subheader} />
          </Text>
        </SubheaderWrapper>
      </HeaderContainer>
      <ApolloErrorBoundary page>
        <ValuesContainer>
          <CardsContainer>
            {formatObservationInputInfo &&
              formatObservationInputInfo.map(
                ({ label, value, type: observationType }) => {
                  const assessObservation =
                    assessObservationData?.assessObservation.find(
                      (observation) => observation.type === observationType
                    ) || undefined;
                  const fhirRating =
                    (assessObservation &&
                      assessObservation?.rating?.fhirRating) ||
                    undefined;
                  const warningLevel =
                    assessObservation && assessObservation.warning?.level;
                  const Badge = fhirRating ? (
                    <RatingBadge type={type} patientRating={fhirRating} />
                  ) : null;

                  return (
                    <Row key={`${observationType}-${value}`}>
                      <Col colEnd={{ xxs: 'span 4', md: 'span 5' }}>
                        <SubmitObservationCard
                          observationValue={value}
                          observationTypeLabel={label}
                          observationType={observationType}
                          dataTestId={`${label}-${value}`}
                          warningLevel={warningLevel}
                          badge={Badge}
                          loading={assessLoading}
                        />
                      </Col>
                    </Row>
                  );
                }
              )}
          </CardsContainer>
          <Row>
            <Col colEnd={{ xxs: 'span 4', md: 'span 5' }}>
              <ButtonWrapper>
                <Button
                  id="submit-reading-btn"
                  variant="primary"
                  size="maximum"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  <FormattedMessage
                    {...createObservationsConfirm.submitButton}
                  />
                </Button>
              </ButtonWrapper>
            </Col>
          </Row>
        </ValuesContainer>
      </ApolloErrorBoundary>
      <SubmissionFailedModal
        isModalOpen={isModalOpen}
        onTryAgain={onTryAgain}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

export const CreateObservationsConfirm = () => {
  const { type: formattedType } = useParams<{ type: string }>();
  const type = mapUrlPathToObservationType(formattedType || '');
  const isKnownObservation = useKnownObservation();
  const [searchParams] = useSearchParams();

  const { searchParamsCreatedObservations } = useMemo(
    () => urlSearchParamsToObservationFormInput(searchParams),
    [searchParams]
  );

  if (isEmpty(searchParamsCreatedObservations)) {
    return (
      <SpinnerLoader
        id="create-observations-confirm"
        data-testid="create-observations-confirm"
      />
    );
  }

  if (!type || !isKnownObservation(type)) {
    return <PageNotFound />;
  }
  return (
    <CreateObservationsConfirmComponent
      type={type}
      createdObservations={searchParamsCreatedObservations}
    />
  );
};
