import { defineMessages } from 'react-intl';

export const queryErrorMessage = defineMessages({
  title: {
    defaultMessage: 'We’re unable to load data',
    id: 'query-error.title',
    description: 'Query Error default title',
  },
  description: {
    defaultMessage:
      'Please try again or contact us below if the error persists.',
    id: 'query-error.description',
    description: 'Query Error default Description',
  },
  tryAgainButton: {
    defaultMessage: 'Try again',
    id: 'query-error.try-again-button',
    description: 'Query Error default Try Again Button',
  },
  contactUsButton: {
    defaultMessage: 'Contact us',
    id: 'query-error.contact-us-button',
    description: 'Query Error default Contact Us Button',
  },
});
