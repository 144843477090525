import styled from 'styled-components';

import { colors, spacing } from '@bt-healthcare/ui-toolkit';

export const AppointmentsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const AppointmentsListItem = styled.li`
  margin-bottom: ${spacing.s4};
  background: ${colors.base.white};
  box-shadow: 0px 4px 10px 1px rgba(112, 144, 176, 0.2);
  border-radius: ${spacing.s2};
`;
