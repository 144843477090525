import {
  ObservationCard,
  ObservationCardLabelled,
} from '@bt-healthcare/ui-toolkit';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { createPath, Link, useSearchParams } from 'react-router-dom';
import { RatingBadge } from '../RatingBadge';
import {
  formatObservation,
  observationPath,
} from '../../formatters/observations';
import { ObservationView } from '../../types';
import { ROUTE } from '../../config/routes';
import { ObservationType } from '../../services/graphql';
import { RecommendationHistoricalListBPAverageCard } from '../Cards/RecommendationHistoricalListBPAverageCard';
import { shouldDisplayRatingBadge } from './utils';
import { AVERAGE_OBSERVATION_ID_SEARCH_PARAM } from '../../pages/RecommendationAverageResult/utils';

const CardView = (view: ObservationView) => {
  const intl = useIntl();

  const {
    res_observationValue: value,
    id,
    recordedDateTime,
    observationType,
  } = view;
  const date = new Date(recordedDateTime);
  const props = {
    id,
    'data-testid': id,
    date,
  };
  const observation = formatObservation(value, intl);

  if (observationType === ObservationType.AverageBloodPressure) {
    return (
      <RecommendationHistoricalListBPAverageCard date={date} value={value} />
    );
  }

  if (observation.length > 1) {
    return (
      <ObservationCardLabelled
        {...props}
        valueColor="grey.grey10"
        borderRadius={8}
        values={observation.map(
          ({ label, value: val }) => [label, val] as [string, string]
        )}
      />
    );
  }
  return (
    <ObservationCard
      {...props}
      value={observation[0].value}
      valueColor="grey.grey10"
      borderRadius={8}
    >
      {shouldDisplayRatingBadge(observationType) && 'rating' in value && (
        <RatingBadge type={observationType} patientRating={value.rating} />
      )}
    </ObservationCard>
  );
};

export const ObservationListItem = (view: ObservationView) => {
  const { id, observationType } = view;
  const [searchParams] = useSearchParams();
  if (observationType === ObservationType.AverageBloodPressure) {
    searchParams.set(AVERAGE_OBSERVATION_ID_SEARCH_PARAM, id);
  }
  const Card = <CardView {...view} />;
  const path =
    observationType === ObservationType.AverageBloodPressure
      ? createPath({
          pathname: ROUTE.RECOMMENDATION_PROGRAMME,
          search: searchParams.toString(),
        })
      : observationPath(observationType, ROUTE.OBSERVATION_RATINGS);
  const Item = shouldDisplayRatingBadge(observationType) ? (
    <Link to={path} state={{ observation: view }}>
      {Card}
    </Link>
  ) : (
    Card
  );
  return <Fragment key={id}>{Item}</Fragment>;
};
