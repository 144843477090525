import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const CreatObservationsBlurb = styled.div`
  display: flex;

  @media ${device.desktop} {
    width: 635px;
  }

  margin-top: ${spacing.s4};
  @media ${device.tablet} {
    margin-top: ${spacing.s6};
  }
  @media ${device.desktop} {
    margin-top: ${spacing.s8};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.s10};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media ${device.tablet} {
    height: unset;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${spacing.s10};
`;

export const ToggleWrapper = styled.div`
  margin-bottom: ${spacing.s6};
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  & label {
    margin-top: 0;
  }
`;

export const GuideLinkContainer = styled.div`
  display: flex;
  gap: ${spacing.s3};
  align-items: center;
  background-color: ${colors.secondaryBlue.blue01};
  color: ${colors.secondaryBlue.blue07};
  padding: ${spacing.s4};
  margin-top: ${spacing.s2};
  border-radius: 8px;
  font-size: ${fontSizes.sm};
  cursor: pointer;
`;
