import styled from 'styled-components';
import {
  Button,
  colors,
  device,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';

const ORDER = {
  HEADER: 1,
  DESCRIPTION: 2,
  BUTTONS_WRAPPER: 3,
};

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled(Text).attrs(({ color }) => ({
  fontSize: fontSizes.h3,
  color: color || colors.grey.grey10,
  fontWeight: 500,
}))`
  text-align: center;
  margin-bottom: ${spacing.s4};
  order: ${ORDER.HEADER};
`;

export const Description = styled(Text).attrs(({ color }) => ({
  color: color || colors.grey.grey08,
}))`
  text-align: center;
  order: ${ORDER.DESCRIPTION};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  margin-top: ${spacing.s6};
  order: ${ORDER.BUTTONS_WRAPPER};
  @media ${device.desktop} {
    flex-direction: row-reverse;
  }
`;

export const PrimaryButton = styled(Button).attrs({
  variant: 'primary',
})`
  order: 1;
`;

export const PrimaryRedButton = styled(Button).attrs({
  variant: 'primaryRed',
})`
  order: 1;
`;

export const SecondaryButton = styled(Button).attrs({
  variant: 'secondary',
})`
  order: 2;
`;
