import { BTHealthIcon, Button, Text, colors } from '@bt-healthcare/ui-toolkit';

import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneMockup from '../../assets/phone-mockup.png';
import CardiologyIcon from '../../assets/cardiology.png';
import ThumbUpIcon from '../../assets/thumb-up.png';
import BarChartIcon from '../../assets/bar-chart.png';
import QrCode from '../../assets/healthyyou-qr.png';
import AppleStore from '../../assets/apple-store-badge.svg';
import GoogleStore from '../../assets/google-play.png';
import HandPhoneMockup from '../../assets/hand-phone-graphic.png';

import { getAndroidOrIOS } from '../../utils';
import { APP_STORE_LINKS } from '../../App.constants';
import {
  AppStoresRow,
  CloseButton,
  CloseButtonContainer,
  ContentContainer,
  DesktopPhoneImage,
  DownloadNowLink,
  HeaderText,
  LinkImg,
  LinkText,
  LinksBodyContainer,
  LinksContainer,
  ListItem,
  PhoneMockupContainer,
  PhoneMockupImg,
  PopupBody,
  PopupContainer,
  Seperator,
} from './styles';
import { useTracking } from '../../hooks/useTracking';
import { healthyYouPopup } from './intl';

type HealthyYouPopupProps = {
  setOpenHYPopup: Dispatch<SetStateAction<boolean>>;
};

export const HealthyYouPopup = ({ setOpenHYPopup }: HealthyYouPopupProps) => {
  const { trackTapped } = useTracking();

  const getDownloadNowLink = () => {
    if (getAndroidOrIOS() === 'iOS') {
      return APP_STORE_LINKS.iOS;
    }

    return APP_STORE_LINKS.android;
  };

  const onDownloadNowClick = () => {
    trackTapped('Clicked on Download now CTA');
  };

  const onAppStoreClick = () => {
    trackTapped('Clicked on App Store CTA');
  };

  const onGoogleStoreClick = () => {
    trackTapped('Clicked on Google Play CTA');
  };

  const onCloseButtonClick = () => {
    setOpenHYPopup(false);
  };

  return (
    <PopupContainer data-testid="healthy-you-screen">
      <PopupBody>
        <CloseButtonContainer>
          <CloseButton id="close-popup-btn" onClick={onCloseButtonClick}>
            <BTHealthIcon icon="Close" color={colors.base.white} />
          </CloseButton>
        </CloseButtonContainer>
        <ContentContainer>
          <PhoneMockupContainer>
            <PhoneMockupImg src={PhoneMockup} alt="phone-mockup" />
          </PhoneMockupContainer>
          <HeaderText>
            <FormattedMessage {...healthyYouPopup.header} />
          </HeaderText>
          <ListItem>
            <LinkImg src={CardiologyIcon} alt="cardiology-icon" />
            <LinkText>
              <FormattedMessage {...healthyYouPopup.cardiology} />
            </LinkText>
          </ListItem>
          <ListItem>
            <LinkImg src={ThumbUpIcon} alt="thumb-up-icon" />
            <LinkText>
              <FormattedMessage {...healthyYouPopup.recommendation} />
            </LinkText>
          </ListItem>
          <ListItem>
            <LinkImg src={BarChartIcon} alt="bar-chart-icon" />
            <LinkText>
              <FormattedMessage {...healthyYouPopup.insights} />
            </LinkText>
          </ListItem>
          <Seperator />
          <DownloadNowLink
            onClick={onDownloadNowClick}
            href={getDownloadNowLink()}
            target="_blank"
            data-testid="download-now-btn"
          >
            <Button id="btn-download-now" variant="primary">
              <FormattedMessage {...healthyYouPopup.downloadNow} />
            </Button>
          </DownloadNowLink>
          <LinksContainer>
            <img
              data-testid="qr-code-download-app"
              src={QrCode}
              width={90}
              height={90}
              alt="qr-code"
            />
            <LinksBodyContainer>
              <Text color={colors.primaryIndigo.indigo02} fontSize="16px">
                <FormattedMessage {...healthyYouPopup.scanQr} />
              </Text>
              <AppStoresRow>
                <a
                  onClick={onAppStoreClick}
                  href={APP_STORE_LINKS.iOS}
                  target="_blank"
                  rel="noreferrer"
                  data-testid="app-store-link"
                >
                  <img
                    src={AppleStore}
                    width={124}
                    height={41.5}
                    alt="app-store"
                  />
                </a>
                <a
                  onClick={onGoogleStoreClick}
                  href={APP_STORE_LINKS.android}
                  target="_blank"
                  rel="noreferrer"
                  data-testid="google-store-link"
                >
                  <img
                    src={GoogleStore}
                    width={142}
                    height={41.5}
                    alt="google-store"
                  />
                </a>
              </AppStoresRow>
            </LinksBodyContainer>
          </LinksContainer>
        </ContentContainer>
        <DesktopPhoneImage src={HandPhoneMockup} />
      </PopupBody>
    </PopupContainer>
  );
};
