import { ScaleLinear } from 'd3-scale';
import { ObservationType } from '../../services/graphql';
import { DateFilter, VisxTooltipProps } from '../ObservationGraph/types';

export enum DataPointType {
  DAILY_AVERAGE = 'DAILY_AVERAGE',
  SINGLE_OBSERVATION = 'SINGLE_OBSERVATION',
}

export type TooltipProps = {
  observationType: ObservationType;
  dataPointType: DataPointType;
  offsetTop?: number;
} & Omit<VisxTooltipProps, 'hideTooltip' | 'containerRef'>;

export type TooltipTextProps = {
  primary?: boolean;
};

export type UseGraphTooltipTypes = {
  dateFilter: DateFilter;
  yScale: ScaleLinear<number, number>;
};
