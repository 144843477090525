import { TooltipContainer } from './styles';

export const SimpleTooltip = ({
  isVisible,
  id,
  children,
}: {
  isVisible: boolean;
  id: string;
  children: React.ReactNode;
}) => (
  <TooltipContainer
    role="tooltip"
    id={id}
    isVisible={isVisible}
    data-testid={id}
  >
    {children}
  </TooltipContainer>
);
