import styled from 'styled-components';

import { device, spacing } from '@bt-healthcare/ui-toolkit';

/*
 *  TODO:
 *
 *  All the Onboarding styled components will be removed
 *  as soon as the PublicContentCard component will be exported from the toolkit
 */

export const PublicContentCardFormWrapper = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PublicContentCardWrapper = styled.div<{
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 100%;

  @media ${device.tablet} {
    height: fit-content;
    background: white;
    padding: ${spacing.s6};
    margin: 0 auto;
    border-radius: ${spacing.s6};
    padding: ${(props) => props.padding || spacing.s8};
    box-shadow: 0px 4px 10px 1px rgba(112, 144, 176, 0.2);
  }
`;

export const PublicContentPageSection = styled.section`
  grid-area: 2 / 1 / 2 / 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: red;

  @media ${device.tablet} {
    grid-area: 2 / 1 / 2 / 8;
  }
  @media ${device.largeTablet} {
    grid-area: 2 / 5 / 2 / 9;
  }
`;

export const PublicContentCardHero = styled.div`
  width: 188px;
  height: 175px;

  @media ${device.tablet} {
    width: 153px;
    height: 180px;
  }
`;

export const PublicContentCardHeaderContentWrapper = styled.div`
  text-align: center;
  max-width: 343px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const PublicContentCardFormHeaderContentWrapper = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
`;
