import { formatISO } from 'date-fns';
import { DateType } from './types';

export const formatDateToLocaleDateString = (date: Date, locale?: string) =>
  date.toLocaleDateString(locale || 'en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

export const formatDateAndHourToString = (date: Date, locale?: string) =>
  date.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    hourCycle: 'h23',
  });

export const formatISO8061Date = ({ year, month, day }: DateType) =>
  formatISO(new Date(Number(year), Number(month) - 1, Number(day)), {
    representation: 'date',
  });

export const dayMonthYearFromISO8061Date = (dateISO8061: string): DateType => {
  const date = new Date(dateISO8061);
  return {
    year: date.getFullYear().toString(),
    month: (date.getMonth() + 1).toString(),
    day: date.getDate().toString(),
  };
};
