import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { SpinnerLoader } from '@bt-healthcare/ui-toolkit';
import { Outlet } from 'react-router-dom';

export const Login = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading) {
    return <SpinnerLoader id="login" data-testid="login" />;
  }

  return <Outlet />;
};
