import { defineMessages } from 'react-intl';

export const healthyYouPopup = defineMessages({
  header: {
    id: 'healthy-you-popup.header',
    defaultMessage:
      'Discover your healthspan,<br></br> download the HealthyYou app',
  },
  cardiology: {
    id: 'healthy-you-popup.cardiology',
    defaultMessage: 'Calculate your healthspan and shape your future years',
  },
  recommendation: {
    id: 'healthy-you-popup.recommendation',
    defaultMessage: 'Tell us about your lifestyle for personal recommendations',
  },
  insights: {
    id: 'healthy-you-popup.insights',
    defaultMessage:
      'Track your vitals to gain health insights in line with NHS guidance',
  },
  downloadNow: {
    id: 'healthy-you-popup.download-now',
    defaultMessage: 'Download now',
  },
  scanQr: {
    id: 'healthy-you-popup.scan-qr',
    defaultMessage: 'Scan the QR code with your phone to get started',
  },
});
