import { defineMessage, defineMessages } from 'react-intl';
import { GenderAssignedAtBirth } from '../../services/graphql';
import { HighUnitsInOneSetting } from './types';

export const healthScoreAlcoholModeration = {
  header: (genderAssignedAtBirth?: GenderAssignedAtBirth) =>
    defineMessage({
      id: 'health-score-alcohol-moderation.header',
      defaultMessage: `In the last month, have you had more <indigo08>than ${
        genderAssignedAtBirth === GenderAssignedAtBirth.Male ? 8 : 6
      } units</indigo08> in one sitting?`,
    }),
  highUnitsInOneSetting: {
    options: defineMessages({
      [HighUnitsInOneSetting.YES]: {
        id: 'health-score-alcohol-moderation.fields.highUnitsInOneSetting.option.yes',
        defaultMessage: 'Yes',
      },
      [HighUnitsInOneSetting.NO]: {
        id: 'health-score-alcohol-moderation.fields.highUnitsInOneSetting.option.no',
        defaultMessage: 'No',
      },
    }),
  },
};
