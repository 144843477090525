import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { ErrorMessage, Stack } from '@bt-healthcare/ui-toolkit';
import { useEffect } from 'react';
import BluePageWrapper from '../../components/BluePageWrapper';
import BaseCard from '../../components/Cards/BaseCard';
import { ObservationLoadingCard } from '../../components/Cards/ObservationLoadingCard';
import { RecommendationBlueScreenLinkCard } from '../../components/Cards/RecommendationBlueScreenLinkCard';
import { RatingBadge } from '../../components/RatingBadge';
import { RecommendationBulletList } from '../../components/RecommendationBulletList';
import { ROUTE } from '../../config/routes';
import { formatDateToLocaleDateString } from '../../formatters/date';
import { formatObservation } from '../../formatters/observations';
import { useTracking } from '../../hooks/useTracking';
import {
  ObservationType,
  useGetPatientProgrammeInitiativeQuery,
  useGetWardPatientProgrammeByAverageObservationIdQuery,
} from '../../services/graphql';
import { recommendationAverageResult } from './intl';
import {
  BrightIcon,
  BulletListHeader,
  CardDescription,
  CardValue,
  CardWrapper,
  LeftItem,
  LinkCardWrapper,
  RecommendationListDate,
  RecommendationListTitle,
} from './styles';
import {
  ProgrammeType,
  RecommendationAverageResultComponentProps,
} from './types';
import {
  AVERAGE_OBSERVATION_ID_SEARCH_PARAM,
  createBulletsList,
} from './utils';
import { QueryErrorMessageVariantsEnum } from '../../components/QueryErrorMessage/types';
import { ApolloErrorBoundary } from '../../components/ApolloErrorBoundary';

const RecommendationAverageResultComponent = ({
  programme,
  loading = true,
  error,
}: RecommendationAverageResultComponentProps) => {
  const navigate = useNavigate();
  const observationValue =
    programme?.attributes.averageObservation?.res_observationValue;
  const programmeId = programme?.id;
  const { trackPage } = useTracking();
  useEffect(() => {
    trackPage('Your ABP insights');
  }, []);

  const intl = useIntl();

  if (!loading && !error && observationValue?.__typename !== 'BloodPressure') {
    console.warn('Unrecognised average observation');
    return <ErrorMessage />;
  }

  const onClose = () => {
    navigate(-1);
  };

  return (
    <BluePageWrapper>
      <BluePageWrapper.Content>
        <BluePageWrapper.Close onClose={onClose} />
        <BluePageWrapper.Header>
          <FormattedMessage {...recommendationAverageResult.header} />
        </BluePageWrapper.Header>
        <ApolloErrorBoundary
          page={{
            title: intl.formatMessage(recommendationAverageResult.errorTitle),
            variant: QueryErrorMessageVariantsEnum.BLUE_SCREEN,
          }}
        >
          {loading && !programme && (
            <CardWrapper>
              <Stack id="group-cells" space="s2">
                <ObservationLoadingCard />
                <ObservationLoadingCard />
              </Stack>
            </CardWrapper>
          )}
          {programme &&
            programmeId &&
            observationValue?.__typename === 'BloodPressure' && (
              <>
                <CardWrapper>
                  <BaseCard id="recommendation-average-result.card">
                    <BaseCard.ItemLeft flexDirection="column" gap={8}>
                      <CardValue>
                        {formatObservation(observationValue, intl)[0].value}
                      </CardValue>
                      <CardDescription>
                        <FormattedMessage
                          {...recommendationAverageResult.card}
                        />
                      </CardDescription>
                    </BaseCard.ItemLeft>
                    <BaseCard.ItemRight>
                      <RatingBadge
                        patientRating={observationValue?.rating}
                        type={ObservationType.BloodPressure}
                      />
                    </BaseCard.ItemRight>
                  </BaseCard>
                </CardWrapper>
                <LinkCardWrapper>
                  <RecommendationBlueScreenLinkCard
                    path={generatePath(
                      ROUTE.RECOMMENDATION_PROGRAMME_OBSERVATIONS,
                      {
                        programmeId,
                      }
                    )}
                    title={
                      <FormattedMessage
                        {...recommendationAverageResult.linkHistoryReadingsCard}
                        values={{
                          daysCount: programme?.attributes.durationInDays,
                          observationsCount:
                            programme?.attributes.observationsCount,
                        }}
                      />
                    }
                  />
                </LinkCardWrapper>
                <BulletListHeader>
                  <LeftItem>
                    <BrightIcon />
                    <RecommendationListTitle>
                      <FormattedMessage
                        {...recommendationAverageResult.recommendationsHeader}
                      />
                    </RecommendationListTitle>
                  </LeftItem>
                  <RecommendationListDate>
                    {formatDateToLocaleDateString(
                      new Date(programme?.attributes.endDateTime!)
                    )}
                  </RecommendationListDate>
                </BulletListHeader>
                <RecommendationBulletList
                  items={createBulletsList(observationValue.fhirRating, intl)}
                />
              </>
            )}
        </ApolloErrorBoundary>
      </BluePageWrapper.Content>
    </BluePageWrapper>
  );
};

export const RecommendationAverageResult = () => {
  const [searchParams] = useSearchParams();
  const averageObservationId = searchParams.get(
    AVERAGE_OBSERVATION_ID_SEARCH_PARAM
  );
  const {
    data: programmeById,
    loading,
    error,
  } = useGetWardPatientProgrammeByAverageObservationIdQuery({
    variables: { averageObservationId: averageObservationId! },
    skip: !averageObservationId,
  });
  const {
    data: mostRecentData,
    loading: mostRecentLoading,
    error: mostRecentError,
  } = useGetPatientProgrammeInitiativeQuery({
    skip: !!averageObservationId,
  });

  let data: ProgrammeType =
    programmeById?.wardPatientProgrammeForAverageObservationId;
  if (
    !data &&
    mostRecentData?.userPatientProfileResults?.__typename ===
      'UserPatientProfile'
  ) {
    data =
      mostRecentData.userPatientProfileResults.programmeInitiative.mostRecent;
  }

  return (
    <RecommendationAverageResultComponent
      programme={data}
      error={error || mostRecentError}
      loading={loading || mostRecentLoading}
    />
  );
};
