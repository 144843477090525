import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors, spacing, Text, SubHeader } from '@bt-healthcare/ui-toolkit';

export const H3 = ({ children }: PropsWithChildren<{}>) => (
  <SubHeader color="indigo08">{children}</SubHeader>
);

export const H4 = ({ children }: PropsWithChildren<{}>) => (
  <Text color={colors.grey.grey10} as="h4" fontWeight={500}>
    {children}
  </Text>
);

export const Table = styled.table`
  border: 1px solid ${colors.grey.grey05};
  border-collapse: collapse;
  margin: ${spacing.s12} 0px;
`;

export const Td = styled.td`
  padding: ${spacing.s5} ${spacing.s8};
  border: 1px solid ${colors.grey.grey05};
  border-collapse: collapse;
`;

export const Th = styled.th`
  padding: ${spacing.s5} ${spacing.s8};
  border: 1px solid ${colors.grey.grey05};
  border-collapse: collapse;
`;

export const Anchor = styled.a`
  color: ${colors.primaryIndigo.indigo08};
`;

export const Ol = styled.ol`
  > * {
    list-style-type: none;
    &:first-child {
      counter-reset: termscounter;
    }
  }
  & li:before {
    counter-increment: termscounter;
    content: counters(termscounter, '.') '. ';
  }
`;

export const Li = styled.li`
  > p:first-child {
    display: inline-block;
  }
`;

export const HeroContainer = styled.div`
  max-height: 40vh;
  display: flex;
`;

export const CookiePreferencesLink = styled.a`
  color: ${colors.primaryIndigo.indigo08};
`;
