import styled from 'styled-components';

import {
  Button,
  device,
  fontCalcMixin,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

export const PopupContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 16px;
  background: linear-gradient(
      90deg,
      rgba(41, 121, 255, 0.12) 15.34%,
      rgba(50, 0, 138, 0.1) 82.23%
    ),
    #02062d;

  @media ${device.desktop} {
    padding: 5% 12%;
  }
`;

export const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: right;
`;

export const CloseButton = styled(Button).attrs({
  variant: 'tertiary',
})`
  width: 24px;
  height: 24px;
  margin-left: auto;
  padding: 0;

  @media ${device.tablet} {
    width: 32px;
    height: 32px;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
  max-width: 504px;
  margin: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.desktop} {
    width: 536px;
    max-width: 536px;
    margin-left: 12%;
  }
`;

export const DownloadNowLink = styled.a`
  @media ${device.tablet} {
    display: none;
  }
`;

export const Seperator = styled.div`
  margin-top: 32px;
`;

export const LinksContainer = styled.div`
  display: none;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  padding: 16px;

  @media ${device.tablet} {
    display: flex;
  }
`;

export const LinksBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LinkText = styled.p`
  ${fontCalcMixin(fontSizes.base)};
  color: white;
`;

export const LinkImg = styled.img`
  width: 20px;
  height: 20px;
`;

export const AppStoresRow = styled.div`
  display: flex;
  flex-direciton: row;
  gap: 8px;
`;

export const DesktopPhoneImage = styled.img`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  height: 100%;

  @media ${device.desktop} {
    display: block;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
`;

export const HeaderText = styled.p`
  ${fontCalcMixin(fontSizes.h3)};
  text-align: center;
  margin-bottom: 32px;
  color: white;

  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.h2)};
  }

  @media ${device.desktop} {
    text-align: left;
    ${fontCalcMixin(fontSizes.h2)};
  }
`;

export const PhoneMockupContainer = styled.div`
  margin: 22px 20%;
`;

export const PhoneMockupImg = styled.img`
  width: 100%;

  @media ${device.desktop} {
    display: none;
  }
`;
