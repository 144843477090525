import styled from 'styled-components';
import {
  colors,
  device,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled(Text).attrs({
  color: colors.notifications.light.errorDark,
  fontSize: fontSizes.h3,
  fontWeight: 500,
})``;

export const Description = styled(Text).attrs({
  color: colors.grey.grey08,
})`
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.s4};
  width: 100%;

  @media ${device.desktop} {
    flex-direction: row-reverse;
  }
`;
