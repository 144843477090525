import { useEffect, useState } from 'react';
import { ContactExclusionPreferences } from '../services/graphql';

export const useContactExclusionPreferencesFormStateHook = (
  contactExclusionPreferences: ContactExclusionPreferences[]
) => {
  const [sms, setSms] = useState<boolean>(true);
  const [reminders, setReminders] = useState<boolean>(true);
  const [recommendations, setRecommendations] = useState<boolean>(true);
  const [updates, setUpdates] = useState<boolean>(true);

  useEffect(() => {
    contactExclusionPreferences?.forEach(
      (exclusionPreference: ContactExclusionPreferences) => {
        switch (exclusionPreference) {
          case ContactExclusionPreferences.ContactexclEmailAdvise:
            setRecommendations(false);
            break;
          case ContactExclusionPreferences.ContactexclEmailLatestNewsUpdates:
            setUpdates(false);
            break;
          case ContactExclusionPreferences.ContactexclEmailReminder:
            setReminders(false);
            break;
          case ContactExclusionPreferences.ContactexclSmsApptReminderGp:
            setSms(false);
            break;
          case ContactExclusionPreferences.ContactexclEmailAll:
            setReminders(false);
            setUpdates(false);
            setRecommendations(false);
            break;
          case ContactExclusionPreferences.ContactexclAll:
            setReminders(false);
            setUpdates(false);
            setRecommendations(false);
            setSms(false);
            break;
          default:
            break;
        }
      }
    );
  }, [contactExclusionPreferences]);

  return {
    sms,
    setSms,
    reminders,
    setReminders,
    recommendations,
    setRecommendations,
    updates,
    setUpdates,
  };
};
