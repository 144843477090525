import styled from 'styled-components';

import {
  Button,
  colors,
  device,
  fontSizes,
  Spacer,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';

export const ImgContainer = styled.div`
  height: 73px;
  width: 73px;
  @media ${device.tablet} {
    height: 113px;
    width: 113px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.s4};
`;

export const AboutSubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionSpacer = styled(Spacer).attrs({
  size: 's6',
  md: 's8',
})``;

export const VersionLabel = styled(Text).attrs({
  color: colors.grey.grey08,
  fontSize: fontSizes.xs,
})``;

export const LogOutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.tablet} {
    align-items: flex-start;
  }
  @media ${device.desktop} {
    display: none;
  }
`;

export const LogOutButton = styled(Button).attrs({
  variant: 'primaryWhite',
})`
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin: 0;
  background-color: transparent;
`;
