import { defineMessages } from 'react-intl';

export const notificationEmailPreferences = defineMessages({
  header: {
    id: 'notification-email-preferences.title',
    defaultMessage: 'Email preferences',
  },
  subtitle: {
    id: 'notification-email-preferences.subtitle',
    defaultMessage:
      'Select the emails you want to receive or choose to unsubscribe from them all.',
  },
  descriptionTitle: {
    id: 'notification-email-preferences.description-title',
    defaultMessage: 'Unsubscribe',
  },
  description: {
    id: 'notification-email-preferences.description',
    defaultMessage:
      'We will still notify you of anything essential to your HealthyYou account.',
  },
  unsubscribeButton: {
    id: 'notification-email-preferences.unsubscribe-button',
    defaultMessage: 'Unsubscribe',
  },
  checkboxSMSTitle: {
    id: 'notification-email-preferences.form.checkbox.SMS-title',
    defaultMessage: 'SMS',
  },
  checkboxSMSDescription: {
    id: 'notification-email-preferences.form.checkbox.SMS-description',
    defaultMessage: 'This includes all text message notifications.',
  },
  checkboxRemindersTitle: {
    id: 'notification-email-preferences.form.checkbox.reminders-title',
    defaultMessage: 'Reminders',
  },
  checkboxRemindersDescription: {
    id: 'notification-email-preferences.form.checkbox.reminders-description',
    defaultMessage:
      'This includes personalised reminders for appointments and to take readings.',
  },
  checkboxRecommendationsTitle: {
    id: 'notification-email-preferences.form.checkbox.recommendations-title',
    defaultMessage: 'Advice and recommendations',
  },
  checkboxRecommendationsDescription: {
    id: 'notification-email-preferences.form.checkbox.recommendations-description',
    defaultMessage:
      'Personalised information relating to your conditions and/or health.',
  },
  checkboxUpdatesTitle: {
    id: 'notification-email-preferences.form.checkbox.updates-title',
    defaultMessage: 'Latest news and updates',
  },
  checkboxUpdatesDescription: {
    id: 'notification-email-preferences.form.checkbox.updates-description',
    defaultMessage: 'Updates relating to HealthyYou.',
  },
  notificationPositive: {
    id: 'notification-email-preferences.form.notification.positive',
    defaultMessage: 'Your preferences have been updated.',
  },
  notificationWarning: {
    id: 'notification-email-preferences.form.notification.warning',
    defaultMessage:
      'There was a problem updating your preferences. Please check your internet connection or try again later.',
  },
  notificationSoftWarning: {
    id: 'notification-email-preferences.form.notification.soft-warning',
    defaultMessage:
      'We will still notify you of anything essential to your HealthyYou account.',
  },
  submitButton: {
    id: 'notification-email-preferences.form.submit-button',
    defaultMessage: 'Save preferences',
  },
});
