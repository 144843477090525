import { Skeleton } from '@bt-healthcare/ui-toolkit';
import { useIntl } from 'react-intl';
import {
  StyledCard,
  MainContainer,
  ObservationTypeLabel,
  ObservationValueLabel,
  Container,
  WarningContainer,
  BadgeSkeletonWrapper,
} from './styles';
import { SubmitObservationTypeProps } from './types';
import { isKeyOf } from '../../../utils';
import { warnings } from './intl';

export const SubmitObservationCard = ({
  observationValue,
  observationTypeLabel,
  badge: Badge,
  dataTestId,
  warningLevel,
  observationType,
  loading,
}: SubmitObservationTypeProps) => {
  const intl = useIntl();
  const warningObject =
    isKeyOf(warnings, observationType) && warnings[observationType];
  const warningMessage =
    warningObject &&
    warningLevel &&
    isKeyOf(warningObject, warningLevel) &&
    warningObject[warningLevel];

  return (
    <StyledCard data-testid={dataTestId}>
      <MainContainer>
        <Container>
          {loading ? (
            <Skeleton
              id={`submit-observation-card-skeleton-value-${dataTestId}`}
              data-testid={`submit-observation-card-skeleton-value-${dataTestId}`}
              width={150}
              height={13}
              variant="text"
            />
          ) : (
            <ObservationValueLabel>{observationValue}</ObservationValueLabel>
          )}
          {loading ? (
            <Skeleton
              id={`submit-observation-card-skeleton-observation-type-${dataTestId}`}
              data-testid={`submit-observation-card-skeleton-observation-type-${dataTestId}`}
              width={50}
              height={13}
              variant="text"
            />
          ) : (
            <ObservationTypeLabel>{observationTypeLabel}</ObservationTypeLabel>
          )}
        </Container>
        {loading ? (
          <BadgeSkeletonWrapper>
            <Skeleton
              id={`submit-observation-card-skeleton-badge-${dataTestId}`}
              data-testid={`submit-observation-card-skeleton-badge-${dataTestId}`}
              height={32}
              width={32}
              variant="circular"
              rounded
            />
          </BadgeSkeletonWrapper>
        ) : (
          Badge
        )}
      </MainContainer>
      {warningLevel && warningMessage && (
        <WarningContainer warningLevel={warningLevel}>
          {intl.formatMessage(warningMessage)}
        </WarningContainer>
      )}
    </StyledCard>
  );
};
