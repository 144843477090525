import { GridSection } from '../../Layouts/GridLayout/styles';

import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import { WhatToDoNextCarousel } from '../../components/WhatToDoNextCarousel';
import { BenefitsCards } from '../../components/BenefitsCards';
import { CollateralLandingHeader } from '../../components/CollateralLandingHeader';
import { RisksCards } from '../../components/RisksCards';
import { GradientBackground } from './styles';
import { FrequentlyAskedQuestions } from '../../components/FrequentlyAskedQuestions';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';

export const CollateralLanding = () => (
  <>
    <Navbar isWithLogin />
    <GridSection row={1} column={4} rowSpan={3} columnSpan={10}>
      <GradientBackground />
    </GridSection>
    <GridSection row={1} column={4} columnSpan={10}>
      <CollateralLandingHeader />
    </GridSection>
    <GridSection row={2} column={1} columnSpan={14}>
      <WhatToDoNextCarousel />
    </GridSection>
    <GridSection row={3} column={1} columnSpan={14}>
      <BenefitsCards />
    </GridSection>
    <GridSection row={4} column={1} columnSpan={14}>
      <RisksCards />
    </GridSection>
    <GridSection row={5} column={1} columnSpan={14}>
      <FrequentlyAskedQuestions />
    </GridSection>
    <GridSection row={6} column={1} columnSpan={14}>
      <Footer />
    </GridSection>
  </>
);
