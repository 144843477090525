import { useState } from 'react';
import { Row, Spacer, Toggle } from '@bt-healthcare/ui-toolkit';
import { HealthInformationForm } from './HealthInformationForm';
import { useInputFieldsForUnitToggle } from '../../hooks/useInputFieldsForUnitToggle';
import { unitsToggleDisplayName } from '../../pages/HealthInformation/intl';
import {
  DataFieldWithUnits,
  HealthInformationFormWithToggleProps,
} from './types';
import { notEmpty } from '../../utils/array.utils';

export const HealthInformationFormWithToggle = ({
  fields,
  unitSystems,
  type,
  initialValues,
  initialUnit,
}: HealthInformationFormWithToggleProps) => {
  const [initialActiveUnit] = useState<string>(initialUnit || unitSystems[0]);

  const { activeFields, activeUnit, setActiveUnit, items } =
    useInputFieldsForUnitToggle<DataFieldWithUnits>({
      unitSystems,
      fields,
      unitsIntl: unitsToggleDisplayName,
      initialActiveUnit,
    });

  const activeFieldNames = activeFields
    .map(({ name }) => name as string)
    .filter(notEmpty);
  const defaultValues = Object.fromEntries(
    Object.entries(initialValues || {}).map(([name, value]) =>
      activeFieldNames.includes(name) ? [name, value] : [name, null]
    )
  );

  return (
    <>
      {items && activeUnit && (
        <Row>
          <Toggle
            activeItem={activeUnit}
            items={items}
            setActive={setActiveUnit}
          />
        </Row>
      )}
      <Spacer size="s6" />
      <HealthInformationForm
        fields={activeFields}
        type={type}
        initialValues={defaultValues}
      />
    </>
  );
};
