import { Stack, colors, Spacer, Text } from '@bt-healthcare/ui-toolkit';

import { LabelTypes } from './types';

export const CustomLabel = ({ header, text }: LabelTypes) => (
  <Stack id="">
    <Text color={colors.grey.grey08} fontWeight="bold">
      {header}
    </Text>
    <Spacer size="s2" />
    <Text color={colors.grey.grey08}>{text}</Text>
  </Stack>
);
