import styled from 'styled-components';
import {
  colors,
  fontSizes,
  Link,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';

import { LightColourThemeType, StyledTexProps } from './types';

export const StyledGrid = styled.div<{
  componentsNumber: number;
  rowAmount: number;
}>`
  display: grid;
  row-gap: ${spacing.s1};
  grid-template-rows: repeat(${({ rowAmount }) => `${rowAmount}`}, 1);
`;

export const HeaderRow = styled.div<{ componentsAmount: number }>`
  display: grid;
  padding: ${spacing.s4};
  grid-template-columns: ${({ componentsAmount }) =>
      componentsAmount === 1 ? '3' : '2'}fr repeat(
      ${({ componentsAmount }) => `${componentsAmount}`},
      1fr
    );
`;

export const StyledRow = styled(HeaderRow)<{
  levelColors: LightColourThemeType;
}>`
  background-color: ${({ levelColors }) => `${levelColors.backgroundColour}`};
  border-radius: 8px;
`;

export const StyledColumn = styled.div<{ gridColumn: number }>`
  grid-column: ${({ gridColumn }) => `${gridColumn}`};
  min-height: auto;
`;

export const RangeWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const RangeIndicator = styled.span`
  font-weight: 400;
`;

export const StyledText = styled(Text).attrs(
  ({ levelColors }: Pick<StyledTexProps, 'levelColors'>) => {
    const color = levelColors?.foregroundColour || colors.grey.grey10;
    return {
      color,
      fontWeight: 500,
      fontSize: `${fontSizes.sm}`,
    };
  }
)<StyledTexProps>``;

export const StyledLink = styled(Link)`
  font-weight: 400;
`;
