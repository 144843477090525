import { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as StepUpSuccessImg } from '../../assets/step-up-success.svg';
import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { useTracking } from '../../hooks/useTracking';

import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';
import { GridLayout } from '../../Layouts/GridLayout';

export const StepUpSuccess = () => {
  const { trackPage, trackTapped } = useTracking();
  const navigate = useNavigate();
  const pageName = 'Step Up - Accept';

  useEffect(() => {
    trackPage(pageName);
  }, []);

  const onBackHome = () => {
    trackTapped('Back home', pageName);
    navigate('/');
  };

  return (
    <GridLayout>
      <Navbar />
      <GridPageCenteredSection>
        <PublicContentCard
          Hero={
            <StepUpSuccessImg width="150%" style={{ marginLeft: '-25%' }} />
          }
          HeaderText={
            <FormattedMessage
              id="step-up-accept.header-text"
              defaultMessage="Welcome to the program!"
            />
          }
          TextDescription={[
            <FormattedMessage
              key="step-up-accept.text-description"
              id="step-up-accept.text-description"
              defaultMessage="We’ll be in touch via email with more details about the service and what you need to do next. Keep an eye on your inbox!"
            />,
          ]}
          CtaText={
            <FormattedMessage
              id="step-up-accept.back-home"
              defaultMessage="Back home"
            />
          }
          ctaAction={onBackHome}
        />
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </GridLayout>
  );
};
