import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { ReactComponent as Email } from '../../assets/email.svg';
import { useTracking } from '../../hooks/useTracking';

import { notificationEmailUnsubscribedSuccess } from './intl';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';

export const EmailUnsubscribed = () => {
  const { trackPageWithAppName, trackEvent } = useTracking();
  const navigate = useNavigate();

  useEffect(() => {
    trackPageWithAppName('Notification - email-preferences - success');
  }, []);

  return (
    <>
      <Navbar />
      <GridPageCenteredSection>
        <PublicContentCard
          Hero={<Email width="100%" height="100%" />}
          HeaderText={
            <FormattedMessage
              {...notificationEmailUnsubscribedSuccess.header}
            />
          }
          ctaVariant="primary"
          CtaText={
            <FormattedMessage {...notificationEmailUnsubscribedSuccess.cta} />
          }
          loadingState={false}
          ctaAction={() => {
            trackEvent('Back home', 'click');
            navigate('/');
          }}
        />
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </>
  );
};
