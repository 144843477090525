import {
  Button,
  Stack,
  Text,
  colors,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from '../../assets/gradient-logo.svg';
import { ROUTE } from '../../config/routes';
import { getEnvVariable } from '../../env.utils';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { useConditionalMetaTag } from '../../hooks/useConditionalMetaTag';
import { CloseIcon, CloseWrapper, Container } from './styles';
import { messages } from './intl';

const isAndroid = () => /android/i.test(navigator.userAgent);

const isLocationValidForSmartBanner = (path: string) => {
  switch (path) {
    case ROUTE.PRIVACY:
    case ROUTE.TERMS_AND_CONDITIONS:
    case ROUTE.COOKIES:
    case ROUTE.ONBOARDING_CHECK_EMAIL:
      return false;
    default:
      return true;
  }
};

export const SmartAppBanner = () => {
  const location = useLocation();
  const { userProfile } = useAuthUserProfile();
  const [closedBanner, setClosedBanner] = useState(false);
  const validLocation = isLocationValidForSmartBanner(location.pathname);

  const shouldShowIOS = validLocation && !!userProfile;
  const shouldShowAndroid =
    validLocation && !!userProfile && !closedBanner && isAndroid();

  useConditionalMetaTag({
    isEnabled: shouldShowIOS,
    metaTag: {
      name: 'apple-itunes-app',
      content: 'app-id=1644078401',
    },
  });

  return shouldShowAndroid ? (
    <Container id="smartapp-banner" data-testid="smartapp-banner">
      <CloseWrapper
        onClick={() => {
          setClosedBanner(true);
        }}
        aria-label="close smartbanner"
      >
        <CloseIcon />
      </CloseWrapper>
      <Logo width={54} />
      <Stack
        id="stretch"
        justifyContent="space-between"
        flexDirection="row"
        flex={1}
        alignItems="center"
      >
        <Text color={colors.base.dark} fontWeight={500}>
          HealthyYou
        </Text>
        <Button
          id="smart-banner-btn"
          size="auto"
          onClick={() =>
            window.open(
              getEnvVariable('VITE_SMART_APP_BANNER_ANDROID_LINK'),
              '_blank'
            )
          }
          style={{ width: 'initial', padding: `0 ${spacing.s3}` }}
          height="sm"
        >
          <FormattedMessage {...messages.openAppButton} />
        </Button>
      </Stack>
    </Container>
  ) : null;
};
