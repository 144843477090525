import {
  InputRangeInput,
  InputRangeContainer,
  InputRangeStep,
  InputRangeStepContainer,
} from './styles';
import { InputRangePropTypes } from './types';

export const InputRange = ({ ...props }: InputRangePropTypes) => {
  const { steps } = props;
  return (
    <InputRangeContainer>
      <InputRangeInput type="range" min={0} max={steps} step={1} {...props} />

      <InputRangeStepContainer>
        {Array.from({ length: steps + 1 }).map(() => (
          <InputRangeStep />
        ))}
      </InputRangeStepContainer>
    </InputRangeContainer>
  );
};
