import { FormattedMessage } from 'react-intl';
import { Accordion } from '../Accordion';
import {
  GetLastObservationAndBandsQuery,
  ObservationType,
} from '../../services/graphql';
import { isKeyOf } from '../../utils';
import { intl } from './intl';
import { FaqWrapper, StyledLink } from './styles';

export const Faq = ({
  type,
  info,
}: {
  type: ObservationType;
  info: GetLastObservationAndBandsQuery['observationBands']['info'] | undefined;
}) => {
  if (!isKeyOf(intl, type)) {
    return null;
  }
  const messages = intl[type];
  const infoKeys = info && Object.keys(info);
  return (
    <FaqWrapper>
      {messages.map((message, index) => {
        const { header, content } = message;
        const link =
          info && Object.keys(message).find((key) => infoKeys?.includes(key));
        const values =
          link && isKeyOf(info, link)
            ? {
                link: (
                  <StyledLink href={info[link]}>
                    <FormattedMessage {...message[link]} />
                  </StyledLink>
                ),
              }
            : null;
        return (
          <Accordion
            headerText={<FormattedMessage {...header} />}
            text={<FormattedMessage {...content} {...(values && { values })} />}
            testId={`faq-${type}-${index}`}
            key={`faq-accordion-${header.id}`}
          />
        );
      })}
    </FaqWrapper>
  );
};
