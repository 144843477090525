import { addDays, startOfDay } from 'date-fns';
import { useMemo, useState } from 'react';
import { DateFilter } from './types';

export const days = (dateFilter: DateFilter) => {
  switch (dateFilter) {
    case DateFilter.TODAY:
      return 1;
    case DateFilter.MONTHLY:
      return 30;
    case DateFilter.WEEK:
    default:
      return 7;
  }
};

export const useDateRange = () => {
  const [dateFilter, setDateFilter] = useState<DateFilter>(DateFilter.WEEK);
  const endDate = startOfDay(addDays(new Date(), 1));
  const startDate = useMemo(
    () => startOfDay(addDays(endDate, -days(dateFilter))),
    [dateFilter, endDate]
  );

  return { endDate, startDate, setDateFilter, dateFilter };
};
