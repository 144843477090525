import { defineMessages } from 'react-intl';

export const createObservationsConfirm = defineMessages({
  header: {
    description: 'Create Observation Confirm page Header',
    id: 'create-observations-confirm.header',
    defaultMessage: 'Submit your reading',
  },
  subheader: {
    id: 'create-observations-confirm.subheader',
    description: 'Create Observation Confirm page subheader',
    defaultMessage: 'Please review your reading below:',
  },
  submitButton: {
    id: 'create-observations-confirm.submit-btn',
    description: 'Create Observation Confirm page submit button',
    defaultMessage: 'Submit reading',
  },
});
