import { defineMessages } from 'react-intl';
import { DateFilter } from '../ObservationGraph/types';

export const insightsBar = defineMessages({
  max: {
    id: 'graph.insights-bar.max-description',
    defaultMessage: 'Max',
  },
  min: {
    id: 'graph.insights-bar.min-description',
    defaultMessage: 'Min',
  },
  [DateFilter.TODAY]: {
    id: 'graph.insights-bar.day-average',
    defaultMessage: 'Day average',
  },
  [`${DateFilter.TODAY}-mobile`]: {
    id: 'graph.insights-bar.day-average.mobile',
    defaultMessage: 'Day avg.',
  },
  [DateFilter.WEEK]: {
    id: 'graph.insights-bar.week-average',
    defaultMessage: 'Week average',
  },
  [`${DateFilter.WEEK}-mobile`]: {
    id: 'graph.insights-bar.week-average.mobile',
    defaultMessage: 'Week avg.',
  },
  [DateFilter.MONTHLY]: {
    id: 'graph.insights-bar.month-average',
    defaultMessage: 'Month average',
  },
  [`${DateFilter.MONTHLY}-mobile`]: {
    id: 'graph.insights-bar.month-average.mobile',
    defaultMessage: 'Month avg.',
  },
});
