import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Img } from '../../assets/blue-screen-hero.svg';
import BluePageWrapper from '../../components/BluePageWrapper';
import { ROUTE } from '../../config/routes';
import { useTracking } from '../../hooks/useTracking';
import { recommendationSuccess } from './intl';
import { Description, ImgContainer, Title, TitleWrapper } from './styles';

export const RecommendationSuccess = () => {
  const navigate = useNavigate();
  const { trackPage } = useTracking();
  useEffect(() => {
    trackPage('ABP Today 1st day');
  }, []);
  return (
    <BluePageWrapper>
      <BluePageWrapper.Content>
        <BluePageWrapper.Close />
        <ImgContainer>
          <Img width="100%" height="100%" />
        </ImgContainer>
        <TitleWrapper>
          <Title>
            <FormattedMessage {...recommendationSuccess.title} />
          </Title>
        </TitleWrapper>
        <Description>
          <FormattedMessage {...recommendationSuccess.description} />
        </Description>
      </BluePageWrapper.Content>
      <BluePageWrapper.Bottom>
        <BluePageWrapper.Button onClick={() => navigate(ROUTE.HOME)}>
          <FormattedMessage {...recommendationSuccess.button} />
        </BluePageWrapper.Button>
      </BluePageWrapper.Bottom>
    </BluePageWrapper>
  );
};
