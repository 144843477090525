import { Stack, Text, colors, fontSizes } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';

import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
import HealthScoreGraphic from '../../assets/health-score.png';

import { useTracking } from '../../hooks/useTracking';
import {
  BannerDescription,
  BannerTitle,
  BannerTopWrapper,
  BannerWrapper,
  GetStartedButton,
} from './styles';

type HealthScoreBannerProps = {
  setOpenHYPopup: Dispatch<SetStateAction<boolean>>;
};

const HealthScoreImg = () => (
  <img
    src={HealthScoreGraphic}
    id="health-score-image"
    data-testid="health-score-image"
    alt="health-score"
    height="100%"
    width="137px"
  />
);

export const HealthScoreBanner = ({
  setOpenHYPopup,
}: HealthScoreBannerProps) => {
  const { trackTapped } = useTracking();

  const openModal = () => {
    trackTapped('Calculate now');
    setOpenHYPopup(true);
  };

  return (
    <BannerWrapper data-testid="health-score-banner">
      <BannerTopWrapper>
        <Stack
          id="dashboard.health-score-banner.header.content"
          flexDirection="column"
          justifyContent="center"
        >
          <BannerTitle>
            <FormattedMessage
              id="dashboard.health-score-banner.header.content.title"
              defaultMessage="Calculate your healthspan"
            />
          </BannerTitle>
          <BannerDescription>
            <FormattedMessage
              id="dashboard.health-score-banner.header.content.description"
              defaultMessage="Discover personalised health recommendations"
            />
          </BannerDescription>
        </Stack>
        <HealthScoreImg />
      </BannerTopWrapper>
      <GetStartedButton
        id="open-health-score-modal"
        date-testid="open-health-score-modal"
        onClick={openModal}
      >
        <Stack
          id="button-inner-container"
          flexDirection="row"
          justifyContent="space-between"
          flex="1"
          alignItems="center"
        >
          <Text
            color={colors.base.white}
            fontWeight={500}
            style={{ fontStyle: 'italic' }}
            fontSize={fontSizes.sm}
          >
            <FormattedMessage
              id="health-score.open-health-score-modal.button"
              defaultMessage="Calculate now"
            />
          </Text>
          <ArrowRight />
        </Stack>
      </GetStartedButton>
    </BannerWrapper>
  );
};
