import { FormattedMessage } from 'react-intl';

import {
  Button,
  colors,
  fontSizes,
  Modal,
  ModalBody,
  Notification,
  Stack,
  spacing,
  Text,
  Link,
} from '@bt-healthcare/ui-toolkit';

import type { CareSetting } from '../../services/graphql';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { getCareSettingContactPoints } from '../../selectors/careSetting';

import { SimpleModalProps } from './types';

export const ContactTheTeam = ({
  modalOpen,
  handleClose,
}: SimpleModalProps) => {
  const { userProfile: data } = useAuthUserProfile();
  const contactHref =
    (data?.careSetting &&
      getCareSettingContactPoints(data?.careSetting as CareSetting)) ||
    undefined;
  const careSetting = data?.careSetting?.attributes;
  return (
    <Modal
      isModalOpen={modalOpen}
      onClose={handleClose}
      id="contact-the-team-modal"
    >
      <ModalBody>
        <Stack
          space="s4"
          id="confirmation"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            as="h3"
            fontSize={fontSizes.h3}
            color={colors.grey.grey10}
            style={{
              display: 'block',
              textAlign: 'center',
            }}
            fontWeight={500}
          >
            <FormattedMessage
              id="modal.contact-the-team-modal.header"
              defaultMessage="Contact the team"
            />
          </Text>
          <Text
            color={colors.grey.grey08}
            style={{
              display: 'block',
              textAlign: 'center',
              margin: ` 0 ${spacing.s2} ${spacing.s6}`,
            }}
          >
            <FormattedMessage
              id="modal.contact-the-team-modal.message"
              defaultMessage="You can get in touch with your care team on the below:"
            />
          </Text>
        </Stack>
        <Stack space="s5" id="ctas-and-warning-wrapper" alignItems="center">
          <Link href={contactHref} fontSize={fontSizes.h3}>
            {careSetting?.res_phone || careSetting?.email}
          </Link>
          <Notification type="softWarning">
            <Text color={colors.grey.grey10}>
              <FormattedMessage
                id="contact-the-team.waring"
                defaultMessage={`If you are experiencing an emergency do not use this form of
                contact. <b>Dial 999 immediately.</b>`}
              />
            </Text>
          </Notification>
          <Button variant="primary" onClick={handleClose} id="close">
            <FormattedMessage
              id="modal.contact-the-team-modal.close.button"
              defaultMessage="Ok"
            />
          </Button>
        </Stack>
      </ModalBody>
    </Modal>
  );
};
