import { ReactNode } from 'react';
import BaseCard from '../BaseCard';
import { StyledCard, StyledLink, Title } from './styles';
import { ChevronRightIcon } from '../RecommendationLinkCard/styles';

export const RecommendationBlueScreenLinkCard = ({
  path,
  title,
}: {
  path: string;
  title: ReactNode;
}) => (
  <StyledLink to={path}>
    <StyledCard id="reccomendation-blue-screen-card">
      <BaseCard.ItemLeft>
        <Title>{title}</Title>
      </BaseCard.ItemLeft>
      <BaseCard.ItemRight>
        <ChevronRightIcon />
      </BaseCard.ItemRight>
    </StyledCard>
  </StyledLink>
);
