import { Button } from '@bt-healthcare/ui-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import HealthScorePageWrapper from '../../components/HealthScorePageWrapper';
import { InputRangeCard } from '../../components/InputRangeCard';
import { useAppDispatch } from '../../context/AppContext';
import { healthScoreNavigator } from '../HealthScoreNavigator/intl';
import { initialValues, validationSchema } from './data';
import { healthScoreStrength } from './intl';
import { StrengthFormData } from './types';

export const HealthScoreStrength = () => {
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<StrengthFormData>({
    mode: 'onChange',
    defaultValues: initialValues,

    resolver: yupResolver(validationSchema),
  });

  const [daysPerWeek] = watch(['daysPerWeek']);

  const onSubmit = (values: StrengthFormData) => {
    dispatch({
      type: 'healthScoreSetStrengthInformation',
      payload: {
        daysPerWeek: values.daysPerWeek,
      },
    });
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setValue('daysPerWeek', parseInt(value, 10));
  };

  return (
    <HealthScorePageWrapper.Form>
      <HealthScorePageWrapper.Content>
        <HealthScorePageWrapper.Header>
          <FormattedMessage {...healthScoreStrength.header} />
        </HealthScorePageWrapper.Header>
        <HealthScorePageWrapper.InputsContainer>
          <InputRangeCard
            steps={7}
            id="health-score.strength"
            title="Days per week"
            onChange={onChange}
            value={daysPerWeek}
          />
        </HealthScorePageWrapper.InputsContainer>
      </HealthScorePageWrapper.Content>
      <Button
        id="health-score-button"
        variant="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={!isValid}
      >
        <FormattedMessage {...healthScoreNavigator.button} />
      </Button>
    </HealthScorePageWrapper.Form>
  );
};
