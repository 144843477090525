import { defineMessage, defineMessages } from 'react-intl';
import { Ethnicity } from '../../services/graphql';
import { Messages } from '../../i18n/messages/types';

export const ethnicityHeader = defineMessage({
  id: 'account.ethnicity.header',
  defaultMessage: 'Ethnicity',
});

export const ethnicityLabel = defineMessage({
  id: 'account.ethnicity.input.label',
  defaultMessage: 'What is your ethnicity?',
});

export const ethnicityOptions: Messages = defineMessages({
  [Ethnicity.WhiteBritish]: {
    id: 'account.ethnicity.input.options.white-british',
    defaultMessage: 'White - British',
  },
  [Ethnicity.WhiteIrish]: {
    id: 'account.ethnicity.input.options.white-irish',
    defaultMessage: 'White - Irish',
  },
  [Ethnicity.WhiteAnyOtherWhiteBackground]: {
    id: 'account.ethnicity.input.options.white-any-other',
    defaultMessage: 'White - Any other White background',
  },
  [Ethnicity.MixedWhiteAndBlackCaribbean]: {
    id: 'account.ethnicity.input.options.mixed-white-and-black-caribbean',
    defaultMessage: 'Mixed - White and Black Caribbean',
  },
  [Ethnicity.MixedWhiteAndBlackAfrican]: {
    id: 'account.ethnicity.input.options.mixed-white-and-black-african',
    defaultMessage: 'Mixed - White and Black African',
  },
  [Ethnicity.MixedWhiteAndAsian]: {
    id: 'account.ethnicity.input.options.mixed-white-and-asian',
    defaultMessage: 'Mixed - White and Asian',
  },
  [Ethnicity.MixedAnyOtherMixed]: {
    id: 'account.ethnicity.input.options.mixed-any-other',
    defaultMessage: 'Mixed - Any other mixed background',
  },
  [Ethnicity.AsianOrAsianBritishIndian]: {
    id: 'account.ethnicity.input.options.asian-or-asian-british-indian',
    defaultMessage: 'Asian or Asian British - Indian',
  },
  [Ethnicity.AsianOrAsianBritishPakistani]: {
    id: 'account.ethnicity.input.options.asian-or-asian-british-pakistani',
    defaultMessage: 'Asian or Asian British - Pakistani',
  },
  [Ethnicity.AsianOrAsianBritishBangladeshi]: {
    id: 'account.ethnicity.input.options.asian-or-asian-british-bangladeshi',
    defaultMessage: 'Asian or Asian British - Bangladeshi',
  },
  [Ethnicity.AsianOrAsianBritishAnyOtherAsianBackground]: {
    id: 'account.ethnicity.input.options.asian-or-asian-british-any-other',
    defaultMessage: 'Asian or Asian British - Any other Asian background',
  },
  [Ethnicity.BlackOrBlackBritishCaribbean]: {
    id: 'account.ethnicity.input.options.black-or-black-british-caribbean',
    defaultMessage: 'Black or Black British - Caribbean',
  },
  [Ethnicity.BlackOrBlackBritishAfrican]: {
    id: 'account.ethnicity.input.options.black-or-black-british-african',
    defaultMessage: 'Black or Black British - African',
  },
  [Ethnicity.BlackOrBlackBritishAnyOtherBlackBackground]: {
    id: 'account.ethnicity.input.options.black-or-black-british-any-other-black-background',
    defaultMessage: 'Black or Black British - Any other Black background',
  },
  [Ethnicity.OtherEthnicGroupsChinese]: {
    id: 'account.ethnicity.input.options.other-ethnic-groups-chinese',
    defaultMessage: 'Other Ethnic Groups - Chinese',
  },
  [Ethnicity.OtherEthnicGroupsAnyOtherEthnicGroup]: {
    id: 'account.ethnicity.input.options.other-ethnic-groups-any-other',
    defaultMessage: 'Other Ethnic Groups - Any other ethnic group',
  },
  [Ethnicity.NotKnown]: {
    id: 'account.ethnicity.input.options.not-known',
    defaultMessage: 'Not known',
  },
  [Ethnicity.NotStated]: {
    id: 'account.ethnicity.input.options.not-stated',
    defaultMessage: 'Not stated',
  },
});

export const ethnicityError: Messages = defineMessages({
  mutation: {
    id: 'account.ethnicity.error.mutation',
    defaultMessage: 'Failed to submit. <b>Please try again.</b>',
  },
});

export const submitButton = defineMessage({
  id: 'account.ethnicity.submit-button',
  defaultMessage: 'Confirm',
});
