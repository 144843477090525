import { Notification, Row } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { ROUTE } from '../../config/routes';
import { WardPatientHypertensionHomeBannerStatus } from '../../services/graphql';
import { RecommendationLinkCard } from '../Cards/RecommendationLinkCard';
import { messages } from './intl';
import { GridItem } from './styles';
import { useTracking } from '../../hooks/useTracking';

export const ProgrammeBannerCard = ({
  bannerStatus,
}: {
  bannerStatus: WardPatientHypertensionHomeBannerStatus | null;
}) => {
  const { trackTapped } = useTracking();

  switch (bannerStatus) {
    case WardPatientHypertensionHomeBannerStatus.Offer:
      return (
        <RecommendationLinkCard
          text={<FormattedMessage {...messages.recommendationInvitation} />}
          path={ROUTE.RECOMMENDATION_INFO}
          onClick={() => trackTapped('Offer ABP insights')}
        />
      );
    case WardPatientHypertensionHomeBannerStatus.InProgress:
      return (
        <RecommendationLinkCard
          text={<FormattedMessage {...messages.recommendationInProgress} />}
          path={ROUTE.RECOMMENDATION_HOW_IT_WORKS}
          withChevron={false}
          onClick={() => trackTapped('ABP insight progress reminder')}
        />
      );
    case WardPatientHypertensionHomeBannerStatus.CanceledMissingReading:
      return (
        <Notification type="informative">
          <FormattedMessage {...messages.recommendationInComplete} />
        </Notification>
      );
    case WardPatientHypertensionHomeBannerStatus.Complete:
      return (
        <RecommendationLinkCard
          text={<FormattedMessage {...messages.recommendationComplete} />}
          path={ROUTE.RECOMMENDATION_PROGRAMME}
          onClick={() => trackTapped('ABP insight available')}
        />
      );
    default:
      return null;
  }
};

export const ProgrammeBanner = ({
  data,
}: {
  data: WardPatientHypertensionHomeBannerStatus | null;
}) =>
  data ? (
    <Row>
      <GridItem>
        <ProgrammeBannerCard bannerStatus={data} />
      </GridItem>
    </Row>
  ) : null;
