import styled from 'styled-components';
import { spacing } from '@bt-healthcare/ui-toolkit';
import { ItemLeftTypes, ItemRightTypes } from './types';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing.s6};
`;

export const ItemLeftContainer = styled.div<ItemLeftTypes>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  ${({ gap }) => (gap ? `gap: ${gap}px;` : '')}
  order: 1;
`;

export const ItemRightContainer = styled.div<ItemRightTypes>`
  order: 2;
  ${({ alignSelf }) => `align-self: ${alignSelf}` || ''};
  justify-self: flex-end;
`;
