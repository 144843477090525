import styled from 'styled-components';
import {
  colors,
  device,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';

export const Header = styled(Text).attrs({
  fontSize: fontSizes.h4,
  color: colors.grey.grey10,
})`
  @media ${device.tablet} {
    font-size: ${fontSizes.h3};
  }
`;

export const HeaderWrapper = styled.div`
  margin: 0 0 ${spacing.s10} 0;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spacing.s8};
  padding-top: ${spacing.s8};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 ${spacing.s4} 0;
  justify-content: space-between;
  gap: ${spacing.s8};
  @media ${device.tablet} {
    justify-content: unset;
  }
`;

export const InputWithInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  width: 100%;
`;
