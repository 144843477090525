import { IntlShape } from 'react-intl';
import { object, string } from 'yup';
import { Ethnicity } from '../../services/graphql';
import { sortArrayByGivenOrder } from '../../utils/array.utils';
import { ethnicityHeader, ethnicityLabel, ethnicityOptions } from './intl';
import type { EthnicityData } from './types';

const ethnicityOrder = [
  Ethnicity.WhiteBritish,
  Ethnicity.WhiteIrish,
  Ethnicity.WhiteAnyOtherWhiteBackground,
  Ethnicity.MixedWhiteAndBlackCaribbean,
  Ethnicity.MixedWhiteAndBlackAfrican,
  Ethnicity.MixedWhiteAndAsian,
  Ethnicity.MixedAnyOtherMixed,
  Ethnicity.AsianOrAsianBritishIndian,
  Ethnicity.AsianOrAsianBritishPakistani,
  Ethnicity.AsianOrAsianBritishBangladeshi,
  Ethnicity.AsianOrAsianBritishAnyOtherAsianBackground,
  Ethnicity.BlackOrBlackBritishCaribbean,
  Ethnicity.BlackOrBlackBritishAfrican,
  Ethnicity.BlackOrBlackBritishAnyOtherBlackBackground,
  Ethnicity.OtherEthnicGroupsChinese,
  Ethnicity.OtherEthnicGroupsAnyOtherEthnicGroup,
  Ethnicity.NotStated,
  Ethnicity.NotKnown,
];
export const ethnicity = (intl: IntlShape): EthnicityData => ({
  header: intl.formatMessage(ethnicityHeader),
  fields: [
    {
      name: 'ethnicity',
      label: intl.formatMessage(ethnicityLabel),
      validationRule: object()
        .shape({
          value: string().oneOf(Object.values(Ethnicity)).required(),
          label: string().required(),
        })
        .typeError(''),
      options: sortArrayByGivenOrder(
        Object.values(Ethnicity),
        ethnicityOrder
      ).map((value) => ({
        value,
        label: intl.formatMessage(ethnicityOptions[value]),
      })),
      type: 'select',
    },
  ],
});
