import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Modal,
  ModalBody,
  Stack,
  ModalFooter,
  Button,
  Notification,
  Text,
  colors,
  fontSizes,
  spacing,
  Checkbox,
} from '@bt-healthcare/ui-toolkit';
import { ApolloError } from '@apollo/client';
import { ReactComponent as Unlink } from '../../assets/unlink.svg';
import { unlinkGPModal } from './intl';
import { SimpleModalProps } from './types';
import { useTracking } from '../../hooks/useTracking';

export const UnlinkGPModal = ({
  modalOpen,
  handleClose,
  handleUnlink,
  error,
}: SimpleModalProps & { handleUnlink: () => void; error?: ApolloError }) => {
  const { trackPageWithAppName } = useTracking();

  useEffect(() => {
    trackPageWithAppName('Unlink My GP Modal');
  }, []);

  const [confirmation, setConfirmation] = useState(false);

  const handleCancel = () => {
    setConfirmation(false);
    handleClose();
  };

  const onToggleConfirmation = () => {
    setConfirmation(!confirmation);
  };

  return (
    <Modal onClose={handleClose} isModalOpen={modalOpen} id="unlink-gp-modal">
      <ModalBody>
        <Stack space="s4" id="unlink-gp" alignItems="center" textAlign="center">
          <Text
            color={colors.grey.grey09}
            as="h3"
            fontSize={fontSizes.h3}
            fontWeight={500}
          >
            <FormattedMessage {...unlinkGPModal.header} />
          </Text>
          <Text color={colors.grey.grey09} fontSize={fontSizes.base}>
            <FormattedMessage {...unlinkGPModal.textDescription} />
          </Text>
          <Stack id="unlink-gp.warning" textAlign="left">
            <Notification type="softWarning">
              <Text color={colors.grey.grey09} fontSize={fontSizes.base}>
                <FormattedMessage {...unlinkGPModal.notificationWarning} />
              </Text>
            </Notification>
          </Stack>
          <Stack id="unlink-gp.checkbox" paddingLeft={spacing.s1} width="100%">
            <Checkbox
              id="unlink-gp-checkbox"
              onChange={onToggleConfirmation}
              checked={confirmation}
              label={
                <Text color={colors.grey.grey09} fontSize={fontSizes.sm}>
                  <FormattedMessage {...unlinkGPModal.checkboxLabel} />
                </Text>
              }
            />
          </Stack>

          {error && (
            <Notification type="warning">
              <FormattedMessage {...unlinkGPModal.error} />
            </Notification>
          )}
        </Stack>
        <ModalFooter>
          <Stack id="modal-buttons-wrapper" gap={spacing.s6}>
            <Button
              id="unlink"
              onClick={handleUnlink}
              variant="primaryRed"
              disabled={!confirmation}
            >
              <Unlink />
              <FormattedMessage {...unlinkGPModal.primaryCTA} />
            </Button>
            <Button id="close" onClick={handleCancel} variant="secondary">
              <FormattedMessage {...unlinkGPModal.secondaryCTA} />
            </Button>
          </Stack>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
