import { defineMessages } from 'react-intl';
import { ObservationType } from '../../services/graphql';
import { Messages } from '../../i18n/messages/types';

export const observationName: Messages = defineMessages({
  [ObservationType.Pulse]: {
    id: 'observation.list.pulse',
    defaultMessage: 'Pulse',
  },
  [ObservationType.Spo2]: {
    id: 'observation.list.spo2',
    defaultMessage: 'SpO2',
  },
  [ObservationType.BloodPressure]: {
    id: 'observation.list.blood-pressure',
    defaultMessage: 'Blood pressure',
  },
  [ObservationType.Weight]: {
    id: 'observation.list.weight',
    defaultMessage: 'Weight',
  },
  [ObservationType.Cholesterol]: {
    id: 'observation.list.cholesterol',
    defaultMessage: 'Cholesterol',
  },
  [ObservationType.FastingBloodGlucose]: {
    id: 'observation.list.fasting-blood-glucose',
    defaultMessage: 'Fasting blood glucose',
  },
  VO2: {
    id: 'observation.list.vo2',
    defaultMessage: 'Cardio fitness (VO2 Max)',
  },
});
