import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Stack,
  Text,
  Header,
  colors,
  Button,
  Spacer,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { AppointmentCard } from '../../components/Cards/AppointmentCard';
import { ReactComponent as Hero } from '../../assets/hero.svg';

import { useTracking } from '../../hooks/useTracking';

import {
  BookingConfirmedContent,
  BodyText,
  BookingConfirmedContainer,
  HeroContainer,
  HeaderText,
  BookingSuccessContainer,
} from './styles';
import { CommonlyAskedQuestion } from '../../components/CommonlyAskedQuestion';
import { BookingSuccessState } from './types';

export const BookingSuccess = () => {
  const { trackPageWithAppName, trackEvent } = useTracking();
  const location = useLocation();

  const state = location.state as BookingSuccessState;

  useEffect(() => {
    trackPageWithAppName('Booking - success');
  }, []);

  if (!state.date) {
    return null;
  }

  return (
    <BookingSuccessContainer>
      <BookingConfirmedContainer>
        <Stack
          id="booking-success.confirmed.stack"
          space="s6"
          alignItems="center"
        >
          <HeroContainer>
            <Hero width="100%" height="100%" />
          </HeroContainer>
          <Header data-testid="booking-success-header">
            <FormattedMessage
              id="booking-success.confirmed.header"
              defaultMessage="Booking confirmed!"
            />
          </Header>
          <Text color={colors.grey.grey08}>
            <BodyText>
              <FormattedMessage
                id="booking-success.confirmed.subtitle"
                defaultMessage="You will shortly receive an email and SMS confirmation of your appointment, and a reminder the day before."
              />
            </BodyText>
          </Text>
          <BookingConfirmedContent>
            <AppointmentCard date={state.date} location={state.location} />
          </BookingConfirmedContent>
          <BookingConfirmedContent>
            <Link to="/">
              <Button
                id="booking-success.confirmed.back-to-home"
                variant="primary"
                onClick={() => trackEvent(`Back home`, 'click')}
              >
                <FormattedMessage
                  id="booking-success.confirmed.back-to-home-text"
                  defaultMessage="Back home"
                />
              </Button>
            </Link>
          </BookingConfirmedContent>
        </Stack>
      </BookingConfirmedContainer>

      <Stack
        id="booking-success.common-questions.stack"
        space="s8"
        justifyContent="center"
        flex={1}
      >
        <Spacer xs="s4" />
        <Text color={colors.grey.grey10}>
          <HeaderText>
            <FormattedMessage
              id="booking-success.common-questions.header"
              defaultMessage="Commonly asked questions"
            />
          </HeaderText>
        </Text>
        <CommonlyAskedQuestion
          id="booking-success.common-questions.1"
          title="How do I cancel or change my appointment?"
          text={{
            message:
              'To cancel or change your appointment, you will need to phone the GP practice on {0}.',
            values: {
              0: (
                <Text color={colors.base.dark}>
                  <strong>01925 598230</strong>
                </Text>
              ),
            },
          }}
        />
        <CommonlyAskedQuestion
          id="booking-success.common-questions.2"
          title="How long do I need to wait to get my results?"
          text={{
            message:
              'Most tests are analysed within 1 week, however, there are certain tests which require several weeks to be analysed.',
          }}
        />
        <CommonlyAskedQuestion
          id="booking-success.common-questions.3"
          title="What happens If my results come back abnormal?"
          text={{
            message:
              'Abnormal results do not necessarily indicate a health problem. A test result outside the reference range signals to your doctor to further investigate your condition, but it may or may not indicate a specific problem. You can have a value outside the range and be fine.',
          }}
        />
      </Stack>
    </BookingSuccessContainer>
  );
};
