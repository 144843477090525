import { FormattedMessage } from 'react-intl';
import {
  AgeCell,
  GenderTag,
  HeaderCell,
  HeaderDescription,
  HeaderDescriptionContainer,
  RangeCell,
  Vo2Column,
  Vo2Grid,
  Vo2Row,
} from './styles';
import { vo2RangesData } from './data';
import { vo2Table } from './intl';
import { Vo2TableProps } from './types';

export const Vo2Table = ({ variant }: Vo2TableProps) => {
  const tableData = vo2RangesData[variant];
  const { columns, header } = vo2Table;
  return (
    <Vo2Grid>
      <Vo2Row>
        <GenderTag>
          <FormattedMessage {...vo2Table[variant]} />
        </GenderTag>
        <HeaderDescriptionContainer>
          <HeaderDescription>
            <FormattedMessage {...header} />
          </HeaderDescription>
        </HeaderDescriptionContainer>
      </Vo2Row>
      <Vo2Row isWhite>
        {columns.map((columnName, index) => (
          <Vo2Column order={index} key={columnName.defaultMessage}>
            <HeaderCell>
              <FormattedMessage {...columnName} />
            </HeaderCell>
          </Vo2Column>
        ))}
      </Vo2Row>
      {tableData.map((rowData, index) => (
        <Vo2Row
          isWhite={index % 2 === 1}
          key={`${rowData.minAge}-${rowData.maxAge}`}
        >
          <Vo2Column order={0}>
            <AgeCell>
              {rowData.minAge}-{rowData.maxAge}
            </AgeCell>
          </Vo2Column>
          {Object.values(rowData.categories).map(
            ({ min, max }, columnIndex) => (
              <Vo2Column order={columnIndex + 1} key={`${min}-${max}`}>
                <RangeCell>
                  {max
                    ? `${min.toFixed(1)} - ${max.toFixed(1)}`
                    : `≥${min.toFixed(1)}`}
                </RangeCell>
              </Vo2Column>
            )
          )}
        </Vo2Row>
      ))}
    </Vo2Grid>
  );
};
