import { Input, MultipleChoiceButton, Text } from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { disallowExponentialInputs } from '../../pages/CreateObservations/data';
import { RHFDropDown } from '../RHFDropDown';
import { HealthInformationFieldProps } from './types';
import { DisabledMessageWrapper } from './styles';

export const HealthInformationField = ({
  field,
}: HealthInformationFieldProps) => {
  const [disabled, setDisabled] = useState(false);
  const {
    formState: { errors },
    watch,
    register,
    getValues,
    setValue,
    control,
  } = useFormContext();
  const { name, type, conditionOptions, disabledMessage } = field;
  const watcher =
    conditionOptions?.conditionFieldName &&
    watch(conditionOptions?.conditionFieldName, { type: 'valueChange' });

  useEffect(() => {
    if (watcher) {
      const value = getValues(conditionOptions?.conditionFieldName);
      if (conditionOptions?.disable) {
        setDisabled(conditionOptions.disable(value));
      }
      conditionOptions.condition(value, setValue);
    }
  }, [setValue, watcher, getValues]);

  if (type === 'number') {
    const { unitLabel, inputMode } = field;
    return (
      <Input
        id={name}
        label="Height"
        unit={unitLabel}
        type="number"
        errorText={errors[name]?.message as string}
        inputMode={inputMode || 'numeric'}
        {...register(name, { valueAsNumber: true })}
        {...disallowExponentialInputs()}
      />
    );
  }
  if (type === 'select') {
    const { options, label } = field;
    return (
      <RHFDropDown
        options={options}
        label={label}
        fieldName={name}
        control={control}
        errorText={errors[name]?.message as string}
      />
    );
  }
  if (type === 'yesNo') {
    const { label, options } = field;
    return (
      <>
        <MultipleChoiceButton
          options={options}
          size="lg"
          label={label}
          disabled={disabled}
          {...register(name)}
        />

        {disabled && disabledMessage && (
          <DisabledMessageWrapper>
            <Text color="grey08">{disabledMessage}</Text>
          </DisabledMessageWrapper>
        )}
      </>
    );
  }
  return null;
};
