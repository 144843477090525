import { defineMessages } from 'react-intl';
import { ObservationType } from '../../services/graphql';
import { Messages } from '../../i18n/messages/types';

export const intl: { [key: string]: Messages[] } = {
  [ObservationType.Spo2]: [
    defineMessages({
      header: {
        id: 'faq.spo2.increase-spo2.header',
        defaultMessage: 'How can I increase my SpO2?',
      },
      content: {
        id: 'faq.spo2.increase-spo2.content',
        defaultMessage:
          'Something as simple as opening your windows for fresh air or going for a short walk can help to increase the amount of oxygen that your body brings in, which increases overall blood oxygen levels. If you have a respiratory condition, slowly increasing your levels of gentle exercise and fitness will aid your body in using your blood oxygen levels to the best of it’s abilities. \n\nIt is important to note that any results over 95% are good and that there are no additional health benefits to getting to 100%.',
      },
    }),
    defineMessages({
      header: {
        id: 'faq.spo2.seek-medical-advice.header',
        defaultMessage: 'When should I seek medical advice?',
      },
      content: {
        id: 'faq.spo2.seek-medical-advice.content',
        defaultMessage:
          'If your readings are consistently low or very low, and you have not yet spoken with your GP, we recommend seeking the advice of a medical professional.',
      },
    }),
  ],
  [ObservationType.Pulse]: [
    defineMessages({
      header: {
        id: 'faq.pulse.lower-pulse.header',
        defaultMessage: 'How can I lower my pulse?',
      },
      content: {
        id: 'faq.pulse.lower-pulse.content',
        defaultMessage:
          'When you are experiencing an elevated pulse it could be due to a number of reasons including: exertion, caffeine or stress. \n\n Physical exertion is the most common cause of an elevated pulse, but you can also be mindful of your caffeine intake. Large doses of caffeine such as energy drinks and multiple cups of coffee, can lead to significant increases in heart rate. ',
      },
    }),
    defineMessages({
      header: {
        id: 'faq.pulse.seek-medical-advice.header',
        defaultMessage: 'When should I seek medical advice?',
      },
      content: {
        id: 'faq.pulse.seek-medical-advice.content',
        defaultMessage:
          'If your readings are consistently low, elevated or high, or you are experiencing chest pains, and you have not yet spoken with your GP, we recommend seeking the advice of a medical professional.',
      },
    }),
  ],
  [ObservationType.BloodPressure]: [
    defineMessages({
      header: {
        defaultMessage: 'How to read the chart',
        id: 'blood-pressure-levels.faq.read-the-chart.header',
      },
      content: {
        defaultMessage:
          'An ideal blood pressure is usually considered to be 120/80mmHg. The above chart should be used as general guidance. If your numbers straddle across two categories, we take the highest category and assign your reading against that. Please note that if you have a chronic condition, the normal range of blood pressure may vary. The chart is in line with current {link}.',
        id: 'blood-pressure-levels.faq.read-the-chart.text',
      },
      nhsGuidanceLink: {
        id: 'blood-pressure-levels.faq.read-the-chart.link',
        defaultMessage: 'NHS guidance',
      },
    }),
    defineMessages({
      header: {
        defaultMessage: 'How can I lower my blood pressure?',
        id: 'blood-pressure-levels.faq.lower-blood-pressure.header',
      },
      content: {
        defaultMessage:
          'A healthy lifestyle with exercise, low salt intake and a balanced diet can help to lower your blood pressure, and lower your risk of having a heart attack or stroke. It can prevent other health issues too. {link}',
        id: 'blood-pressure-levels.faq.lower-blood-pressure.text',
      },
      lowerBloodPressureLink: {
        id: 'blood-pressure-levels.faq.lower-blood-pressure.link',
        defaultMessage: 'Find out more',
      },
    }),
    defineMessages({
      header: {
        defaultMessage: 'When should I seek medical advice?',
        id: 'blood-pressure-levels.faq.medical-advice.header',
      },
      content: {
        defaultMessage:
          'If your readings are consistently High or Elevated, and you have not yet spoken with your GP, we recommend seeking the advice of a medical professional.',
        id: 'blood-pressure-levels.faq.medical-advice.text',
      },
    }),
  ],
  [ObservationType.FastingBloodGlucose]: [
    defineMessages({
      header: {
        defaultMessage: 'What causes fasting blood glucose to spike?',
        id: 'fasting-blood-glucose.faq.causes.header',
      },
      content: {
        defaultMessage:
          'There are many reasons your blood glucose may spike after a fasting period including: a diet high in sugar or carbohydrates, a more sedentary lifestyle, smoking cigarettes, feeling significant stress, poor quality or lack of sleep and medication side effects.',
        id: 'fasting-blood-glucose.faq.causes.text',
      },
    }),
    defineMessages({
      header: {
        defaultMessage: 'When should I seek medical advice?',
        id: 'fasting-blood-glucose.faq.seek-medical-advice.header',
      },
      content: {
        defaultMessage:
          'If your readings are consistently high and you have not yet spoken with your GP, we recommend seeking the advice of a medical professional.',
        id: 'fasting-blood-glucose.faq.seek-medical-advice..text',
      },
    }),
  ],
};
