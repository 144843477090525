import { ObservationType } from '../services/graphql';

export const useKnownObservation = () => {
  const knownObservations: ObservationType[] = [
    ObservationType.Spo2,
    ObservationType.BloodPressure,
    ObservationType.Pulse,
    ObservationType.Weight,
    ObservationType.Cholesterol,
    ObservationType.FastingBloodGlucose,
  ];
  return (type: ObservationType) => knownObservations.includes(type);
};
