import 'cross-fetch/polyfill';

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
  from,
  split,
  Context,
} from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren, useRef } from 'react';

import { getEnvVariable } from '../../env.utils';
import { errorLink } from './links/error';
import { WardPatientObservationView } from '../../services/graphql';
import { useApolloError } from '../ApolloErrorBoundary/ApolloErrorProvider';
import { createAuthLink } from './links/auth';

export const graphqlServerURL = `${getEnvVariable(
  'VITE_SERVICE_GRAPHQL'
)}/graphql`;

export const graphqlServerAnonURL = `${getEnvVariable(
  'VITE_SERVICE_ANON_GRAPHQL'
)}/graphql`;

const isAnonymousOperation = ({ getContext }: Context) =>
  getContext().isAnonymous;

const mergeSearchResults = (
  existing: any,
  incoming: any,
  args: Record<string, any> | null
) => {
  const merged = existing?.searchResults?.slice(0) || [];
  if (incoming && args) {
    incoming?.searchResults.forEach(
      (item: WardPatientObservationView, index: number) => {
        merged[args.offset + index] = item;
      }
    );
  }
  return { ...incoming, searchResults: merged };
};

export const AuthApolloProvider = ({ children }: PropsWithChildren<{}>) => {
  const { getIdTokenClaims, getAccessTokenSilently, logout } = useAuth0();
  const client = useRef<ApolloClient<NormalizedCacheObject>>();
  const { setError } = useApolloError();

  const httpLink = split(
    isAnonymousOperation,
    new HttpLink({ uri: graphqlServerAnonURL }),
    new HttpLink({ uri: graphqlServerURL })
  );

  const authLink = split(
    isAnonymousOperation,
    createAuthLink({
      attachBearer: false,
      getAccessTokenSilently,
      getIdTokenClaims,
    }),
    createAuthLink({
      attachBearer: true,
      getAccessTokenSilently,
      getIdTokenClaims,
    })
  );

  if (!client.current) {
    client.current = new ApolloClient({
      link: from([
        authLink,
        errorLink({
          handleError: setError,
          logout: (returnTo = window.location.origin) => {
            client.current?.clearStore();
            logout({ logoutParams: { returnTo } });
          },
        }),
        httpLink,
      ]),
      connectToDevTools: import.meta.env.DEV,
      cache: new InMemoryCache({
        typePolicies: {
          UserPatientProfile: {
            keyFields: ['userId'],
          },
          Query: {
            fields: {
              searchWardPatientObservations: {
                merge(existing, incoming, { args }) {
                  return mergeSearchResults(existing, incoming, args);
                },
                keyArgs: ['types', 'wardPatientId'],
              },
              wardPatientObservationsForProgramme: {
                merge(existing, incoming, { args }) {
                  return mergeSearchResults(existing, incoming, args);
                },
                keyArgs: ['wardPatientId'],
              },
            },
          },
        },
      }),
    });
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};
