import { IntlShape } from 'react-intl';
import { is } from 'ramda';

import { createPath, generatePath } from 'react-router-dom';
import { EMPTY_VALUE } from '../App.constants';
import {
  BodyWeightUnitInput,
  CholesterolUnit,
  CreateWardPatientObservationInput,
  ObservationType,
} from '../services/graphql';
import { ObservationValue } from '../types';
import { messages, messagesWithoutUnit, readingNames } from './intl';
import {
  getFullStoneAndPoundsFromPounds,
  getPoundsFromStoneAndPounds,
} from './poundsStone';
import { notEmpty } from '../utils/array.utils';
import { FormatNumberType, ObservationRoutesType } from './types';

export const formatObservationType = (type: ObservationType) => {
  switch (type) {
    case ObservationType.Spo2:
      return 'SpO2';
    case ObservationType.Pulse:
      return 'Pulse';
    case ObservationType.BloodPressure:
      return 'Blood pressure';
    case ObservationType.Weight:
      return 'Weight';
    case ObservationType.Cholesterol:
      return 'Cholesterol';
    case ObservationType.FastingBloodGlucose:
      return 'Fasting blood glucose';
    default:
      return type;
  }
};

export const formatForDomAndUrlPath = (type: ObservationType) =>
  formatObservationType(type).replace(/\s/g, '-').toLowerCase();

export const observationPath = (
  type: ObservationType,
  route: ObservationRoutesType
) => generatePath(route, { type: formatForDomAndUrlPath(type) });

export const observationPathWithSearchParams = (
  type: ObservationType,
  route: ObservationRoutesType,
  searchParams: URLSearchParams
) =>
  createPath({
    pathname: observationPath(type, route),
    search: searchParams.toString(),
  });

export const mapUrlPathToObservationType = (
  type: string
): ObservationType | null =>
  Object.values(ObservationType).find(
    (observationType) => formatForDomAndUrlPath(observationType) === type
  ) || null;

export const getMaximumDecimalDigits = (
  type: ObservationType,
  unit: FormatNumberType['unit']
) => {
  switch (type) {
    case ObservationType.FastingBloodGlucose:
    case ObservationType.Cholesterol:
      return 1;
    case ObservationType.Weight:
      return unit === BodyWeightUnitInput.Kg ? 1 : 0;
    default:
      return 0;
  }
};

export const formatNumber = ({
  values,
  type,
  intl,
  unit,
  withUnit = true,
}: FormatNumberType): string => {
  const formattedType = [type, unit].filter(notEmpty).join('-');
  const maximumFractionDigits = getMaximumDecimalDigits(type, unit);
  const message =
    !withUnit && formattedType === ObservationType.BloodPressure
      ? messagesWithoutUnit[formattedType]
      : messages[formattedType];

  return intl.formatMessage(
    message,
    Object.fromEntries(
      Object.entries(values).map(([key, value]) => [
        key,
        value.toLocaleString(undefined, {
          style: 'decimal',
          maximumFractionDigits,
        }),
      ])
    )
  );
};

export const submitCardInfoData = (
  input: CreateWardPatientObservationInput,
  intl: IntlShape
) => {
  let result: { label: string; value: string; type: ObservationType }[] = [];
  if (input.spo2) {
    result = [
      ...result,
      {
        value: formatNumber({
          values: { value: input.spo2.observationValue.oxygenSaturation },
          type: ObservationType.Spo2,
          intl,
        }),
        label: intl.formatMessage(readingNames[ObservationType.Spo2]),
        type: ObservationType.Spo2,
      },
    ];
  }
  if (input.bodyWeight) {
    const { weight, weightUnit } = input.bodyWeight.observationValue;
    const values =
      weightUnit === BodyWeightUnitInput.Kg
        ? { kg: weight! }
        : getFullStoneAndPoundsFromPounds({ lb: weight! });
    result = [
      ...result,
      {
        value: formatNumber({
          values,
          type: ObservationType.Weight,
          intl,
          unit: weightUnit!,
        }),
        label: intl.formatMessage(readingNames[ObservationType.Weight]),
        type: ObservationType.Weight,
      },
    ];
  }
  if (input.cholesterol) {
    const { total, hdl, unit } = input.cholesterol.observationValue;

    result = [
      ...result,
      {
        value: formatNumber({
          values: { value: total },
          type: ObservationType.Cholesterol,
          intl,
          unit,
        }),
        label: intl.formatMessage(
          readingNames[`${ObservationType.Cholesterol}-total`]
        ),
        type: ObservationType.Cholesterol,
      },
      {
        value: formatNumber({
          values: { value: hdl },
          type: ObservationType.Cholesterol,
          intl,
          unit,
        }),
        label: intl.formatMessage(
          readingNames[`${ObservationType.Cholesterol}-hdl`]
        ),
        type: ObservationType.Cholesterol,
      },
    ];
  }
  if (input.bloodPressure) {
    const { systolic, diastolic } = input.bloodPressure.observationValue;
    result = [
      ...result,
      {
        value: formatNumber({
          values: { systolic, diastolic },
          type: ObservationType.BloodPressure,
          intl,
        }),
        label: intl.formatMessage(readingNames[ObservationType.BloodPressure]),
        type: ObservationType.BloodPressure,
      },
    ];
  }
  if (input.pulse) {
    result = [
      ...result,
      {
        value: formatNumber({
          values: { value: input.pulse.observationValue.bpm },
          type: ObservationType.Pulse,
          intl,
        }),
        label: intl.formatMessage(readingNames[ObservationType.Pulse]),
        type: ObservationType.Pulse,
      },
    ];
  }
  if (input.fastingBloodGlucose) {
    result = [
      ...result,
      {
        value: formatNumber({
          values: {
            value: input.fastingBloodGlucose.observationValue.bloodGlucose,
          },
          type: ObservationType.FastingBloodGlucose,
          intl,
        }),
        label: intl.formatMessage(
          readingNames[ObservationType.FastingBloodGlucose]
        ),
        type: ObservationType.FastingBloodGlucose,
      },
    ];
  }
  return result;
};

export const formatObservation = (
  value: ObservationValue,
  intl: IntlShape
): { label: string | undefined; value: string }[] => {
  switch (value.__typename) {
    case 'Pulse':
      return [
        {
          value: formatNumber({
            values: { value: value.bpm },
            type: ObservationType.Pulse,
            intl,
          }),
          label: intl.formatMessage(readingNames[ObservationType.Pulse]),
        },
      ];
    case 'Spo2':
      return [
        {
          value: formatNumber({
            values: { value: value.oxygenSaturation },
            type: ObservationType.Spo2,
            intl,
          }),
          label: intl.formatMessage(readingNames[ObservationType.Spo2]),
        },
      ];
    case 'BloodPressure':
      return [
        {
          value: formatNumber({
            values: { systolic: value.systolic, diastolic: value.diastolic },
            type: ObservationType.BloodPressure,
            intl,
          }),
          label: intl.formatMessage(
            readingNames[ObservationType.BloodPressure]
          ),
        },
      ];
    case 'BodyWeight': {
      const { weightUnit: unit, weight } = value;
      const values =
        unit === BodyWeightUnitInput.Kg
          ? { kg: weight }
          : getFullStoneAndPoundsFromPounds({ lb: weight });
      return [
        {
          value: formatNumber({
            values,
            type: ObservationType.Weight,
            intl,
            unit,
          }),
          label: intl.formatMessage(readingNames[ObservationType.Weight]),
        },
      ];
    }
    case 'Cholesterol': {
      const { total, hdl, unit } = value;
      return [
        {
          value: formatNumber({
            values: { value: total },
            type: ObservationType.Cholesterol,
            intl,
            unit,
          }),
          label: intl.formatMessage(
            readingNames[`${ObservationType.Cholesterol}-total`]
          ),
        },
        {
          value: formatNumber({
            values: { value: hdl },
            type: ObservationType.Cholesterol,
            intl,
            unit,
          }),
          label: intl.formatMessage(
            readingNames[`${ObservationType.Cholesterol}-hdl`]
          ),
        },
      ];
    }
    case 'FastingBloodGlucose': {
      const { bloodGlucose } = value;
      return [
        {
          value: formatNumber({
            values: { value: bloodGlucose },
            type: ObservationType.FastingBloodGlucose,
            intl,
          }),
          label: intl.formatMessage(
            readingNames[ObservationType.FastingBloodGlucose]
          ),
        },
      ];
    }
    default:
      return [{ value: EMPTY_VALUE, label: undefined }];
  }
};

export const weightObservationToObservationInput = (values: {
  kg?: number;
  lb?: number;
  st?: number;
}) => {
  const { kg, st, lb } = values;
  const convertedLb =
    (is(Number, st) &&
      is(Number, lb) &&
      getPoundsFromStoneAndPounds({ st, lb })) ||
    null;
  const weightUnit = is(Number, kg)
    ? BodyWeightUnitInput.Kg
    : BodyWeightUnitInput.Lb;
  const weight = kg || convertedLb!;
  return { weight, weightUnit };
};

export const rHFObservationToObservationInput = (
  values: { [key: string]: number },
  observationType: ObservationType
): CreateWardPatientObservationInput => {
  const result: CreateWardPatientObservationInput = {
    spo2: null,
    pulse: null,
    bloodPressure: null,
    bodyWeight: null,
    bodyHeight: null,
    fastingBloodGlucose: null,
    cholesterol: null,
    bodyTemperature: null,
    heartRhythm: null,
  };
  const observationCoreObject = {
    recordedDateTime: new Date().toISOString(),
  };

  switch (observationType) {
    case ObservationType.Spo2:
      return {
        ...result,
        spo2: {
          ...observationCoreObject,
          observationValue: { oxygenSaturation: values.spo2 },
        },
      };
    case ObservationType.Pulse:
      return {
        ...result,
        pulse: {
          ...observationCoreObject,
          observationValue: { bpm: values.pulse },
        },
      };
    case ObservationType.BloodPressure:
      return {
        ...result,
        pulse: {
          ...observationCoreObject,
          observationValue: { bpm: values.pulse },
        },
        bloodPressure: {
          ...observationCoreObject,
          observationValue: {
            systolic: values.systolic!,
            diastolic: values.diastolic,
          },
        },
      };
    case ObservationType.Weight: {
      return {
        ...result,
        bodyWeight: {
          ...observationCoreObject,
          observationValue: {
            kg: null,
            lb: null,
            ...weightObservationToObservationInput(values),
          },
        },
      };
    }
    case ObservationType.Cholesterol: {
      const { totalMmol, hdlMmol, totalMg, hdlMg } = values;
      const unit = is(Number, totalMmol)
        ? CholesterolUnit.MillimolePerLiter
        : CholesterolUnit.MilligramPerDeciliter;
      return {
        ...result,
        cholesterol: {
          ...observationCoreObject,
          observationValue: {
            total: totalMmol || totalMg,
            hdl: hdlMmol || hdlMg,
            unit,
          },
        },
      };
    }
    case ObservationType.FastingBloodGlucose: {
      const { bloodGlucose } = values;

      return {
        ...result,
        fastingBloodGlucose: {
          ...observationCoreObject,
          observationValue: {
            bloodGlucose,
          },
        },
      };
    }
    default:
      return result;
  }
};
