import { IntlShape } from 'react-intl';
import {
  formatDateAndHourToString,
  formatDateToLocaleDateString,
} from '../../formatters/date';
import { formatObservation } from '../../formatters/observations';
import { GetLastObservationAndBandsQuery } from '../../services/graphql';
import { ObservationView } from '../../types';

export const findRating = (
  rating: string,
  bands: GetLastObservationAndBandsQuery['observationBands']
):
  | GetLastObservationAndBandsQuery['observationBands']['ratings'][number]
  | undefined => bands?.ratings?.find((el) => el.fhirRating === rating);

export const observationsRenderData = ({
  selectedObservation,
  lastObservation,
  bands,
  intl,
}: {
  selectedObservation: ObservationView | undefined;
  lastObservation: ObservationView | undefined;
  bands: GetLastObservationAndBandsQuery['observationBands'];
  intl: IntlShape;
}) => {
  const lastObservationRenderData =
    lastObservation &&
    'rating' in lastObservation.res_observationValue &&
    selectedObservation?.id !== lastObservation.id
      ? {
          rating: findRating(lastObservation.res_observationValue.rating, bands)
            ?.displayName,
        }
      : null;
  if (
    selectedObservation &&
    'rating' in selectedObservation.res_observationValue
  ) {
    const value = selectedObservation.res_observationValue;
    const result = {
      value: formatObservation(value, intl)[0].value.replace(/\s/g, ''),
      date: formatDateToLocaleDateString(
        new Date(selectedObservation.recordedDateTime)
      ),
      time: formatDateAndHourToString(
        new Date(selectedObservation.recordedDateTime)
      ),
      rating: findRating(value.rating, bands)?.displayName,
    };
    return { lastObservationRenderData, selectedObservationRenderData: result };
  }
  return { lastObservationRenderData };
};
