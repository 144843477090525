import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ApolloErrorContext } from './context';
import type { ApolloError } from './types';
import { shouldShowOperationError } from './utils';

export const useApolloError = () => {
  const context = useContext(ApolloErrorContext);
  if (!context) {
    throw new Error(
      'useApolloError must be used within an ApolloErrorProvider'
    );
  }
  return context;
};

export const ApolloErrorProvider = ({ children }: PropsWithChildren) => {
  const [error, setError] = useState<ApolloError | null>(null);
  const [handlers, setHandlers] = useState<((arg0: ApolloError) => void)[]>([]);

  const pushHandler = useCallback(
    (callback: (arg0: ApolloError) => void) => {
      setHandlers(() => [callback, ...handlers]);
    },
    [setHandlers]
  );
  const popHandler = useCallback(() => {
    const [, ...rest] = handlers;
    setHandlers(rest);
  }, []);

  useEffect(() => {
    if (error && handlers.length > 0 && shouldShowOperationError(error)) {
      handlers[0](error);
    }
  }, [error]);
  const contextValue = useMemo(
    () => ({ pushHandler, popHandler, setError }),
    [error, setError]
  );

  return (
    <ApolloErrorContext.Provider value={contextValue}>
      {children}
    </ApolloErrorContext.Provider>
  );
};
