import { Spacer, Stack } from '@bt-healthcare/ui-toolkit';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  DateLabel,
  StyledDivider,
} from '../../pages/HistoricalObservationsList/styles';
import { ObservationView } from '../../types';
import { ObservationListItem } from '../ObservationsListItem';
import { groupSearchResultsByDay } from './utils';
import { ObservationsListProps } from './types';
import { ObservationLoadingCard } from '../Cards/ObservationLoadingCard';

export const ObservationsList = ({
  loadNext,
  data,
  loading,
}: ObservationsListProps) => {
  const { searchResults, hits: totalLength } = data || {};
  const sortedSearchResults = searchResults
    ? groupSearchResultsByDay(searchResults)
    : [];
  const loadedLength = searchResults?.length || 0;
  const hasMore = loadedLength < (totalLength || 0);

  return (
    <InfiniteScroll
      next={loadNext}
      hasMore={hasMore}
      dataLength={loadedLength}
      loader={null}
    >
      {sortedSearchResults.map((item) => {
        const groupKey = Object.keys(item)[0];
        return (
          <Stack id={groupKey} key={groupKey}>
            <Stack id={`${groupKey}-header`}>
              <DateLabel data-testid="date-label">{groupKey}</DateLabel>
              <Spacer size="s2" />
              <StyledDivider />
              <Spacer size="s6" />
            </Stack>
            <Stack id="group-cells" space="s2">
              {item[groupKey].map((view: ObservationView) => (
                <ObservationListItem {...view} key={view.id} />
              ))}
            </Stack>
            <Spacer size="s6" />
          </Stack>
        );
      })}
      {loading && (
        <Stack id="loading-group-cells" space="s2">
          <ObservationLoadingCard />
          <ObservationLoadingCard />
          <ObservationLoadingCard />
        </Stack>
      )}
    </InfiniteScroll>
  );
};
