import styled from 'styled-components';
import {
  device,
  fontSizes,
  spacing,
  fontCalcMixin,
} from '@bt-healthcare/ui-toolkit';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 30px;
  align-items: center;
  height: 100%;
  padding-top: ${spacing.s8};

  @media ${device.desktop} {
    max-width: 465px;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0;
  }
`;

export const Container = styled.div`
  padding: 0 ${spacing.s4};
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;

  @media ${device.desktop} {
    padding: 0 132px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: ${spacing.s6};
  width: 100%;

  @media ${device.mobile} {
    bottom: ${spacing.s10};
    width: 100%;
  }

  @media ${device.tablet} {
    position: initial;
  }

  @media ${device.desktop} {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
  }
`;

export const Header = styled.h1`
  width: 100%;
  font-size: ${fontSizes.h2};
  line-height: 1.3;

  @media ${device.desktop} {
    font-size: ${spacing.s12};
    line-height: ${spacing.s14};
  }
`;

export const HeroContainer = styled.div`
  width: 271px;
  height: 190px;

  @media ${device.tablet} {
    width: 400px;
    height: 280px;
    margin-top: ${spacing.l1};
  }

  @media ${device.desktop} {
    position: absolute;
    left: calc(100% + ${spacing.s9});
    margin-top: 0;
    width: 466px;
    height: 324px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  @media ${device.tablet} {
    width: 329px;
  }

  @media ${device.desktop} {
    width: 215px;
  }
`;

export const LandingPageButton = styled.a`
  cursor: pointer;
`;

export const LandingBackgroundDesktopContainer = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 684px;
  top: 0;
  z-index: -2;

  @media ${device.desktop} {
    display: initial;
  }
`;

export const LandingBackground = styled.div`
  @media ${device.mobile} {
    left: 0;
    height: 200px;
  }
  @media ${device.desktop} {
    height: initial;
    left: 900px;
    bottom: 0;
    background-color: #eaebf6;
  }
  background-color: #e4e3f4;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
`;

export const LandingBackgroundMobileContainer = styled.div`
  display: initial;
  position: absolute;
  z-index: -2;
  top: -50px;
  left: 0;
  right: 0;
  @media ${device.desktop} {
    display: none;
  }
`;

export const SubtitleText = styled.p`
  ${fontCalcMixin(fontSizes.sm)};
  width: 100%;
  margin-top: -${spacing.s4};

  @media ${device.desktop} {
    margin-top: 0;
  }
`;

// TODO - Remove once as soon as the LandingPage page is using the CSS grid system

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${spacing.s4};

  @media ${device.desktop} {
    padding: 0 132px;
  }
`;
