import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  MultipleChoiceButton,
  Notification,
} from '@bt-healthcare/ui-toolkit';
import { object } from 'yup';
import { differenceInYears } from 'date-fns';
import { isEmpty } from 'ramda';

import HealthScorePageWrapper from '../../components/HealthScorePageWrapper';
import { healthScorePersonalInfo } from './intl';
import { RHFDateOfBirthInput } from '../../components/RHFDateOfBirthInput';
import { RHFDropDown } from '../../components/RHFDropDown';
import { data } from './data';
import { dateOfBirthSchema } from '../../components/RHFDateOfBirthInput/schema';
import { healthScoreNavigator } from '../HealthScoreNavigator/intl';
import { PersonalInfoFormData } from './types';
import { useAppDispatch } from '../../context/AppContext';
import { HealthScoreInfoCard } from '../../components/Cards/HealthScoreInfoCard';
import { HealthScorerExplanationCard } from '../../components/Cards/HealthScorerExplanationCard';
import { usePersonalInfoDefaultValues } from './hooks';
import { formatISO8061Date } from '../../formatters/date';

export const HealthScorePersonalInfo = () => {
  const intl = useIntl();
  const fields = data(intl);
  const defaultValues = usePersonalInfoDefaultValues();

  const {
    genderIdentity: genderIdentityData,
    genderAssignedAtBirth: genderAssignedAtBirthData,
  } = fields;
  const dispatch = useAppDispatch();

  const validationSchema = object({
    ...dateOfBirthSchema,
    genderIdentity: genderIdentityData.validationRule,
    genderAssignedAtBirth: genderAssignedAtBirthData.validationRule,
  });

  const methods = useForm<PersonalInfoFormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: { ...defaultValues, ...defaultValues.dob },
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { isValid },
    watch,
    setValue,
  } = methods;

  const [yearOfBirth, monthOfBirth, dayOfBirth, dateOfBirth] = watch([
    'yearOfBirth',
    'monthOfBirth',
    'dayOfBirth',
    'dateOfBirth',
  ]);

  useEffect(() => {
    if (yearOfBirth && monthOfBirth && dayOfBirth) {
      setValue(
        'dateOfBirth',
        formatISO8061Date({
          year: yearOfBirth,
          month: monthOfBirth,
          day: dayOfBirth,
        })
      );
    }
  }, [yearOfBirth, monthOfBirth, dayOfBirth]);

  const age = useMemo(
    () => differenceInYears(new Date(), new Date(dateOfBirth)),
    [dateOfBirth]
  );
  const onSubmit = (values: PersonalInfoFormData) => {
    const {
      genderAssignedAtBirth,
      genderIdentity,
      dateOfBirth: dateOfBirthValue,
    } = values;

    dispatch({
      type: 'healthScoreSetPersonalInformation',
      payload: {
        genderAssignedAtBirth,
        dateOfBirth: dateOfBirthValue,
        genderIdentity: genderIdentity?.value,
      },
    });
  };

  return (
    <HealthScorePageWrapper.Form>
      <FormProvider {...methods}>
        <HealthScorePageWrapper.Content>
          <HealthScorePageWrapper.Header>
            <FormattedMessage {...healthScorePersonalInfo.header} />
          </HealthScorePageWrapper.Header>
          <HealthScorePageWrapper.InputsContainer>
            <HealthScorePageWrapper.InputWithInfo>
              <RHFDateOfBirthInput />
              {!!age && (
                <HealthScoreInfoCard>
                  <FormattedMessage
                    {...healthScorePersonalInfo.age}
                    values={{ age }}
                  />
                </HealthScoreInfoCard>
              )}
            </HealthScorePageWrapper.InputWithInfo>
            <MultipleChoiceButton
              options={genderAssignedAtBirthData.options}
              size="lg"
              label={genderAssignedAtBirthData.label}
              defaultValue={defaultValues.genderAssignedAtBirth}
              {...register('genderAssignedAtBirth')}
              variant="fullWidth"
            />
            <RHFDropDown
              options={genderIdentityData.options}
              label={genderIdentityData.label}
              fieldName="genderIdentity"
              control={control}
            />
            {!isEmpty(defaultValues) && (
              <Notification type="informative">
                <FormattedMessage {...healthScorePersonalInfo.preFilled} />
              </Notification>
            )}
            <HealthScorerExplanationCard>
              <FormattedMessage
                {...healthScorePersonalInfo.genderExplanation}
              />
            </HealthScorerExplanationCard>
          </HealthScorePageWrapper.InputsContainer>
        </HealthScorePageWrapper.Content>
        <Button
          id="health-score-button"
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          <FormattedMessage {...healthScoreNavigator.button} />
        </Button>
      </FormProvider>
    </HealthScorePageWrapper.Form>
  );
};
