import styled from 'styled-components';
import {
  BTHealthIcon,
  Button,
  colors,
  device,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { CSSProperties } from 'react';
import {
  PAGE_PADDING,
  PAGE_PADDING_TABLET_HORIZONTAL,
} from '../PageWithOverlayWrapper/styles';

export const ContentWrapper = styled.div<{
  alignItems?: CSSProperties['alignItems'];
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
`;

export const ButtonWrapper = styled.div`
  margin: ${spacing.s10} 0 ${spacing.s6} 0;
  width: 100%;
  @media ${device.tablet} {
    margin: ${spacing.s14} 0 ${spacing.s6} 0;
  }
`;

export const CloseIcon = styled(BTHealthIcon).attrs({
  icon: 'Close',
  size: 18,
  color: colors.base.white,
})``;

export const CloseIconWrapper = styled.div`
  align-self: flex-end;
  cursor: pointer;
  margin: 0 0 ${spacing.s8} 0;
  @media ${device.tablet} {
    position: fixed;
    top: ${PAGE_PADDING};
    right: ${PAGE_PADDING_TABLET_HORIZONTAL};
  }
  @media ${device.desktop} {
    position: static;
  }
`;

export const Header = styled(Text).attrs({
  color: colors.base.white,
  fontSize: fontSizes.h3,
  fontWeight: 500,
})`
  margin: 0 0 ${spacing.s8} 0;
`;

export const StyledButton = styled(Button).attrs({
  id: 'submit-reading-btn',
  variant: 'primary',
  size: 'maximum',
})``;

export const FullWidthContainer = styled.div`
  width: 100%;
`;
