import { defineMessages } from 'react-intl';

export const recommendationFinishingWithoutSetUp = defineMessages({
  header: {
    id: 'recommendation.finishing-without-set-up-modal.header',
    defaultMessage: 'Do you want to leave without finishing set up?',
  },
  description: {
    id: 'recommendation.finishing-without-set-up-modal.description',
    defaultMessage: 'You can leave and complete this later.',
  },
  primaryButton: {
    id: 'recommendation.finishing-without-set-up-modal.primary-button',
    defaultMessage: 'Continue set up',
  },
  secondaryButton: {
    id: 'recommendation.finishing-without-set-up-modal.secondary-button',
    defaultMessage: 'Finish later',
  },
});
