import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const BenefitsCardWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background: white;
  padding: ${spacing.s5};
  border-radius: ${spacing.s6};
  text-align: start;
`;

export const BenefitsCardsList = styled.ul`
  list-style: none;
  display: flex;
  gap: ${spacing.s6};
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;
