import {
  colors,
  fontCalcMixin,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled, { css } from 'styled-components';

export const TooltipContainer = styled.div<{ isVisible: boolean }>`
  display: inline-flex;
  padding: ${spacing.s4};
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${spacing.s6};
  visibility: hidden;
  position: absolute;
  top: 2rem;
  left: 2rem;
  border-radius: ${spacing.s2};
  background: ${colors.base.white};
  box-shadow: 0px 4px 30px 1px rgba(112, 144, 176, 0.15);
  font-weight: 400;
  ${fontCalcMixin(fontSizes.base)};
  width: 183px;
  padding: ${spacing.s4};
  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: visible;
    `}
`;
