import { defineMessages } from 'react-intl';

export const notificationSettings = defineMessages({
  title: {
    defaultMessage: 'Notification settings',
    id: 'account.notification-settings.title',
  },
  description: {
    defaultMessage: 'Customise how you receive notifications from HealthyYou.',
    id: 'account.notification-settings.description',
  },
  formSectionSmsTile: {
    defaultMessage: 'SMS',
    id: 'account.notification-settings.form-section-sms-title',
  },
  checkboxSmsTitle: {
    defaultMessage: 'SMS',
    id: 'account.notification-settings.checkbox.sms-title',
  },
  checkboxSmsDescription: {
    defaultMessage: 'This includes all text message notifications.',
    id: 'account.notification-settings.form.checkbox.sms-description',
  },
  formSectionEmailTile: {
    defaultMessage: 'Email',
    id: 'account.notification-settings.form-section-email-title',
  },
  checkboxRemindersTitle: {
    id: 'notification-email-preferences.form.checkbox.reminders-title',
    defaultMessage: 'Reminders',
  },
  checkboxRemindersDescription: {
    id: 'notification-email-preferences.form.checkbox.reminders-description',
    defaultMessage:
      'This includes personalised reminders for appointments and to take readings.',
  },
  checkboxRecommendationsTitle: {
    id: 'notification-email-preferences.form.checkbox.recommendations-title',
    defaultMessage: 'Advice and recommendations',
  },
  checkboxRecommendationsDescription: {
    id: 'notification-email-preferences.form.checkbox.recommendations-description',
    defaultMessage:
      'Personalised information relating to your conditions and/or health.',
  },
  checkboxUpdatesTitle: {
    id: 'notification-email-preferences.form.checkbox.updates-title',
    defaultMessage: 'Latest news and updates',
  },
  checkboxUpdatesDescription: {
    id: 'notification-email-preferences.form.checkbox.updates-description',
    defaultMessage: 'Updates relating to HealthyYou.',
  },
});
