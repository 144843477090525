import { EnvTypes } from '@bt-healthcare/ui-toolkit';
import * as Sentry from '@sentry/react';
import { getEnvVariable, isLive } from '../env.utils';

const needsMask = (ENV: EnvTypes) => ENV === 'staging' || ENV === 'live';

export enum SentryTags {
  USER_ID = 'aimUserId',
}

const fieldsToObfuscate = ['password'];

export const obfuscateExceptionMessage = (message: string) => {
  let messageObfuscated = message;
  fieldsToObfuscate.forEach((fieldName) => {
    const regex = new RegExp(`${fieldName}: "[^"]*"`, 'g');

    messageObfuscated = message.replaceAll(
      regex,
      `${fieldName}: "<obfuscated value>"`
    );
  });

  return messageObfuscated;
};

export const beforeSend = (event: {
  exception?: { values?: { value?: string }[] };
}) => {
  const exception = event.exception?.values?.[0];

  if (exception?.value) {
    exception.value = obfuscateExceptionMessage(exception.value);
  }

  return event;
};

export const initializeSentry = () => {
  const environment = getEnvVariable('VITE_ENV') as EnvTypes;
  Sentry.init({
    dsn: getEnvVariable('VITE_SENTRY_DSN'),
    environment,
    integrations: [
      new Sentry.Replay({
        maskAllText: needsMask(getEnvVariable('VITE_ENV') as EnvTypes),
        blockAllMedia: needsMask(getEnvVariable('VITE_ENV') as EnvTypes),
      }),
    ],
    tracesSampleRate: 0, // no navigation transactions for hash router
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: isLive() ? 0.1 : 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: isLive() ? 1.0 : 0,
    beforeSend,
  });
};
