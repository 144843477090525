import styled from 'styled-components';
import { Grid, spacing } from '@bt-healthcare/ui-toolkit';

export const StyledGrid = styled(Grid)`
  gap: ${spacing.s4};
`;

export const HeaderContainer = styled.div`
  margin-bottom: ${spacing.s8};
`;
