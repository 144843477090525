import { defineMessages } from 'react-intl';

export const submissionFailed = defineMessages({
  header: {
    id: 'create-observation-confirm.submission-failed-modal.header',
    defaultMessage: 'Submission failed',
    description:
      'Submission failed modal header on Create Observation Confirm page',
  },
  description: {
    id: 'create-observation-confirm.submission-failed-modal.description',
    defaultMessage:
      'We’re sorry, there’s been an issue with submitting your reading. Please try again.',
    description:
      'Submission failed modal description on Create Observation Confirm page',
  },
  tryAgainButton: {
    id: 'create-observation-confirm.submission-failed-modal.try-again-button',
    defaultMessage: 'Try again',
    description:
      'Submission failed modal try again button on Create Observation Confirm page',
  },
  cancelButton: {
    id: 'create-observation-confirm.submission-failed-modal.cancel-button',
    defaultMessage: 'Cancel',
    description:
      'Submission failed modal cancel button on Create Observation Confirm page',
  },
});
