import { device, Link, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const FaqWrapper = styled.div`
  display: grid;
  gap: ${spacing.s6};
  @media ${device.tablet} {
    gap: ${spacing.s8};
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 400;
`;
