import { defineMessages } from 'react-intl';

import type { WhatToDoNext } from './types';

export const whatToDoNext: WhatToDoNext = {
  carousel: defineMessages({
    title: {
      defaultMessage: 'Here’s what to do next',
      id: 'what-to-do-next.carousel.title',
    },
    description: {
      defaultMessage: '',
      id: 'what-to-do-next.carousel.description',
    },
  }),
  slideOne: defineMessages({
    title: {
      defaultMessage: 'Log in to the HealthyYou app',
      id: 'what-to-do-next.slide-one.title',
    },
    description: {
      defaultMessage:
        'If you’ve not confirmed your account, check your email for your invitation.',
      id: 'what-to-do-next.slide-one.description',
    },
  }),
  slideTwo: defineMessages({
    title: {
      defaultMessage:
        'Upload readings add a minimum of 8 blood pressure readings',
      id: 'what-to-do-next.slide-two.title',
    },
    description: {
      defaultMessage:
        'Either 1 reading per day for 8 days, or 2 per day over 4 days.',
      id: 'what-to-do-next.slide-two.description',
    },
  }),
  slideThree: defineMessages({
    title: {
      defaultMessage:
        'The remote care team will review your health vitals and lifestyle',
      id: 'what-to-do-next.slide-three.title',
    },
    description: {
      defaultMessage:
        'Then one of our remote clinicians will reach out to discuss next steps.',
      id: 'what-to-do-next.slide-three.description',
    },
  }),
};
