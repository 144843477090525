import { defineMessage, defineMessages } from 'react-intl';
import { HealthInformationType } from './types';
import { Messages } from '../../i18n/messages/types';
import {
  CvdFamilyInformation,
  DiabetesStatus,
  HeightUnit,
  SmokingStatus,
} from '../../services/graphql';

export const headers: Messages = defineMessages({
  [HealthInformationType.HEIGHT]: {
    id: 'health-information.height.header',
    defaultMessage: 'Your height',
  },
  [HealthInformationType.SMOKING]: {
    id: 'health-information.smoking.header',
    defaultMessage: 'Smoking status',
  },
  [HealthInformationType.BLOOD_PRESSURE_MEDICATION]: {
    id: 'health-information.blood-pressure-medication.header',
    defaultMessage: 'Blood pressure medication',
  },
  [HealthInformationType.CHRONIC_KIDNEY_DISEASE]: {
    id: 'health-information.chronic-kidney-disease.header',
    defaultMessage: 'Chronic kidney disease status',
  },
  [HealthInformationType.CARDIOVASCULAR_DISEASE]: {
    id: 'health-information.cardiovascular-disease.header',
    defaultMessage: 'Cardiovascular disease',
  },
  [HealthInformationType.DIABETES]: {
    id: 'health-information.diabetes.header',
    defaultMessage: 'Diabetes status',
  },
});

export const subheaders: Messages = defineMessages({
  [HealthInformationType.CHRONIC_KIDNEY_DISEASE]: {
    id: 'health-information.chronic-kidney-disease.sub-header',
    defaultMessage:
      'This condition affects how well your kidneys work. It may affect your heart health.',
  },
  [HealthInformationType.CARDIOVASCULAR_DISEASE]: {
    id: 'health-information.cardiovascular-disease.sub-header',
    defaultMessage:
      'The 3 types of cardiovascular disease (disease of the heart or the blood vessels) are:',
  },
});

export const errorsHealthInformation: Messages = defineMessages({
  mutation: {
    id: 'health-information.error.mutation',
    defaultMessage: 'Failed to submit. <b>Please try again.</b>',
  },
});

export const heightLabel = defineMessage({
  id: 'health-information.height.label',
  defaultMessage: 'Height',
});

export const unitsToggleDisplayName: Messages = defineMessages({
  [HeightUnit.Cm]: {
    id: 'health-information.height.toggle.cm',
    defaultMessage: 'cm',
  },
  [HeightUnit.Inch]: {
    id: 'health-information.height.toggle.in',
    defaultMessage: 'ft / inches',
  },
});

export const heightFieldsLabelUnits: Messages = defineMessages({
  cm: {
    id: 'health-information.height.cm.label',
    defaultMessage: 'cm',
  },
  inch: {
    id: 'health-information.height.in.label',
    defaultMessage: 'inches',
  },
  ft: {
    id: 'health-information.height.ft.label',
    defaultMessage: 'feet',
  },
});

export const errorMessages = defineMessages({
  inLowestFt: {
    id: 'health-information.height.in.error.ft.lowest',
    defaultMessage: `Cannot be less than 4 feet 4 inches`,
  },
  inHighestFt: {
    id: 'health-information.height.in.error.ft.highest',
    defaultMessage: `Cannot be more than 7 feet 2 inches`,
  },
});

export const smokingStatusLabel = defineMessage({
  id: 'health-information.smoking.label',
  defaultMessage: 'Do you smoke?',
});

export const smokingStatusOptions: Messages = defineMessages({
  [SmokingStatus.PreferNotSay]: {
    id: 'health-information.smoking.prefer-not-say',
    defaultMessage: 'Prefer not to say',
  },
  [SmokingStatus.NoNeverSmoked]: {
    id: 'health-information.smoking.no-never-smoked',
    defaultMessage: 'No, I’ve never smoked',
  },
  [SmokingStatus.NoQuit]: {
    id: 'health-information.smoking.no-quit',
    defaultMessage: 'No, I quit',
  },
  [SmokingStatus.YesLessThan_10ADay]: {
    id: 'health-information.smoking.less-than-10',
    defaultMessage: 'Yes, I smoke less than 10 a day',
  },
  [SmokingStatus.YesLessThan_20ADay]: {
    id: 'health-information.smoking.less-than-20',
    defaultMessage: 'Yes, I smoke less than 20 a day',
  },
  [SmokingStatus.YesMoreThan_20ADay]: {
    id: 'health-information.smoking.more-than-20',
    defaultMessage: 'Yes, I smoke 20+ a day',
  },
});

export const bloodPressureMedicationLabels = defineMessages({
  hasBPMedication: {
    id: 'health-information.blood-pressure-medication.has.label',
    defaultMessage: 'Are you currently on medication for your blood pressure?',
  },
  hadBPMedication: {
    id: 'health-information.blood-pressure-medication.had.label',
    defaultMessage: 'Have you ever taken medication for your blood pressure? ',
  },
  hadBPMedicationDisabled: {
    id: 'health-information.blood-pressure-medication.had.disabled-message',
    defaultMessage:
      'This is automatically selected due to your previous answer.',
  },
});

export const yesNo = defineMessages({
  yes: {
    id: 'health-information.blood-pressure-medication.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'health-information.blood-pressure-medication.no',
    defaultMessage: 'No',
  },
});

export const submitButton = defineMessage({
  id: 'health-information.submit-button',
  defaultMessage: 'Confirm',
});

export const ckdLabel = defineMessage({
  id: 'health-information.chronic-kidney-disease.disabled-message',
  defaultMessage: 'Have you been diagnosed with chronic kidney disease?',
});

export const cvdLabels = defineMessages({
  hasCvd: {
    id: 'health-information.cardiovascular-disease.has-cvd',
    defaultMessage: 'Do you have cardiovascular disease?',
  },
  familyHadCvd: {
    id: 'health-information.cardiovascular-disease.family-had-cvd',
    defaultMessage:
      'Was your mother, father, sister or brother diagnosed with cardiovascular disease before the age of 60? ',
  },
});

export const cvdBulletPoints = defineMessages({
  stroke: {
    id: 'health-information.cardiovascular-disease.bullet-point.stroke',
    defaultMessage: 'Stroke',
  },
  coronaryHeartDiseaseTitle: {
    id: 'health-information.cardiovascular-disease.bullet-point.coronary-heart-disease.title',
    defaultMessage: 'Coronary heart disease',
  },
  coronaryHeartDiseaseExplanation: {
    id: 'health-information.cardiovascular-disease.bullet-point.coronary-heart-disease.explanation',
    defaultMessage: 'This can cause angina or heart attacks',
  },
  peripheralArterialDiseaseTitle: {
    id: 'health-information.cardiovascular-disease.bullet-point.peripheral-arterial-disease.title',
    defaultMessage: 'Peripheral arterial disease',
  },
  peripheralArterialDiseaseExplanation: {
    id: 'health-information.cardiovascular-disease.bullet-point.peripheral-arterial-disease.explanation',
    defaultMessage: 'Also know as  peripheral vascular disease',
  },
});

export const familyHadCvdOptions: Messages = defineMessages({
  [CvdFamilyInformation.PreferNotToSay]: {
    id: 'health-information.cardiovascular-disease.family-had-cvd.options.prefer-not-say',
    defaultMessage: 'Prefer not to say',
  },
  [CvdFamilyInformation.Yes]: {
    id: 'health-information.cardiovascular-disease.family-had-cvd.options.yes',
    defaultMessage: 'Yes',
  },
  [CvdFamilyInformation.No]: {
    id: 'health-information.cardiovascular-disease.family-had-cvd.options.no',
    defaultMessage: 'No',
  },
  [CvdFamilyInformation.DontKnow]: {
    id: 'health-information.cardiovascular-disease.family-had-cvd.options.dont-know',
    defaultMessage: "I don't know",
  },
});

export const diabetesLabel = defineMessage({
  id: 'health-information.diabetes.status.label',
  defaultMessage: 'Have you been diagnosed with diabetes?',
});

export const diabetesStatusOptions: Messages = defineMessages({
  [DiabetesStatus.PreferNotToSay]: {
    id: 'health-information.diabetes.status.options.prefer-not-say',
    defaultMessage: 'Prefer not to say',
  },
  [DiabetesStatus.No]: {
    id: 'health-information.diabetes.status.options.no',
    defaultMessage: 'No',
  },
  [DiabetesStatus.Type_1]: {
    id: 'health-information.diabetes.status.options.type-1',
    defaultMessage: 'Type 1',
  },
  [DiabetesStatus.Type_2]: {
    id: 'health-information.diabetes.status.options.type-2',
    defaultMessage: 'Type 2',
  },
});
