import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Button, Stack } from '@bt-healthcare/ui-toolkit';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as LogoOnScroll } from '../../assets/purpleLogo.svg';

import { Nav, PublicLayoutNavbarWrapper, NavContent } from './styles';
import { NavbarProps } from './types';

export const PublicLayoutNavbar = () => (
  <Stack id="public-layout-navbar">
    <PublicLayoutNavbarWrapper>
      <Link to="/">
        <Logo />
      </Link>
    </PublicLayoutNavbarWrapper>
  </Stack>
);

export const Navbar = ({ isWithLogin }: NavbarProps) => {
  const [scrolled, setScrolled] = useState(false);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Nav isScrolled={scrolled}>
      <NavContent>
        <Link to="/">{scrolled ? <LogoOnScroll /> : <Logo />} </Link>
        {isWithLogin && (
          <div>
            <Button
              id=""
              variant="secondary"
              onClick={() => loginWithRedirect()}
              transparent
            >
              Login
            </Button>
          </div>
        )}
      </NavContent>
    </Nav>
  );
};
