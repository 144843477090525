import { defineMessage, defineMessages } from 'react-intl';

export const recommendationConfirmYourPlan = {
  header: defineMessage({
    id: 'recommendation.confirm-your-plan.header',
    defaultMessage: 'Confirm your plan',
  }),
  radioButtonGroup: {
    description: defineMessage({
      id: 'recommendation.confirm-your-plan.radio-button-group.description',
      defaultMessage: 'Take a minimum of 8 blood pressure readings over:',
    }),
    options: defineMessages({
      label: {
        id: 'recommendation.confirm-your-plan.radio-button-group.label',
        defaultMessage: '{days, plural, =1 {1 day} other {# consecutive days}}',
      },
      helper: {
        id: 'recommendation.confirm-your-plan.radio-button-group.helper',
        defaultMessage:
          '{readings, plural, =1 {1 reading per day} other {# readings per day}}',
      },
    }),
  },
  confirm: defineMessage({
    id: 'recommendation.confirm-your-plan.continue',
    defaultMessage: 'Confirm',
  }),
};
