export const pathNameToSiteTitle = (pathName: string) =>
  pathName
    .split('-')
    .map((s) => s[0].toUpperCase() + s.slice(1))
    .join(' ');

export const uppercaseToPathName = (string: string) =>
  string.toLowerCase().split('_').join('-');

export const pathNameToUppercase = (pathName: string) =>
  pathName.toUpperCase().split('-').join('_');
