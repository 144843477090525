import { Button, ErrorMessage } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const Discharged = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <ErrorMessage
      title={intl.formatMessage({
        id: 'no-ward-patient.title',
        defaultMessage:
          'We have logged you out of your account as you have completed your remote monitoring',
      })}
      message={intl.formatMessage({
        id: 'no-ward-patient.message',
        defaultMessage:
          'If you believe this is a mistake, or for further information, please get in touch with your care team.',
      })}
    >
      <Button
        id="no-ward-patient.button"
        variant="primary"
        onClick={() => {
          navigate('/');
        }}
      >
        <FormattedMessage
          id="no-ward-patient.button.understand"
          defaultMessage={intl.formatMessage({
            id: 'no-ward-patient.ok',
            defaultMessage: 'OK, I understand',
          })}
        />
      </Button>
    </ErrorMessage>
  );
};
