import { timeDay, timeHours } from 'd3-time';
import { differenceInDays, endOfDay, isSameDay, sub } from 'date-fns';
import { DateFilter, type DateRange, ObservationData } from './types';
import { type GetObservationDataPointsQuery } from '../../services/graphql';

export const getTickValues = (dateRange: DateRange, dateFilter: DateFilter) => {
  const { startDate } = dateRange;
  const endDate = endOfDay(dateRange.endDate);

  const noOfDays = differenceInDays(endDate, startDate);
  const maxLabels = 7;
  const interval = Math.ceil(noOfDays / maxLabels);

  if (dateFilter === DateFilter.TODAY) {
    const todayRange = timeHours(startDate, endDate, 12);
    const thirdValue = sub(todayRange[2], { minutes: 1 });
    return todayRange.map((item, index) => (index === 2 ? thirdValue : item));
  }

  return timeDay
    .range(startDate, endDate)
    .filter(
      (d, i) =>
        i % interval === 0 || isSameDay(d, startDate) || isSameDay(d, endDate)
    );
};

export const observationToGraphData = (
  dataPoint: GetObservationDataPointsQuery['observationDataPoints']['searchResults'][number]
): ObservationData => ({
  date: dataPoint.recordedDateTime,
  series: [...dataPoint.res_componentValues].sort((a, b) => b.value - a.value),
});
