import { useEffect, useMemo, useState } from 'react';
import { localPoint } from '@visx/event';
import { useTooltip } from '@visx/tooltip';

import {
  DateFilter,
  ObservationData,
  VisxTooltipEvent,
} from '../ObservationGraph/types';
import { DataPointType, UseGraphTooltipTypes } from './types';
import {
  LINE_CHART_HEIGHT,
  MARGIN,
  TOOLTIP_LEFT_OFFSET,
  TOOLTIP_TOP_OFFSET,
} from '../ObservationGraph/config';
import { ObservationComponentName } from '../../services/graphql';

const tooltipHeightMap = {
  [DataPointType.SINGLE_OBSERVATION]: 38,
  [DataPointType.DAILY_AVERAGE]: 58,
};

export const useGraphTooltip = ({
  dateFilter,
  yScale,
}: UseGraphTooltipTypes) => {
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [pointData, setPointData] = useState<ObservationData>();
  const {
    tooltipOpen,
    showTooltip,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    hideTooltip,
  } = useTooltip<ObservationData>();

  const dataPointType =
    dateFilter === DateFilter.MONTHLY
      ? DataPointType.DAILY_AVERAGE
      : DataPointType.SINGLE_OBSERVATION;

  useEffect(() => {
    if (!pointData) {
      hideTooltip();
      return;
    }
    const systolicItem = pointData.series.find(
      (item) => item.name === ObservationComponentName.SystolicBloodPressure
    );
    const adjustedY = systolicItem
      ? yScale(systolicItem.value)
      : tooltipPosition.y;
    showTooltip({
      tooltipLeft: tooltipPosition.x,
      tooltipTop: adjustedY,
      tooltipData: pointData,
    });
  }, [pointData, tooltipOpen]);

  const tooltipOffsetTop = useMemo(() => {
    const tooltipHeight = tooltipHeightMap[dataPointType];
    const isBottomOfTooltipAboveGraph =
      tooltipTop + TOOLTIP_TOP_OFFSET + tooltipHeight < 0;
    return isBottomOfTooltipAboveGraph
      ? -tooltipTop - tooltipHeight
      : TOOLTIP_TOP_OFFSET;
  }, [dataPointType, tooltipOpen]);

  const handleTooltipPosition = (event: VisxTooltipEvent) => {
    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    const adjustedY = y;
    let adjustedX = x - TOOLTIP_LEFT_OFFSET;
    if (adjustedX < 0) adjustedX = 0;
    setTooltipPosition({ x: adjustedX, y: adjustedY });
  };

  const tooltipLineFrom = {
    x: tooltipLeft - MARGIN.right,
    y: tooltipTop + TOOLTIP_TOP_OFFSET,
  };

  const tooltipLineTo = {
    x: tooltipLeft - MARGIN.right,
    y: LINE_CHART_HEIGHT,
  };

  return {
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOffsetTop,
    dataPointType,
    tooltipLineFrom,
    tooltipLineTo,
    handleTooltipPosition,
    setPointData,
  };
};
