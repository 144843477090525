import styled from 'styled-components';
import {
  BTHealthIcon,
  colors,
  fontSizes,
  spacing,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { rgbaFromHexAndOpacity } from '../../utils/css';

export const BulletListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 ${spacing.s4} 0;
  width: 100%;
`;

export const RecommendationListTitle = styled(Text).attrs({
  color: colors.base.white,
  fontWeight: 500,
})``;

export const RecommendationListDate = styled(Text).attrs({
  fontSize: fontSizes.sm,
  color: rgbaFromHexAndOpacity(colors.base.white, 0.7),
})``;

export const LeftItem = styled.div`
  display: flex;
  gap: ${spacing.s3};
`;

export const CardValue = styled(Text).attrs({
  fontWeight: 500,
  color: colors.grey.grey10,
})``;
export const CardWrapper = styled.div`
  width: 100%;
  margin: 0 0 ${spacing.s2} 0;
`;

export const LinkCardWrapper = styled.div`
  width: 100%;
  margin: 0 0 ${spacing.s10} 0;
`;

export const CardDescription = styled(Text).attrs({
  fontSize: fontSizes.sm,
  color: colors.grey.grey08,
})``;

export const BrightIcon = styled(BTHealthIcon).attrs({
  size: 18,
  color: colors.base.white,
  icon: 'Bright',
})``;

export const ArrowIcon = styled(BTHealthIcon).attrs({
  size: 24,
  color: colors.base.white,
  icon: 'ArrowFat',
})``;

export const WarningIcon = styled(BTHealthIcon).attrs({
  size: 24,
  color: colors.base.white,
  icon: 'WarningFull',
})``;
