import { IntlShape } from 'react-intl';
import { object, string } from 'yup';
import { SmokingDummy } from './types';
import { sortArrayByGivenOrder } from '../../utils/array.utils';
import { healthScoreSmoking } from './intl';

const smokingOrder = [
  SmokingDummy.NEVER_SMOKED,
  SmokingDummy.QUIT_OVER_20,
  SmokingDummy.QUIT_OVER_15,
  SmokingDummy.QUIT_OVER_10,
  SmokingDummy.QUIT_OVER_5,
  SmokingDummy.QUIT_LESS_5,
  SmokingDummy.YES,
];

const smoking = (intl: IntlShape) => ({
  name: 'smoking',
  validationRule: object()
    .shape({
      value: string().oneOf(Object.values(SmokingDummy)).required(),
      label: string(),
    })
    .required(),

  options: sortArrayByGivenOrder(Object.values(SmokingDummy), smokingOrder).map(
    (value) => ({
      value,
      label: intl.formatMessage(healthScoreSmoking.smokingOptions[value]),
    })
  ),
});
export const data = (intl: IntlShape) => ({
  smoking: smoking(intl),
});
