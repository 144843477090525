import { Paragraph } from '@bt-healthcare/ui-toolkit';
import Markdown from 'markdown-to-jsx';
import { useEffect, useState } from 'react';

import {
  H4,
  H3,
  Table,
  Td,
  Th,
  Anchor,
  Ol,
  Li,
  CookiePreferencesLink,
} from './styles';

export const PolicyContent = ({ url }: { url: string }) => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
      });
  }, [setMarkdown, url]);

  return (
    <Markdown
      options={{
        overrides: {
          p: { component: Paragraph },
          h3: {
            component: H3,
          },
          h4: { component: H4 },
          table: { component: Table },
          td: { component: Td },
          th: { component: Th },
          a: { component: Anchor },
          ol: { component: Ol },
          li: { component: Li },
          CookiePreferencesLink: {
            component: CookiePreferencesLink,
            props: { className: 'cky-banner-element' },
          },
        },
      }}
    >
      {markdown}
    </Markdown>
  );
};
