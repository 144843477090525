import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';
import { AppContext, AppDispatchContext } from './AppContext';
import type { State } from '../reducers/types';
import { appReducer, initialAppState } from '../reducers/appReducer';

export const AppProvider = ({
  children,
  value = initialAppState,
}: PropsWithChildren<{ value?: State }>) => {
  const [state, dispatch] = useReducer(appReducer, value);
  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};
