import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { colors } from '@bt-healthcare/ui-toolkit';
import BaseModal from '../BaseModal';
import { recommendationFinishingWithoutSetUp } from './intl';
import { ROUTE } from '../../../config/routes';
import { RecommendationFinishingWithoutSetModalProps } from './types';
import { rgbaFromHexAndOpacity } from '../../../utils/css';
import { useTracking } from '../../../hooks/useTracking';

export const RecommendationExitConfirmationModal = ({
  onCancel: cancel,
  isModalOpen,
}: RecommendationFinishingWithoutSetModalProps) => {
  const navigate = useNavigate();
  const { trackTapped } = useTracking();

  const onCancel = () => {
    cancel();
    trackTapped('Cancelling ABP how it works - cancel');
  };

  const onFinishRecommendation = () => {
    navigate(ROUTE.HOME);
    trackTapped('Cancelling ABP how it works - continue');
  };

  return (
    <BaseModal
      isModalOpen={isModalOpen}
      onCancel={onCancel}
      id="recommendation-finishing-without-set-modal"
      overlayColor={rgbaFromHexAndOpacity(colors.grey.grey10, 0.8).toString()}
    >
      <BaseModal.Header>
        <FormattedMessage {...recommendationFinishingWithoutSetUp.header} />
      </BaseModal.Header>
      <BaseModal.Description>
        <FormattedMessage
          {...recommendationFinishingWithoutSetUp.description}
        />
      </BaseModal.Description>
      <BaseModal.ButtonsWrapper>
        <BaseModal.PrimaryButton
          id="recommendation-finishing-without-set-modal.primary-button"
          onClick={onCancel}
        >
          <FormattedMessage
            {...recommendationFinishingWithoutSetUp.primaryButton}
          />
        </BaseModal.PrimaryButton>
        <BaseModal.SecondaryButton
          onClick={onFinishRecommendation}
          id="recommendation-finishing-without-set-modal.secondary-button"
        >
          <FormattedMessage
            {...recommendationFinishingWithoutSetUp.secondaryButton}
          />
        </BaseModal.SecondaryButton>
      </BaseModal.ButtonsWrapper>
    </BaseModal>
  );
};
