import { SpinnerLoader } from '@bt-healthcare/ui-toolkit';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { RegistrationStatus } from '../../services/graphql';
import { STEP_UP_NOT_VERIFIED } from '../../App.constants';

export const UserVerifiedRoute = ({
  redirectPath,
}: {
  redirectPath: string;
}) => {
  const { data, loading } = useAuthUserProfile();
  const stepUpNotVerified = localStorage.getItem(
    STEP_UP_NOT_VERIFIED
  ) as string;

  if (loading) {
    return (
      <SpinnerLoader
        id="user-verified-route"
        data-testid="user-verified-route"
      />
    );
  }

  if (
    data?.userPatientProfileResults.user.attributes.registrationStatus ===
      RegistrationStatus.Verified &&
    !stepUpNotVerified
  ) {
    return <Outlet />;
  }

  return <Navigate to={redirectPath} />;
};
