import { useFlags } from 'flagsmith/react';

import { FeatureFlags, Flags } from './types';

type FlagKey = keyof typeof FeatureFlags;

export const useFeatureFlags = (): Record<FlagKey, boolean> => {
  const flags: Flags = useFlags([...Object.values(FeatureFlags)]);
  return Object.fromEntries(
    Object.entries(FeatureFlags).map(([key, flag]) => [
      key,
      flags[flag]?.enabled ?? false,
    ])
  ) as Record<FlagKey, boolean>;
};
