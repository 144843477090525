import {
  Stack,
  SubHeader,
  BTHealthIcon,
  colors,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { HeroContainer, PlusContainer, StyledCard } from './styles';

// Used png as svg is 10x heavier
import Hero from '../../../assets/take-reading.png';

export const TakeReadingCard = () => (
  <StyledCard data-testid="add-reading-app-card">
    <Stack id="add-reading" space="s4" flexDirection="row" alignItems="center">
      <SubHeader>
        <FormattedMessage
          id="home.add-reading.card"
          defaultMessage="Add reading"
        />
      </SubHeader>
      <PlusContainer>
        <BTHealthIcon icon="Add" size={16} color={colors.base.dark} />
      </PlusContainer>
    </Stack>
    <HeroContainer data-testid="add-reading-app-card.illustration">
      <img src={Hero} alt="hero" height="100%" />
    </HeroContainer>
  </StyledCard>
);
