import {
  Stack,
  Notification,
  Col,
  Text,
  colors,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';

import { Button } from './styles';

export const ErrorNotification = ({ retry }: { retry: () => void }) => (
  <Col colEnd={{ xxs: 'span 4', lg: 'span 6' }} key="error">
    <Notification type="softWarning">
      <Button data-testid="completed-tasks.error.retry" onClick={() => retry()}>
        <Stack space="s1" id="stack" flexDirection="column">
          <FormattedMessage
            id="completed-tasks.error.message"
            defaultMessage="Failed to load completed tasks"
          />
          <Text color={colors.grey.grey10} fontWeight={500}>
            <FormattedMessage
              id="completed-tasks.error.retry"
              defaultMessage="Press here to retry"
            />
          </Text>
        </Stack>
      </Button>
    </Notification>
  </Col>
);
