import styled from 'styled-components';

import {
  Col,
  Text,
  colors,
  device,
  fontCalcMixin,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

export const GradientBackground = styled.div`
  height: calc(20vh + 16px);
  width: 100%;
  background-image: linear-gradient(to top, ${colors.base.light}, transparent);
  bottom: 0;
  position: sticky;
`;

export const Form = styled.form`
  width: 100%;
`;

export const HeroContainer = styled.div`
  display: flex;
`;

export const HeaderText = styled(Text).attrs({
  color: colors.grey.grey10,
  fontWeight: 500,
})`
  ${fontCalcMixin(fontSizes.h3)}
  text-align: center;

  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.h2)}
  }
`;

export const DescriptionText = styled(Text).attrs({
  color: colors.grey.grey09,
})`
  ${fontCalcMixin(fontSizes.base)}
`;

export const NoteText = styled(Text).attrs({
  color: colors.grey.grey07,
})`
  ${fontCalcMixin(fontSizes.sm)}
`;

export const CustomCol = styled(Col)`
  margin-left: 0px;

  @media ${device.desktop} {
    margin-left: 70px;
  }
`;
