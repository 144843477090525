import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${device.tablet} {
    width: 412px;
  }
`;

export const HeroContainer = styled.div`
  margin-top: ${spacing.s6};
  width: 153px;
  height: 180px;

  @media ${device.tablet} {
    width: 272px;
    height: 320px;
  }
`;

export const CtaContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: ${spacing.s4};

  @media ${device.tablet} {
    position: initial;
    margin: 0;
  }
`;
