import { FormattedMessage } from 'react-intl';

import {
  Col,
  Stack,
  Grid,
  GRID_BREAKPOINT,
  Header,
  Spacer,
} from '@bt-healthcare/ui-toolkit';

import type { PolicyPageProps } from './types';
import { title } from './translations';
import { PolicyContent } from './PolicyContent';
import { Hero } from './Hero';
import { markdownURL } from './utils';
import { HeroContainer } from './styles';

export const Policy = ({ type }: PolicyPageProps) => (
  <Stack space="none" id="public-policy">
    <Spacer size="s17" />
    <Grid>
      <Col col="1 / -1" justifyContent="center">
        <HeroContainer>
          <Stack id={`${type}-header`} space="s4" alignItems="center">
            <Hero type={type} />
            <Spacer size="s8" />
            <Header>
              <FormattedMessage {...title[type]} />
            </Header>
          </Stack>
        </HeroContainer>
      </Col>
      <Col
        col={{
          [GRID_BREAKPOINT.small]: '1 / 5',
          [GRID_BREAKPOINT.medium]: '1 / 9',
          [GRID_BREAKPOINT.large]: '3 / 11',
        }}
      >
        <PolicyContent url={markdownURL(type)} />
      </Col>
    </Grid>
  </Stack>
);
