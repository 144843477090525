import { defineMessage, defineMessages } from 'react-intl';
import { BodyWeightUnitInput, CholesterolUnit } from '../../services/graphql';
import { Messages } from '../../i18n/messages/types';

export const unitsDisplayName: Messages = defineMessages({
  [CholesterolUnit.MillimolePerLiter]: {
    id: 'take-observation.toggle.cholesterol.mmol',
    defaultMessage: 'mmol/L',
  },
  [CholesterolUnit.MilligramPerDeciliter]: {
    id: 'take-observation.toggle.cholesterol.mg',
    defaultMessage: 'mg/dL',
  },
  [BodyWeightUnitInput.Kg]: {
    id: 'take-observation.toggle.weight.metric',
    defaultMessage: 'kg',
  },
  [BodyWeightUnitInput.Lb]: {
    id: 'take-observation.toggle.weight.imperial',
    defaultMessage: 'stone / lbs',
  },
});

export const tooltipMessages = defineMessages({
  totalCholesterol: {
    id: 'take-observation.cholesterol.total.tooltip',
    defaultMessage:
      'The overall amount of cholesterol in your blood. This includes both your "good" and "bad" cholesterol.',
  },
  hdlCholesterol: {
    id: 'take-observation.cholesterol.hdl.tooltip',
    defaultMessage:
      'This is your good cholesterol. This may make you less likely to have heart problems or a stroke.',
  },
});

export const unitFieldLabel = defineMessages({
  percent: {
    id: 'take-observation.spo2.label.unit',
    defaultMessage: '%',
  },
  bpm: {
    id: 'take-observation.bpm.label.unit',
    defaultMessage: 'bpm',
  },
  mmhg: {
    id: 'take-observation.mmhg.label.unit',
    defaultMessage: 'mmHg',
  },
  mmoll: {
    id: 'take-observation.mmolperliter.label.unit',
    defaultMessage: 'mmol/L',
  },
  mgdl: {
    id: 'take-observation.mgperdeciliter.label.unit',
    defaultMessage: 'mg/dL',
  },
  kg: {
    id: 'take-observation.kg.label.unit',
    defaultMessage: 'kg',
  },
  lb: {
    id: 'take-observation.lb.label.unit',
    defaultMessage: 'lb',
  },
  st: {
    id: 'take-observation.st.label.unit',
    defaultMessage: 'st',
  },
});

export const spo2Messages = defineMessages({
  header: {
    id: 'take-observation.spo2.header',
    defaultMessage: 'SpO2 reading',
    description: 'SpO2 new observation header',
  },
  subheader: {
    id: 'take-observation.spo2.subtitle',
    defaultMessage:
      'Please turn on your device, and place it on your index finger.<br></br><br></br>Give the device 15 seconds to stabilise with a reading and input the values below:',
    description: 'SpO2 new observation subheader',
  },
  label: {
    id: 'take-observation.spo2.field-label',
    defaultMessage: 'SpO2',
    description: 'SpO2 new observation input label',
  },
});

export const pulseMessages = defineMessages({
  header: {
    id: 'take-observation.pulse.header',
    defaultMessage: 'Pulse reading',
    description: 'Pulse new observation header',
  },
  subheader: {
    id: 'take-observation.pulse.subtitle',
    defaultMessage: `Please turn on your device, and place it on your index finger.<br></br><br></br>Give the device 15 seconds to stabilise with a reading and input the values below:`,
    description: 'Pulse new observation subheader',
  },
  label: {
    id: 'take-observation.pulse.field-label',
    defaultMessage: 'Pulse',
    description: 'Pulse new observation input label',
  },
});

export const cholesterolMessages = defineMessages({
  header: {
    defaultMessage: 'Cholesterol check in',
    id: 'take-observation.cholesterol.header',
    description: 'Cholesterol new observation header',
  },
  subheader: {
    id: 'take-observation.cholesterol.subtitle',
    defaultMessage: `You can test your cholesterol with a home testing kit, or a test at your doctors. We track your Total cholesterol and HDL cholesterol. Once you have your results, please add them below:`,
    description: 'Cholesterol new observation subheader',
  },
  totalLabel: {
    id: 'take-observation.cholesterol.total.field-label',
    defaultMessage: 'Total cholesterol',
    description: 'Total cholesterol new observation label',
  },
  hdlLabel: {
    id: 'take-observation.cholesterol.hdl.field-label',
    defaultMessage: 'HDL cholesterol',
    description: 'HDL cholesterol new observation label',
  },
});

export const weightMessages = defineMessages({
  header: {
    defaultMessage: 'Weight check in',
    id: 'take-observation.weight.head1er',
    description: 'Weight new observation header',
  },
  subheader: {
    id: 'take-observation.weight.subtitle',
    defaultMessage: `To keep your weight trends as accurate as possible, step onto your scale with bare feet and light clothing. Always try to weigh yourself at the same time of day.`,
    description: 'Weight new observation subheader',
  },
  kilogramsLabel: {
    id: 'take-observation.weight.metric-system.kilograms.field-label',
    defaultMessage: 'Kilograms',
    description: 'Weight new observation kilograms label',
  },
  stoneLabel: {
    id: 'take-observation.weight.imperial-system.stone.field-label',
    defaultMessage: 'Stone',
    description: 'Weight new observation stone label',
  },
  poundLabel: {
    id: 'take-observation.weight.imperial-system.pounds.field-label',
    defaultMessage: 'Pounds',
    description: 'Weight new observation pound label',
  },
});

export const bloodPressureMessages = defineMessages({
  header: {
    id: 'take-observation.blood-pressure.header',
    defaultMessage: 'Add blood pressure',
    description: 'Blood Pressure new observation header',
  },
  systolicLabel: {
    id: 'take-observation.blood-pressure.systolic.field-label',
    defaultMessage: 'Systolic',
    description: 'Blood pressure new observation systolic label',
  },
  diastolicLabel: {
    id: 'take-observation.blood-pressure.diastolic.field-label',
    defaultMessage: 'Diastolic',
    description: 'Blood pressure new observation diastolic label',
  },
  diastolicErrorMessage: {
    id: 'take-observation.blood-pressure.diastolic.error.diastolic',
    description: 'Blood pressure new observation diastolic error message',
    defaultMessage: 'Diastolic reading must be lower than systolic reading',
  },
  systolicErrorMessage: {
    id: 'take-observation.blood-pressure.diastolic.error.systolic',
    description: 'Blood pressure new observation systolic error message',
    defaultMessage: 'Systolic reading must be higher than diastolic reading',
  },
});

export const fastingBloodGlucoseMessages = defineMessages({
  header: {
    id: 'take-observation.fasting-blood-glucose.header',
    defaultMessage: 'Fasting blood glucose',
    description: 'Fasting blood glucose new observation header',
  },
  subheader: {
    id: 'take-observation.fasting-blood-glucose.subtitle',
    defaultMessage: `Fasting blood glucose is a measure of your blood sugar level after an 8 hour fast. It is typically measured in the morning, and is often used to assess diabetes risk.`,
    description: 'Fasting blood glucose new observation subheader',
  },
  label: {
    id: 'take-observation.fasting-blood-glucose.label',
    defaultMessage: 'Fasting blood glucose',
    description: 'Fasting blood glucose new observation label',
  },
});

export const submitButton = defineMessage({
  id: 'create-observation.submit-button',
  defaultMessage: 'Continue',
  description: 'New observation continue button',
});
