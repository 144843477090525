import styled from 'styled-components';

import { spacing } from '@bt-healthcare/ui-toolkit';

import {
  ButtonWrapper as ButtonWrapperType,
  UpcomingEventSectionWrapperProps,
} from './types';

export const ButtonWrapper = styled.div<ButtonWrapperType>`
  display: block;
  width: 100%;
`;

export const ButtonsWrapper = styled.div<ButtonWrapperType>`
  display: flex;
  flex-direction: row;
  gap: ${spacing.s6};
  margin: 0 0;

  @media ${(props) => props.maxMobileWidth} {
    flex-direction: column-reverse;
    gap: ${spacing.s4};
  }
`;

export const UpcomingEventsTimeWrapper = styled.span`
  font-size: 24px;
  font-weight: 500;
`;

export const UpcomingEventsDateWrapper = styled.span`
  font-weight: 500;
`;

export const UpcomingEventSectionWrapper = styled.div<UpcomingEventSectionWrapperProps>`
  padding: ${(props) => props.padding};
  background: ${(props) => props.background};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
`;
