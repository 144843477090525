import { ReactComponent as CookieHero } from '../../assets/cookie-policy.svg';
import { ReactComponent as PrivacyHero } from '../../assets/privacy-policy.svg';
import { ReactComponent as TCHero } from '../../assets/tcs.svg';
import { PolicyType } from './types';

export const Hero = ({ type }: { type: PolicyType }) => {
  switch (type) {
    case PolicyType.COOKIE:
      return <CookieHero />;
    case PolicyType.PRIVACY:
      return <PrivacyHero />;
    default:
      return <TCHero />;
  }
};
