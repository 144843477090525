import { defineMessages } from 'react-intl';
import { ObservationComponentName } from '../../services/graphql';
import { DateFilter } from './types';

export const legendLabel = defineMessages({
  [ObservationComponentName.SystolicBloodPressure]: {
    id: 'graph.observation-component.blood-pressure.systolic',
    defaultMessage: 'Systolic',
  },
  [ObservationComponentName.DiastolicBloodPressure]: {
    id: 'graph.observation-component.blood-pressure.diastolic',
    defaultMessage: 'Diastolic',
  },
});

export const graph = defineMessages({
  noReadingsLastNDays: {
    id: 'observation-graph.data.no-readings-last-n-days',
    defaultMessage:
      'No readings to display {days, plural, one {for today.} other {from the last {days} days.}} <b>Submit a reading to view your trend</b>',
  },
  genericError: {
    id: 'observation-graph.data.failed-to-load',
    defaultMessage: 'Failed to load historical readings.',
  },
});

export const dateFilterIntl = defineMessages({
  [DateFilter.TODAY]: {
    id: 'observation-graph.date-filter.today',
    description: 'Observation graph toggle today option',
    defaultMessage: 'Today',
  },
  [DateFilter.WEEK]: {
    id: 'observation-graph.date-filter.week',
    description: 'Observation graph toggle week option',
    defaultMessage: 'Week',
  },
  [DateFilter.MONTHLY]: {
    id: 'observation-graph.date-filter.monthly',
    description: 'Observation graph toggle month option',
    defaultMessage: 'Month',
  },
});
