import { useEffect } from 'react';
import {
  Button,
  Header,
  Paragraph,
  Spacer,
  Stack,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Hero } from '../../assets/gp-unlinkage-success.svg';
import { Content, HeroContainer } from './styles';
import { useTracking } from '../../hooks/useTracking';
import { ROUTE } from '../../config/routes';
import { CtaContainer } from '../GPLinkageSuccess/styles';

export const GPUnlinkageSuccess = () => {
  const navigate = useNavigate();
  const { trackPageWithAppName } = useTracking();

  useEffect(() => {
    trackPageWithAppName('GP unlinkage - success');
  }, []);

  return (
    <Stack
      id="gp-unlinkage.success.container"
      justifyContent="center"
      alignItems="center"
      space="none"
    >
      <Content>
        <HeroContainer>
          <Hero width="100%" height="100%" />
        </HeroContainer>
        <Spacer size="s6" />
        <Header>
          <FormattedMessage
            id="gp-unlinkage.success.title"
            defaultMessage="Your practice has been unlinked from your account"
          />
        </Header>
        <Spacer size="s4" />
        <Paragraph textAlign="center">
          <FormattedMessage
            id="gp-unlinkage.success.body"
            defaultMessage="To access GP services again you will need to re-link your GP information. You can do this in the Account tab."
          />
        </Paragraph>
        <Spacer size="s4" />
        <CtaContainer>
          <Button
            id="back-to-account"
            onClick={() => navigate(ROUTE.ACCOUNT)}
            variant="primary"
          >
            <FormattedMessage
              id="gp-unlinkage.success.back-to-account.button"
              defaultMessage="Back to acccount"
            />
          </Button>
        </CtaContainer>
      </Content>
    </Stack>
  );
};
