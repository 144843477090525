import {
  device,
  fontCalcMixin,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const BodyText = styled.p`
  ${fontCalcMixin(fontSizes.base)}
`;

export const Container = styled.div`
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
  }
`;

export const Content = styled.div`
  @media ${device.tablet} {
    width: 410px;
    text-align: center;
  }
`;

export const CardContent = styled.div`
  text-align: left;
`;

export const SetupLaterContainer = styled.div`
  position: fixed;
  bottom: ${spacing.s6};
  left: 0;
  width: 100%;

  @media ${device.tablet} {
    position: initial;
    bottom: initial;
    left: initial;
    width: initial;
  }
`;
