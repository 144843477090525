import { useEffect } from 'react';
import {
  Col,
  colors,
  Header,
  Row,
  Spacer,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { path } from 'ramda';
import { ReactComponent as Tick } from '../../assets/tick.svg';
import { data } from './data';
import { HealthInformationType } from './types';
import { HealthInformationFormWithToggle } from '../../components/HealthInformationForm/HealthInformationFormWithToggle';
import { HealthInformationForm } from '../../components/HealthInformationForm/HealthInformationForm';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { toHealthInformationDefaultValues } from '../../mappers/healthInformation';
import { getHealthInformationNameFromType } from '../../components/HealthInformationForm/utils';
import { useTracking } from '../../hooks/useTracking';
import {
  pathNameToSiteTitle,
  pathNameToUppercase,
} from '../../formatters/string';
import {
  DescriptionContainer,
  BulletExplanation,
  HeaderContainer,
  BulletTitle,
} from './styles';

export const HealthInformationComponent = ({ type }: { type: string }) => {
  const intl = useIntl();
  const { trackPageWithAppName } = useTracking();
  const { userProfile: userProfileData } = useAuthUserProfile();
  const formattedType = pathNameToUppercase(type) as HealthInformationType;
  const { fields, unitSystems, header, description } = data(
    formattedType,
    intl
  )!;

  useEffect(() => {
    trackPageWithAppName(`Health Information - ${pathNameToSiteTitle(type)}`);
  }, []);

  const latestHealthInformation =
    userProfileData?.person?.attributes?.latestHealthInformation;
  const healthInformationName = getHealthInformationNameFromType(formattedType);
  const initialUnit: string | undefined =
    healthInformationName &&
    path([healthInformationName, 'unit'], latestHealthInformation);
  const initialValues =
    latestHealthInformation &&
    toHealthInformationDefaultValues(
      latestHealthInformation,
      formattedType,
      intl
    );
  return (
    <>
      <HeaderContainer>
        <Header>{header}</Header>
        {description && (
          <>
            <Spacer as="div" size="s6" />
            <DescriptionContainer>
              {description.map((item) => {
                if (item.type === 'subheader')
                  return (
                    <Row>
                      <Col from={1}>
                        <Text color={colors.grey.grey08}>{item.text}</Text>
                      </Col>
                    </Row>
                  );
                if (item.type === 'bullet')
                  return (
                    <Row>
                      <Col from={1}>
                        <Tick />
                        <BulletTitle>{item.title}</BulletTitle>
                        {item?.explanation && (
                          <Row>
                            <Col from={1}>
                              <BulletExplanation>
                                {item.explanation}
                              </BulletExplanation>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  );
                return null;
              })}
            </DescriptionContainer>
          </>
        )}
      </HeaderContainer>
      {unitSystems ? (
        <HealthInformationFormWithToggle
          fields={fields}
          type={formattedType}
          unitSystems={unitSystems}
          initialUnit={initialUnit}
          {...(initialValues && { initialValues })}
        />
      ) : (
        <HealthInformationForm
          fields={fields}
          type={formattedType}
          {...(initialValues && { initialValues })}
        />
      )}
    </>
  );
};

export const HealthInformation = () => {
  const { type } = useParams<{ type: string }>();
  if (!type) return null;

  return <HealthInformationComponent type={type} />;
};
