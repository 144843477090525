import { omit } from 'ramda';
import { UNIT_SEARCH_PARAM_NAME } from './data';
import { CreateObservationFormRHF } from './types';
import { ObservationType } from '../../services/graphql';

export const searchParamObjectFromSearchParams = (
  searchParams: URLSearchParams
) => Object.fromEntries(searchParams.entries());

export const urlSearchParamsToObservationFormInput = (
  searchParams: URLSearchParams
) => {
  const searchParamsObject = searchParamObjectFromSearchParams(searchParams);
  const searchParamsCreatedObservations: CreateObservationFormRHF = omit(
    [UNIT_SEARCH_PARAM_NAME],
    Object.entries(searchParamsObject).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: Number(value) }),
      {}
    )
  );
  const searchParamsUnit: string | undefined =
    searchParamsObject[UNIT_SEARCH_PARAM_NAME];
  return { searchParamsUnit, searchParamsCreatedObservations };
};

export const hasGuide = (type: ObservationType) =>
  ObservationType.BloodPressure === type;
