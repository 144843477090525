import { Link } from 'react-router-dom';
import { colors, fontSizes, Text } from '@bt-healthcare/ui-toolkit';
import { SettingCardProps } from './types';
import { StyledRequestCard, TitleWrapper, Value } from './styles';

const titleFontSize = {
  small: fontSizes.xs,
  large: fontSizes.sm,
};

const valueFontSize = {
  small: fontSizes.sm,
  large: fontSizes.base,
};

export const SettingCard = ({
  title,
  values,
  id,
  path,
  variation = 'large',
}: SettingCardProps) => (
  <Link to={path} rel="noopener noreferrer">
    <StyledRequestCard
      data-testid={id}
      id={id}
      icon={values ? 'Edit' : 'ChevronRight'}
      cardTitle={
        <TitleWrapper data-testid={`wrapper-${id}`}>
          <Text
            color={values ? colors.grey.grey08 : colors.grey.grey10}
            fontSize={titleFontSize[variation]}
            data-testid={`title-${id}`}
          >
            {title}
          </Text>
          {values?.map((value) => (
            <Value
              fontSize={valueFontSize[variation]}
              key={value}
              data-testid={`value-${id}-${value}`}
            >
              {value}
            </Value>
          ))}
        </TitleWrapper>
      }
    />
  </Link>
);
