import styled from 'styled-components';

export const GradientBackground = styled.div`
  height: 100%;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background: linear-gradient(
    359deg,
    rgba(242, 244, 249, 0.1) -44.13%,
    rgba(225, 224, 245, 0) -1.3%,
    rgba(161, 151, 228, 0.1) 21.12%,
    rgba(71, 46, 205, 0.1) 37.46%,
    rgba(82, 26, 185, 0.1) 83.18%,
    rgba(85, 20, 180, 0.1) 107.21%
  );
`;
