import { IntlShape } from 'react-intl';
import { bloodPressureIntl } from './intl';
import { ReactComponent as Relax } from '../../assets/guideRelax.svg';
import Arm from '../../assets/guideArm.png';
import { ReactComponent as Leg } from '../../assets/guideLeg.svg';
import { ReactComponent as Reading } from '../../assets/guideReading.svg';

const ArmImg = () => <img src={Arm} alt="arm" height="100%" width="100%" />;

export const bloodPressure = (intl: IntlShape) => ({
  header: intl.formatMessage(bloodPressureIntl.header),
  button: intl.formatMessage(bloodPressureIntl.button),
  items: [
    {
      icon: Relax,
      title: intl.formatMessage(bloodPressureIntl.hints.relax.title),
      description: intl.formatMessage(
        bloodPressureIntl.hints.relax.description
      ),
    },
    {
      icon: Leg,
      title: intl.formatMessage(bloodPressureIntl.hints.dontCrossLegs.title),
      description: intl.formatMessage(
        bloodPressureIntl.hints.dontCrossLegs.description
      ),
    },
    {
      icon: Reading,
      title: intl.formatMessage(bloodPressureIntl.hints.takeReading.title),
      description: intl.formatMessage(
        bloodPressureIntl.hints.takeReading.description
      ),
    },
    {
      icon: ArmImg,
      title: intl.formatMessage(bloodPressureIntl.hints.sameArm.title),
      description: intl.formatMessage(
        bloodPressureIntl.hints.sameArm.description
      ),
    },
  ],
});

export const getData = (intl: IntlShape) => bloodPressure(intl);
