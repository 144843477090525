import { colors, Text } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../config/routes';
import {
  Bla,
  FooterContent,
  FooterStyling,
  PublicLayoutFooterWrapper,
} from './styles';
import { ReactComponent as Logo } from '../../assets/purpleLogo.svg';

export const PublicLayoutFooter = () => (
  <PublicLayoutFooterWrapper>
    <Link to={ROUTE.PRIVACY}>
      <Text
        color={colors.grey.grey09}
        style={{
          textDecoration: 'underline',
        }}
      >
        <FormattedMessage
          id="public-content.privacy-policy"
          defaultMessage="Privacy policy"
        />
      </Text>
    </Link>
  </PublicLayoutFooterWrapper>
);

export const Footer = () => (
  <FooterStyling>
    <Bla>
      <FooterContent>
        <Logo />
        <Text color={colors.base.dark}>
          <FormattedMessage
            id="public-content.privacy-policy.paragraph"
            defaultMessage="HealthyYou is a product of "
          />
          <Link to={ROUTE.PRIVACY}>
            <Text
              color={colors.base.dark}
              style={{
                textDecoration: 'underline',
              }}
            >
              <FormattedMessage
                id="public-content.privacy-policy.bt-link"
                defaultMessage="Etc. at BT Group."
              />
            </Text>
          </Link>
        </Text>
      </FooterContent>
      <FooterContent>
        <Link to={ROUTE.PRIVACY}>
          <Text color={colors.base.dark}>
            <FormattedMessage
              id="public-content.privacy-policy.privacy-link"
              defaultMessage="Privacy policy"
            />
          </Text>
        </Link>
        <Text color={colors.grey.grey08}>
          <FormattedMessage
            id="public-content.privacy-policy.terms-link"
            defaultMessage="©2023 HealthyYou"
          />
        </Text>
      </FooterContent>
    </Bla>
  </FooterStyling>
);
