import { number, object, string } from 'yup';

export const schema = object().shape({
  organisationCode: string()
    .required()
    .label('Organisation Code')
    .min(1)
    .max(10),
  accountId: number()
    .typeError(() => 'Account ID must be numeric')
    .label('Account ID')
    .required()
    .max(9999999999, 'Account ID must be less than 10 digits'),
  linkageKey: string().label('Linkage Key').required().min(1).max(20),
});
