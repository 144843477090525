import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  UseInputFieldsForUnitToggleInterface,
  UseInputFieldsForUnitToggleProps,
} from './useInputFieldsForUnitToggle.types';

export const useInputFieldsForUnitToggle = <T extends { unitSystem: string }>({
  unitSystems,
  fields,
  unitsIntl,
  initialActiveUnit,
}: UseInputFieldsForUnitToggleProps<T>): UseInputFieldsForUnitToggleInterface<T> => {
  const [activeUnit, setActiveUnit] = useState<string>(
    initialActiveUnit || unitSystems[0]
  );
  const [activeFields, setActiveFields] = useState<T[]>(fields);
  const intl = useIntl();

  useEffect(() => {
    const newFields = fields.filter((field) => field.unitSystem === activeUnit);
    setActiveFields(newFields);
  }, [activeUnit]);

  const items = unitSystems.map((unit) => ({
    value: unit,
    displayName: intl.formatMessage(unitsIntl[unit]),
  }));

  return { activeFields, activeUnit, setActiveUnit, items };
};
