import { object } from 'yup';
import {
  convertRequirementsToSchema,
  PASSWORD_REQUIREMENT_LOWERCASE_CHARACTER,
  PASSWORD_REQUIREMENT_MINIMUM_CHARACTERS,
  PASSWORD_REQUIREMENT_NUMBER_CHARACTER,
  PASSWORD_REQUIREMENT_UPPERCASE_CHARACTER,
} from '../../components/PasswordValidator/utils';

export const schema = object().shape({
  ...convertRequirementsToSchema([
    PASSWORD_REQUIREMENT_MINIMUM_CHARACTERS,
    PASSWORD_REQUIREMENT_UPPERCASE_CHARACTER,
    PASSWORD_REQUIREMENT_LOWERCASE_CHARACTER,
    PASSWORD_REQUIREMENT_NUMBER_CHARACTER,
  ]),
});
