import {
  Spacer,
  colors,
  fontSizes,
  Stack,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { ReactNode } from 'react';
import {
  PublicContentCardHeaderContentWrapper,
  PublicContentCardHero,
} from './styles';
import { PublicContentCardHeaderPropsType } from './types';

export const PublicContentCardHeader = ({
  Hero,
  HeaderText,
  TextDescription,
}: PublicContentCardHeaderPropsType) => (
  <PublicContentCardHeaderContentWrapper>
    <PublicContentCardHero>{Hero}</PublicContentCardHero>
    <Spacer size="s8" />
    <Text
      color={colors.grey.grey10}
      as="h3"
      fontSize={fontSizes.h3}
      fontWeight={500}
      data-testid="public-content-card-header"
    >
      {HeaderText}
    </Text>
    <Spacer size="s4" />
    {TextDescription?.map((paragraph: ReactNode) => (
      <Stack
        key={paragraph?.toString()} // TODO: update key
        space="none"
        id="public-content-card-header-text-wrapper"
      >
        <Text color={colors.grey.grey08}>{paragraph}</Text>
        <Spacer size="s6" />
      </Stack>
    ))}
  </PublicContentCardHeaderContentWrapper>
);
