import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { useTracking } from '../../hooks/useTracking';

import { OnboardingPersonalDetailsForm } from './OnboardingPersonalDetailsForm';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';

export const OnboardingPersonalDetails = () => {
  const { trackPageWithAppName } = useTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
    trackPageWithAppName('Onboarding - personal-details');
  }, []);

  return (
    <>
      <Navbar />
      <GridPageCenteredSection>
        <PublicContentCard
          HeaderText={
            <FormattedMessage
              id="onboarding-personal-details.header"
              defaultMessage="Tell us a little about yourself"
            />
          }
          SubHeaderText={
            <FormattedMessage
              id="onboarding-personal-details.subtitle"
              defaultMessage="This helps us to personalise your account"
            />
          }
        >
          <OnboardingPersonalDetailsForm />
        </PublicContentCard>
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </>
  );
};
