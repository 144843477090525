import { useAnalytics } from '@bt-healthcare/ui-toolkit';
import { appName, basePageName, EMPTY_VALUE } from '../App.constants';
import { useAuthUserProfile } from './useAuthUserProfile';
import { TrackingProps } from './useTracking.types';

export const useTracking = (): TrackingProps => {
  const { userProfile: data } = useAuthUserProfile();
  const {
    trackPage: trackPageLoaded,
    trackAction,
    trackEvent,
  } = useAnalytics();
  const monitoredConditions =
    data?.wardPatient?.attributes.res_activeMonitoredConditions?.reduce(
      (acc, condition) => ({
        ...acc,
        [`${condition.condition}MonitoringStatus`]:
          condition.monitoringStatus?.status || EMPTY_VALUE,
        [`${condition.condition}TestStatus`]:
          condition.testStatus?.status || EMPTY_VALUE,
        [`${condition.condition}ConsultationStatus`]:
          condition.consultationStatus?.status || EMPTY_VALUE,
      }),
      {}
    );

  const trackingProperties = {
    appName,
    careSetting: data?.careSetting?.attributes.name || EMPTY_VALUE,
    admissionStatus:
      data?.careSettingPatient?.attributes.admissionStatus || EMPTY_VALUE,
    ...monitoredConditions,
  };

  const trackTapped = (buttonName: string, pageName?: string) =>
    trackEvent('Tapped', {
      buttonName,
      pageName: pageName ?? '',
      ...trackingProperties,
    });

  return {
    trackPage: (pageName: string) =>
      trackPageLoaded(pageName, window.location.href, trackingProperties),
    trackPageWithAppName: (pageName: string) =>
      trackPageLoaded(
        `${pageName}${basePageName}`,
        window.location.href,
        trackingProperties
      ),
    trackEvent: (eventName: string, eventType: 'click' | 'hover') =>
      trackAction(eventName, eventType, { ...trackingProperties }),
    trackTapped,
    track: trackEvent,
  };
};
