import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Stack,
  Checkbox,
  colors,
  Spacer,
  Button,
  Notification,
  Header,
  Text,
  SubHeader,
  spacing,
  Grid,
  Col,
} from '@bt-healthcare/ui-toolkit';
import { CustomLabel } from '../NotificationEmailPreferences/CustomLabel';
import { notificationEmailPreferences } from '../NotificationEmailPreferences/intl';
import { Form } from '../NotificationEmailPreferences/styles';
import { notificationSettings } from './intl';
import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { getPersonAttribute, getPersonId } from '../../selectors/person';
import {
  ContactExclusionPreferences,
  ContactExclusionPreferencesInput,
  useUpdateContactExclusionPreferencesMutation,
} from '../../services/graphql';
import { useContactExclusionPreferencesFormStateHook } from '../../hooks/useContactExclusionPreferencesFormState';
import { useTracking } from '../../hooks/useTracking';

export const NotificationSettingsPage = () => {
  const { trackPageWithAppName, trackEvent } = useTracking();

  const { userProfile: userData, refetch } = useAuthUserProfile();
  const contactExclusionPreferences = getPersonAttribute(
    userData,
    'contactExclusionPreferences'
  );

  useEffect(() => {
    trackPageWithAppName('Account - Notification Settings');
  }, []);

  const [updateContactExclusionPreferencesMutation, { error, loading, data }] =
    useUpdateContactExclusionPreferencesMutation();

  const {
    sms,
    reminders,
    setReminders,
    recommendations,
    setRecommendations,
    updates,
    setUpdates,
  } = useContactExclusionPreferencesFormStateHook(
    contactExclusionPreferences as ContactExclusionPreferences[]
  );

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    updateContactExclusionPreferencesMutation({
      variables: {
        input: {
          [ContactExclusionPreferences.ContactexclEmailAdvise]:
            !recommendations,
          personId: getPersonId(userData) as string,
          [ContactExclusionPreferences.ContactexclEmailLatestNewsUpdates]:
            !updates,
          [ContactExclusionPreferences.ContactexclEmailReminder]: !reminders,
          [ContactExclusionPreferences.ContactexclSmsApptReminderGp]: !sms,
        } as ContactExclusionPreferencesInput,
      },
    });
  };

  return (
    <Grid style={{ gridTemplateRows: 'auto 1fr', gap: spacing.s6 }}>
      <Col colStart={{ xxs: 1, md: 2 }} colEnd={{ xxs: 5, md: 7 }}>
        <Header>
          <FormattedMessage {...notificationSettings.title} />
        </Header>
        <Text color={colors.grey.grey08}>
          <FormattedMessage {...notificationSettings.description} />
        </Text>
      </Col>
      <Col colStart={{ xxs: 1, md: 2 }} colEnd={{ xxs: 5, md: 7 }}>
        <Form onSubmit={handleSubmit}>
          <Stack id="notification-settings-form" gap={spacing.s6}>
            <Stack
              id=""
              backgroundColor={colors.base.white}
              padding={spacing.s6}
              borderRadius={spacing.s2}
            >
              <SubHeader>
                <FormattedMessage
                  {...notificationSettings.formSectionEmailTile}
                />
              </SubHeader>
              <Spacer size="s6" />
              <Checkbox
                id="reminders"
                position="leftTop"
                checked={reminders}
                onClick={() => trackEvent('Reminders', 'click')}
                onChange={() => setReminders(!reminders)}
                label={
                  <CustomLabel
                    header={
                      <FormattedMessage
                        {...notificationEmailPreferences.checkboxRemindersTitle}
                      />
                    }
                    text={
                      <FormattedMessage
                        {...notificationEmailPreferences.checkboxRemindersDescription}
                      />
                    }
                  />
                }
              />
              <Spacer size="s6" />

              <Checkbox
                id="recommendations"
                position="leftTop"
                checked={recommendations}
                onClick={() => trackEvent('Recommendations', 'click')}
                onChange={() => setRecommendations(!recommendations)}
                label={
                  <CustomLabel
                    header={
                      <FormattedMessage
                        {...notificationEmailPreferences.checkboxRecommendationsTitle}
                      />
                    }
                    text={
                      <FormattedMessage
                        {...notificationEmailPreferences.checkboxRecommendationsDescription}
                      />
                    }
                  />
                }
              />
              <Spacer size="s6" />

              <Checkbox
                id="updates"
                position="leftTop"
                checked={updates}
                onClick={() => trackEvent('Update', 'click')}
                onChange={() => setUpdates(!updates)}
                label={
                  <CustomLabel
                    header={
                      <FormattedMessage
                        {...notificationEmailPreferences.checkboxUpdatesTitle}
                      />
                    }
                    text={
                      <FormattedMessage
                        {...notificationEmailPreferences.checkboxUpdatesDescription}
                      />
                    }
                  />
                }
              />
              {!reminders && !recommendations && !updates && (
                <>
                  <Spacer size="s6" />

                  <Notification type="softWarning">
                    <FormattedMessage
                      {...notificationEmailPreferences.notificationSoftWarning}
                    />
                  </Notification>
                </>
              )}
            </Stack>
            {data && (
              <Notification type="positive">
                <FormattedMessage
                  {...notificationEmailPreferences.notificationPositive}
                />
              </Notification>
            )}
            {error && (
              <Notification type="warning">
                <FormattedMessage
                  {...notificationEmailPreferences.notificationWarning}
                />
              </Notification>
            )}

            <Button
              id="notifications-email-preferences-form-submit-button"
              isLoading={!!loading}
              onClick={() => trackEvent('Save preferences', 'click')}
            >
              <FormattedMessage
                {...notificationEmailPreferences.submitButton}
              />
            </Button>
          </Stack>
        </Form>
      </Col>
    </Grid>
  );
};
