import { Spacer, Stack, SubHeader } from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';
import { AboutSubHeaderContainer, VersionLabel } from './styles';
import { about } from './intl';
import { LinkCard } from '../../components/Cards/LinkCard';
import { ROUTE } from '../../config/routes';
import { version } from '../../../package.json';

export const AboutSection = () => (
  <Stack space="s2" id="landp">
    <SubHeader>
      <AboutSubHeaderContainer>
        <FormattedMessage {...about.title} />
        <VersionLabel>
          <FormattedMessage {...about.version} values={{ version }} />
        </VersionLabel>
      </AboutSubHeaderContainer>
    </SubHeader>
    <Spacer size="s2" />
    <LinkCard
      title={<FormattedMessage {...about.termsAndConditions} />}
      path={ROUTE.TERMS_AND_CONDITIONS}
      dataTestId="terms-link"
      isTargetSelf={false}
      colorTitle="grey.grey10"
    />
    <LinkCard
      title={<FormattedMessage {...about.privacyPolicy} />}
      path={ROUTE.PRIVACY}
      dataTestId="privacy-link"
      isTargetSelf={false}
      colorTitle="grey.grey10"
    />
    <LinkCard
      title={<FormattedMessage {...about.cookiePolicy} />}
      path={ROUTE.COOKIES}
      dataTestId="cookie-policy-link"
      isTargetSelf={false}
      colorTitle="grey.grey10"
    />
  </Stack>
);
