import React from 'react';
import { CardProps } from '@bt-healthcare/ui-toolkit/dist/dts/components/Card/types';
import BaseCard from '../BaseCard';

export const BlueCard = ({ children, id, ...props }: CardProps) => (
  <BaseCard
    id={id}
    background="var(
    --Dark-gradient,
    linear-gradient(
    90deg,
    rgba(41, 121, 255, 0.12) 15.34%,
    rgba(50, 0, 138, 0.1) 82.23%
    ),
    #02062d
    )"
    {...props}
  >
    {children}
  </BaseCard>
);
