import { PropsWithChildren } from 'react';
import {
  Overlay,
  Content,
  StyledColumn,
  StyledGrid,
  StyledRow,
} from './styles';
import { OverlayBackground } from './types';

export const PageWithOverlayWrapper = ({
  children,
  variant,
}: PropsWithChildren<{ variant: OverlayBackground }>) => (
  <>
    <Overlay data-testid="page-overlay" variant={variant} />
    <StyledGrid>
      <StyledRow>
        <StyledColumn>
          <Content>{children}</Content>
        </StyledColumn>
      </StyledRow>
    </StyledGrid>
  </>
);
