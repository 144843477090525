import { PropsWithChildren } from 'react';
import {
  ButtonWrapper,
  ContentWrapper,
  FullWidthContainer,
  Header,
  StyledButton,
} from './styles';
import { Close } from './Close';
import { PageWithOverlayWrapper } from '../PageWithOverlayWrapper';
import { OverlayBackground } from '../PageWithOverlayWrapper/types';

const BluePageWrapper = ({ children }: PropsWithChildren) => (
  <PageWithOverlayWrapper variant={OverlayBackground.BLUE}>
    {children}
  </PageWithOverlayWrapper>
);

BluePageWrapper.Content = ContentWrapper;
BluePageWrapper.Bottom = ButtonWrapper;
BluePageWrapper.Close = Close;
BluePageWrapper.Button = StyledButton;
BluePageWrapper.Header = Header;
BluePageWrapper.FullWidthContainer = FullWidthContainer;

export default BluePageWrapper;
