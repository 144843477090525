import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'home.completed-tasks.subheader',
    defaultMessage: "Today's readings",
  },
  viewMore: {
    id: 'home.completed-tasks.view-more.button',
    defaultMessage: 'View more',
  },
  recommendationInvitation: {
    id: 'home.completed-tasks.recommendation-offer',
    defaultMessage:
      '<blue02>Discover personalised insights</blue02> about your  blood pressure.',
  },
  recommendationInComplete: {
    id: 'home.completed-tasks.recommendation.incomplete',
    defaultMessage:
      'As you missed more than a day, we’ve cancelled your blood pressure insights plan.',
  },
  recommendationComplete: {
    id: 'home.completed-tasks.recommendation.complete',
    defaultMessage:
      'Your personalised blood pressure insights are <blue02>available to view now!</blue02>',
  },
  recommendationInProgress: {
    id: 'home.completed-tasks.recommendation.inprogress',
    defaultMessage:
      '<blue02>Personalised blood pressure insights</blue02><br></br> <br></br>Remember to submit your readings each day, to get your health recommendations.',
  },
});
