import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Modal,
  ModalBody,
  Stack,
  Body,
  ModalFooter,
  Button,
  Notification,
  Text,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

import { maxMobileWidth } from '../../utils';

import { ButtonsWrapper } from './styles';
import { publicContentModal } from './intl';
import { ROUTE } from '../../config/routes';
import { SimpleModalProps } from './types';
import {
  ContactExclusionPreferencesInput,
  useUpdateContactExclusionPreferencesMutation,
} from '../../services/graphql';

export const PublicContentModal = ({
  modalOpen,
  handleClose,
}: SimpleModalProps) => {
  const navigate = useNavigate();

  const { personId } = useParams<{ personId: string }>();

  const [updateContactExclusionPreferencesMutation, { data, loading, error }] =
    useUpdateContactExclusionPreferencesMutation();

  const handleUnsubscribe = () => {
    updateContactExclusionPreferencesMutation({
      variables: {
        input: {
          CONTACTEXCL_EMAIL_ADVISE: true,
          personId: personId as string,
          CONTACTEXCL_EMAIL_LATEST_NEWS_UPDATES: true,
          CONTACTEXCL_EMAIL_REMINDER: true,
        } as ContactExclusionPreferencesInput,
      },
      context: {
        isAnonymous: true,
      },
    });
  };

  useEffect(() => {
    if (data) {
      navigate(ROUTE.UNSUBSCRIBED);
    }
  }, [data]);

  return (
    <Modal
      onClose={handleClose}
      isModalOpen={modalOpen}
      id="public-content-modal"
    >
      <ModalBody>
        <Stack space="s4" id="confirmation" alignItems="center">
          <Text
            color={colors.grey.grey09}
            as="h3"
            fontSize={fontSizes.h3}
            style={{
              display: 'block',
              textAlign: 'center',
            }}
            fontWeight={500}
          >
            <FormattedMessage {...publicContentModal.header} />
          </Text>
          <Body style={{ textAlign: 'center' }}>
            <Text color={colors.grey.grey09}>
              <FormattedMessage {...publicContentModal.textDescription} />
            </Text>
          </Body>

          {error && (
            <Notification type="softWarning">
              <FormattedMessage {...publicContentModal.error} />
            </Notification>
          )}
        </Stack>
        <ModalFooter>
          <ButtonsWrapper maxMobileWidth={maxMobileWidth}>
            <Button
              id="submit"
              onClick={handleUnsubscribe}
              variant="secondary"
              isLoading={loading}
            >
              <FormattedMessage {...publicContentModal.secondaryCTA} />
            </Button>
            <Button id="cancel" onClick={handleClose} variant="primary">
              <FormattedMessage {...publicContentModal.primaryCTA} />
            </Button>
          </ButtonsWrapper>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};
