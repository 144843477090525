import { useEffect, useState } from 'react';

import {
  colors,
  Spacer,
  Stack,
  Text,
  Button,
  Portal,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';

import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { EmailPreferencesForm } from './EmailPreferencesForm';
import { PublicContentModal } from '../../components/Modal/PublicContentModal';
import { useTracking } from '../../hooks/useTracking';
import { notificationEmailPreferences } from './intl';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';

export const EmailPreferences = () => {
  const { trackPageWithAppName, trackEvent } = useTracking();
  const [toggleDeclineModal, setToggleDeclineModal] = useState(false);

  useEffect(() => {
    trackPageWithAppName('Notification - email-preferences');
  }, []);

  return (
    <>
      <Navbar />
      <GridPageCenteredSection>
        <PublicContentCard
          HeaderText={
            <FormattedMessage {...notificationEmailPreferences.header} />
          }
          SubHeaderText={
            <FormattedMessage {...notificationEmailPreferences.subtitle} />
          }
        >
          <EmailPreferencesForm />
          <Spacer size="s6" />
          <Stack id="" width="100%">
            <Text color={colors.grey.grey08} fontWeight="bold">
              <FormattedMessage
                {...notificationEmailPreferences.descriptionTitle}
              />
            </Text>
            <Text color={colors.grey.grey08}>
              <FormattedMessage {...notificationEmailPreferences.description} />
            </Text>
            <Spacer size="s6" />

            <Button
              id="notifications-email-preferences-form-unsubscribe-button"
              variant="secondary"
              onClick={() => {
                trackEvent('Unsubscribe', 'click');
                setToggleDeclineModal(true);
              }}
            >
              <FormattedMessage
                {...notificationEmailPreferences.unsubscribeButton}
              />
            </Button>
          </Stack>
          <Portal>
            <PublicContentModal
              modalOpen={toggleDeclineModal}
              handleClose={() => setToggleDeclineModal(false)}
            />
          </Portal>
        </PublicContentCard>
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </>
  );
};
