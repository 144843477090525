import { device, fontCalcMixin, fontSizes } from '@bt-healthcare/ui-toolkit';

import styled from 'styled-components';

export const BodyText = styled.p`
  ${fontCalcMixin(fontSizes.base)};
  text-align: center;
`;

export const HeaderText = styled.p`
  ${fontCalcMixin(fontSizes.h3)};
  text-align: center;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;

  @media ${device.desktop} {
    width: 295px;
  }
`;
