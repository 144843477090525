import { AnySchema, object } from 'yup';

export type PrepareSchemaField = {
  name: string;
  validationRule: AnySchema;
};
export const prepareSchema = <T extends PrepareSchemaField>(fields: T[]) =>
  object(
    fields.reduce((prev, currentField) => {
      const fieldName = currentField.name;
      const result = prev;
      result[fieldName] = currentField.validationRule;

      return result;
    }, {} as { [k: string]: AnySchema })
  );
