import type { Context, Dispatch } from 'react';
import { createContext, useContext } from 'react';

import type { AppActions, State } from '../reducers/types';

export const AppContext = createContext<State | undefined>(undefined);
export const AppDispatchContext = createContext<
  Dispatch<AppActions> | undefined
>(undefined);

const useAppContext = <T,>(ctx: Context<T | undefined>) => {
  const context = useContext(ctx);
  if (!context) {
    throw Error('Context but be wrapped in a Provider');
  }
  return context;
};

export const useAppState = () => useAppContext(AppContext);

export const useAppDispatch = () => useAppContext(AppDispatchContext);
