import { Tooltip as VisxTooltip } from '@visx/tooltip';

import { useIntl } from 'react-intl';
import { DataPointType, TooltipProps } from './types';
import { styles, TooltipText } from './styles';
import { getTooltipDate, formatObservationDataForTooltip } from './utils';
import {
  TOOLTIP_LEFT_OFFSET,
  TOOLTIP_TOP_OFFSET,
} from '../ObservationGraph/config';
import { dailyAvgMessage } from './intl';

export const GraphTooltip = ({
  tooltipOpen,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  observationType,
  dataPointType,
  offsetTop = TOOLTIP_TOP_OFFSET,
}: TooltipProps) => {
  const intl = useIntl();

  if (!tooltipOpen || !tooltipData) return null;

  return (
    tooltipData && (
      <VisxTooltip
        key={tooltipData.date}
        top={tooltipTop}
        left={tooltipLeft}
        offsetTop={offsetTop}
        offsetLeft={TOOLTIP_LEFT_OFFSET}
        style={styles}
      >
        {dataPointType === DataPointType.DAILY_AVERAGE && (
          <TooltipText>{intl.formatMessage(dailyAvgMessage)}</TooltipText>
        )}
        <TooltipText primary>
          {formatObservationDataForTooltip(tooltipData, observationType, intl)}
        </TooltipText>
        <TooltipText>{getTooltipDate(tooltipData, dataPointType)}</TooltipText>
      </VisxTooltip>
    )
  );
};
